/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React  from 'react';
import { useParams } from 'react-router-dom';
import DiscoverResultsContainer from "./DiscoverResultsContainer";

export default function DiscoverCampingState() {
    const { state } = useParams();
    const stateName = decodeURIComponent(state).replace(/\+/g, ' ').replace(/-/g, ' ')
    return <DiscoverResultsContainer
        titlePrefix={`Discover Camping in `}
        location={null}
        state={stateName} />
}
