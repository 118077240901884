/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * SimpleMap is a simple template to
 * render a single data source
 * recommended for maps with cards on the side
 */

import React, { useRef, useEffect, useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import mask from '@turf/mask';
import * as turf from '@turf/turf';
import PropTypes from 'prop-types';
import { maxBy, isEqual } from 'lodash';
import { useIntersect, useWindowSize } from 'sarsaparilla';
import { createEmptyCollection, getBiggestPolygon } from '../helpers/utils';
import { getLayersPerService } from '../layers/layers';
import {
    getBoundaryContent,
    getPopupContentByService,
    getTrailsContent,
    getEvContent,
} from '../helpers/mapPopup';
import { BOUNDARY_TYPES } from '../../../ui-search/src/constants';

import {
    addMapControls,
    addMapSpecialControls,
    getControlsPerService,
    getSpecialControlsPerService,
} from '../controls/controls';
import {
    createRangeSlider,
    cursorToDefault,
    cursorToPointer,
    fitMapBounds,
    getLayerIconsPerId,
    trackIsMapExtended,
    updateMapPosition,
} from '../helpers/common';
import SimpleStaticMapImage from './SimpleStaticMapImage';
import { evAdapters, evLevels, evNetworks, zoomStops } from '../constants';
import useMapPDF from '../helpers/useMapPDF';
import LoadingSpinner from '../components/LoadingSpinner';
import useLayerPanel from '../helpers/useLayerPanel';

function SimpleMap(props) {
    const mapContainer = useRef(props.mapContainer || 'map');
    const map = useRef(null);
    const defaultPopup = useRef();
    const renderedSiteIds = useRef([]);
    const isSelectedFromClick = useRef(false);
    const basicControls = useRef(null);
    const basicControlsList = useRef(getControlsPerService(props.service));
    const hasDataRef = useRef(false);
    const isSliderDragged = useRef(false);
    const emptyCollection = turf.featureCollection([]);
    const mapFacilitiesToDisplay = useRef(getLayerIconsPerId());
    const mapEvFilters = useRef({
        ...evLevels,
        ...evAdapters,
        ...evNetworks,
    });

    const [geojson, setGeojson] = useState(props.geojson || emptyCollection);
    const [layerSetup, setLayerSetup] = useState({});
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(props.selectedFeatureId);
    const [shouldFitMapBounds, setShouldFitMapBounds] = useState(false);
    const [shouldDisplayStaticImage, setShouldDisplayStaticImage] = useState(false);
    const [boundary, setBoundary] = useState(null);
    const [trails, setTrails] = useState(null);
    const [ev, setEv] = useState(null);
    const [isMapExtended, setIsMapExtended] = useState(false);
    const [radiusGeojson, setRadiusGeojson] = useState(emptyCollection);
    const [nearbyRadius, setNearbyRadius] = useState('16'); // 10 miles. 100 miles > 161 km
    const [hasTrails, setHasTrails] = useState(false);
    const [selectedTrailId, setSelectedTrailId] = useState(null);
    const [hoveredTrailId, setHoveredTrailId] = useState(null);
    const [hasEv, setHasEv] = useState(false);
    const [selectedEvId, setSelectedEvId] = useState(null);
    const [iconsToDisplay, setIconsToDisplay] = useState([]);

    const [ref, entry] = useIntersect({ rootMargin: '100px' });
    const { width } = useWindowSize();

    useMapPDF({
        map,
        basicControls,
        service: props.service,
        subtitle: props.featureName,
    });

    // Simple map allows sources below
    const sourceName = 'mapSource';
    const maskSourceName = 'maskSourceName';
    const boundaryLineSourceName = 'boundaryLineSourceName';
    const boundarySourceName = 'boundarySourceName';
    const radiusLineSourceName = 'radiusLineSourceName';
    const radiusSourceName = 'radiusSourceName';
    const trailsSourceName = 'trailsSourceName';
    const evSourceName = 'evSourceName';

    const updateReservableLocationsLayer = (e) => {
        const checkedFacility = { ...mapFacilitiesToDisplay.current[e.target.id] };
        if (!checkedFacility) return;
        mapFacilitiesToDisplay.current = {
            ...mapFacilitiesToDisplay.current,
            [e.target.id]: {
                ...mapFacilitiesToDisplay.current[e.target.id],
                shouldDisplay: e.target.checked,
            },
        };

        const newIconsToDisplay = Object.values(mapFacilitiesToDisplay.current).reduce((acc, item) => {
            if (item?.shouldDisplay) return [...acc, ...item.icons];
            return acc;
        },[]);

        setIconsToDisplay(newIconsToDisplay);
    };

    const updateEvLayer = (e) => {
        const targetId = e?.target?.id;
        const selectedFilter = mapEvFilters.current[targetId];

        if (!selectedFilter) return;

        mapEvFilters.current = {
            ...mapEvFilters.current,
            [targetId]: {
                ...selectedFilter,
                shouldDisplay: e?.target?.checked,
            },
        };

        const filteredEv = props?.ev?.features?.filter((evFeature) => {
            const { level, adapter, network } = evFeature?.properties || {};

            const standardizedAdapter = adapter?.toLowerCase();
            let featureAdapter =
                standardizedAdapter === 'tesla' ? 'tesla-adapter' : standardizedAdapter;
            if (!mapEvFilters.current[featureAdapter]) featureAdapter = 'other-adapter';

            const standardizedNetwork = network?.toLowerCase()?.split(' ')?.join('-');
            let featureNetwork =
                standardizedNetwork === 'tesla' ? 'tesla-network' : standardizedNetwork;
            if (!mapEvFilters.current[featureNetwork]) featureNetwork = 'other-network';

            if (
                mapEvFilters.current[level].shouldDisplay &&
                mapEvFilters.current[featureAdapter].shouldDisplay &&
                mapEvFilters.current[featureNetwork].shouldDisplay
            ) {
                return true;
            }

            return false;
        });

        const filteredEvCollection = turf.featureCollection(filteredEv);
        if (!isEqual(filteredEv, ev)) setEv(filteredEvCollection);
    };

    useLayerPanel({
        map,
        basicControls,
        hasTrails,
        hasEv,
        updateReservableLocationsLayer,
        updateEvLayer,
        mapEvFilters,
        mapFacilitiesToDisplay,
        iconsToDisplay
    });

    useEffect(() => {
        if (map.current) map.current.resize();
    }, [width]);

    // Update service setup params
    useEffect(() => {
        const layerParams = {
            service: props.service,
            source: sourceName,
            maskSource: maskSourceName,
            boundaryLineSource: boundaryLineSourceName,
            boundarySource: boundarySourceName,
            radiusLineSource: radiusLineSourceName,
            radiusSource: radiusSourceName,
            trailsSource: trailsSourceName,
            evSource: evSourceName,
        };
        setLayerSetup(getLayersPerService(layerParams));
    }, [props.service]);

    useEffect(() => {
        if (!map.current || !basicControlsList.current?.gesture) return;
        map.current._cooperativeGestures = isMapExtended
            ? false
            : basicControlsList.current?.gesture;
    }, [isMapExtended]);

    useEffect(() => {
        if (shouldFitMapBounds) {
            fitMapBounds(map, geojson, props.zoom);
            setShouldFitMapBounds(false);
        }
    }, [shouldFitMapBounds]);

    useEffect(() => {
        const feature = geojson?.features?.find(
            (feat) =>
                feat?.properties?.id === props.selectedFeatureId ||
                feat?.properties?.featureId === props.selectedFeatureId ||
                feat?.properties.entityId === props.selectedFeatureId
        );

        const hasId =
            props.selectedFeatureId &&
            renderedSiteIds.current.includes(props.selectedFeatureId);
        if (props.config?.jumpMapToPin && feature && !hasId) {
            updateMapPosition({
                map,
                center: feature.geometry.coordinates,
            });
        }
        setSelectedFeature(feature);
        if (feature) isSelectedFromClick.current = false;
    }, [props.selectedFeatureId]);

    useEffect(() => {
        if (!isEqual(props.boundary, boundary)) setBoundary(props.boundary);
    }, [props.boundary]);

    useEffect(() => {
        if (!isEqual(props.trails, trails)) setTrails(props.trails);
    }, [props.trails]);

    useEffect(() => {
        if (props?.ev?.features?.length) setHasEv(true);
        else setHasEv(false);
        if (!isEqual(props.ev, ev)) {
            setEv(props.ev);
            updateEvLayer();
        }
    }, [props.ev]);

    useEffect(() => {
        if (!map.current) return;

        if (boundary && props.config.shouldFitBoundary) {
            const isAlaska = boundary.properties?.name === 'Alaska';
            fitMapBounds(
                map,
                turf.featureCollection([
                    isAlaska ? getBiggestPolygon(boundary) : boundary,
                ])
            );
        }
        const emptyBoundary = emptyCollection;
        const isFacility = boundary?.properties?.type === BOUNDARY_TYPES.facility;
        const boundaryMask = boundary && !isFacility ? mask(boundary) : emptyBoundary;

        const maskSource = map.current.getSource(maskSourceName);
        const boundaryLineSource = map.current.getSource(boundaryLineSourceName);
        const boundarySource = map.current.getSource(boundarySourceName);

        if (boundarySource)
            boundarySource.setData(isFacility && boundary ? boundary : emptyBoundary);
        if (boundaryLineSource) boundaryLineSource.setData(boundary || emptyBoundary);
        if (maskSource) maskSource.setData(boundaryMask);
    }, [boundary, isMapLoaded]);

    useEffect(() => {
        if (!map.current) return;
        const source = map.current.getSource(trailsSourceName);
        if (source && (!trails || !trails?.features?.length)) {
            source.setData(emptyCollection);
            setHasTrails(false);
            return;
        }
        if (source) source.setData(trails);
        setHasTrails(true);
    }, [trails, isMapLoaded]);

    useEffect(() => {
        if (!map.current) return;
        const source = map.current.getSource(evSourceName);
        if (source && (!ev || !ev?.features?.length)) {
            source.setData(emptyCollection);
            return;
        }
        if (source) source.setData(ev);
    }, [ev, isMapLoaded]);

    const buildRadiusGeojson = useCallback(
        (radius) => {
            if (!geojson?.features?.length) return emptyCollection;
            const circleGeojson = radius
                ? turf.circle(props.radiusCenter, radius, { unit: 'kilometers' })
                : {};
            return turf.featureCollection([circleGeojson]);
        },
        [props.radiusCenter]
    );

    const removeRadiusLayer = () => {
        setRadiusGeojson(emptyCollection);
        props.specialMapControls?.current?.searchNearbyFilter?.hide();
    };

    const updateRadius = (radiusProps) => {
        if (isSliderDragged.current) return;
        const { zoom } = radiusProps;
        if (!zoom) return;
        const newZoom = Math.round(zoom);
        const newRadius = zoom >= 13 ? 1 : zoomStops[newZoom];
        const sliderControl =
            props.specialMapControls.current?.searchNearbyFilter?.getElementById?.(
                'slider-control'
            );

        if (sliderControl) {
            const label = sliderControl.getElementsByTagName('output')[0];
            props.specialMapControls.current?.searchNearbyFilter.updateElementProps({
                element: label,
                key: 'innerHTML',
                value: `: ${newRadius}mi`,
            });

            const slider = sliderControl.getElementsByTagName('input')[0];
            props.specialMapControls.current?.searchNearbyFilter.updateElementProps({
                element: slider,
                key: 'value',
                value: newRadius,
            });
        }

        setNearbyRadius(newRadius);
    };

    const isRadiusVisible = () => {
        const hasBoundary = !!boundary?.geometry;
        let shouldUpdateRadius = !hasBoundary && hasDataRef.current;
        if (props.searchTerm?.isFacility || props.searchTerm?.isState)
            shouldUpdateRadius = false;
        return shouldUpdateRadius;
    };

    const getRadiusGeojson = () => {
        if (!props.radiusCenter) {
            removeRadiusLayer();
            return;
        }

        if (!map.current || !props?.specialMapControls.current?.searchNearbyFilter)
            return;

        let builtRadiusGeojson = emptyCollection;

        if (!isRadiusVisible()) {
            if (radiusGeojson?.features?.length) setRadiusGeojson(builtRadiusGeojson);
            props.specialMapControls.current.searchNearbyFilter.hide();
            return;
        }

        builtRadiusGeojson = buildRadiusGeojson(parseFloat(nearbyRadius));
        props.specialMapControls.current.searchNearbyFilter.show();

        if (!isEqual(builtRadiusGeojson, radiusGeojson))
            setRadiusGeojson(builtRadiusGeojson);
    };

    useEffect(() => {
        getRadiusGeojson();
    }, [props.radiusCenter, geojson, boundary, nearbyRadius, isMapLoaded]);

    useEffect(() => {
        if (!map.current) return;
        if (radiusGeojson && props.config?.shouldFitRadius) {
            fitMapBounds(map, turf.featureCollection(radiusGeojson));
        }

        const radiusSource = map.current.getSource(radiusSourceName);
        const radiusLineSource = map.current.getSource(radiusLineSourceName);
        if (radiusSource) radiusSource.setData(radiusGeojson || emptyCollection);
        if (radiusLineSource) radiusLineSource.setData(radiusGeojson || emptyCollection);
    }, [radiusGeojson]);

    const addMapSources = () => {
        if (!map.current) return;
        map.current.addSource(
            sourceName,
            createEmptyCollection({
                cluster: props.config?.cluster || false,
            })
        );

        const overlayList = [
            maskSourceName,
            boundaryLineSourceName,
            boundarySourceName,
            radiusSourceName,
            radiusLineSourceName,
            trailsSourceName,
        ];

        overlayList.forEach((overlay) => {
            map.current.addSource(overlay, createEmptyCollection({ cluster: false }));
        });

        const clusterOverlayList = [evSourceName];
        clusterOverlayList.forEach((overlay) => {
            map.current.addSource(
                overlay,
                createEmptyCollection({ cluster: true, clusterMaxZoom: 13 })
            );
        });
    };

    const addMapLayers = () => {
        if (!map.current) return;

        // eslint-disable-next-line no-unused-expressions
        layerSetup?.layers.forEach((layer) => {
            if (layer.firstLayer) map.current.addLayer(layer, layer.firstLayer);
            else map.current.addLayer(layer);
        });
    };

    const removePopup = () => {
        defaultPopup.current?.remove();
        defaultPopup.current = null;
    };

    const setPopup = (feature) => {
        if (!map.current) return;
        if (defaultPopup.current) removePopup();

        if (!feature || feature?.geometry?.type !== 'Point') {
            if (props.selectedEvent) props.selectedEvent(null);
            return;
        }

        const popup = new mapboxgl.Popup({
            maxWidth: 'none',
            closeButton: false,
            focusAfterOpen: false,
        });

        const isBoundary = feature?.properties?.isBoundary;
        const isTrail = feature?.properties?.isTrail;
        const isEv = feature?.properties?.isEv;
        let popupContent = isBoundary
            ? getBoundaryContent(feature)
            : getPopupContentByService(feature, props.service);
        if (isBoundary) popupContent = getBoundaryContent(feature);
        if (isTrail) popupContent = getTrailsContent(feature);
        if (isEv) popupContent = getEvContent(feature);
        if (!popupContent) return;

        if (!props.isMobile) {
            popup
            .setDOMContent(popupContent)
            .setLngLat(feature.geometry?.coordinates)
            .addTo(map.current);
        }

        map.current.panBy([0, 0]);

        defaultPopup.current = popup;

        if (props.selectedEvent && isSelectedFromClick.current && !isEv && !isBoundary) {
            let featParam =
                feature.properties?.id ||
                feature.properties?.featureId ||
                feature.properties?.entityId;
            if (props.config.sendSelectedFeature) featParam = feature;
            const featureType = isTrail ? 'trail' : '';
            props.selectedEvent(featParam, featureType);
        }

        if (isBoundary) {
            props.selectedEvent(null);
        }
    };

    useEffect(() => {
        if (!selectedFeature) {
            if (selectedTrailId) setSelectedTrailId(null);
            if (selectedEvId) setSelectedEvId(null);
            setPopup(null);
            return;
        }

        const { source, clickedPoint, properties } = selectedFeature || {};

        const isBoundary = source === boundarySourceName;
        const isTrail = source === trailsSourceName;
        const isEv = source === evSourceName;

        if (clickedPoint && (isBoundary || isTrail || isEv)) {
            const { lat, lng } = clickedPoint;
            const { entity_id } = properties || {};

            if (isTrail) {
                if (!isEqual(entity_id, selectedTrailId)) setSelectedTrailId(entity_id);
            } else if (selectedTrailId) {
                setSelectedTrailId(null);
            }

            if (isEv) {
                if (!isEqual(entity_id, selectedEvId)) setSelectedEvId(entity_id);
            } else if (selectedEvId) {
                setSelectedEvId(null);
            }

            const pointProps = {
                ...properties,
                isBoundary,
                isTrail,
                isEv,
            };
            const point = turf.point([lng, lat], pointProps);
            setPopup(point);
            return;
        }
        setPopup(selectedFeature);
    }, [selectedFeature]);

    const updateRenderedIds = () => {
        if (map.current) {
            const layers = ['pointsLayer', 'campsitesLayer', 'mapSourceLayer'].filter(
                (l) => map.current.getLayer(l)
            );
            const sites = map.current.queryRenderedFeatures({ layers });
            if (sites?.length) {
                const bounds = map.current.getBounds();
                const bboxPolygon = turf.bboxPolygon([
                    bounds._sw.lng,
                    bounds._sw.lat,
                    bounds._ne.lng,
                    bounds._ne.lat,
                ]);
                const ids = sites
                    .filter((f) => turf.booleanWithin(f, bboxPolygon))
                    .map((f) => f.properties.id || f.properties.entityId);
                renderedSiteIds.current = ids;
            }
        }
    };

    useEffect(() => {
        if (props.onMouseMove && !selectedTrailId) props.onMouseMove(hoveredTrailId);
    }, [hoveredTrailId]);

    const addMapEvents = () => {
        map.current.on('load', () => {
            setIsMapLoaded(true);

            addMapSources();
            addMapLayers();

            // eslint-disable-next-line no-unused-expressions
            layerSetup?.layers?.forEach((layer) => {
                if (layer.isHiddenByDefault) {
                    map.current.setLayoutProperty(layer.id, 'visibility', 'none');
                }

                if (layer?.ignoreSelection) return;
                map.current.on('mouseenter', layer.id, () => cursorToPointer(map));
                map.current.on('mouseleave', layer.id, () => cursorToDefault(map));
            });

            map.current.on('moveend', () => {
                if (props.onMoveEnd) props.onMoveEnd(map?.current);
                if (props.config?.jumpMapToPin) updateRenderedIds();
            });

            map.current.on('movestart', () => {
                if (props.onMoveStart) props.onMoveStart(map?.current);
                if (props.config?.closePopupOnMove) removePopup();
            });

            map.current.on('dragend', () => {
                if (props.onDragEnd) props.onDragEnd({ isMapDragged: true });
            });

            map.current.on('zoomend', () => {
                if (props.onZoomEnd) props.onZoomEnd();

                if (props.service !== 'navigation') return;
                if (isRadiusVisible()) updateRadius({ zoom: map.current?.getZoom() });
            });

            map.current.on('mousemove', `${trailsSourceName}LineLayer`, (e) => {
                if (!e?.features?.length) return;
                const trailId = e.features[0]?.properties?.id;
                if (!selectedTrailId) setHoveredTrailId(trailId);
            });

            map.current.on('mouseleave', `${trailsSourceName}LineLayer`, () => {
                if (!selectedTrailId) setHoveredTrailId(null);
            });
        });

        // Track double click
        let clicks = 0;

        map.current.on('click', (e) => {
            const point = e.point;
            clicks = 0;
            setTimeout(() => {
                if (!clicks) {
                    const features = map.current.queryRenderedFeatures(point, {
                        layers: layerSetup?.layers
                            .filter((layer) => !layer?.ignoreSelection)
                            .map((layer) => layer.id),
                    });

                    let feature = features?.length ? features[0] : null;
                    if (features?.length > 1 && feature?.properties?.priority) {
                        feature = maxBy(features, (feats) => feats.properties.priority);
                    }

                    if (feature) feature.clickedPoint = e?.lngLat;
                    setSelectedFeature(feature);
                    if (feature) isSelectedFromClick.current = true;

                    if (
                        feature &&
                        layerSetup?.hasClusterLayer &&
                        props.zoom > map.current.getZoom()
                    ) {
                        setShouldFitMapBounds(true);
                    }
                }
            }, 250);
        });

        map.current.on('dblclick', () => {
            clicks += 1;
        });
    };

    const initMap = () => {
        if (!map.current) return;
        if (
            props?.config?.mapBasicControls &&
            typeof props?.config?.mapBasicControls === 'object'
        ) {
            basicControlsList.current = {
                ...basicControlsList.current,
                ...props.config.mapBasicControls,
                ignoreFullscreen: props.hideExpandIcon,
            };
        }

        basicControls.current = addMapControls(map, basicControlsList.current);

        if (basicControlsList.current?.gesture)
            trackIsMapExtended(basicControls.current, setIsMapExtended);

        const specialControlParams = {
            ...getSpecialControlsPerService(props.service),
            ...props.config?.mapSpecialControls,
        };

        addMapEvents();

        if (props.initialEvent) props.initialEvent(map.current);

        /* eslint-disable no-param-reassign */
        if (props.specialMapControls) {
            const specialControls = addMapSpecialControls(map, specialControlParams);
            if (specialControls?.searchNearbyFilter) {
                specialControls?.searchNearbyFilter.addElement(
                    createRangeSlider(nearbyRadius, setNearbyRadius, {
                        maxValue: 200,
                        isSliderDragged,
                    }),
                    'slider-control'
                );
            }
            props.specialMapControls.current = specialControls;
        }

        if (props.mapRef) props.mapRef.current = map.current;
        /* eslint-enable no-param-reassign */
    };

    // Initiates map if it was not initiated already
    useEffect(() => {
        if (!map.current && entry?.isIntersecting && !shouldDisplayStaticImage) {
            mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: process.env.MAPBOX_STYLE,
                center: props.center,
                zoom: props.zoom,
                attributionControl: false,
                cooperativeGestures: basicControlsList.current?.gesture,
                preserveDrawingBuffer: true,
            });
            initMap();
        }
    }, [entry?.isIntersecting, shouldDisplayStaticImage]);

    useEffect(() => {
        const isCenterValid = props.center?.[0] && props.center?.[1];
        if (!map.current) setShouldDisplayStaticImage(!isCenterValid);
        if (
            !map.current ||
            !isCenterValid ||
            !props.config?.jumpMapToCenter ||
            !props.zoom
        )
            return;
        map.current.jumpTo({ center: props.center, zoom: props.zoom });
    }, [props.center, props.zoom]);

    useEffect(() => {
        isSliderDragged.current = false;
    }, [props.center]);

    // Update provided geojson data
    useEffect(() => {
        setGeojson(props.geojson);
    }, [props.geojson]);

    const setMapSource = () => {
        if (map.current && geojson) {
            const mapSource = map.current.getSource(sourceName);
            if (map.current.getSource(sourceName)) {
                mapSource.setData(geojson);
                if (props.config?.shouldAlwaysFitData)
                    fitMapBounds(map, geojson, props.zoom);
            }
        }
    };

    // Update map sources
    useEffect(() => {
        setMapSource();
        if (geojson?.features?.length) hasDataRef.current = true;
        else hasDataRef.current = false;
    }, [geojson, isMapLoaded]);

    useEffect(() => {
        if (props.initialEvent) props.initialEvent(map.current);
    }, [isMapLoaded]);

    return shouldDisplayStaticImage ? (
        <SimpleStaticMapImage />
    ) : (
        <LoadingSpinner mapRef={ref} isMapLoaded={isMapLoaded} />
    );
}

SimpleMap.propTypes = {
    geojson: PropTypes.object.isRequired,
    config: PropTypes.object,
    center: PropTypes.array,
    zoom: PropTypes.number,
    service: PropTypes.string.isRequired,
    selectedFeatureId: PropTypes.string,
    selectedEvent: PropTypes.func,
    initialEvent: PropTypes.func,
    onMoveEnd: PropTypes.func,
    onMoveStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    onZoomEnd: PropTypes.func,
    specialMapControls: PropTypes.object,
    mapRef: PropTypes.object,
    mapContainer: PropTypes.string,
    boundary: PropTypes.object,
    trails: PropTypes.object,
    ev: PropTypes.object,
    radiusCenter: PropTypes.array,
    featureName: PropTypes.string,
    searchTerm: PropTypes.object,
    onMouseMove: PropTypes.func,
};

export default SimpleMap;

// cSpell:ignore  moveend, movestart, zoomend, bbox
