/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useEffect, useState } from 'react';
import { useWindowSize } from 'sarsaparilla';
import LayersPanel from '../controls/LayersPanel';
import { NONE, VISIBILITY, VISIBLE } from '../constants';

function useLayerPanel({
    map,
    basicControls,
    hasTrails,
    hasEv,
    updateReservableLocationsLayer,
    updateEvLayer,
    mapEvFilters,
    mapFacilitiesToDisplay,
    iconsToDisplay
}) {
    const { width } = useWindowSize();
    const isMobile = width && width < 768;
    const trailsLayerName = 'trailsSourceNameLineLayer';
    const trailsBorderLayerName = 'trailsSourceNameBorderLineLayer';
    const evLayerName = 'evSourceNameLayer';
    const evLabelName = 'evSourceNameCustomLabelLayer';
    const evCircleName = 'evSourceNameCircleLayer';
    const evClusterCircleName = 'evSourceNameClusterCircleLayer';
    const evClusterLabelLayer = 'evSourceNameClusterLabelLayer';
    const trailsElementId = 'map-layers-panel-control';
    const mapSource = 'mapSource';

    const [showPanel, setShowPanel] = useState(false);
    const [isTrailVisible, setIsTrailVisible] = useState(false);
    const [isEvVisible, setIsEvVisible] = useState(false);
    const [displayTrailsMessage, setDisplayTrailsMessage] = useState(true);

    const onTrailsClick = () => {
        if (!map?.current) return;

        [trailsLayerName, trailsBorderLayerName].forEach((trailLayer) => {
            const visibility = map.current.getLayoutProperty(trailLayer, VISIBILITY);
            const isLayerVisible = visibility === VISIBLE;
            map.current.setLayoutProperty(
                trailLayer,
                VISIBILITY,
                isLayerVisible ? NONE : VISIBLE
            );
            if (trailLayer === trailsLayerName) setIsTrailVisible(!isLayerVisible);
        });
    };

    const onEvClick = () => {
        if (!map.current) return;
        [
            evLabelName,
            evCircleName,
            evLayerName,
            evClusterCircleName,
            evClusterLabelLayer,
        ].forEach((evLayer) => {
            const visibility = map.current.getLayoutProperty(evLayer, VISIBILITY);
            const isLayerVisible = visibility === VISIBLE;
            map.current.setLayoutProperty(
                evLayer,
                VISIBILITY,
                isLayerVisible ? NONE : VISIBLE
            );
            if (evLayer === evLabelName) setIsEvVisible(!isLayerVisible);
        });
    };

    const onMapStyleClick = (isSatellite) => {
        if (!map.current) return;
        map.current.setLayoutProperty(
            'fixed-satellite',
            VISIBILITY,
            isSatellite ? VISIBLE : NONE
        );
    };

    const getLayersPanel = () => {
        const props = {
            onTrailsClick,
            onEvClick,
            onMapStyleClick,
            hasTrails,
            hasEv,
            showPanel,
            setShowPanel,
            isTrailVisible,
            isEvVisible,
            updateReservableLocationsLayer,
            updateEvLayer,
            mapEvFilters,
            mapFacilitiesToDisplay,
            displayTrailsMessage,
            setDisplayTrailsMessage,
            isMobile,
        };
        return LayersPanel(props);
    };

    // On mount, add the layers panel
    useEffect(
        () => {
            if (basicControls?.current?.layersPanelControl) {
                basicControls.current.layersPanelControl.addElement(
                    getLayersPanel(),
                    trailsElementId
                );
            }
        },
        // Should run only when the control changes ... basically on mount ... so we don't need to memoize the functions or props
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [basicControls?.current?.layersPanelControl]
    );

    // On trails result, panel visibility status or trail visibility status,
    // update panel content
    useEffect(
        () => {
            if (basicControls?.current?.layersPanelControl) {
                basicControls.current.layersPanelControl.updateElementById(
                    getLayersPanel(),
                    trailsElementId
                );
            }
        },
        // Should run when getting trails results, changing panel or trail data visibility status
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            hasTrails,
            hasEv,
            showPanel,
            isTrailVisible,
            isEvVisible,
            mapEvFilters.current,
            mapFacilitiesToDisplay.current,
            iconsToDisplay,
        ]
    );

    useEffect(() => {
        map?.current?.setFilter(`${mapSource}Layer`, ['in', 'icon', ...iconsToDisplay]);
        map?.current?.setFilter(`${mapSource}CircleLayer`, ['in', 'icon', ...iconsToDisplay]);
    }, [iconsToDisplay]);
}

export default useLayerPanel;
