/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// issuance statuses
export const PERMIT_PRELIMINARY = 'PermitPreliminary';
export const PERMIT_IN_PROGRESS = 'PermitInProgress';
export const LOTTERY_IN_PROGRESS = 'In Progress';
export const LOTTERY_APPLIED = 'Applied';
export const LOTTERY_AWARDED = 'Awarded';
export const LOTTERY_AWARDED_IN_PROGRESS = 'Awarded In Progress';
export const LOTTERY_LOST = 'Unsuccessful';
export const LOTTERY_DECLINED = 'Declined';
export const LOTTERY_ACCEPTED = 'Accepted';
export const LOTTERY_NO_RESPONSE = 'No Response';
export const PERMIT_COMPLETE = 'PermitComplete';
export const PERMIT_ISSUED = 'PermitIssued';
export const CANCELLED_NO_RELEASE = 'CancelledNoRelease';
export const PERMIT_CANCELLED_BY_USER = 'PermitCancelledByUser';
export const LOTTERY_CANCELLED = 'Cancelled';
export const PERMIT_REFUNDED = 'PermitRefunded';
export const LOTTERY_VOIDED = 'Voided';
export const LOTTERY_DISQUALIFIED = 'Disqualified';
export const PERMIT_NO_SHOW = 'PermitNoShow';
export const LOTTERY_PENDING = 'Pending';
export const PERMIT_IN_REVIEW = 'PermitInReview';
export const PERMIT_REVIEWED = 'PermitReviewed';
// New issuance statuses
export const PERMIT_VOIDED = 'PermitVoided';
export const LOTTERY_SUBMITTED = 'LotterySubmitted';
export const LOTTERY_WON = 'LotteryWon';
export const LOTTERY_WON_IN_PROGRESS = 'LotteryWonInProgress';
export const LOTTERY_REJECTED = 'LotteryRejected';
