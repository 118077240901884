/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * RvRentals.jsx
 * Created by Destin Frasier on 08/07/20
 */

import React from 'react';
import {
    ContentBlock,
    FlexRow,
    FlexCol,
    Heading,
    GearRentalIcons,
    ExternalLink,
    Text,
    Carousel,
    InventoryPromoLink,
    useWindowSize,
} from 'sarsaparilla';

const rvRentalItems = [
    {
        title: 'Motorhomes',
        image: 'motorhome',
        imageAlt: '',
        url: 'aff_c?offer_id=9&aff_id=1697&source=motorhomes&url=https%3A%2F%2Fwww.outdoorsy.com%2Frv-search%3Ffilter%5Btype%5D%3Dc%26hotid%3D{transaction_id}%26offer_id%3D{offer_id}%26cam%3D751%26subcam%3D{affiliate_id}%26aff_sub%3Dmotorhomes%26utm_source%3D{affiliate_name}%26sale_amount%3D{sale_amount}%26utm_medium%3Daffiliate%26utm_campaign%3Dhasoffers',
    },
    {
        title: 'Travel Trailers',
        image: 'travel-trailer',
        imageAlt: '',
        url: 'aff_c?offer_id=9&aff_id=1697&source=travel-trailers&url=https%3A%2F%2Fwww.outdoorsy.com%2Frv-search%3Ffilter%5Btype%5D%3Dtrailer%2Cfifth-wheel%2Ctoy-hauler%2Cfolding-trailer%26hotid%3D{transaction_id}%26offer_id%3D{offer_id}%26cam%3D751%26subcam%3D{affiliate_id}%26aff_sub%3Dtravel-trailers%26utm_source%3D{affiliate_name}%26sale_amount%3D{sale_amount}%26utm_medium%3Daffiliate%26utm_campaign%3Dhasoffers',
    },
    {
        title: 'Campervans',
        image: 'campervan',
        imageAlt: '',
        url: 'aff_c?offer_id=9&aff_id=1697&source=campervans&url=https%3A%2F%2Fwww.outdoorsy.com%2Frv-search%3F%3D%26filter%5Btype%5D%3Db%2Ccamper-van%26hotid%3D{transaction_id}%26offer_id%3D{offer_id}%26cam%3D751%26subcam%3D{affiliate_id}%26aff_sub%3Dcampervans%26utm_source%3D{affiliate_name}%26sale_amount%3D{sale_amount}%26utm_medium%3Daffiliate%26utm_campaign%3Dhasoffers',
    },
    {
        title: 'Fifth-wheel',
        image: 'fifth-wheel',
        imageAlt: '',
        url: 'aff_c?offer_id=9&aff_id=1697&source=fifth-wheel&url=https%3A%2F%2Fwww.outdoorsy.com%2Frv-search%3Ffilter%5Btype%5D%3Dfifth-wheel%26hotid%3D{transaction_id}%26offer_id%3D{offer_id}%26cam%3D751%26subcam%3D{affiliate_id}%26aff_sub%3Dfifth-wheel%26utm_source%3D{affiliate_name}%26sale_amount%3D{sale_amount}%26utm_medium%3Daffiliate%26utm_campaign%3Dhasoffers',
    },
    {
        title: 'Popup Trailers',
        image: 'popup',
        imageAlt: '',
        url: 'aff_c?offer_id=9&aff_id=1697&source=fifth-wheel&url=https%3A%2F%2Fwww.outdoorsy.com%2Frv-search%3Ffilter%5Btype%5D%3Dfolding-trailer%26hotid%3D{transaction_id}%26offer_id%3D{offer_id}%26cam%3D751%26subcam%3D{affiliate_id}%26aff_sub%3Dfifth-wheel%26utm_source%3D{affiliate_name}%26sale_amount%3D{sale_amount}%26utm_medium%3Daffiliate%26utm_campaign%3Dhasoffers',
    },
];

export default function GearRentals() {
    const { width } = useWindowSize();
    const seeAllURL = 'https://outdoorsyinc.go2cloud.org/aff_c?offer_id=9&aff_id=1697';

    return (
        <section className="nav-rv-rentals-section">
            <ContentBlock className="my-0">
                <FlexRow justifyContent="center" className="mb-5">
                    <FlexCol md={6} alignSelf="center">
                        <Heading headingLevel={1} appearance="h3" className="mb-2">
                            RV Rentals
                        </Heading>
                        <Text size="xl">
                            Dreaming about taking an RV vacation but don&#39;t want to
                            purchase your own rig? Make your dreams a reality by renting
                            an RV and hitting the open road for your next adventure.{' '}
                            <ExternalLink url={seeAllURL}>
                                See All RV Rental Options
                            </ExternalLink>
                        </Text>
                    </FlexCol>
                    <FlexCol md={6} className="nav-rv-rentals-section-image-block">
                        <GearRentalIcons.RentalRv />
                    </FlexCol>
                </FlexRow>

                <FlexRow justifyContent="center">
                    <FlexCol>
                        <Carousel
                            slideTargetWidth={325}
                            hasNegativeMargins={width > 1240}
                            hasGutters
                            hasPaddingBelow
                            className="mb-1"
                        >
                            {rvRentalItems.map((rvRentalItem, index) => (
                                <FlexCol key={index}>
                                    <InventoryPromoLink
                                        imagePath={`${process.env.CDN}/img/rentals/${rvRentalItem.image}.jpg`}
                                        imageAlt={rvRentalItem.imageAlt}
                                        href={`https://outdoorsyinc.go2cloud.org/${rvRentalItem.url}`}
                                        heading={rvRentalItem.title}
                                        headingLevel={2}
                                        isExternalLink
                                    />
                                </FlexCol>
                            ))}
                        </Carousel>
                    </FlexCol>
                </FlexRow>

                <FlexRow justifyContent="center">
                    <FlexCol xs="variable">
                        <ExternalLink
                            className="rec-button-tertiary nav-rv-rentals-see-all-btn"
                            url={seeAllURL}
                            hasExternalIcon={false}
                        >
                            See All RV Rental Options
                        </ExternalLink>
                    </FlexCol>
                </FlexRow>
            </ContentBlock>
        </section>
    );
}
