/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { get, uniqueId } from 'lodash';
import {
    MapHelper,
    FauxImage,
    FauxUnavailableImage,
    InventoryTag,
    Heading,
    StarRating,
    FlexRow,
    FlexCol,
    Button,
    InlineBulletList,
} from 'sarsaparilla';
import { inventoryTags } from '../../../constants/mapValues';

export function HomepageCard({ item, onCardLeave, onCardOver }) {
    const renderItemType = (itemType) => {
        let tag = inventoryTags[itemType.entity_type];
        if (!tag) {
            if (itemType.entity_type === 'campground') {
                const pills = [];

                if (
                    itemType.campsite_type_of_use &&
                    itemType.campsite_type_of_use.length > 0 &&
                    itemType.campsite_type_of_use[0] !== 'na'
                ) {
                    // calculate pills for campgrounds, day use, and mixed types
                    itemType.campsite_type_of_use.forEach((type) => {
                        if (type === 'Day') {
                            pills[pills.length] = (
                                <InventoryTag.InventoryDayUse
                                    isInverse
                                    isSpanItem
                                    size="sm"
                                    key={pills.length}
                                />
                            );
                        } else if (type === 'Overnight') {
                            pills[pills.length] = (
                                <InventoryTag.InventoryCamping
                                    isInverse
                                    isSpanItem
                                    size="sm"
                                    key={pills.length}
                                />
                            );
                        }
                    });
                } else {
                    // in case of blank type use, assume a campground
                    pills[pills.length] = (
                        <InventoryTag.InventoryCamping
                            isInverse
                            isSpanItem
                            size="sm"
                            key={pills.length}
                        />
                    );
                }
                tag = pills[0];
            } else {
                tag = <InventoryTag.InventoryPOI isInverse isSpanItem size="sm" />;
            }
        }

        return tag;
    };

    const getTargetUri = (site) => {
        return site.entity_id ? MapHelper.getTargetUri(site) : null;
    };

    const onDetailURLClick = (e) => {
        e.preventDefault();
        window.open(getTargetUri(item), '_blank').focus();
        return false;
    };

    const onRatingURLClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(`${getTargetUri(item)}?tab=ratings`, '_blank').focus();
        return false;
    };

    const getNumberOfRatings = (site) => {
        return Number.parseInt(get(site, 'number_of_ratings', 0), 10);
    };

    const decodeHTML = (val) => {
        let html = val;
        if (html) {
            html = html.replace('&#257;', 'ā');
            html = html.replace('&#39;', "'");
            html = html.replace('&amp;', '&');
            html = html.replace('&nbsp;', ' ');
            html = html.replace('&lt;', '<');
            html = html.replace('&gt;', '>');
        }
        return html;
    };

    const formatLocationName = (name) => {
        return decodeHTML(name);
    };

    const getAverageRating = (site) => {
        return Number.parseFloat(get(site, 'average_rating', 0.0));
    };

    const renderParentOrg = (site) => {
        const hasParentName = site.parent_name && site.parent_name.length > 0;
        const hasOrgName = site.org_name && site.org_name.length > 0;
        if (!hasParentName && !hasOrgName) return null;

        return (
            <li>
                {' '}
                {formatLocationName(
                    hasParentName ? site.parent_name : site.org_name
                )}{' '}
            </li>
        );
    };

    const detailURL = getTargetUri(item);
    const numberOfRatings = getNumberOfRatings(item);
    const averageRating = getAverageRating(item);

    return (
        <div
            className={`nav-map-card`}
            id={`rec-card-${item.entity_id}_${item.entity_type}`}
        >
            <div className="nav-map-card-content-wrap">
                <div data-card className="nav-map-card-image-wrap" alt={item.name}>
                    {item.preview_image_url ? (
                        <FauxImage
                            style={{ minHeight: '72px' }}
                            src={item.preview_image_url}
                            altText={item.name}
                            className="rec-flex-card-image-wrap-faux-image"
                        />
                    ) : (
                        <FauxUnavailableImage
                            style={{ minHeight: '72px' }}
                            className="rec-flex-card-image-wrap-faux-image"
                        />
                    )}
                </div>

                <div className="nav-map-card-body-wrap">
                    <FlexRow hasGutters={false} className="nav-map-card-title-wrap">
                        <FlexCol xs="variable" className="nav-map-card-title-icon">
                            {renderItemType(item)}
                            {item.park_pass_facility_id && (
                                <InventoryTag.InventorySitePass isInverse isSpanItem />
                            )}
                        </FlexCol>
                        <Heading headingLevel={3} appearance="h6">
                            <a
                                href={detailURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={onDetailURLClick}
                                onFocus={onCardOver.bind(this, item)}
                                onMouseOut={onCardLeave.bind(this)}
                                onMouseOver={onCardOver.bind(this, item)}
                                onBlur={onCardLeave.bind(this)}
                                id={`rec-card-heading-${item.entity_id}_${item.entity_type}`}
                                data-ga-tracking-id="858265557121"
                            >
                                {formatLocationName(item.name)}
                            </a>
                        </Heading>
                    </FlexRow>
                    <InlineBulletList className="nav-map-card-meta mb-1">
                        {renderParentOrg(item)}
                        {item.city && item.state_code && (
                            <li>
                                {' '}
                                {item.city}, {item.state_code}{' '}
                            </li>
                        )}
                    </InlineBulletList>
                    <div className="nav-map-card-search-ratings">
                        {numberOfRatings > 0 && (
                            <Button
                                className="nav-map-search-rating-link"
                                key={uniqueId()}
                                appearance="link"
                                onClick={onRatingURLClick}
                                isDisabled={numberOfRatings === 0}
                                size="sm"
                            >
                                <StarRating
                                    rating={averageRating}
                                    size="sm"
                                    reviewCount={numberOfRatings}
                                />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

HomepageCard.propTypes = {
    item: PropTypes.object,
    onCardLeave: PropTypes.func,
    onCardOver: PropTypes.func,
};

HomepageCard.defaultProps = {
    onCardLeave: () => {},
    onCardOver: () => {},
};
