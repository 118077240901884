/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import {
    HelmetWrapper,
    FlexRow,
    FlexCol,
    BrowserIcons,
    ExternalLink,
    SingleColumnContainer,
    Heading,
} from 'sarsaparilla';

const pageTitle = 'Unsupported Browser';

const browserLinks = [
    {
        url: 'microsoft.com/en-us/windows/microsoft-edge',
        icon: <BrowserIcons.BrowserIconEdge />,
        text: 'Edge',
        title: 'Get Microsoft Edge',
    },
    {
        url: 'google.com/chrome/browser/',
        icon: <BrowserIcons.BrowserIconChrome />,
        text: 'Chrome',
        title: 'Get Google Chrome',
    },
    {
        url: 'mozilla.org/firefox',
        icon: <BrowserIcons.BrowserIconFirefox />,
        text: 'Firefox',
        title: 'Get Firefox',
    },
    {
        url: 'support.apple.com/downloads/#safari',
        icon: <BrowserIcons.BrowserIconSafari />,
        text: 'Safari (Mac only)',
        title: 'Get Safari',
    },
];

export default function UnsupportedBrowser() {
    return (
        <SingleColumnContainer role="main">
            <section className="nav-unsupported-browser-page">
                <HelmetWrapper title={pageTitle} />

                <Heading headingLevel={1} appearance="h1">
                    Update Your Browser
                </Heading>
                <p>
                    Update your browser for better security, speed, and the best
                    experience on the site. You will not be able to purchase anything
                    until you have upgraded your browser or use an alternative compatible
                    browser.
                </p>

                <p>
                    Go to one of these sites to get the latest version of your preferred
                    browser.
                </p>

                <FlexRow>
                    {browserLinks.map((browserLink) => (
                        <FlexCol md={6} lg={3} key={browserLink.text}>
                            <ExternalLink
                                url={`https://www.${browserLink.url}`}
                                className="rec-browser-link"
                                title={browserLink.title}>
                                {browserLink.icon}
                                <br />
                                {browserLink.text}
                            </ExternalLink>
                        </FlexCol>
                    ))}
                </FlexRow>
            </section>
        </SingleColumnContainer>
    );
}
