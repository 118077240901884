/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Icons, Box } from 'sarsaparilla';

export default function Pagination({
    currentPage,
    itemsPerPage,
    totalItems,
    paginate
}){

    const pageNumbers = [];
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    for(let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i += 1) {
        pageNumbers.push(i);
    }

    function handleScrollClick(e) {
        e.preventDefault();

        document.getElementById('whats-new-top').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }

    return (
        <>
            {pageCount > 1 &&
                <Box alignX='right'>
                    <ul className='rec-pagination'>
                
                        {/* Previous Button*/}
                        {pageCount > 1 &&
                            <li>
                                <button
                                    type='button'
                                    aria-label="Previous Page"
                                    className={currentPage <= 1 ? 'rec-disabled-button' : ''}
                                    onClick={(e) => {
                                        if (paginate && currentPage > 1) {
                                            paginate(currentPage - 1); handleScrollClick(e)
                                        }
                                    }}><Icons.IconArrowBack />
                                </button>
                            </li>
                        }
                    
                        {pageNumbers.map(pageNumber => (
                            <li key={pageNumber}>
                                <button
                                    type='button'
                                    className={(currentPage === pageNumber ? 'rec-active-page' : '')}
                                    onClick={(e) => { paginate(pageNumber); handleScrollClick(e)}}
                                    aria-label={(currentPage === pageNumber ? `Current Page, Page ${pageNumber}` : `Goto Page ${pageNumber}`)}>
                                        {pageNumber}
                                </button>
                            </li>
                        ))}
                        
                        {/* Next Button*/}
                        {pageCount > 1 &&
                            <li>
                                <button
                                    type='button'
                                    aria-label="Next Page"
                                    className={currentPage >= pageCount ? 'rec-disabled-button' : ''}
                                    onClick={(e) => {
                                        if (paginate && currentPage < pageCount) {
                                            paginate(currentPage + 1); handleScrollClick(e)
                                        }
                                    }}>
                                    <Icons.IconArrowForward />
                                </button>
                            </li>
                        }
                    </ul>
                </Box>
            }
            <div />
        </>
    );
}


Pagination.propTypes = {
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    totalItems: PropTypes.number,
    paginate: PropTypes.func
}