/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-template-curly-in-string */

// api base
export const CAMP_BASEPATH = '/camps/internal';
export const RENTAL_BASEPATH = '/rentals/internal';
export const MEDIA_BASEPATH = '/media/internal';
export const INVENTORY_LOADER_BASEPATH = '/inventory/loader';
export const INVENTORY_AMENITIES_BASEPATH = '/inventory/amenities';
export const AVAILABILITY_NOTIFICATIONS_BASEPATH = '/availabilitynotification/internal';

// global camping constants
export const CAMPING_BASE_URL = '/internal/camping';
export const RENTALS_BASE_URL = '/internal/rentals';
export const INTERNAL_ACCOUNT_BASE_URL = '/internal/account';
export const INVENTORY_BASE_URL = '/inventory';
export const SALES_BASE_URL = '/internal/sales';

export const CALL_CENTER_PHONE_NUMBER = '1 (877) 444-6777';
export const TDD_CALL_CENTER_PHONE_NUMBER = '1 (877) 833-6777';
export const INTERNATIONAL_CALL_CENTER_PHONE_NUMBER = '1 (606) 515-6777';

export const INVENTORY_TAB_TITLES = [
    'Campground',
    'Campsites',
    'Schedules',
    'GIS',
    'Facility Specific Reports',
];

export const CLOSURE_TYPES = {
    ALaCarte: 'a-la-carte',
    Honored: 'honored',
    Immediate: 'immediate',
    Standard: 'standard',
};

export const INVENTORY_MANAGEMENT_FACILITY_BASE_URL = CAMPING_BASE_URL.concat(
    '/facilities/${facility_id}'
);

export const INVENTORY_MANAGEMENT_CAMPSITES_BASE_URL = CAMPING_BASE_URL.concat(
    '/facilities/${facility_id}/campsites'
);

export const INVENTORY_MANAGEMENT_CAMPSITE_DETAILS_BASE_URL = CAMPING_BASE_URL.concat(
    '/campsites/${campsite_id}'
);

export const INVENTORY_MANAGEMENT_CLOSURES_BASE_URL = CAMPING_BASE_URL.concat(
    '/facilities/${facility_id}/closures'
);

export const INVENTORY_MANAGEMENT_GIS_URL = `${CAMPING_BASE_URL}/facilities/\${facility_id}/gis`;

export const INVENTORY_MANAGEMENT_REPORTS_URL = `${CAMPING_BASE_URL}/facilities/\${facility_id}/reports`;

export const INVENTORY_PANEL_URLS = [
    INVENTORY_MANAGEMENT_FACILITY_BASE_URL,
    INVENTORY_MANAGEMENT_CAMPSITES_BASE_URL,
    INVENTORY_MANAGEMENT_CLOSURES_BASE_URL,
    INVENTORY_MANAGEMENT_GIS_URL,
    INVENTORY_MANAGEMENT_REPORTS_URL,
];

export const GIS_TAB_TITLES = ['ONE', 'TWO', 'THREE'];

export const FACILITY_TAB_TITLES = [
    'Overview',
    'Need to Know',
    'Seasons & Fees',
    'Getting Here',
    'Contact',
    'Business Rules',
    'Photos / Media',
    'Additional Info Links',
    'Entrance/Combo Lock Code',
    'Long Term Stays',
];

export const CAMPSITE_TAB_TITLES = [
    'Details',
    'Photos / Media',
    'Entrance/Combo Lock Code',
];

export const TABS_WITHOUT_GROUP_SUBMISSION = [
    'Photos / Media',
    'Entrance/Combo Lock Code',
];

export const GATEWAY_TABS_WITHOUT_GROUP_SUBMISSION = [
    ...TABS_WITHOUT_GROUP_SUBMISSION,
    'Business Rules',
];

export const LOTTERY_TAB_TITLES = ['Availability'];

export const RESERVATION_TAB_TITLES = [
    'Checking In Today',
    'Checking Out Today',
    'Overdue Check-ins',
    'Overdue Check-outs',
    'Long Term Stays',
    'All Reservations',
];

export const GATEWAY_TAB_TITLES = ['Page Content', 'Photos / Media', 'Business Rules'];

export const SEASON_TAB_TITLES = [
    'Seasons',
    'Peak Season Fees',
    'Non-Peak Season Fees',
    'First-come, First-served Season Fees',
    'Transition Fees',
];

export const SEASON_TYPES_KEYS = ['Peak', 'Off Peak', 'Walk In', 'Transition'];

export const SEASON_TYPES_TITLE_MAP = {
    Peak: 'Peak Season',
    'Off Peak': 'Non-Peak Season',
    'Walk In': 'First-come, First-served Season',
    Transition: 'Transition',
    Lottery: 'Lottery',
};

// campground
export const PENDING_FACILITY_INITIALIZE = 'PENDING_FACILITY_INITIALIZE';
export const PENDING_FACILITY_PROP_CHANGED = 'PENDING_FACILITY_PROP_CHANGED';
export const PENDING_FACILITY_PROP_ROLLBACK = 'PENDING_FACILITY_PROP_ROLLBACK';
export const PENDING_FACILITY_EDIT_SUBMIT = 'PENDING_FACILITY_EDIT_SUBMIT';
export const PENDING_FACILITY_EDIT_CANCEL = 'PENDING_FACILITY_EDIT_CANCEL';
export const PENDING_FACILITY_APPROVE = 'PENDING_FACILITY_APPROVE';
export const CAMPGROUND_NAME_CHECK_ERROR = 'CAMPGROUND_NAME_CHECK_ERROR';
export const CAMPGROUND_NAME_CHECK_LOADING = 'CAMPGROUND_NAME_CHECK_LOADING';
export const CAMPGROUND_NAME_CHECK_COMPLETE = 'CAMPGROUND_NAME_CHECK_COMPLETE';
export const CAMPGROUND_NAME_UPDATING = 'CAMPGROUND_NAME_UPDATING';
export const CAMPGROUND_NAME_UPDATED = 'CAMPGROUND_NAME_UPDATED';
export const CAMPGROUND_NAME_UPDATE_ERROR = 'CAMPGROUND_NAME_UPDATE_ERROR';
export const CAMPGROUND_NAME_CHECK_INIT = 'CAMPGROUND_NAME_CHECK_INIT';

// facility fees
export const PENDING_FACILITY_FEES_INITIALIZE = 'PENDING_FACILITY_FEES_INITIALIZE';
export const PENDING_FACILITY_FEES_PROP_CHANGED = 'PENDING_FACILITY_FEES_PROP_CHANGED';
export const PENDING_FACILITY_FEES_PROP_ROLLBACK = 'PENDING_FACILITY_FEES_PROP_ROLLBACK';
export const GET_CAMPGROUND_FEES = 'GET_CAMPGROUND_FEES';
export const GET_CAMPGROUND_FEES_ERROR = 'GET_CAMPGROUND_FEES_ERROR';
export const GET_CAMPGROUND_TAXES = 'GET_CAMPGROUND_TAXES';
export const GET_CAMPGROUND_TAXES_ERROR = 'GET_CAMPGROUND_TAXES_ERROR';
export const UPDATE_CAMPGROUND_TAXES = 'UPDATE_CAMPGROUND_TAXES';
export const UPDATE_CAMPGROUND_TAXES_ERROR = 'UPDATE_CAMPGROUND_TAXES_ERROR';
export const GET_CAMPSITE_TYPES = 'GET_CAMPSITE_TYPES';
export const GET_CAMPSITE_TYPES_ERROR = 'GET_CAMPSITE_TYPES_ERROR';
export const OPEN_FACILITY_SITES = 'OPEN_FACILITY_SITES';
export const OPEN_FACILITY_SITES_ERROR = 'OPEN_FACILITY_SITES_ERROR';
export const CLOSE_FACILITY_SITES = 'CLOSE_FACILITY_SITES';
export const CLOSE_FACILITY_SITES_ERROR = 'CLOSE_FACILITY_SITES_ERROR';
export const GET_FACILITY_RESERVATIONS_AFFECTED = 'GET_FACILITY_RESERVATIONS_AFFECTED';
export const GET_FACILITY_RESERVATIONS_AFFECTED_ERROR =
    'GET_FACILITY_RESERVATIONS_AFFECTED_ERROR';
export const GET_CAMPSITES_SCHEDULES = 'GET_CAMPSITES_SCHEDULES';
export const GET_CAMPSITES_SCHEDULES_ERROR = 'GET_CAMPSITES_SCHEDULES_ERROR';
export const GET_CAMPGROUND_SCHEDULES = 'GET_CAMPGROUND_SCHEDULES';
export const GET_CAMPGROUND_SCHEDULES_INTERNAL = 'GET_CAMPGROUND_SCHEDULES_INTERNAL';
export const GET_BULK_SCHEDULES_CAMPGROUND_INTERNAL =
    'GET_BULK_SCHEDULES_CAMPGROUND_INTERNAL';
export const GET_CAMPGROUND_SCHEDULES_ERROR = 'GET_CAMPGROUND_SCHEDULES_ERROR';
export const GET_CAMPGROUND_SCHEDULE_DETAILS = 'GET_CAMPGROUND_SCHEDULE_DETAILS';
export const GET_CAMPGROUND_SCHEDULE_DETAILS_ERROR =
    'GET_CAMPGROUND_SCHEDULE_DETAILS_ERROR';
export const GET_CAMPGROUND_SCHEDULE_REPORT = 'GET_CAMPGROUND_SCHEDULE_REPORT';
export const GET_CAMPGROUND_SCHEDULE_REPORT_ERROR =
    'GET_CAMPGROUND_SCHEDULE_REPORT_ERROR';
export const GET_CAMPGROUND_SCHEDULE_REPORT_SUCCESS =
    'GET_CAMPGROUND_SCHEDULE_REPORT_SUCCESS';

// permission
export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_PERMISSION_ERROR = 'GET_PERMISSION_ERROR';
export const CLEAR_PERMISSION = 'CLEAR_PERMISSION';

export const FACILITY_SITES_CLOSURE_DROPDOWN = [
    'Administration',
    'Bears',
    'Construction',
    'Earthquake Damage',
    'Fire',
    'Flooding',
    'Hazard Trees',
    'Hurricane',
    'Inventory Released Incorrectly',
    'Maintenance',
    'Public Safety Concerns',
    'Rock Fall',
    'Season Change',
    'Site Closure',
    'Tour Canceled',
    'Tropical Storm',
    'Services Not Available',
    'Weather',
    'Winter Damage',
];

export const FACILITY_SITES_CLOSURE_REASON_DESCRIPTION = {
    Administration: 'Enter Administrative Reason: Volunteer Site, Camp Host Site',
    Bears: '',
    Construction:
        'Enter type of construction i.e. Dump Station, Electrical, Facility, Road, Site, Shelter, Water',
    'Earthquake Damage': 'Enter name of event',
    Fire: 'Enter name of event',
    Flooding: 'Enter name of event',
    'Hazard Trees': '',
    Hurricane: 'Enter name of event',
    'Inventory Released Incorrectly': '',
    Maintenance:
        'Enter type of mainteance i.e. Dump Station, Refurbishment, Site Restoration, Stove Repairs, Water System, Well',
    'Public Safety Concerns': '',
    'Rock Fall': '',
    'Season Change': '',
    'Site Closure': '',
    'Tour Canceled': '',
    'Tropical Storm': 'Enter name of event',
    'Services Not Available':
        'Enter type of utility: Dump Station, Electric, Elevator, Generator, Heating, Power, Propane, Shower House, Water, etc',
    Weather: '',
    'Winter Damage': '',
};

export const CLOSURE_ADDTL_INFO_TOOLTIP_TEXT =
    'This replaces the need for additional "Alerts and Communications" messages related to this closure. If you need to communicate any additional information not already included in the closure emails, such as hyperlinks or other facility details, please do so here.';

export const ROLE_BAH_ADMIN = 'ROLE_BAH_ADMIN';
export const ROLE_PMO = 'ROLE_PMO';
export const ROLE_CSR = 'ROLE_CSR';
export const ROLE_CSR2 = 'ROLE_CSR2';
export const ROLE_CSR_SUPERVISOR = 'ROLE_CSR_SUPERVISOR';
export const ROLE_FINANCE_ADMIN = 'ROLE_FINANCE_ADMIN';
export const ROLE_FINANCE_USER = 'ROLE_FINANCE_USER';
export const ROLE_FACILITY_MANAGER = 'ROLE_FACILITY_MANAGER';
export const ROLE_FACILITY_OPERATOR = 'ROLE_FACILITY_OPERATOR';
export const ROLE_ATTENDANT = 'ROLE_ATTENDANT';
export const ROLE_USGS = 'ROLE_USGS';
export const ROLE_ATR = 'ROLE_ATR';
export const ROLE_HOST = 'ROLE_HOST';

export const ROLES_SCOPE = {
    ROLE_SUPER_USER: [
        ROLE_BAH_ADMIN,
        ROLE_PMO,
        ROLE_CSR,
        ROLE_FINANCE_USER,
        ROLE_FINANCE_ADMIN,
        ROLE_USGS,
        ROLE_ATR,
        ROLE_FACILITY_MANAGER,
        ROLE_FACILITY_OPERATOR,
        ROLE_ATTENDANT,
        ROLE_HOST,
    ],
    ROLE_SYSTEM_USER: [
        ROLE_BAH_ADMIN,
        ROLE_PMO,
        ROLE_CSR,
        ROLE_FINANCE_USER,
        ROLE_FINANCE_ADMIN,
        ROLE_USGS,
        ROLE_ATR,
        ROLE_FACILITY_MANAGER,
        ROLE_FACILITY_OPERATOR,
        ROLE_ATTENDANT,
        ROLE_HOST,
    ],
    ROLE_BAH_ADMIN: [
        ROLE_BAH_ADMIN,
        ROLE_PMO,
        ROLE_CSR,
        ROLE_FINANCE_USER,
        ROLE_FINANCE_ADMIN,
        ROLE_USGS,
        ROLE_ATR,
        ROLE_FACILITY_MANAGER,
        ROLE_FACILITY_OPERATOR,
        ROLE_ATTENDANT,
        ROLE_HOST,
    ],
    ROLE_PMO: [
        ROLE_PMO,
        ROLE_CSR,
        ROLE_FINANCE_USER,
        ROLE_FINANCE_ADMIN,
        ROLE_USGS,
        ROLE_ATR,
        ROLE_FACILITY_MANAGER,
        ROLE_FACILITY_OPERATOR,
        ROLE_ATTENDANT,
        ROLE_HOST,
    ],
    ROLE_CSR: [ROLE_CSR, ROLE_ATTENDANT, ROLE_HOST],
    ROLE_CSR2: [ROLE_CSR2, ROLE_FACILITY_OPERATOR],
    ROLE_CSR_SUPERVISOR: [
        ROLE_CSR_SUPERVISOR,
        ROLE_FACILITY_OPERATOR,
        ROLE_CSR,
        ROLE_ATTENDANT,
        ROLE_HOST,
    ],
    ROLE_FINANCE_USER: [],
    ROLE_FINANCE_ADMIN: [],
    ROLE_USGS: [],
    ROLE_ATR: [
        ROLE_ATR,
        ROLE_FACILITY_MANAGER,
        ROLE_FACILITY_OPERATOR,
        ROLE_ATTENDANT,
        ROLE_HOST,
    ],
    ROLE_FACILITY_MANAGER: [
        ROLE_FACILITY_MANAGER,
        ROLE_FACILITY_OPERATOR,
        ROLE_ATTENDANT,
        ROLE_HOST,
    ],
    ROLE_FACILITY_OPERATOR: [ROLE_FACILITY_OPERATOR, ROLE_ATTENDANT, ROLE_HOST],
    ROLE_ATTENDANT: [ROLE_ATTENDANT, ROLE_HOST],
    ROLE_HOST: [ROLE_HOST],
};

export const ROLES_WITHOUT_FCFS_PERMISSION = [ROLE_CSR, ROLE_HOST];

export const ROLES_WITH_FCFS_PERMISSION_FOR_PREVIOUS_DAY = [
    ROLE_CSR,
    ROLE_CSR2,
    ROLE_CSR_SUPERVISOR,
];

// facility fees templates
export const GET_CAMPGROUND_FEE_TEMPLATES = 'GET_CAMPGROUND_FEE_TEMPLATES';
export const GET_CAMPGROUND_FEE_TEMPLATES_ERROR = 'GET_CAMPGROUND_FEE_TEMPLATES_ERROR';
export const CREATE_CAMPGROUND_FEE_TEMPLATE = 'CREATE_CAMPGROUND_FEE_TEMPLATE';
export const CREATE_CAMPGROUND_FEE_TEMPLATE_ERROR =
    'CREATE_CAMPGROUND_FEE_TEMPLATE_ERROR';
export const DELETE_CAMPGROUND_FEE_TEMPLATE = 'DELETE_CAMPGROUND_FEE_TEMPLATE';
export const DELETE_CAMPGROUND_FEE_TEMPLATE_ERROR =
    'DELETE_CAMPGROUND_FEE_TEMPLATE_ERROR';
export const UPDATE_CAMPGROUND_FEE_TEMPLATE = 'UPDATE_CAMPGROUND_FEE_TEMPLATE';
export const UPDATE_CAMPGROUND_FEE_TEMPLATE_ERROR =
    'UPDATE_CAMPGROUND_FEE_TEMPLATE_ERROR';

// facility seasons
export const PENDING_FACILITY_SEASONS_INITIALIZE = 'PENDING_FACILITY_SEASONS_INITIALIZE';
export const PENDING_FACILITY_SEASONS_PROP_CHANGED =
    'PENDING_FACILITY_SEASONS_PROP_CHANGED';
export const PENDING_FACILITY_SEASONS_PROP_ROLLBACK =
    'PENDING_FACILITY_SEASONS_PROP_ROLLBACK';
export const GET_CAMPGROUND_SEASONS = 'GET_CAMPGROUND_SEASONS';
export const GET_CAMPGROUND_SEASONS_ERROR = 'GET_CAMPGROUND_SEASONS_ERROR';
export const CREATE_SEASON = 'CREATE_SEASON';
export const CREATE_SEASON_ERROR = 'CREATE_SEASON_ERROR';
export const UPDATE_SEASON = 'UPDATE_SEASON';
export const UPDATE_SEASON_ERROR = 'UPDATE_SEASON_ERROR';
export const DELETE_SEASON = 'DELETE_SEASON';
export const DELETE_SEASON_ERROR = 'DELETE_SEASON_ERROR';

// campsite
export const CAMPSITE_BULK_EDIT_CLEAR = 'CAMPSITE_BULK_EDIT_CLEAR';
export const CAMPSITE_BULK_EDIT_INITIALIZE = 'CAMPSITE_BULK_EDIT_INITIALIZE';
export const CAMPSITE_BULK_EDIT_SELECTION = 'CAMPSITE_BULK_EDIT_SELECTION';
export const CAMPSITE_CHANGE_STATUS = 'CAMPSITE_CHANGE_STATUS';
export const CAMPSITE_CHANGE_STATUS_ERROR = 'CAMPSITE_CHANGE_STATUS_ERROR';
export const CAMPSITE_CHANGE_TYPE_MANAGE = 'CAMPSITE_CHANGE_TYPE_MANAGE';
export const CAMPSITE_CHANGE_TYPE_MANAGE_ERROR = 'CAMPSITE_CHANGE_TYPE_MANAGE_ERROR';
export const CAMPSITE_CHANGE_TYPE_OVERFLOW = 'CAMPSITE_CHANGE_TYPE_OVERFLOW';
export const CAMPSITE_CHANGE_TYPE_OVERFLOW_ERROR = 'CAMPSITE_CHANGE_TYPE_OVERFLOW_ERROR';
export const PENDING_CAMPSITE_INITIALIZE = 'PENDING_CAMPSITE_INITIALIZE';
export const PENDING_CAMPSITE_PROP_CHANGED = 'PENDING_CAMPSITE_PROP_CHANGED';
export const PENDING_CAMPSITE_RESERVABLE_STATUS = 'PENDING_CAMPSITE_RESERVABLE_STATUS';
export const PENDING_CAMPSITE_PROP_ROLLBACK = 'PENDING_CAMPSITE_PROP_ROLLBACK';
export const PENDING_CAMPSITE_REDUX_STATE_ROLLBACK =
    'PENDING_CAMPSITE_REDUX_STATE_ROLLBACK';
export const PENDING_CAMPSITE_EDIT_SUBMIT = 'PENDING_CAMPSITE_EDIT_SUBMIT';
export const PENDING_CAMPSITE_EDIT_CANCEL = 'PENDING_CAMPSITE_EDIT_CANCEL';
export const PENDING_CAMPSITE_APPROVE = 'PENDING_CAMPSITE_APPROVE';
export const BULK_EDIT_PENDING_CAMPSITES_SUBMISSION =
    'BULK_EDIT_PENDING_CAMPSITES_SUBMISSION';
export const COMPLETE_BULK_EDIT_SUBMISSION_COMPLETE =
    'COMPLETE_BULK_EDIT_SUBMISSION_COMPLETE';
export const COMPLETE_BULK_EDIT_SUBMISSION_PENDING =
    'COMPLETE_BULK_EDIT_SUBMISSION_PENDING';
export const CAMPSITE_DEACTIVATE_ERROR = 'CAMPSITE_DEACTIVATE_ERROR';
export const CAMPSITE_DEACTIVATE_PENDING = 'CAMPSITE_DEACTIVATE_PENDING';
export const CAMPSITE_DEACTIVATE_SUCCESS = 'CAMPSITE_DEACTIVATE_SUCCESS';
export const CAMPSITE_FIELD_GIS_ERROR = 'CAMPSITE_FIELD_GIS_ERROR';
export const CAMPSITE_FIELD_GIS_PENDING = 'CAMPSITE_FIELD_GIS_PENDING';
export const CAMPSITE_FIELD_GIS_SUCCESS = 'CAMPSITE_FIELD_GIS_SUCCESS';
export const CAMPSITE_SUPPLEMENTAL_BULK_CREATED = 'CAMPSITE_SUPPLEMENTAL_BULK_CREATED';
export const CAMPSITE_SUPPLEMENTAL_BULK_DELETED = 'CAMPSITE_SUPPLEMENTAL_BULK_DELETED';
export const CAMPSITE_SUPPLEMENTAL_BULK_EDITED = 'CAMPSITE_SUPPLEMENTAL_BULK_EDITED';
export const CAMPSITE_SUPPLEMENTAL_CREATED = 'CAMPSITE_SUPPLEMENTAL_CREATED';
export const CAMPSITE_SUPPLEMENTAL_CREATING = 'CAMPSITE_SUPPLEMENTAL_CREATING';
export const CAMPSITE_SUPPLEMENTAL_DELETED = 'CAMPSITE_SUPPLEMENTAL_DELETED';
export const CAMPSITE_SUPPLEMENTAL_ERROR = 'CAMPSITE_SUPPLEMENTAL_ERROR';
export const CAMPSITE_SUPPLEMENTAL_UPDATING = 'CAMPSITE_SUPPLEMENTAL_UPDATING';
export const CAMPSITE_SUPPLEMENTAL_UPDATED = 'CAMPSITE_SUPPLEMENTAL_UPDATED';

// reservation
export const CLEAR_RESERVATION_INIT = 'CLEAR_RESERVATION_INIT';
export const CLEAR_INVALID_RESERVATION = 'CLEAR_INVALID_RESERVATION';
export const CLEAR_INVALID_RESERVATION_ERROR = 'CLEAR_INVALID_RESERVATION_ERROR';
export const PENDING_RESERVATION_INITIALIZE = 'PENDING_RESERVATION_INITIALIZE';
export const PENDING_RESERVATION_PROP_CHANGED = 'PENDING_RESERVATION_PROP_CHANGED';
export const PENDING_RESERVATION_PROP_CHANGE_REQUEST =
    'PENDING_RESERVATION_PROP_CHANGE_REQUEST';
export const RESERVATION_UPDATE_STATUS_ERROR = 'RESERVATION_UPDATE_STATUS_ERROR';
export const RESERVATION_UPDATE_STATUS = 'RESERVATION_UPDATE_STATUS';
export const RESERVATION_REPROCESS = 'RESERVATION_REPROCESS';
export const RESERVATION_REPROCESS_ERROR = 'RESERVATION_REPROCESS_ERROR';
export const GET_RESERVATION_REFUND = 'GET_RESERVATION_REFUND';
export const WAIVE_FEES_ERROR = 'WAIVE_FEES_ERROR';
export const SCHEDULE_FACILITY_SITES_ERROR = 'SCHEDULE_FACILITY_SITES_ERROR';
export const GET_RESERVATION_PRICE_DETAILS = 'GET_RESERVATION_PRICE_DETAILS';
export const GET_RESERVATION_PRICE_DETAILS_ERROR = 'GET_RESERVATION_PRICE_DETAILS_ERROR';
export const ASSIGN_ACCOUNT_TO_RESERVATION = 'ASSIGN_ACCOUNT_TO_RESERVATION';
export const ADMIN_HOLD_ASSIGNED = 'ADMIN_HOLD_ASSIGNED';
export const ADMIN_HOLD_ASSIGNED_ERROR = 'ADMIN_HOLD_ASSIGNED_ERROR';
export const CAMPSITE_CHANGE_NOTIFICATION = 'CAMPSITE_CHANGE_NOTIFICATION';
export const GET_VIOLATIONS_RESERVATION = 'GET_VIOLATIONS_RESERVATION';
export const GET_VIOLATIONS_RESERVATION_SUCCESS = 'GET_VIOLATIONS_RESERVATION_SUCCESS';
export const GET_RESERVATIONS_BY_TYPE = 'GET_RESERVATIONS_BY_TYPE';

// recarea
export const PENDING_GATEWAY_INITIALIZE = 'PENDING_GATEWAY_INITIALIZE';
export const PENDING_GATEWAY_PROP_CHANGED = 'PENDING_GATEWAY_PROP_CHANGED';
export const PENDING_GATEWAY_PROP_ROLLBACK = 'PENDING_GATEWAY_PROP_ROLLBACK';
export const PENDING_GATEWAY_EDIT_SUBMIT = 'PENDING_GATEWAY_EDIT_SUBMIT';
export const PENDING_GATEWAY_EDIT_CANCEL = 'PENDING_GATEWAY_EDIT_CANCEL';
export const PENDING_GATEWAY_APPROVE = 'PENDING_GATEWAY_APPROVE';

// Field Sales
export const INIT_RESERVATION_CREATIONS = 'INIT_RESERVATION_CREATIONS';
export const COMPLETE_RESERVATION_CREATIONS_SUCCESS =
    'COMPLETE_RESERVATION_CREATIONS_SUCCESS';
export const COMPLETE_RESERVATION_CREATIONS_FAILURE =
    'COMPLETE_RESERVATION_CREATIONS_FAILURE';
export const INIT_RESERVATION_HOLDS = 'INIT_RESERVATION_HOLDS';
export const COMPLETE_RESERVATION_HOLDS_SUCCESS = 'COMPLETE_RESERVATION_HOLDS_SUCCESS';
export const COMPLETE_RESERVATION_HOLDS_FAILURE = 'COMPLETE_RESERVATION_HOLDS_FAILURE';
export const SET_FIELD_SALES_REDIRECTION_URL = 'SET_FIELD_SALES_REDIRECTION_URL';
export const OPEN_HOLD_MODAL = 'OPEN_HOLD_MODAL';
export const CLOSE_HOLD_MODAL = 'CLOSE_HOLD_MODAL';
export const HIDE_HOLD_NOTIFICATION = 'HIDE_HOLD_NOTIFICATION';

// field sales (EVALUATE)
export const CREATE_CAMPSITE_RESERVATION = 'CREATE_CAMPSITE_RESERVATION';
export const CREATE_CAMPSITE_RESERVATION_ERROR = 'CREATE_CAMPSITE_RESERVATION_ERROR';
export const CREATE_RECURRING_RESERVATIONS = 'CREATE_RECURRING_RESERVATIONS';
export const CREATE_RECURRING_RESERVATIONS_SUCCESS_COMPLETE =
    'CREATE_RECURRING_RESERVATIONS_SUCCESS_COMPLETE';
export const CREATE_RECURRING_RESERVATIONS_SUCCESS_PENDING =
    'CREATE_RECURRING_RESERVATIONS_SUCCESS_PENDING';
export const RESET_RECURRING_RESERVATIONS = 'RESET_RECURRING_RESERVATIONS';
export const CREATE_RENTAL_RESERVATION = 'CREATE_RENTAL_RESERVATION';
export const CREATE_RENTAL_RESERVATION_ERROR = 'CREATE_RENTAL_RESERVATION';
export const GET_CAMPSITE_RESERVATIONS = 'GET_CAMPSITE_RESERVATIONS';
export const GET_CAMPSITE_RESERVATIONS_ERROR = 'GET_CAMPSITE_RESERVATIONS_ERROR';
// export const GET_RENTAL_RESERVATIONS = 'GET_RENTAL_RESERVATIONS'; // TEMPORARY
// export const GET_RENTAL_RESERVATIONS_ERROR = 'GET_RENTAL_RESERVATIONS_ERROR'; // TEMPORARY
export const ALTER_RESERVATION = 'ALTER_RESERVATION';
export const ALTER_RESERVATION_ERROR = 'ALTER_RESERVATION_ERROR';
export const ALTER_RENTAL_RESERVATION = 'ALTER_RESERVATION';
export const ALTER_RENTAL_RESERVATION_ERROR = 'ALTER_RESERVATION_ERROR';
export const CAMPING_GET_CART = 'CAMPING_GET_CART';
export const SET_FIELD_SALES_LOCATION = 'SET_FIELD_SALES_LOCATION';

// Order Details
export const GET_ORDER_DETAILS_RESERVATIONS = 'GET_ORDER_DETAILS_RESERVATIONS';
export const GET_ORDER_DETAILS_RESERVATIONS_ERROR =
    'GET_ORDER_DETAILS_RESERVATIONS_ERROR';
export const GET_RENTAL_RESERVATIONS = 'GET_RENTAL_RESERVATIONS';
export const GET_RENTAL_RESERVATIONS_ERROR = 'GET_RENTAL_RESERVATIONS_ERROR';
export const CLEAR_ALL_RESERVATIONS = 'CLEAR_ALL_RESERVATIONS';
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const OVERRIDE_VALIDATION_ERRORS = 'OVERRIDE_VALIDATION_ERRORS';
export const TRIGGER_ORDER_DETAILS_VALIDATION = 'TRIGGER_ORDER_DETAILS_VALIDATION';
export const COMPLETE_SUBMISSION_FAILURE = 'COMPLETE_SUBMISSION_FAILURE';
export const COMPLETE_SUBMISSION_SUCCESS = 'COMPLETE_SUBMISSION_SUCCESS';
export const CHANGE_ORDER_DETAILS_VIEW = 'CHANGE_ORDER_DETAILS_VIEW';
export const GET_PENDING_RESERVATION_PRICE_DETAILS =
    'GET_PENDING_RESERVATION_PRICE_DETAILS';
export const GET_PENDING_RESERVATION_PRICE_DETAILS_ERROR =
    'GET_PENDING_RESERVATION_PRICE_DETAILS_ERROR';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR';
export const FETCH_ORDER_DETAILS_EXTERNAL_ACCOUNT =
    'FETCH_ORDER_DETAILS_EXTERNAL_ACCOUNT';
export const FETCH_ORDER_DETAILS_EXTERNAL_ACCOUNT_AND_GET_CART =
    'FETCH_ORDER_DETAILS_EXTERNAL_ACCOUNT_AND_GET_CART';
export const SET_ORDER_DETAILS_EXTERNAL_ACCOUNT = 'SET_ORDER_DETAILS_EXTERNAL_ACCOUNT';
export const SET_ORDER_DETAILS_EXTERNAL_ACCOUNT_ERROR =
    'SET_ORDER_DETAILS_EXTERNAL_ACCOUNT_ERROR';
export const SET_ORDER_DETAILS_EXTERNAL_ACCOUNT_AND_GET_CART =
    'SET_ORDER_DETAILS_EXTERNAL_ACCOUNT_AND_GET_CART';
export const SET_REDIRECTION_URL = 'SET_REDIRECTION_URL';
export const REFRESH_PAGE = 'REFRESH_PAGE';

// Access Info
export const GET_ACCESS_INFO = 'GET_ACCESS_INFO';
export const GET_ACCESS_INFO_LIST = 'GET_ACCESS_INFO_LIST';
export const GET_ACCESS_INFO_ERROR = 'GET_ACCESS_INFO_ERROR';
export const UPDATE_ACCESS_INFO = 'UPDATE_ACCESS_INFO';
export const UPDATE_ACCESS_INFO_ERROR = 'UPDATE_ACCESS_INFO_ERROR';
export const DELETE_ACCESS_INFO = 'DELETE_ACCESS_INFO';
export const DELETE_ACCESS_INFO_ERROR = 'DELETE_ACCESS_INFO_ERROR';

// Media
export const GET_MEDIA_PDF = 'GET_MEDIA_PDF';
export const GET_MEDIA_PDF_ERROR = 'GET_MEDIA_PDF_ERROR';
export const UPLOAD_MEDIA_PDF_SUCCESS = 'UPLOAD_MEDIA_PDF_SUCCESS';
export const DELETE_MEDIA_PDF_SUCCESS = 'DELETE_MEDIA_PDF_SUCCESS';
export const EDIT_MEDIA_PDF_SUCCESS = 'EDIT_MEDIA_PDF_SUCCESS';

// Lookups
export const FACILITY_AMENITIES_LOOKUP = 'facility_amenities';
export const FACILITY_ACTIVITIES_LOOKUP = 'facility_activities';
export const GATEWAY_ACTIVITIES_LOOKUP = 'recarea_activities';
export const CAMPSITE_TYPES_LOOKUP = 'campsite_type';
export const CAMPSITE_DETAILS_LABELS_LOOKUP = 'campsite_details_labels';
export const DEPOSIT_WITHHELD_REASON_LOOKUP = 'deposit_withheld_reason';
export const CAMPSITE_EQUIPMENT_LOOKUP = 'campsite_equipment';

// Business Rules
export const RULE_VALUE_PATH = 'value';
export const RULE_UNITS_PATH = 'units';
export const RULE_SECONDARY_VALUE_PATH = 'secondary_value';
export const RULE_START_DATE_PATH = 'start_date';
export const RULE_END_DATE_PATH = 'end_date';
export const RULE_DESCRIPTION_PATH = 'description';

// Occupancy Alerts
export const SET_OCCUPANCY_ALERT_LIST = 'SET_OCCUPANCY_ALERT_LIST';
export const UPDATE_OCCUPANCY_FILTER = 'UPDATE_OCCUPANCY_FILTER';

// Facility Time Zone
// export const TIME_ZONE_RULE_NAME = "facility_time_zone";

// Facility Stay Rules
export const MIN_CONSECUTIVE_STAY_RULE_NAME = 'minConsecutiveStay';
export const MAX_CONSECUTIVE_STAY_RULE_NAME = 'maxConsecutiveStay';
export const MAX_CONCURRENT_STAY_RULE_NAME = 'maxConcurrentStay';
export const MIN_WEEKEND_STAY_RULE_NAME = 'minWeekendStay';
export const MIN_HOLLYDAY_WEEKEND_RULE_NAME = 'minHolidayWeekendStay';
export const MAX_STAY_RULE_NAME = 'maxStay';

// Facility Reservation Rules
export const MAX_TOTAL_RESERVATION_RULE_NAME = 'maxSimultaneousReservations';
export const MAX_TRANSACTION_RESERVATION_RULE_NAME = 'maxTransactionReservations';
export const MAX_RESERVATION_CHANGES_RULE_NAME = 'maxChanges';

// Facility Booking Window Rules
export const RESERVATION_CUTOFF_RULE_NAME = 'reservationCutOff';
export const RESERVATION_WINDOW_STANDARD_RULE_NAME = 'reservationWindow';
export const RESERVATION_WINDOW_GROUP_RULE_NAME = 'groupReservationWindow';
export const BLOCK_RELEASE_RULE_NAME = 'blockReleaseDay';
export const SLIDING_WINDOW_RULE_NAME = 'slidingWindow';

// Recarea Rules
export const STAY_AWAY_RULE_NAME = 'stayAwayRule';
export const RULE_CAMPGROUND_LIST_PATH = 'facility_id_list';

// Down Payment Rule
export const DOWN_PAYMENT_RULE_NAME = 'hasPartialPayment';
export const DOWN_PAYMENT_RULE_PATH = 'partial_payment_deposit';

// Length of stay discount rule
export const LOS_DISCOUNT_RULE_NAME = 'hasLengthOfStayDiscount';
export const LOS_DISCOUNT_RULE_PATH = 'length_of_stay_discount';

// Taxes Enabled Rule
export const TAXES_ENABLED_RULE_NAME = 'hasTaxes';
export const TAXES_ENABLED_RULE_PATH = 'has_taxes';

// Lottery Enabled Rule
export const LOTTERY_ENABLED_RULE_NAME = 'hasLottery';

// Queueing Lottery Enabled Rule
export const QUEUEING_LOTTERY_ENABLED_RULE_NAME = 'hasQueueLottery';

// Public Visitor Self Service Modifications After Arrival Allowed Enabled Rule
export const IN_FLIGHT_MODIFICATION_ENABLED_RULE_NAME = 'allowMidStayModification';

// Disable no sliding window validation rule
export const NO_MODIFICATION_ENABLED_RULE_NAME = 'overrideSlidingWindow';

// Suppress Accessibility Alerts Enabled Rule
export const SUPPRESS_ACCESSIBILITY_ALERTS_ENABLED_RULE_NAME = 'suppressAccessibility';

// Scan & Pay Banner Rule
export const SCAN_AND_PAY_RULE_NAME = 'scanAndPay';

// First night due rule
export const FIRST_NIGHT_DUE_RULE_NAME = 'allowOneNightDown';

export const EARLY_DEPARTURE_NO_REFUND_ENABLED_RULE_NAME = 'allowEarlyDepartureNoRefund';

// Long term stay
export const LONG_TERM_STAY_RULE_NAME = 'allowRecurringPayment';
export const LONG_TERM_STAY_MONTHLY_PAYMENT_DUE = 'recurringPaymentDays';
export const LONG_TERM_LATE_FEE_RULE_NAME = 'hasRecurringLateFees';
export const LONG_TERM_LATE_FEE_AMOUNT_RULE_NAME = 'recurringLateFeeAmount';

// Validations
export const IS_INTERNAL_INPUT_VALID = 'IS_INTERNAL_INPUT_VALID';
export const IS_CAMPSITE_DETAILS_INPUT_VALID = 'IS_CAMPSITE_DETAILS_INPUT_VALID';
export const IS_INVENTORY_INPUT_VALID = 'IS_INVENTORY_INPUT_VALID';

// Facility Time Zone
export const TIME_ZONE_PATH = 'facility_time_zone';

// Scan And Pay Rule
export const SCAN_AND_PAY_RULE_ON_OFF_PATH = `facility_rules.${SCAN_AND_PAY_RULE_NAME}`;
export const SCAN_AND_PAY_VALUE_PATH = `${SCAN_AND_PAY_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const SCAN_AND_PAY_START_DATE_PATH = `${SCAN_AND_PAY_RULE_ON_OFF_PATH}.${RULE_START_DATE_PATH}`;
export const SCAN_AND_PAY_END_DATE_PATH = `${SCAN_AND_PAY_RULE_ON_OFF_PATH}.${RULE_END_DATE_PATH}`;

// Min Consecutive Stay Rule
export const MIN_STAY_RULE_ON_OFF_PATH = `facility_rules.${MIN_CONSECUTIVE_STAY_RULE_NAME}`;
export const MIN_LENGTH_STAY_VALUE_PATH = `${MIN_STAY_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const MIN_LENGTH_STAY_UNIT_PATH = `${MIN_STAY_RULE_ON_OFF_PATH}.${RULE_UNITS_PATH}`;
export const MIN_LENGTH_STAY_START_DATE_PATH = `${MIN_STAY_RULE_ON_OFF_PATH}.${RULE_START_DATE_PATH}`;
export const MIN_LENGTH_STAY_END_DATE_PATH = `${MIN_STAY_RULE_ON_OFF_PATH}.${RULE_END_DATE_PATH}`;
export const MIN_LENGTH_STAY_ADDITIONAL_OPTION_PATH = `${MIN_STAY_RULE_ON_OFF_PATH}.${RULE_SECONDARY_VALUE_PATH}`;
export const MIN_STAY_RULE_DEFAULT_VALUE = 1;

// Max Consecutive Stay Rule
export const MAX_CONSECUTIVE_STAY_RULE_ON_OFF_PATH = `facility_rules.${MAX_CONSECUTIVE_STAY_RULE_NAME}`;
export const MAX_CONSECUTIVE_STAY_VALUE_PATH = `${MAX_CONSECUTIVE_STAY_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const MAX_CONSECUTIVE_STAY_UNIT_PATH = `${MAX_CONSECUTIVE_STAY_RULE_ON_OFF_PATH}.${RULE_UNITS_PATH}`;
export const MAX_CONSECUTIVE_STAY_START_DATE_PATH = `${MAX_CONSECUTIVE_STAY_RULE_ON_OFF_PATH}.${RULE_START_DATE_PATH}`;
export const MAX_CONSECUTIVE_STAY_END_DATE_PATH = `${MAX_CONSECUTIVE_STAY_RULE_ON_OFF_PATH}.${RULE_END_DATE_PATH}`;
export const MAX_CONSECUTIVE_STAY_RULE_DEFAULT_VALUE = 14;

// Max Concurrent Stay Rule
export const MAX_CONCURRENT_STAY_RULE_ON_OFF_PATH = `facility_rules.${MAX_CONCURRENT_STAY_RULE_NAME}`;
export const MAX_CONCURRENT_STAY_VALUE_PATH = `${MAX_CONCURRENT_STAY_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const MAX_CONCURRENT_STAY_UNIT_PATH = `${MAX_CONCURRENT_STAY_RULE_ON_OFF_PATH}.${RULE_UNITS_PATH}`;
export const MAX_CONCURRENT_STAY_START_DATE_PATH = `${MAX_CONCURRENT_STAY_RULE_ON_OFF_PATH}.${RULE_START_DATE_PATH}`;
export const MAX_CONCURRENT_STAY_END_DATE_PATH = `${MAX_CONCURRENT_STAY_RULE_ON_OFF_PATH}.${RULE_END_DATE_PATH}`;

// Stay Away Rule
export const STAY_AWAY_RULE_ON_OFF_PATH = `gateway_rules.${STAY_AWAY_RULE_NAME}`;
export const STAY_AWAY_RULE_ID = `${STAY_AWAY_RULE_ON_OFF_PATH}.rule_id`;
export const STAY_AWAY_RULE_TYPE = `${STAY_AWAY_RULE_ON_OFF_PATH}.rule_type`;
export const STAY_AWAY_RULE_KEY_PATH = `${STAY_AWAY_RULE_ON_OFF_PATH}.rule_value.${RULE_SECONDARY_VALUE_PATH}`;
export const STAY_AWAY_RULE_VALUE_PATH = `${STAY_AWAY_RULE_ON_OFF_PATH}.rule_value.${RULE_VALUE_PATH}`;
export const STAY_AWAY_RULE_UNIT_PATH = `${STAY_AWAY_RULE_ON_OFF_PATH}.rule_value.${RULE_UNITS_PATH}`;
export const STAY_AWAY_RULE_START_DATE_PATH = `${STAY_AWAY_RULE_ON_OFF_PATH}.rule_value.${RULE_START_DATE_PATH}`;
export const STAY_AWAY_RULE_END_DATE_PATH = `${STAY_AWAY_RULE_ON_OFF_PATH}.rule_value.${RULE_END_DATE_PATH}`;
export const STAY_AWAY_RULE_CAMPGROUND_LIST_PATH = `${STAY_AWAY_RULE_ON_OFF_PATH}.${RULE_CAMPGROUND_LIST_PATH}`;

// Max Stay Away Rule
export const STAY_AWAY_DROPDOWN_ITEMS = [
    'Each Month',
    'Each Year',
    'Rolling 30 Days',
    'Rolling 60 Days',
    'Selected Dates',
];

export const GATEWAY_RULE_TYPES = [
    MAX_CONSECUTIVE_STAY_RULE_NAME,
    MAX_CONCURRENT_STAY_RULE_NAME,
    MAX_STAY_RULE_NAME,
];

export const MAX_STAY_AWAY_RULE_ON_OFF_PATH = `facility_rules.${MAX_STAY_RULE_NAME}`;
export const MAX_STAY_WITHIN_KEY_PATH = `${MAX_STAY_AWAY_RULE_ON_OFF_PATH}.${RULE_SECONDARY_VALUE_PATH}`;
export const MAX_STAY_AWAY_VALUE_PATH = `${MAX_STAY_AWAY_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const MAX_STAY_AWAY_UNIT_PATH = `${MAX_STAY_AWAY_RULE_ON_OFF_PATH}.${RULE_UNITS_PATH}`;
export const MAX_STAY_AWAY_START_DATE_PATH = `${MAX_STAY_AWAY_RULE_ON_OFF_PATH}.${RULE_START_DATE_PATH}`;
export const MAX_STAY_AWAY_END_DATE_PATH = `${MAX_STAY_AWAY_RULE_ON_OFF_PATH}.${RULE_END_DATE_PATH}`;
export const MAX_STAY_AWAY_RULE_DEFAULT_VALUE = 14;

// Order Details (Internal)
export const IS_HOLD = 'reservation_options.is_hold';
export const NIGHT_MAP_PATH = 'reservation_options.night_map';
export const IS_ITINERARY_SINGLE_PATH = 'reservation_options.is_itinerary_single';
export const SOURCE_PATH = 'reservation_options.source';
export const OVERRIDE_RULES_PATH = 'reservation_options.override_rules';
export const WAIVE_ALL_FEES_PATH = 'reservation_options.waive_all_fees';
export const WAIVE_CHANGE_FEES_PATH = 'reservation_options.waive_change_fee';
export const WAIVE_RESERVATION_FEES_PATH = 'reservation_options.waive_reservation_fee';
export const OPEN_PASS_CONFIRMATION_MODAL = 'OPEN_PASS_CONFIRMATION_MODAL';
export const CLOSE_PASS_CONFIRMATION_MODAL = 'CLOSE_PASS_CONFIRMATION_MODAL';
export const CONFIRM_PASS = 'CONFIRM_PASS';

// Min Weekend Rule
export const MIN_WEEKEND_RULE_ON_OFF_PATH = `facility_rules.${MIN_WEEKEND_STAY_RULE_NAME}`;
export const MIN_WEEKEND_START_DATE_PATH = `${MIN_WEEKEND_RULE_ON_OFF_PATH}.${RULE_START_DATE_PATH}`;
export const MIN_WEEKEND_END_DATE_PATH = `${MIN_WEEKEND_RULE_ON_OFF_PATH}.${RULE_END_DATE_PATH}`;
export const MIN_WEEKEND_ADDITIONAL_OPTION_PATH = `${MIN_WEEKEND_RULE_ON_OFF_PATH}.${RULE_SECONDARY_VALUE_PATH}`;

// Min Holiday Weekend Rule
export const MIN_HOLIDAY_RULE_ON_OFF_PATH = `facility_rules.${MIN_HOLLYDAY_WEEKEND_RULE_NAME}`;
export const MIN_HOLIDAY_START_DATE_PATH = `${MIN_HOLIDAY_RULE_ON_OFF_PATH}.${RULE_START_DATE_PATH}`;
export const MIN_HOLIDAY_END_DATE_PATH = `${MIN_HOLIDAY_RULE_ON_OFF_PATH}.${RULE_END_DATE_PATH}`;
export const MIN_HOLIDAY_ADDITIONAL_DATE_PATH = `${MIN_HOLIDAY_RULE_ON_OFF_PATH}.${RULE_SECONDARY_VALUE_PATH}`;

// Booking window business rules
export const MAX_TOTAL_RESERVATION_RULE_ON_OFF_PATH = `facility_rules.${MAX_TOTAL_RESERVATION_RULE_NAME}`;
export const MAX_TOTAL_RESERVATION_VALUE_PATH = `${MAX_TOTAL_RESERVATION_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const MAX_TRANSACTION_RESERVATION_RULE_ON_OFF_PATH = `facility_rules.${MAX_TRANSACTION_RESERVATION_RULE_NAME}`;
export const MAX_TRANSACTION_RESERVATION_VALUE_PATH = `${MAX_TRANSACTION_RESERVATION_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const MAX_RESERVATION_CHANGES_RULE_ON_OFF_PATH = `facility_rules.${MAX_RESERVATION_CHANGES_RULE_NAME}`;
export const MAX_RESERVATION_CHANGES_VALUE_PATH = `${MAX_RESERVATION_CHANGES_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Booking window business rules
export const RESERVATION_CUTOFF_RULE_ON_OFF_PATH = `facility_rules.${RESERVATION_CUTOFF_RULE_NAME}`;
export const RESERVATION_CUTOFF_VALUE_PATH = `${RESERVATION_CUTOFF_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const RESERVATION_CUTOFF_TYPE_PATH = `${RESERVATION_CUTOFF_RULE_ON_OFF_PATH}.${RULE_SECONDARY_VALUE_PATH}`;
export const RESERVATION_WINDOW_STANDARD_RULE_ON_OFF_PATH = `facility_rules.${RESERVATION_WINDOW_STANDARD_RULE_NAME}`;
export const RESERVATION_WINDOW_STANDARD_VALUE_PATH = `${RESERVATION_WINDOW_STANDARD_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const RESERVATION_WINDOW_STANDARD_UNIT_PATH = `${RESERVATION_WINDOW_STANDARD_RULE_ON_OFF_PATH}.${RULE_UNITS_PATH}`;
export const RESERVATION_WINDOW_GROUP_RULE_ON_OFF_PATH = `facility_rules.${RESERVATION_WINDOW_GROUP_RULE_NAME}`;
export const RESERVATION_WINDOW_GROUP_VALUE_PATH = `${RESERVATION_WINDOW_GROUP_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const RESERVATION_WINDOW_GROUP_UNIT_PATH = `${RESERVATION_WINDOW_GROUP_RULE_ON_OFF_PATH}.${RULE_UNITS_PATH}`;
export const BLOCK_RELEASE_RULE_ON_OFF_PATH = `facility_rules.${BLOCK_RELEASE_RULE_NAME}`;
export const BLOCK_RELEASE_VALUE_PATH = `${BLOCK_RELEASE_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const SLIDING_WINDOW_RULE_ON_OFF_PATH = `facility_rules.${SLIDING_WINDOW_RULE_NAME}`;
export const SLIDING_WINDOW_VALUE_PATH = `${SLIDING_WINDOW_RULE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const SLIDING_WINDOW_DEFAULT_VALUE = '14';

// Booking Window Rule Units
export const BOOKING_WINDOW_UNIT_DAYS = 'Days';
export const BOOKING_WINDOW_UNIT_MONTHS = 'Months';

// Down Payment Rule
export const DOWN_PAYMENT_ON_OFF_PATH = `facility_rules.${DOWN_PAYMENT_RULE_NAME}`;
export const DOWN_PAYMENT_VALUE_PATH = `${DOWN_PAYMENT_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Long term stay discount rule
export const LOS_DISCOUNT_ON_OFF_PATH = `facility_rules.${LOS_DISCOUNT_RULE_NAME}`;
export const LOS_DISCOUNT_VALUE_PATH = `${LOS_DISCOUNT_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Taxes Enabled Rule
export const TAXES_ENABLED_ON_OFF_PATH = `facility_rules.${TAXES_ENABLED_RULE_NAME}`;
export const TAXES_ENABLED_VALUE_PATH = `${TAXES_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Lottery Enabled Rule
export const LOTTERY_ENABLED_ON_OFF_PATH = `facility_rules.${LOTTERY_ENABLED_RULE_NAME}`;
export const LOTTERY_ENABLED_VALUE_PATH = `${LOTTERY_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Queueing Lottery Enabled Rule
export const QUEUEING_LOTTERY_ENABLED_ON_OFF_PATH = `facility_rules.${QUEUEING_LOTTERY_ENABLED_RULE_NAME}`;
export const QUEUEING_LOTTERY_ENABLED_VALUE_PATH = `${QUEUEING_LOTTERY_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Public Visitor Self Service Modifications After Arrival Allowed Enabled Rule
export const IN_FLIGHT_MODIFICATION_ENABLED_ON_OFF_PATH = `facility_rules.${IN_FLIGHT_MODIFICATION_ENABLED_RULE_NAME}`;
export const IN_FLIGHT_MODIFICATION_ENABLED_VALUE_PATH = `${IN_FLIGHT_MODIFICATION_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Disable no sliding window validation rule
export const NO_MODIFICATION_ENABLED_ON_OFF_PATH = `facility_rules.${NO_MODIFICATION_ENABLED_RULE_NAME}`;
export const NO_MODIFICATION_ENABLED_VALUE_PATH = `${NO_MODIFICATION_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Suppress Accessibility Alerts to Customers Enabled Rule
export const SUPPRESS_ACCESSIBILITY_ALERTS_ENABLED_ON_OFF_PATH = `facility_rules.${SUPPRESS_ACCESSIBILITY_ALERTS_ENABLED_RULE_NAME}`;
export const SUPPRESS_ACCESSIBILITY_ALERTS_ENABLED_VALUE_PATH = `${SUPPRESS_ACCESSIBILITY_ALERTS_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Scan & Pay Rule
export const SCAN_AND_PAY_ENABLED_ON_OFF_PATH = `facility_rules.${SCAN_AND_PAY_RULE_NAME}`;
export const SCAN_AND_PAY_ENABLED_VALUE_PATH = `${SCAN_AND_PAY_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const SCAN_AND_PAY_ENABLED_UNIT_PATH = `${SCAN_AND_PAY_ENABLED_ON_OFF_PATH}.${RULE_UNITS_PATH}`;

// Long term stay rules enabled
export const LONG_TERM_STAY_ENABLED_ON_OFF_PATH = `facility_rules.${LONG_TERM_STAY_RULE_NAME}`;
export const LONG_TERM_STAY_ENABLED_VALUE_PATH = `${LONG_TERM_STAY_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const LONG_TERM_STAY_MONTHLY_PAYMENT_DUE_ON_OFF_PATH = `facility_rules.${LONG_TERM_STAY_MONTHLY_PAYMENT_DUE}`;
export const LONG_TERM_STAY_MONTHLY_PAYMENT_DUE_VALUE_PATH = `${LONG_TERM_STAY_MONTHLY_PAYMENT_DUE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const LONG_TERM_STAY_LATE_FEE_ENABLED_ON_OFF_PATH = `facility_rules.${LONG_TERM_LATE_FEE_RULE_NAME}`;
export const LONG_TERM_STAY_LATE_FEE_VALUE_PATH = `${LONG_TERM_STAY_LATE_FEE_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;
export const LONG_TERM_STAY_LATE_FEE_SECONDARY_VALUE_PATH = `${LONG_TERM_STAY_LATE_FEE_ENABLED_ON_OFF_PATH}.${RULE_SECONDARY_VALUE_PATH}`;
export const LONG_TERM_STAY_LATE_FEE_AMOUNT_PATH = `facility_rules.${LONG_TERM_LATE_FEE_AMOUNT_RULE_NAME}`;
export const LONG_TERM_STAY_LATE_FEE_AMOUNT_VALUE_PATH = `${LONG_TERM_STAY_LATE_FEE_AMOUNT_PATH}.${RULE_VALUE_PATH}`;
export const LONG_TERM_STAY_LATE_FEE_AMOUNT_SECONDARY_VALUE_PATH = `${LONG_TERM_STAY_LATE_FEE_AMOUNT_PATH}.${RULE_SECONDARY_VALUE_PATH}`;

// Early departure no refune
export const EARLY_DEPARTURE_NO_REFUND_ENABLED_ON_OFF_PATH = `facility_rules.${EARLY_DEPARTURE_NO_REFUND_ENABLED_RULE_NAME}`;
export const EARLY_DEPARTURE_NO_REFUND_ENABLED_VALUE_PATH = `${EARLY_DEPARTURE_NO_REFUND_ENABLED_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// First night due rule
export const FIRST_NIGHT_DUE_ON_OFF_PATH = `facility_rules.${FIRST_NIGHT_DUE_RULE_NAME}`;
export const FIRST_NIGHT_DUE_VALUE_PATH = `${FIRST_NIGHT_DUE_ON_OFF_PATH}.${RULE_VALUE_PATH}`;

// Campsite Availability Statuses
export const CAMPSITE_AVAILABLE = 'Available';
export const CAMPSITE_NOT_AVAILABLE = 'Not Available';
export const CAMPSITE_NOT_RESERVABLE = 'Not Reservable';
export const CAMPSITE_NOT_RESERVABLE_MNG = 'Not Reservable Management';
export const CAMPSITE_OPEN = 'Open';

// Fee Types
export const FEE_TYPE_CLIN = 0;
export const FEE_TYPE_TRANSACTION = 1;
export const FEE_TYPE_USE = 2;
export const FEE_TYPE_TICKET = 3;
export const FEE_TYPE_ATTRIBUTE = 4;
export const FEE_TYPE_POS = 5;
export const FEE_TYPE_TAX = 6;
export const FEE_TYPE_LENGTH_OF_STAY_DISCOUNT = 11;

// Reservation Status
export const RESERVATION_STATUSES = {
    Hold: 'HOLD',
    HoldIndefinite: 'HOLD_INDEFINITE',
    Reserved: 'RESERVED',
    CheckedIn: 'CHECKED_IN',
    CheckedOut: 'CHECKED_OUT',
    Cancelled: 'CANCELLED',
    NoShow: 'NOSHOW',
    Altered: 'ALTERED',
    ReservationClosed: 'CLOSED',
    UndoCheckIn: 'UNDO_CHECK_IN',
    pendingAccountTransfer: 'PENDING_ACCOUNT_TRANSFER',
    PENDING: 'PENDING',
    PENDING_MODIFICATION: 'PENDING_MODIFICATION',
    pendingPayment: 'PENDING_PAYMENT',
    pendingCompletion: 'PENDING_COMPLETION',
    MODIFIED: 'MODIFIED',
    EarlyDeparture: 'EARLY_DEPARTURE',
};

export const RESERVATION_MNG_TABS = [
    'Primary Occupant Details',
    'Transfer Campsite or Date',
];

// Gateway Rules
export const PENDING_GATEWAY_RULES_INITIALIZE = 'PENDING_GATEWAY_RULES_INITIALIZE';
export const PENDING_GATEWAY_RULES_PROP_CHANGED = 'PENDING_GATEWAY_RULES_PROP_CHANGED';
export const PENDING_GATEWAY_RULES_PROP_ROLLBACK = 'PENDING_GATEWAY_RULES_PROP_ROLLBACK';

export const GET_GATEWAY_RULES = 'GET_GATEWAY_RULES';
export const GET_GATEWAY_RULES_ERROR = 'GET_GATEWAY_RULES_ERROR';
export const UPDATE_GATEWAY_RULE = 'UPDATE_GATEWAY_RULE';
export const UPDATE_GATEWAY_RULE_ERROR = 'UPDATE_GATEWAY_RULE_ERROR';
export const DELETE_GATEWAY_RULE = 'DELETE_GATEWAY_RULE';
export const DELETE_GATEWAY_RULE_ERROR = 'DELETE_GATEWAY_RULE_ERROR';

// Data Certification field name
export const CERTIFICATION_LOG = 'certification_log';

export const Campground = 'Campground';
export const RecArea = 'Rec Area';
export const Campsite = 'Campsite';

// TYPE OF USE
export const OVERNIGHT = 'Overnight';
export const DAY = 'Day';
export const MULTI = 'Multi';

export const zeroDate = '0001-01-01T00:00:00Z';

// Error Message Mapping
export const ERROR_MESSAGE_MAPPING = {
    'permission denied': 'Your user role does not have permission to perform this task.',
};

// Reservation Manangment
export const SET_IS_FETCHING_RESERVATIONS = 'SET_IS_FETCHING_RESERVATIONS';

// Rental Management
export const SET_IS_FETCHING_RENTAL_RESERVATIONS = 'SET_IS_FETCHING_RENTAL_RESERVATIONS';

// Inventory type
export const INVENTORY_GATEWAY = 'INVENTORY_GATEWAY';

// actions for permit printing
export const PRINT_PERMIT_PDF_ERROR = 'PRINT_PERMIT_PDF_ERROR';

export const FETCH_GROUP_SITE_MONTHLY_REPORT = 'FETCH_GROUP_SITE_MONTHLY_REPORT';
export const FETCH_GROUP_SITE_MONTHLY_REPORT_ERROR =
    'FETCH_GROUP_SITE_MONTHLY_REPORT_ERROR';
export const FETCH_GROUP_SITE_MONTHLY_REPORT_SUCCESS =
    'FETCH_GROUP_SITE_MONTHLY_REPORT_SUCCESS';

export const GIS_FETCH_CAMPSITES = 'GIS_FETCH_CAMPSITES';

export const GIS_SETUP_GEOCAMPSITES = 'GIS_SETUP_GEOCAMPSITES';

// GIS management
export const GIS_FETCH_AMENITIES = 'GIS_FETCH_AMENITIES';
export const GIS_FETCH_AMENITIES_ERROR = 'GIS_FETCH_AMENITIES_ERROR';
export const AGENCY_GIS_MAP = {
    70902: 'usace',
    70903: 'usfs',
    70904: 'nps',
    70901: 'blm',
    BAH5171993: 'bah',
};
export const FEATURE_TYPES = {
    campsite: 'campsite',
    amenity: 'amenity',
};

export const LOAD_TYPE = {
    upload: 'upload',
    onboard: 'onboard',
    deactivate: 'deactivate',
};
