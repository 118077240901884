/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import './GatewayV1Hero.scss';

import * as React from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
    ContentBlock,
    Icon,
    Heading,
    Button,
    MediaGalleryModal,
    FavoriteHeart,
    ExternalLink,
    useWindowSize,
    BREAKPOINTS,
    STATUS,
} from 'sarsaparilla';

import { PARTNERS } from './GatewayV1Hero.utils';
import { LayoutSm } from './LayoutSm';
import { LayoutMd } from './LayoutMd';

import { Photo } from './GatewayV1Hero.types';

const { IDLE, PENDING, ERROR } = STATUS;

interface HeroImageGalleryTallProps {
    galleryImages: Photo[];
    heroImage?: Photo;

    /** API fetch status */
    status: keyof typeof STATUS;

    /** Shows the thumbnail strip at the bottom of the open gallery */
    hasThumbnailStrip: boolean;

    /** Title of the page/hero image */
    title: string;

    /**  Managing government agency */
    partnerAgency?: keyof typeof PARTNERS;

    /** The state where this inventory is located */
    locationState: string;

    /** Total number of campgrounds */
    totalCampgrounds: number;

    /** Total number of activities */
    totalActivities: number;

    /** Total number of POI */
    totalPOI: number;

    /** On Click function for Campgrounds */
    onCampgroundsClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;

    /** On Click function for Activities */
    onActivitiesClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;

    /** On Click function for POI */
    onPOIClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;

    /** Inventory list renders as buttons */
    inventoryListButtons?: boolean;

    /** Url of official website */
    officialUrl?: string;

    /** Additional className added to the outer container */
    className?: string;

    /** Favorite entity id */
    favoriteEntityId?: string;

    /** Favorite entity type */
    favoriteEntityType?: string;

    /** Boolean value on whether to ignore the inventory count */
    isInventoryCountIgnored: boolean;
}

function InventoryItem({
    inventoryListButtons,
    text,
    onClick,
}: {
    inventoryListButtons?: boolean;
    text: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}) {
    if (inventoryListButtons) {
        return (
            <Button onClick={onClick} appearance="link">
                {text}
            </Button>
        );
    }

    return text;
}

export function HeroImageGalleryTall({
    galleryImages = [],
    heroImage,
    title,
    partnerAgency,
    locationState,
    totalCampgrounds = 0,
    totalActivities = 0,
    totalPOI = 0,
    onCampgroundsClick,
    onActivitiesClick,
    onPOIClick,
    inventoryListButtons,
    officialUrl,
    status,
    hasThumbnailStrip = true,
    className,
    favoriteEntityId,
    favoriteEntityType,
    isInventoryCountIgnored = true,
}: HeroImageGalleryTallProps) {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { width = 0 } = useWindowSize();
    const images = Array.isArray(galleryImages) ? [...galleryImages] : [];
    const agencyInfo = partnerAgency ? PARTNERS[partnerAgency] : null;
    const hasInventory = totalCampgrounds >= 1 || totalActivities >= 1 || totalPOI >= 1;

    let galleryImagesToShow = [];

    if (!isEmpty(heroImage)) {
        images.unshift(heroImage);
        // prepend hero image to the gallery
        galleryImagesToShow.push(heroImage);
    }

    galleryImagesToShow = galleryImagesToShow.concat(galleryImages);

    function renderHeroTextContent() {
        return (
            <div
                className={cx('sarsa-hero-image-gallery-body', {
                    'sarsa-hero-image-gallery-body-with-multi-image':
                        galleryImagesToShow.length > 1 && width >= BREAKPOINTS.md,
                })}
            >
                <div className="sarsa-hero-image-gallery-location-info">
                    {agencyInfo && (
                        <>
                            {agencyInfo.icon} {agencyInfo.name}{' '}
                            {locationState ? ' | ' : null}
                        </>
                    )}
                    {locationState}
                </div>
                <Heading
                    headingLevel={1}
                    appearance="h1"
                    className={cx('sarsa-hero-image-gallery-title', {
                        'sarsa-hero-image-gallery-long-title': title.length > 30,
                    })}
                >
                    {title}
                </Heading>
                {isInventoryCountIgnored || hasInventory ? (
                    <ul className="sarsa-hero-image-gallery-inventory-list">
                        {totalCampgrounds > 0 && (
                            <li>
                                <InventoryItem
                                    inventoryListButtons={inventoryListButtons}
                                    text={`${totalCampgrounds} ${
                                        totalCampgrounds > 1
                                            ? 'Campgrounds'
                                            : 'Campground'
                                    }`}
                                    onClick={onCampgroundsClick}
                                />
                            </li>
                        )}
                        {totalActivities > 0 && (
                            <li>
                                <InventoryItem
                                    text={`${totalActivities}  ${
                                        totalActivities > 1 ? 'Activities' : 'Activity'
                                    }`}
                                    onClick={onActivitiesClick}
                                />
                            </li>
                        )}
                        {totalPOI > 0 && (
                            <li>
                                <InventoryItem
                                    text={`${totalPOI} ${
                                        totalPOI && totalPOI > 1
                                            ? 'Points of Interest'
                                            : 'Point of Interest'
                                    }`}
                                    onClick={onPOIClick}
                                />
                            </li>
                        )}
                        {officialUrl && (
                            <li>
                                <ExternalLink hasExternalIcon={false} url={officialUrl}>
                                    <Button
                                        appearance="link"
                                        iconAfterElement={<Icon iconName="launch" />}
                                    >
                                        View Official Website
                                    </Button>
                                </ExternalLink>
                            </li>
                        )}
                        {favoriteEntityId && (
                            <li>
                                <FavoriteHeart
                                    entityId={favoriteEntityId}
                                    entityType={favoriteEntityType}
                                    showFavoriteText
                                />
                            </li>
                        )}
                    </ul>
                ) : null}
            </div>
        );
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // RENDER LOGIC
    //
    // Case: API request is not finished - show grey box
    if (status === PENDING || status === IDLE) {
        return (
            <div className={cx('gateway-v1-hero', className)}>
                <LayoutSm />
            </div>
        );
    }

    // Case: API error, or success but no images - show unavailable image
    if (status === ERROR || !images || images.length === 0) {
        return (
            <div className={cx('gateway-v1-hero', className)}>
                <ContentBlock>{renderHeroTextContent()}</ContentBlock>
                <LayoutSm showUnavailableImage />
            </div>
        );
    }

    // Case: Less than 3 images - show single image
    if (images.length < 3) {
        return (
            <div className={cx('gateway-v1-hero', className)}>
                <ContentBlock>{renderHeroTextContent()}</ContentBlock>
                <LayoutSm image={images[0]} />
            </div>
        );
    }

    // Case: HAPPY PATH 3+ IMAGES!
    return (
        <div>
            <div className={cx('gateway-v1-hero', className)}>
                <ContentBlock>
                    {renderHeroTextContent()}

                    <Button
                        size="xs"
                        appearance="solid-white"
                        iconBeforeElement={<Icon iconName="photo-gallery" />}
                        onClick={openModal}
                        className="view-more-button"
                    >
                        View Photos
                    </Button>
                </ContentBlock>

                {width >= BREAKPOINTS.md && <LayoutMd images={images} />}

                {width < BREAKPOINTS.md && <LayoutSm image={images[0]} />}
            </div>

            <MediaGalleryModal
                images={galleryImagesToShow}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                activeIndex={0}
                hasThumbnailStrip={hasThumbnailStrip}
            />
        </div>
    );
}

// cSpell:ignore USDOT, NOAA, USACE, USFS, USFWS
