/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './Footer.scss';

import * as React from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import cx from 'classnames';

import {
    Icons,
    Accordion,
    ExternalLink,
    ContentBlock,
    Stack,
    Box,
    FlexRow,
    FlexCol,
    Heading,
    Text,
    FeatureFlag,
    IncompatibleBrowserNotice,
    SecurityHelper,
    isMobileDevice,
} from 'sarsaparilla';

import { LDAnnouncement } from 'shared-ui/components/footer-elements/LDAnnouncement';
import { DialogflowChatBot } from 'shared-ui/components/footer-elements/DialogflowChatBot';

import type { FooterProps } from '../../../../types/Footer';
import { COPYRIGHT } from '../../constants/copyright';
import { About } from './About';
import { PlanTrip } from './PlanTrip';
import { GetInvolved } from './GetInvolved';
import { HelpSupport } from './HelpSupport';
import { OurPartners } from './OurPartners';
import { getFooterVisibilityByLocation } from './Footer.utils';

export function Footer({ inline = false }: FooterProps) {
    const location = useLocation();
    const [isFooterLoaded] = React.useState(true);
    const shouldRender = inline || getFooterVisibilityByLocation(location.pathname);
    const isExternalCSR = SecurityHelper.isExternalCSRLoggedIn(); // Do not show part of the footer for external CSRs

    const handleDfMessenger = () => {
        const interval = setInterval(() => {
            const dfRoot = document.querySelector('df-messenger')?.shadowRoot;
            if (dfRoot?.appendChild) {
                clearInterval(interval);
                const input =
                    dfRoot?.children[1]?.children[0]?.shadowRoot?.children[1]?.children[2]
                        ?.shadowRoot?.children[1].children[1].children[0];
                const button = dfRoot?.children[1].children[1] as HTMLElement;
                const image = Array.from(button?.children).filter(
                    (e) => e.tagName === 'IMG'
                )[0];
                button.onfocus = () => {
                    button?.style.setProperty('outline', '#000000 solid 3px');
                };
                button.onblur = () => {
                    button?.style.setProperty('outline', '#000000 solid 0px');
                };
                image?.setAttribute('alt', 'Ask a Question');

                input?.setAttribute('tabindex', '-1');
                button?.setAttribute('aria-label', 'Click here to Ask a Question');
                input?.setAttribute('aria-label', 'Search something');
                button?.addEventListener('focus', () => {
                    if (input?.getAttribute('tabindex') === '-1') {
                        input?.setAttribute('tabindex', '2');
                        button?.setAttribute('tabIndex', '1');
                    } else {
                        input?.setAttribute('tabindex', '-1');
                    }
                });
            }
        }, 1000);
    };

    React.useEffect(() => {
        handleDfMessenger();
    }, []);

    const setAnnouncementClose = () => {};

    if (!shouldRender) return null;

    // don't show header in native mobile app
    if (SecurityHelper.isMobileApp()) return <footer />;

    const accordionItems = [
        {
            heading: 'About',
            content: <About />,
        },
        {
            heading: 'Plan a Trip',
            content: <PlanTrip />,
        },
        {
            heading: 'Get Involved',
            content: <GetInvolved />,
        },
        {
            heading: 'Help & Support',
            content: <HelpSupport isExternalCSR={isExternalCSR} />,
        },
        {
            heading: 'Our Partners',
            content: <OurPartners isFooterInline={inline} />,
        },
    ];

    return (
        <footer
            role={inline ? undefined : 'contentinfo'}
            aria-label="Footer"
            className={cx('r1s-nav-footer', { inline })}
            id="footer"
        >
            <div>
                {/* RecColorBlocks */}
                <ul className="rec-primary-color-blocks">
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                </ul>
                <Box background="dark-blue" className="pb-1 pb-md-6 pt-6">
                    <ContentBlock>
                        <FlexRow>
                            <FlexCol md={12} lg={5} className="nav-footer-copy-wrap">
                                <Stack space="sm">
                                    <Heading headingLevel={2} appearance="h5">
                                        Bring Home a Story!
                                    </Heading>
                                    <Text color="white" className="mb-4 mb-lg-0">
                                        Recreation.gov is the government&apos;s
                                        centralized travel planning platform and
                                        reservation system for 14 federal agencies,
                                        offering the tools, tips, and information needed
                                        for you to discover destinations and activities,
                                        plan a trip, and explore outdoor and cultural
                                        destinations in your zip code and across the
                                        country. Find and reserve incredible experiences
                                        that help you bring home a story through
                                        Recreation.gov!
                                    </Text>
                                </Stack>
                            </FlexCol>

                            <FlexCol
                                sm={12}
                                lg={7}
                                xxl={6}
                                xxlOffset={1}
                                className="nav-footer-list-wrap"
                            >
                                <FlexRow>
                                    <FlexCol
                                        md={3}
                                        lg={12}
                                        xl={3}
                                        className="mb-lg-3 mb-xl-0"
                                    >
                                        <Heading
                                            headingLevel={3}
                                            appearance="h5"
                                            className="mb-1"
                                        >
                                            {accordionItems[0].heading}
                                        </Heading>
                                        <About />
                                    </FlexCol>

                                    <FlexCol
                                        md={3}
                                        lg={12}
                                        xl={3}
                                        className="mb-lg-3 mb-xl-0"
                                    >
                                        <Heading
                                            headingLevel={3}
                                            appearance="h5"
                                            className="mb-1"
                                        >
                                            {accordionItems[1].heading}
                                        </Heading>
                                        <PlanTrip />
                                    </FlexCol>

                                    <FlexCol
                                        md={3}
                                        lg={12}
                                        xl={3}
                                        className="mb-lg-3 mb-xl-0"
                                    >
                                        <Heading
                                            headingLevel={3}
                                            appearance="h5"
                                            className="mb-1"
                                        >
                                            {accordionItems[2].heading}
                                        </Heading>
                                        <GetInvolved />
                                    </FlexCol>

                                    <FlexCol md={3} lg={12} xl={3}>
                                        <Heading
                                            headingLevel={3}
                                            appearance="h5"
                                            className="mb-1"
                                        >
                                            {accordionItems[3].heading}
                                        </Heading>
                                        <HelpSupport isExternalCSR={isExternalCSR} />
                                    </FlexCol>
                                </FlexRow>
                            </FlexCol>
                        </FlexRow>

                        <div className="nav-footer-links-accordion">
                            <Accordion items={accordionItems} />
                        </div>
                    </ContentBlock>
                </Box>
                <Box background="blue-gray" paddingY="md" className="nav-partners-wrap">
                    <ContentBlock>
                        {isFooterLoaded && <OurPartners isFooterInline={inline} />}
                    </ContentBlock>
                </Box>

                <Box background="dark-blue" paddingY="md">
                    <ContentBlock>
                        <FlexRow className="nav-footer-bottom-wrapper">
                            <FlexCol
                                sm={12}
                                lg="variable"
                                lgOrder={2}
                                className="nav-list-social-wrap"
                            >
                                <ul className="nav-footer-inline-list">
                                    <li>
                                        <ExternalLink url="https://www.usa.gov/">
                                            USA.gov
                                        </ExternalLink>
                                    </li>
                                    <li>
                                        <ExternalLink url="https://www.usda.gov/non-discrimination-statement">
                                            Non-Discrimination Statement
                                        </ExternalLink>
                                    </li>
                                    <li>
                                        <a href="/foia">FOIA</a>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="/disclaimers">Disclaimers</a>
                                    </li>
                                </ul>
                                <ul className="nav-footer-social-links">
                                    <li className="social-media">
                                        <ExternalLink url="https://www.facebook.com/Recreation.gov/">
                                            <Icons.IconFacebook />
                                            <span className="rec-sr-only">Facebook</span>
                                        </ExternalLink>
                                    </li>
                                    <li className="social-media">
                                        <ExternalLink url="https://www.instagram.com/recreation_gov/#">
                                            <Icons.IconInstagram />
                                            <span className="rec-sr-only">Instagram</span>
                                        </ExternalLink>
                                    </li>
                                    <li className="social-media">
                                        <ExternalLink url="https://www.pinterest.com/recreationgov/">
                                            <Icons.IconPinterest />
                                            <span className="rec-sr-only">Pinterest</span>
                                        </ExternalLink>
                                    </li>
                                </ul>
                            </FlexCol>
                            <FlexCol lgOrder={1} className="nav-copyright">
                                <Text color="white" size="sm">
                                    {COPYRIGHT}
                                </Text>
                            </FlexCol>
                        </FlexRow>
                    </ContentBlock>
                </Box>
            </div>

            <LDAnnouncement setAnnouncementClose={setAnnouncementClose} />

            <FeatureFlag flag="dialogflowMessengerExternal">
                {!isMobileDevice() && <DialogflowChatBot />}
            </FeatureFlag>

            <IncompatibleBrowserNotice />

            <FlexRow justifyContent="center">
                <FlexCol className="nav-print-only-copyright">
                    <Text color="black" size="sm">
                        {COPYRIGHT}
                    </Text>
                </FlexCol>
            </FlexRow>
        </footer>
    );
}

// cSpell:ignore dialogflow, contentinfo, foia, pinterest
