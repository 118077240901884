/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { entries } from 'lodash';
import PropTypes from 'prop-types';
import { toTitleCase, Icons } from 'sarsaparilla';
import { CAMPING_TYPED_ICONS } from '../helpers/utils';

function CampingLegendControl(props) {
    const renderLegends = (legends) => {
        return entries(legends).map(([iconName, displayNames]) => {
            const Icon = Icons?.[`Icon${iconName}`];

            return (
                <li key={iconName}>
                    <Icon />
                    <span>{Array.from(displayNames).join(', ')}</span>
                </li>
            );
        });
    };

    const populateLegendsObject = (legends, key, value) => {
        const nextLegends = { ...legends };

        nextLegends[key] = nextLegends[key] ?? new Set();
        nextLegends[key].add(value);

        return nextLegends;
    };

    const renderCampsiteLegends = () => {
        const campsiteFeatures = props?.campsites?.features || [];
        const campsiteTypes = campsiteFeatures.map(
            (feat) => feat?.properties?.campsite_type
        );
        let legends = {};

        campsiteTypes.forEach((campsiteType) => {
            const iconName = CAMPING_TYPED_ICONS[campsiteType];
            const displayName = toTitleCase(campsiteType);

            legends = populateLegendsObject(legends, iconName, displayName);
        });

        return renderLegends(legends);
    };

    const renderAmenitiesLegends = () => {
        const amenitiesFeatures = props?.amenities?.features || [];
        const amenitiesProperties = amenitiesFeatures.map((feat) => feat?.properties);
        let legends = {};

        amenitiesProperties.forEach((property) => {
            const { icon, name } = property;
            const dirtyIconStr = icon.replace(/rec-icon-/, '');
            const titleCaseIconStr = toTitleCase(dirtyIconStr);
            const iconName = titleCaseIconStr.replace(/-/g, '');

            legends = populateLegendsObject(legends, iconName, name);
        });

        return renderLegends(legends);
    };

    return (
        <ul>
            {renderCampsiteLegends()}
            {renderAmenitiesLegends()}
        </ul>
    );
}

CampingLegendControl.propTypes = {
    campsites: PropTypes.object,
    amenities: PropTypes.object,
};

export default CampingLegendControl;
