/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * TripBuilder.jsx
 * Created by Destin Frasier on 09/16/19
 */

import React from 'react';
import {FlexRow, FlexCol, Heading} from 'sarsaparilla';

import SiteMapItem from './SiteMapItem';

export default class TripBuilder extends React.PureComponent {
    render() {
        return (
            <FlexRow>
                <FlexCol>
                    <Heading headingLevel={2} appearance="h5">
                        Trip Builder
                    </Heading>
                    <ul className="nav-site-map-wrap">
                        <SiteMapItem
                            name="Trip Builder"
                            url="/trips/new" />
                    </ul>
                </FlexCol>
            </FlexRow>
        )
    }
}
