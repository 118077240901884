/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * Tickets.jsx
 * Created by Destin Frasier on 09/16/19
 */

import React from 'react';
import {FlexRow, FlexCol, Heading} from 'sarsaparilla';

import SiteMapItem from './SiteMapItem';

export default class Tickets extends React.PureComponent {
    render() {
        return (
            <FlexRow>
                <FlexCol>
                    <Heading headingLevel={2} appearance="h5">
                        Tickets
                    </Heading>
                    <ul className="nav-site-map-wrap no-border">
                        <SiteMapItem
                            name="Adams National Historical Park Tours"
                            url="/ticket/facility/300003" />

                        <SiteMapItem
                            name="Blanchard Springs Caverns"
                            url="/ticket/facility/233266" />

                        <SiteMapItem
                            name="Cape Cod National Seashore Ranger-Guided Programs"
                            url="/ticket/facility/250795" />

                        <SiteMapItem
                            name="Cape Hatteras Lighthouse Full Moon Tour"
                            url="/ticket/facility/252034" />

                        <SiteMapItem
                            name="Cape Lookout National Seashore Tours"
                            url="/ticket/facility/252276" />

                        <SiteMapItem
                            name="Carlsbad Caverns National Park Tours"
                            url="/ticket/facility/234637" />

                        <SiteMapItem
                            name="Carrizo Plain National Monument Tours"
                            url="/ticket/facility/233244" />

                        <SiteMapItem
                            name="Carter G. Woodson Home National Historic Site Tours"
                            url="/ticket/facility/261701" />

                        <SiteMapItem
                            name="Chimney Rock National Monument"
                            url="/ticket/facility/234787" />

                        <SiteMapItem
                            name="Congaree National Park Ranger-Guided Canoe Tours"
                            url="/ticket/facility/252279" />

                        <SiteMapItem
                            name="Crystal Cave Tours Sequoia National Park"
                            url="/ticket/facility/251898" />

                        <SiteMapItem
                            name="Dog Mountain Trail System Permit"
                            url="/ticket/facility/273800" />

                        <SiteMapItem
                            name="Eleanor Roosevelt National Historic Site"
                            url="/ticket/facility/300001" />

                        <SiteMapItem
                            name="Fiery Furnace Loop Tour - Arches"
                            url="/ticket/facility/234668" />

                        <SiteMapItem
                            name="Firefly Event Lottery"
                            url="/ticket/facility/233374" />

                        <SiteMapItem
                            name="Frederick Douglass National Historic Site Tours"
                            url="/ticket/facility/234636" />

                        <SiteMapItem
                            name="Gateway National Recreation Area - Sandy Hook Canoe Cruise"
                            url="/ticket/facility/252275" />

                        <SiteMapItem
                            name="Haleakala National Park Summit Sunrise Reservations"
                            url="/ticket/facility/253731" />

                        <SiteMapItem
                            name="Home of Franklin D. Roosevelt National Historic Site"
                            url="/ticket/facility/300000" />

                        <SiteMapItem
                            name="Independence National Historical Park Tours"
                            url="/ticket/facility/234639" />

                        <SiteMapItem
                            name="Joshua Tree National Park Tours"
                            url="/ticket/facility/300004" />

                        <SiteMapItem
                            name="Klondike Gold Rush National Historical Park"
                            url="/ticket/facility/251937" />

                        <SiteMapItem
                            name="Lava Beds National Monument Tours"
                            url="/ticket/facility/251978" />

                        <SiteMapItem
                            name="Lehman Caves Tours"
                            url="/ticket/facility/251853" />

                        <SiteMapItem
                            name="Mammoth Cave National Park Tours"
                            url="/ticket/facility/234640" />

                        <SiteMapItem
                            name="Mesa Verde National Park"
                            url="/ticket/facility/233362" />

                        <SiteMapItem
                            name="Minute Man National Historical Park Tours"
                            url="/ticket/facility/252277" />

                        <SiteMapItem
                            name="National Archives"
                            url="/ticket/facility/234645" />

                        <SiteMapItem
                            name="National Christmas Tree Lighting Ceremony"
                            url="/ticket/facility/250006" />

                        <SiteMapItem
                            name="Oregon Caves National Monument and Preserve Tours"
                            url="/ticket/facility/251610" />

                        <SiteMapItem
                            name="Pearl Harbor Historic Sites (USS Arizona)"
                            url="/ticket/facility/233338" />

                        <SiteMapItem
                            name="Pecos National Historical Park Fishing Reservations"
                            url="/ticket/facility/274313" />

                        <SiteMapItem
                            name="Perry&#39;s Victory and International Peace Memorial"
                            url="/ticket/facility/274287" />

                        <SiteMapItem
                            name="Picket Wire Canyonlands Guided Auto Tour"
                            url="/ticket/facility/234166" />

                        <SiteMapItem
                            name="Piedras Blancas Light Station"
                            url="/ticket/facility/252948" />

                        <SiteMapItem
                            name="Rapidan Camp Tours"
                            url="/ticket/facility/251745" />

                        <SiteMapItem
                            name="Roosevelt And Vanderbilt National Historic Sites (Includes The Home Of Franklin D. Roosevelt Eleanor Roosevelt And Vanderbilt Mansion National Historic Sites)"
                            url="/ticket/facility/234638" />

                        <SiteMapItem
                            name="Sagamore Hill National Historic Site Tours"
                            url="/ticket/facility/251573" />

                        <SiteMapItem
                            name="San Francisco Maritime National Historic Park Tours"
                            url="/ticket/facility/249985" />

                        <SiteMapItem
                            name="Scotty&#39;s Castle - Death Valley National Park"
                            url="/ticket/facility/233282" />

                        <SiteMapItem
                            name="Sweet Home Nature And Heritage Tours"
                            url="/ticket/facility/233373" />

                        <SiteMapItem
                            name="The White House Easter Egg Roll"
                            url="/ticket/facility/250029" />

                        <SiteMapItem
                            name="Timpanogos Cave National Monument Tours"
                            url="/ticket/facility/249993" />

                        <SiteMapItem
                            name="Tumacacori National Historical Park Tours"
                            url="/ticket/facility/233274" />

                        <SiteMapItem
                            name="Vanderbilt Mansion National Historic Site"
                            url="/ticket/facility/300002" />

                        <SiteMapItem
                            name="Voyageurs National Park Tours"
                            url="/ticket/facility/233405" />

                        <SiteMapItem
                            name="Washington Monument"
                            url="/ticket/facility/234635" />

                        <SiteMapItem
                            name="Whiskeytown National Recreation Area Ranger-Guided Kayak And Paddle Board Tours"
                            url="/ticket/facility/274349" />

                        <SiteMapItem
                            name="White Sands National Monument Tours"
                            url="/ticket/facility/253503" />

                        <SiteMapItem
                            name="Yaquina Head Lighthouse Tours"
                            url="/ticket/facility/251944" />
                    </ul>
                </FlexCol>
            </FlexRow>
        )
    }
}
