/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { LoggingHelper } from 'sarsaparilla';

// Adapted from detectmobilebrowser.com:
export function isMobileBrowser() {
    return (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|ad|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s)|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v)|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v)|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-|)|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substr(0, 4)
            )
        )
            return true;
        return false;
    })(navigator.userAgent || navigator.vendor || window.opera);
}

export function isEmpty(str) {
    return !str || str.length === 0;
}

// This is needed to compensate for fetch's lack of url parameter management without string interpolation
export function urlWithParams(urlString, params = {}) {
    let searchParams = '';
    Object.keys(params).forEach((key) => {
        let sep = '';
        if (searchParams !== '') {
            sep = '&';
        }
        if (params[key] || params[key] === 0) {
            const val = params[key];
            if (!isEmpty(val) || val === 0) {
                if (Array.isArray(val)) {
                    val.forEach((v) => {
                        if (searchParams !== '') {
                            sep = '&';
                        }
                        searchParams = `${searchParams + sep + key}=${encodeURIComponent(
                            v
                        )}`;
                    });
                } else {
                    searchParams = `${searchParams + sep + key}=${encodeURIComponent(
                        val
                    )}`;
                }
            }
        }
    });
    let ret = urlString;
    if (!isEmpty(searchParams)) {
        ret = `${ret}?${searchParams}`;
    }
    return ret;
}

let lastId = 0;

export function newId(prefix = 'id') {
    lastId += 1;
    return `${prefix}${lastId}`;
}

// fetch doesn't handle errors well
export function status(response) {
    if (response.status >= 200 && response.status < 300) {
        // console.log(response.status, response)
        return Promise.resolve(response);
    }
    LoggingHelper.logError(response.statusText);
    // return Promise.reject(new Error(response.statusText))
    return response
        .json()
        .catch(() => {
            const error = new Error(response.statusText);
            error.response = response;
            // throw error
            return Promise.resolve(response);
        })
        .then((data) => {
            const error = new Error(data || response.statusText);
            error.response = response;
            // throw error
            return Promise.resolve(data);
        });
}

// parse fetch response to json
export function json(response) {
    return response.json();
}

// helper method to dispatch UI errors
export function getFailed(message) {
    return {
        type: 'GET_ERROR',
        payload: message,
        error: true,
    };
}

export function getWeekDayStr(day) {
    switch (day) {
        case 0:
            return 'Su';
        case 1:
            return 'M';
        case 2:
            return 'Tu';
        case 3:
            return 'W';
        case 4:
            return 'Th';
        case 5:
            return 'F';
        case 6:
            return 'Sa';
        default:
            return 'Invalid';
    }
}

export function getHoldsStatusStr(statusStr) {
    switch (statusStr) {
        case 'Available':
            return 'A';
        case 'Reservation':
            return 'R';
        case 'Temporary':
            return 'X';
        case 'Administrative':
            return 'D';
        default:
            return 'N';
    }
}

export function getStatusStateStr(statusStr) {
    switch (statusStr) {
        case 'Available':
            return 'rec-state-blue';
        case 'Reservation':
            return 'rec-state-red';
        case 'Temporary':
            return 'rec-state-green';
        case 'Administrative':
            return 'rec-state-white';
        default:
            return 'rec-state-gray';
    }
}

export function hasCartLimitExceededError(errorString) {
    return /number of reservations in cart exceeded/.test(errorString);
}
