/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * BasicMap is a simple template to
 * render a single data source.
 * Currently supports only point data
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { logoPngSrc } from 'site-kit';

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        flexDirection: 'column',
        marginTop: 20,
        padding: 20,
    },
    section: {
        flexGrow: 1,
    },
    siteLogo: {
        maxWidth: 225,
        maxHeight: 36,
    },
    headerContainer: {
        marginTop: 12,
        marginBottom: 16,
    },
    title: {
        color: '#565C65',
        fontSize: 12,
    },
    subtitle: {
        color: '#565C65',
        fontSize: 12,
        marginTop: 5,
    },
    description: {
        color: '#565C65',
        fontSize: 11,
        marginTop: 5,
    },
    copyright: {
        marginTop: 12,
        color: '#565C65',
        fontSize: 10,
        textAlign: 'center',
    },
    copyrightContainer: {
        flexDirection: 'column-reverse',
        flexGrow: 2,
        marginBottom: 20,
    },
});

function MapPDFDocument(props = {}) {
    return (
        <Document>
            <Page size="A4" style={styles?.page}>
                <View style={styles?.section}>
                    <Image alt="site logo" src={logoPngSrc} style={styles?.siteLogo} />
                    <View style={styles?.headerContainer}>
                        {!!props.title && (
                            <Text style={styles?.title}>{props.title}</Text>
                        )}
                        {!!props.subtitle && (
                            <Text style={styles?.subtitle}>{props.subtitle}</Text>
                        )}
                        {!!props.description && (
                            <Text style={styles?.description}>{props.description}</Text>
                        )}
                    </View>
                    {!!props.image && <Image alt="map" src={props.image} />}
                </View>
                <View style={styles?.copyrightContainer}>
                    <Text style={styles?.copyright}>
                        {`Copyright 2023 ${process.env.SITE_NAME}. All rights reserved.`}
                    </Text>
                </View>
            </Page>
        </Document>
    );
}

MapPDFDocument.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
};

export default MapPDFDocument;
