/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { orderBy, groupBy } from 'lodash';
import {
    NoMapboxGlNotice,
    FlexRow,
    FlexCol,
    useFlags,
    Select,
    announce,
    Icons,
    Button,
    Badge,
    useWindowSize,
} from 'sarsaparilla';
import MapSearchBox from './MapSearchBox';
import { HomepageSearchResult } from './HomepageSearchResult';
import { HomepageFilter } from './HomepageFilter';
import { useOnClickOutside } from '../../../../../ui-camping/src/shared/hooks/useOnClickOutside';
import HomepageMapContainer from './HomepageMapContainer';

export default function HomepageMapWrapper({
    isMapboxGlSupported,
    selectedMapSearchTerm,
    updateSelectedMapSearchTerm,
    performMapSearch,
    clearSuggestions,
    suggestions,
    selectSearchResult,
    mapInventory,
    mapSearchResultLocation,
    fetchMapInventory,
    ignoreSessionStore,
    mapWrapper,
    selectedCardItem,
    uiFilters,
    filteredMapInventory,
    inventoryIsLoaded,
    isGatewayMap,
}) {
    const filtersLength = Object.keys(uiFilters).length > 0;
    const { useNavSidebar } = useFlags();

    const [currentSortOpt, setCurrentSortOpt] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [hasFilters, setHasFilters] = useState(filtersLength);
    const [mapData, setMapData] = useState(
        filtersLength ? filteredMapInventory : mapInventory
    );
    const [filterCounts, setFilterCounts] = useState({});
    const [mapInv, setMapInv] = useState(mapInventory);
    const [hasInvData, setHasInvData] = useState(false);
    const [showNavSidebar, setShowNavSidebar] = useState(false);
    const [shouldBeFullWidth, setShouldBeFullWidth] = useState(false);
    const filterRef = useRef();
    const searchResultRef = useRef();
    const [showSearchResult, setShowSearchResult] = useState(false);
    const { width } = useWindowSize(200);
    const navFilterRef = useRef();

    useEffect(() => {
        setHasFilters(Object.keys(uiFilters).length > 0);
    }, [uiFilters]);

    useEffect(() => {
        setMapInv(mapInventory);
        setHasInvData(!!mapInventory.length);
    }, [mapInventory]);

    const updateMapData = () => {
        setMapData(hasFilters ? filteredMapInventory : mapInventory);
    };

    const onShowFullMapClick = () => {
        setShouldBeFullWidth(true);
        setShowNavSidebar(!showNavSidebar);
    };

    useEffect(() => {
        updateMapData();
    }, []);

    const countInvPerEntity = (data) => {
        const campgroundItems = data.filter((item) => item.entity_type === 'campground');
        const invItems = data.filter((item) => item.entity_type !== 'campground');
        const campGroup = groupBy(campgroundItems, 'campsite_type_of_use');
        const invGroup = groupBy(invItems, 'entity_type');
        campGroup.size = campgroundItems.length;
        invGroup.campground = campGroup;
        return invGroup;
    };

    useEffect(() => {
        setFilterCounts(countInvPerEntity(mapInventory));
    }, [mapInventory]);

    // Handle window resize
    useEffect(() => {
        setShowNavSidebar(width > 790 && !shouldBeFullWidth);
    }, [width]);

    // Sorting
    const ASC = 'asc';
    const DESC = 'desc';

    const sortInventory = (features, sortOpt) => {
        if (sortOpt) {
            setMapData(orderBy(features, 'name', sortOpt));
        } else {
            setMapData(features);
        }
    };

    useEffect(() => {
        sortInventory(hasFilters ? filteredMapInventory : mapInv, currentSortOpt);
    }, [mapInv, filteredMapInventory, hasFilters]);

    const onSortChange = (val) => {
        const message = 'The navigation results are now sorted by name';

        if (val === ASC) {
            sortInventory(mapData, ASC);
            setCurrentSortOpt(ASC);
            announce(`${message} in ascending order.`);
        } else if (val === DESC) {
            sortInventory(mapData, DESC);
            setCurrentSortOpt(DESC);
            announce(`${message} in descending order.`);
        }
    };

    const sortOpts = [
        { label: 'Alphabetical: A-Z', value: ASC },
        { label: 'Alphabetical: Z-A', value: DESC },
    ];

    const onFilterClick = () => {
        setShowFilters(!showFilters);
    };

    const renderMapSearchBox = () => {
        return (
            <MapSearchBox
                selectedMapSearchTerm={selectedMapSearchTerm}
                updateSelectedMapSearchTerm={updateSelectedMapSearchTerm}
                performMapSearch={performMapSearch}
                clearSuggestions={clearSuggestions}
                suggestions={suggestions}
                selectSearchResult={selectSearchResult}
                dynamicClassName={
                    useNavSidebar
                        ? 'nav-map-sidebar-search-input'
                        : 'nav-map-full-width-search-input'
                }
            />
        );
    };

    const renderMap = () => {
        return (
            <div className="map-background">
                <HomepageMapContainer
                    mapInventory={mapData}
                    mapSearchResultLocation={mapSearchResultLocation}
                    selectedMapSearchTerm={selectedMapSearchTerm}
                    fetchMapInventory={fetchMapInventory}
                    ignoreSessionStore={ignoreSessionStore}
                    mapWrapper={mapWrapper}
                    selectedCardItem={selectedCardItem}
                    showNavSidebar={showNavSidebar}
                    setShowNavSidebar={setShowNavSidebar}
                    useNavSidebar={useNavSidebar}
                    setShouldBeFullWidth={setShouldBeFullWidth}
                    shouldBeFullWidth={shouldBeFullWidth}
                    width={width}
                    updateSelectedMapSearchTerm={updateSelectedMapSearchTerm}
                />
            </div>
        );
    };

    useOnClickOutside(navFilterRef, () => {
        setShowFilters(false);
    });

    useOnClickOutside(searchResultRef, () => {
        if (showSearchResult) {
            setShowSearchResult(false);
        }
    });

    // TODO: Remove simple call to map when removing the flag,
    // and might just merge this script into HomepageSearchResult
    const renderNavMap = () => {
        const filtersCount = Object.keys(uiFilters).length;
        const navMapContainerProps =
            showNavSidebar && useNavSidebar && !isGatewayMap
                ? { md: 6, lg: 7, xxl: 8 }
                : { className: 'nav-map-container-full-width' };

        return (
            <div className="nav-map">
                {isMapboxGlSupported && (
                    <FlexRow>
                        {useNavSidebar && showNavSidebar && !isGatewayMap && (
                            <FlexCol
                                sm="auto"
                                className="nav-map-search-container-wrapper"
                            >
                                <div className="nav-map-search-container">
                                    <div className="nav-map-v2-buttons-container">
                                        <div className="nav-map-filters-search-wrap">
                                            {renderMapSearchBox()}
                                        </div>
                                        <div className="nav-map-filters-sort-by-wrap">
                                            <Select
                                                placeholder="Sort By"
                                                label="Sort By"
                                                id="nav-map-search-sort-dropdown"
                                                options={sortOpts}
                                                onChange={(e) =>
                                                    onSortChange(e.target.value)
                                                }
                                                value={currentSortOpt}
                                                isDisabled={!hasInvData}
                                                gaTrackingId="876406516767"
                                            />
                                        </div>
                                    </div>
                                    <div className="nav-map-links-container">
                                        <div
                                            className="nav-map-filters-filter-by-wrap"
                                            ref={navFilterRef}
                                        >
                                            <Button
                                                appearance="link"
                                                className="nav-filter-button"
                                                shouldFitContainer
                                                iconBeforeElement={
                                                    <Icons.IconFilterList />
                                                }
                                                aria-label="Filters"
                                                aria-expanded={showFilters}
                                                onClick={onFilterClick}
                                                isDisabled={!hasInvData}
                                                gaTrackingId="193598830690"
                                            >
                                                <span className="nav-map-v2-filter-text">
                                                    {'Filters'}
                                                </span>
                                                {filtersCount > 0 && (
                                                    <Badge
                                                        className="nav-map-v2-badge ml-half"
                                                        appearance="primary"
                                                    >
                                                        {filtersCount}
                                                    </Badge>
                                                )}
                                            </Button>
                                            {showFilters && (
                                                <HomepageFilter
                                                    filterRef={filterRef}
                                                    uiFilters={uiFilters}
                                                    counts={filterCounts}
                                                />
                                            )}
                                        </div>
                                        <div className="nav-map-show-full-map-container">
                                            <Button
                                                appearance="link"
                                                className="nav-show-full-map-button"
                                                shouldFitContainer
                                                iconBeforeElement={<Icons.IconMap />}
                                                aria-label="Show full map"
                                                onClick={onShowFullMapClick}
                                                gaTrackingId="665401403964"
                                            >
                                                <span className="nav-map-v2-filter-text">
                                                    {'Show Full Map'}
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <HomepageSearchResult
                                    mapInventory={mapData}
                                    inventoryIsLoaded={inventoryIsLoaded}
                                />
                            </FlexCol>
                        )}
                        <FlexCol {...navMapContainerProps}>
                            {renderMap()}
                            {(!useNavSidebar || !showNavSidebar) && (
                                <div className="nav-map-search-wrapper">
                                    {renderMapSearchBox()}
                                </div>
                            )}
                        </FlexCol>
                    </FlexRow>
                )}
                {!isMapboxGlSupported && <NoMapboxGlNotice />}
            </div>
        );
    };

    return renderNavMap();
}

HomepageMapWrapper.propTypes = {
    isMapboxGlSupported: PropTypes.bool,
    selectedMapSearchTerm: PropTypes.string,
    updateSelectedMapSearchTerm: PropTypes.func,
    performMapSearch: PropTypes.func,
    clearSuggestions: PropTypes.func,
    suggestions: PropTypes.array,
    selectSearchResult: PropTypes.func,
    mapInventory: PropTypes.array,
    mapSearchResultLocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }),
    fetchMapInventory: PropTypes.func,
    ignoreSessionStore: PropTypes.func,
    mapWrapper: PropTypes.object,
    uiFilters: PropTypes.object,
    filteredMapInventory: PropTypes.array,
    inventoryIsLoaded: PropTypes.bool,
    isGatewayMap: PropTypes.bool,
};

HomepageMapWrapper.defaultProps = {
    mapInventory: [],
    uiFilters: {},
    filteredMapInventory: [],
    isGatewayMap: false,
};
