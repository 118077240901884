/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { formatMoney as formatMoneySarsa, capitalize } from 'sarsaparilla';
import moment from 'moment-timezone';
import { get, keys, isArray } from 'lodash';
import { BOUNDARY_WATERS_PERMIT_ID } from 'ui-boundarywaters/src/constants';
import { issuanceStatuses } from '../../../ui-permit/src/utils/utils';
import { stringToObject, arraybufferToString } from '../../../ui-ticket/src/utils';
import * as permitTypes from '../../../ui-permit/src/constants';
// eslint-disable-next-line
import {
    INVENTORY_QUEUE_LOTTERY,
    INVENTORY_VENUE_APPLICATION,
    TICKETS_RESERVATION_TABS,
} from '../constants';

export function getLotteryStatusString(status, inventoryType) {
    switch (status) {
        case 'UNKNOWN':
            return 'Unknown';
        case 'PENDING':
            return 'Pending Results';
        case 'REJECTED':
            return 'Unsuccessful';
        case 'ACCEPTED':
            return inventoryType === INVENTORY_QUEUE_LOTTERY ? 'Successful' : 'Confirmed';
        case 'CANCELLED':
            return 'Cancelled';
        case 'DECLINED':
            return 'Declined';
        case 'LOST':
            return 'Unsuccessful';
        case 'SUBMITTED':
            return 'Submitted';
        case 'RESERVED':
            return 'Successful';
        case 'VOIDED':
            return 'Voided';
        case 'WON':
            return 'Won';
        default:
            return status;
    }
}

export function formatMoney(val) {
    return formatMoneySarsa(val);
}

export function formatDate(val) {
    return moment(val).utc().format('ll');
}

export function formatDateTz(val, timezone) {
    const date = timezone ? moment(val).tz(timezone) : moment(val).utc();
    return date.format('ll');
}

export function getReservationDetailsUrl(orderId, reservationId) {
    return `/account/orders/${orderId}/reservations/${reservationId}`;
}

export function getReviewReservationUrl(orderId, reservationId) {
    return `/account/orders/${orderId}/reservations/${reservationId}/review/rating`;
}

export function getReceiptUrl(orderId) {
    return `/account/orders/${orderId}`;
}

export function getCancelUrl(orderId, reservationId) {
    return `/account/orders/${orderId}/reservations/${reservationId}/cancel`;
}

export function getRentalReservationDetailsUrl(orderId, reservationId) {
    return `${getReservationDetailsUrl(orderId, reservationId)}/rentals/viewReservation`;
}

export function getCampingReservationDetailsUrl(orderId, reservationId) {
    return `${getReservationDetailsUrl(orderId, reservationId)}/camping/viewReservation`;
}

export function getTicketReservationDetailsUrl(orderId, reservationId) {
    return `${getReservationDetailsUrl(orderId, reservationId)}/tickets/${
        TICKETS_RESERVATION_TABS?.viewReservation?.value
    }`;
}

export function getTicketReceiptUrl(orderId, reservationId) {
    return `${getReservationDetailsUrl(orderId, reservationId)}/tickets/${
        TICKETS_RESERVATION_TABS?.viewReceipt?.value
    }`;
}

export function getTicketModifyUrl(orderId, reservationId) {
    return `${getReservationDetailsUrl(orderId, reservationId)}/tickets/${
        TICKETS_RESERVATION_TABS?.orderDetails?.value
    }`;
}

export function getTicketCancelUrl(orderId, reservationId) {
    return `${getReservationDetailsUrl(orderId, reservationId)}/tickets/${
        TICKETS_RESERVATION_TABS?.cancelReservation?.value
    }`;
}

export function issuePermitReservationUrl(permitId, issuanceId) {
    return `/api/permits/${permitId}/issuances/${issuanceId}/issue`;
}

export function tourTicketReservationUrl(permitId, tourId) {
    return `/api/permits/${permitId}/issuances/${tourId}/issue`;
}

export function canModifyReservation(r) {
    return r.can_modify;
}

export function canModifyIssuance({ status }) {
    return (
        status !== issuanceStatuses.get(permitTypes.PERMIT_ISSUED) &&
        status !== issuanceStatuses.get(permitTypes.PERMIT_CANCELLED_BY_USER) &&
        status !== issuanceStatuses.get(permitTypes.PERMIT_REFUNDED) &&
        status !== issuanceStatuses.get(permitTypes.PERMIT_NO_SHOW)
    );
}

const featureFlagMappings = {
    account: {
        includes: (account, path, values) => {
            if (isArray(values)) {
                return values.includes(get(account, path, 'NAN'));
            }
            return false;
        },
    },
};

export const featuresHelperBuilder = (features) => {
    const retObj = {};
    keys(features).forEach((key) => {
        const condition = features[key].condition;
        const action = get(condition, 'action', '');
        const entity = get(condition, 'entity', '');
        const helper = get(featureFlagMappings, [entity, action]);
        let func;
        if (helper) {
            func = (val) => helper(val, condition.path, condition.values);
        }
        if (func) {
            retObj[key] = { [condition.entity]: func };
        }
    });
    return retObj;
};

export const getStateNameFromStateCode = (stateCode) => {
    const states = [
        ['Arizona', 'AZ'],
        ['Alabama', 'AL'],
        ['Alaska', 'AK'],
        ['Arizona', 'AZ'],
        ['Arkansas', 'AR'],
        ['California', 'CA'],
        ['Colorado', 'CO'],
        ['Connecticut', 'CT'],
        ['Delaware', 'DE'],
        ['Florida', 'FL'],
        ['Georgia', 'GA'],
        ['Hawaii', 'HI'],
        ['Idaho', 'ID'],
        ['Illinois', 'IL'],
        ['Indiana', 'IN'],
        ['Iowa', 'IA'],
        ['Kansas', 'KS'],
        ['Kentucky', 'KY'],
        ['Kentucky', 'KY'],
        ['Louisiana', 'LA'],
        ['Maine', 'ME'],
        ['Maryland', 'MD'],
        ['Massachusetts', 'MA'],
        ['Michigan', 'MI'],
        ['Minnesota', 'MN'],
        ['Mississippi', 'MS'],
        ['Missouri', 'MO'],
        ['Montana', 'MT'],
        ['Nebraska', 'NE'],
        ['Nevada', 'NV'],
        ['New Hampshire', 'NH'],
        ['New Jersey', 'NJ'],
        ['New Mexico', 'NM'],
        ['New York', 'NY'],
        ['North Carolina', 'NC'],
        ['North Dakota', 'ND'],
        ['Ohio', 'OH'],
        ['Oklahoma', 'OK'],
        ['Oregon', 'OR'],
        ['Pennsylvania', 'PA'],
        ['Rhode Island', 'RI'],
        ['South Carolina', 'SC'],
        ['South Dakota', 'SD'],
        ['Tennessee', 'TN'],
        ['Texas', 'TX'],
        ['Utah', 'UT'],
        ['Vermont', 'VT'],
        ['Virginia', 'VA'],
        ['Washington', 'WA'],
        ['West Virginia', 'WV'],
        ['Wisconsin', 'WI'],
        ['Wyoming', 'WY'],
    ];
    const code = stateCode.toUpperCase();
    let stateName = code;
    for (let i = 0; i < states.length; i += 1) {
        if (states[i][1] === code) {
            stateName = states[i][0];
        }
    }
    return stateName;
};

export function getReservationsUrl(reservation = null) {
    if (process.env.FEATURES?.reservations_listing) {
        if (reservation) {
            // cancelled reservation check and listing
            if (reservation?.status === 'CANCELLED' || reservation?.status === 'VOIDED') {
                return '/account/reservations/cancelled';
            }
            // past reservation check and listing
            const today = moment.utc().startOf('day');
            const resStart = moment.utc(reservation.reservation_start);
            const resEnd = moment.utc(reservation.reservation_end);
            const isBoundaryWaters =
                reservation.location_id === BOUNDARY_WATERS_PERMIT_ID;
            if (resStart && resEnd && resEnd.isAfter(resStart)) {
                if (resEnd.isBefore(today)) {
                    return '/account/reservations/past';
                }
            } else if (isBoundaryWaters) {
                if (today.isAfter(resStart.add(7, 'd'))) {
                    return '/account/reservations/past';
                }
            } else if (today.isAfter(resStart)) {
                return '/account/reservations/past';
            }
        }
        // all others go to upcoming
        return '/account/reservations/upcoming';
    }
    return '/account/reservations';
}

export const getPrintError = (error) => {
    let message =
        'Oops! We’re having a little trouble loading your tickets. Please check your email inbox or try again.';
    const data = stringToObject(arraybufferToString(error?.response?.data));
    if (
        data?.error?.includes(
            'reservation must be in either CONFIRMED or DELIVERED status to be printed'
        )
    ) {
        message =
            'Your reservation is not yet ready to print, please wait and try again in a few minutes.';
    }

    return { message, data };
};

export const getGuestTypeByGuestTypeId = (guestTypeId = '') => {
    return guestTypeId
        .split('_')
        .slice(1)
        .map((wordChunk) => {
            return capitalize(wordChunk);
        })
        .join(' ');
};

export const hideZeroAmtCampingFee = (cartItem, campingIndex) => {
    // this is only happening to CAMPING USE FEE
    // if there is more than one camping use fee and one of them has a 0 subtotal and other camping use Fees have subtotals.
    // then skip the one with the 0 subtotal

    const qty = campingIndex + 1;
    const hasSubtotal = cartItem && cartItem.subtotal !== '0.00';
    const showAddtlCamFee = hasSubtotal && qty >= 1;
    return showAddtlCamFee;
};

export const venueHasRequestRefund = (reservation) => {
    const eventDate = reservation?.order_details?.event_date.split('T');
    const eventEndTime = reservation?.order_details?.event_end;
    const venueEndDateTime = moment(
        `${eventDate[0]} ${eventEndTime}`,
        'YYYY-MM-DD hh:mm A'
    );
    const venueLastRefundDay = venueEndDateTime?.clone()?.add(7, 'd');
    const currentDate = moment.utc();

    if (
        currentDate.isAfter(venueEndDateTime) &&
        currentDate.isBefore(venueLastRefundDay) &&
        parseInt(reservation?.total, 10) > 0
    ) {
        return true;
    }

    return false;
};

export const venueHasCancel = (reservation) => {
    if (reservation?.inventory_type === INVENTORY_VENUE_APPLICATION) {
        return false;
    }

    const eventDate = reservation?.order_details?.event_date.split('T');
    const eventEndTime = reservation?.order_details?.event_end;
    const venueEndDateTime = moment(
        `${eventDate[0]} ${eventEndTime}`,
        'YYYY-MM-DD hh:mm A'
    );
    const currentDate = moment.utc();
    if (currentDate.isAfter(venueEndDateTime)) {
        return false;
    }
    return true;
};
