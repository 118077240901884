/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import type {
    CampingDefaultPassListTypes,
    CampingPassMapLabelTypes,
} from '../../../types/campingDefaultPassListTypes';

export const DEFAULT_PASS_LIST: CampingDefaultPassListTypes = [
    'Interagency Access Pass',
    'Interagency Annual Senior Pass',
    'Interagency Lifetime Senior Pass',
    'Golden Access Pass',
    'Golden Age Pass',
];

export const PASS_LABEL_MAP: CampingPassMapLabelTypes = {
    'Interagency Access Pass': 'Interagency Access Pass (Permanent Disability)',
    'Interagency Annual Senior Pass': 'Interagency Annual Senior Pass',
    'Interagency Lifetime Senior Pass': 'Interagency Lifetime Senior Pass',
    'Golden Access Pass': 'Golden Access Passport',
    'Golden Age Pass': 'Golden Age Passport',
    'Military Fee Waiver': 'Military Fee Waiver',
    'Tribal Discount': 'Tribal Fee Waiver',
};
