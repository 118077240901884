/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { ImageUnavailable, Button, Icons, FlexRow, FlexCol } from 'sarsaparilla';
import cx from 'classnames';
import { campingSources, evLevels } from '../constants';
import campingMapPopup from './campingMapPopup';
import searchMapPopup from './searchMapPopup';
import tripPlannerMapPopup from './tripPlannerMapPopup';
import featureMapPopup from './featureMapPopup';
import licensingMapPopup from './licensingMapPopup';
import { createComponent, handleClosePopUp } from './common';

const getContent = (props) => {
    const { name, location, href, featureId, content, className, isBoundary } = props;
    if (!name && !location && !href && !featureId) return null;

    const popupContent = (
        <div className={cx('map-popup', className)}>
            {!isBoundary && (
                <Button
                    className="close-pop-up-btn"
                    appearance="subtle"
                    size="sm"
                    shouldFitContainer={false}
                    onClick={handleClosePopUp}
                    iconBeforeElement={<Icons.IconClose />}
                    screenReaderTextBefore="Close the modal"
                />
            )}
            {name || location || href ? (
                <div className="map-popup-message">
                    {name && (
                        <div className="h4 map-popup-title popup-title-no-image">
                            {name}
                        </div>
                    )}
                    {location && (
                        <ul className="map-popup-location-count">
                            <li>{location}</li>
                        </ul>
                    )}
                    {href && (
                        <div className="map-popup-button">
                            <Button
                                appearance="link"
                                iconBeforeElement={<Icons.IconAddCircle />}
                                href={`/${href}/${featureId}`}
                            >
                                Buy Now
                            </Button>
                        </div>
                    )}
                    {content && (
                        <ul>
                            <li>{content}</li>
                        </ul>
                    )}
                </div>
            ) : null}
        </div>
    );

    return createComponent(popupContent);
};

const getTripDestinationContent = (feature) => {
    const { imageUrl, name, isStartLocation, shortDescription } =
        feature?.properties || {};
    const title = isStartLocation ? 'Start' : 'Destination';
    const titleClassName = imageUrl ? '' : ' popup-title-no-image';
    const content = (
        <div className="map-popup-trip-planner">
            <Button
                className="close-pop-up-btn"
                appearance="subtle"
                size="sm"
                shouldFitContainer={false}
                onClick={handleClosePopUp}
                iconBeforeElement={<Icons.IconClose />}
                screenReaderTextBefore="Close the modal"
            />
            {imageUrl && (
                <div className="map-popup-image">
                    <img src={imageUrl} alt={name} /> : <ImageUnavailable />
                </div>
            )}
            <div className="map-popup-message">
                <span className={`h4 map-popup-title${titleClassName}`}>
                    {title}: {name}
                </span>
                {shortDescription && <p className="map-popup-text">{shortDescription}</p>}
            </div>
        </div>
    );

    return createComponent(content);
};

export const getBoundaryContent = (feature) => {
    if (!feature?.properties) return null;
    const { name, isBoundary } = feature.properties;
    return getContent({
        className: 'map-popup-boundary',
        name,
        isBoundary,
    });
};

export const getTrailsContent = (feature) => {
    if (!feature?.properties) return null;

    const { name, type, surface, use, length } = feature.properties;

    const popupContent = (
        <div className={cx('map-popup', 'map-popup-trails')}>
            <Button
                className="close-pop-up-btn"
                appearance="subtle"
                size="sm"
                shouldFitContainer={false}
                onClick={handleClosePopUp}
                iconBeforeElement={<Icons.IconClose />}
                screenReaderTextBefore="Close the modal"
            />
            <div className="map-popup-message">
                <FlexRow>
                    {name ? (
                        <FlexCol xs={12} className="map-trails-name-title">
                            {name}
                        </FlexCol>
                    ) : null}
                    {!!type && (
                        <FlexCol xs={6} className="map-trails-popup-element">
                            <div className="map-trails-title">Type: </div>
                            {type}
                        </FlexCol>
                    )}
                    {!!surface && (
                        <FlexCol xs={6} className="map-trails-popup-element">
                            <div className="map-trails-title">Surface: </div>
                            {surface}
                        </FlexCol>
                    )}
                    {!!use && (
                        <FlexCol xs={6} className="map-trails-popup-element">
                            <div className="map-trails-title">Use: </div>
                            {use}
                        </FlexCol>
                    )}
                    {!!length && (
                        <FlexCol xs={6} className="map-trails-popup-element">
                            <div className="map-trails-title">Length: </div>
                            {`${Number.parseFloat(length).toFixed(4)} miles`}
                        </FlexCol>
                    )}
                </FlexRow>
            </div>
        </div>
    );
    return createComponent(popupContent);
};

export const getEvContent = (feature) => {
    if (!feature?.properties) return null;

    const { name, level, fullAddress, adapter, hours, price, levelPorts, maxVehicle } =
        feature.properties;

    const evAdapter =
        adapter && adapter !== 'other' && adapter !== 'undefined' ? adapter : '';
    const levelName = level && evLevels[level]?.name ? evLevels[level]?.name : '';
    const evTopInfo = `${levelName}${levelName ? ', ' : ''}${evAdapter}`;

    const popupContent = (
        <div className={cx('map-popup', 'map-popup-ev')}>
            <Button
                className="close-pop-up-btn"
                appearance="subtle"
                size="sm"
                shouldFitContainer={false}
                onClick={handleClosePopUp}
                iconBeforeElement={<Icons.IconClose />}
                screenReaderTextBefore="Close the modal"
            />
            <div className="map-popup-message">
                <FlexRow>
                    {!!name && (
                        <FlexCol xs={12} className="map-ev-title map-ev-title-name">
                            {name}
                        </FlexCol>
                    )}
                    {!!evTopInfo && (
                        <FlexCol xs={12} className="map-ev-subtitle">
                            {evTopInfo}
                        </FlexCol>
                    )}
                    {!!fullAddress && (
                        <FlexCol xs={12} className="map-ev-address-title">
                            {fullAddress}
                        </FlexCol>
                    )}
                    {!!hours && (
                        <FlexCol xs={6} className="map-ev-popup-element">
                            <Icons.IconClock size="md" className="map-ev-popup-icon" />
                            {hours}
                        </FlexCol>
                    )}
                    {!!price && (
                        <FlexCol xs={6} className="map-ev-popup-element">
                            <Icons.IconAtm size="md" className="map-ev-popup-icon" />
                            {price}
                        </FlexCol>
                    )}
                    {!!levelPorts && (
                        <FlexCol xs={6} className="map-ev-popup-element">
                            <Icons.IconGasStation
                                size="md"
                                className="map-ev-popup-icon"
                            />
                            {`${levelPorts} plugs`}
                        </FlexCol>
                    )}
                    {!!maxVehicle && (
                        <FlexCol xs={6} className="map-ev-popup-element">
                            <Icons.IconCar size="md" className="map-ev-popup-icon" />
                            {`${maxVehicle} Max Vehicle`}
                        </FlexCol>
                    )}
                </FlexRow>
            </div>
        </div>
    );

    return createComponent(popupContent);
};

// Holds popup content setup per service
export const getPopupContentByService = (feature, service, deselectFeature) => {
    const featProps = feature?.properties;
    const parseAsset = () => {
        const props = featProps?.asset || featProps;
        if (!props) return {};

        if (typeof props === 'string') return JSON.parse(props);
        return props;
    };

    const parsed = parseAsset();

    let content = '';
    if (['campingpoi', 'campingsinglepoint', 'permit'].includes(service)) {
        content = getContent({ name: featProps?.name });
    } else if (['pass', 'destination'].includes(service)) {
        content = getContent({ ...featProps, href: 'sitepass' });
    } else if (['venue', 'treepermit'].includes(service)) {
        const href =
            service === 'treepermit'
                ? 'tree-permits'
                : `venues/${featProps.facilityId}/details`;
        content = getContent({ ...featProps, href });
    }

    let campingContent = '';
    if (service === 'camping' && featProps) {
        const { asset, currentCampground, featureType, name, entityType } = featProps;
        if (entityType !== 'campsite' && asset) campingContent = searchMapPopup(asset);
        else if (currentCampground || featureType === 'amenity')
            campingContent = getContent({ name });
        else campingContent = campingMapPopup(featProps, deselectFeature);
    }

    let campingItineraryContent = '';
    if (service === 'campingitinerary' && featProps) {
        if (featProps.featureType === 'amenity')
            campingItineraryContent = getContent({ name: featProps.name });
        else if (feature?.source === campingSources.amenities)
            campingItineraryContent = featProps.description;
        else if (feature.properties.featureId)
            campingItineraryContent = campingMapPopup(featProps || {});
        else
            campingItineraryContent = getContent({
                name: featProps.name,
                content: featProps.description,
                isItinerary: true,
            });
    }

    let tripPlannerContent = '';
    if (service === 'tripplanner' && feature) {
        tripPlannerContent = feature.asset
            ? tripPlannerMapPopup(feature.asset)
            : getTripDestinationContent(feature);
    }

    let campingInternalContent = '';
    if (service === 'campinginternal') {
        campingInternalContent =
            parsed?.entityType === 'amenity'
                ? getContent({ name: parsed.name })
                : campingMapPopup(parsed);
    }

    const facilityContent = ['search', 'navigation', 'gateway'].includes(service)
        ? searchMapPopup(parsed)
        : '';
    const fieldContent =
        service === 'internalcampingfieldsales' ? campingMapPopup(parsed) : '';
    const featureContent = ['ticket', 'timedentry'].includes(service)
        ? featureMapPopup(parsed)
        : '';

    let licensingColoradoContent = '';
    if (service === 'licensingColorado') {
        licensingColoradoContent = licensingMapPopup(parsed);
    }

    const popupContents = {
        pass: content,
        destination: content,
        venue: content,
        treepermit: content,
        campingpoi: content,
        campingsinglepoint: content,
        permit: content,
        ticket: featureContent,
        timedentry: featureContent,
        navigation: facilityContent,
        gateway: facilityContent,
        search: facilityContent,
        internalcampingfieldsales: fieldContent,
        camping: campingContent,
        campinginternal: campingInternalContent,
        campingitinerary: campingItineraryContent,
        tripplanner: tripPlannerContent,
        licensingColorado: licensingColoradoContent,
    };

    return popupContents[service];
};

// cSpell:ignore campinginternal, campingitinerary, campingpoi, campingsinglepoint, internalcampingfieldsales, sitepass, timedentry, treepermit, tripplanner
