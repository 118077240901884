/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import type { CampsiteSupplementalItemType } from '../../../types/CampsiteSupplementalItemType';

export const CAMPSITE_SUPPLEMENTAL_ITEMS: CampsiteSupplementalItemType = [
    {
        description:
            "In addition to the total vehicle(s) allowed at the campsite you have selected, this fee allows access for one additional vehicle at the facility. If the campsite allows more than one extra vehicle, each vehicle is charged. Use 'quantity' to reserve the total number of additional vehicles needed per day (for Day Use sites) or night (for overnight stays). Parking may be near your campsite or in the extra vehicle parking area identified by the facility. Please read the facility information closely as there may be additional fees.",
        name: 'Extra Vehicle Fee Per Night',
    },
    {
        description:
            "In addition to the amenities provided at the campsite you have selected, this fee allows access to one horse corral. The horse corral may be near your campsite or in an area identified by the facility. If more than one horse corral is available each corral is charged individually. Use 'quantity' to reserve the total number of corrals needed per day (for Day Use sites) or night (for overnight stays).",
        name: 'Horse Corral Per Night',
    },
    {
        description:
            'This facility offers a dump station for depositing RV waste using a sanitary system. Operation of the dump station is done by you, the visitor (staff are not available to assist). The dump station is a shared service at this facility and you may have to wait in line to use the dump station. For a one-time use of the sanitary dump station at this facility choose one day (for Day Use sites) or one night (for overnight stays).',
        name: 'Sanitary Dump Station Fee',
    },
];
