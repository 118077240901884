/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { HelmetWrapper, SingleColumnContainer, Heading } from 'sarsaparilla';

export default class DonationTACBLM extends React.Component {
    render() {
        return (
            <SingleColumnContainer role="main">
                <HelmetWrapper title="BLM Terms & Conditions for Donations" />

                <section className="rules-reservation-policies-wrap mb-8">
                    <Heading headingLevel={1} appearance="h1">
                        BLM Terms &amp; Conditions for Donations
                    </Heading>

                    <p>
                        By making a donation to the Bureau of Land Management you are
                        agreeing to the following terms and conditions:
                        <ol>
                            <li>
                                Three percent of your donation will be used to cover a
                                transaction fee for {process.env.SITE_NAME}’s online services.
                            </li>
                            <li>
                                One-time donations are limited to a maximum of $50. If
                                your desired one-time donation amount exceeds $50, please
                                contact the{' '}
                                <a href="https://www.blm.gov/locations">
                                    Bureau of Land Management office
                                </a>{' '}
                                of your choice.
                            </li>
                            <li>Donations and associated fees are not refundable.</li>
                            <li>
                                Your donation will only be used to benefit the programs
                                and projects that support the BLM’s recreation area or
                                facility you are donating to.
                            </li>
                            <li>
                                Please do not provide a donation if one or more of these
                                situations apply to you:
                                <ul>
                                    <li>
                                        You are party to a contract, grant, or cooperative
                                        agreement with the BLM or are seeking to do
                                        business with the BLM;
                                    </li>
                                    <li>
                                        You have a financial interest that may be
                                        substantially affected by performance or
                                        non-performance of the BLM; and/or
                                    </li>
                                    <li>
                                        You represent an organization involved in the
                                        direct production of alcohol, tobacco, or
                                        firearms.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                For more information regarding donations to the BLM and
                                BLM’s Donations, Solicitation, and Fundraising Policy,
                                please visit{' '}
                                <a href="https://www.blm.gov/get-involved/donations">
                                    https://www.blm.gov/get-involved/donations
                                </a>
                                .
                            </li>
                        </ol>
                    </p>
                </section>
            </SingleColumnContainer>
        );
    }
}

// cSpell:ignore TACBLM
