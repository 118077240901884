/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * NOTE
 * Recreation.gov does not current support gift cards, so this file is a placeholder to maintain
 * site-kit's normal behavior
 */

import type {
    GiftCardOptionsImage,
    GiftCardPromoImage,
    GiftCardRailOptionsImage,
    HeroImageConfig,
    ImageSpecs,
} from '../../../types/gift-cards';

export const giftCardOptionsImageXs: GiftCardOptionsImage = {};
export const giftCardOptionsImageSm: GiftCardOptionsImage = {};
export const giftCardOptionsImageMd: GiftCardOptionsImage = {};
export const giftCardOptionsImageLg: GiftCardOptionsImage = {};
export const giftCardOrderDetailsRailImage: GiftCardRailOptionsImage = {};
export const giftCardPromoImageSm: GiftCardPromoImage = {
    alt: '',
    src: '',
    srcset: [],
};
export const giftCardHeroImageConfig: HeroImageConfig = {
    imageSrcConfig: {
        avif: {
            lg: '',
            md: '',
            sm: '',
        },
        webp: {
            lg: '',
            md: '',
            sm: '',
        },
        jpg: {
            lg: '',
            md: '',
            sm: '',
        },
    },
    alt: '',
};
export const giftCardPromoImageSpecs: ImageSpecs = {
    png: [],
    avif: [],
    webp: [],
    alt: '',
};
