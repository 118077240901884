/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {Select, SelectOption } from 'sarsaparilla';

export default function WhatsNewSort({onChange}){
    return (
        <Select
            id="whats-new-select"
            label="What's New Select"
            placeholder="Choose an option to sort by"
            defaultValue="new"
            onChange={onChange}
            isLabelVisible={false} >
                <SelectOption value="new">Sort By: Date Added</SelectOption>
                <SelectOption value="name">Sort By: Name</SelectOption>
        </Select>
    );
}

WhatsNewSort.propTypes = {
    onChange: PropTypes.func,
}