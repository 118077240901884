/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React  from 'react';
import { useParams } from 'react-router-dom';
import DiscoverResultsContainer from "./DiscoverResultsContainer";

export default function DiscoverCampingLocation() {
    const { location } = useParams();
    const locationName = decodeURIComponent(location).replace(/\+/g, ' ').replace(/-/g, ' ')
    return <DiscoverResultsContainer
        titlePrefix={`Discover `}
        location={locationName}
        state={null} />
}
