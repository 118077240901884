/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * FAQ.jsx
 * Created by Destin Frasier on 02/19/23
 */

import React from 'react';
import {
    HelmetWrapper,
    SingleColumnContainer,
    Heading,
    HeroImageSectionFront,
    TextBlock,
    Accordion,
    AccordionItem,
    AccordionHeading,
    AccordionContent,
    FeatureFlag,
    FlexRow,
    FlexCol,
} from 'sarsaparilla';

export default function FAQ() {
    const flagOff = (
        <FlexRow justifyContent="center">
            <FlexCol xs="variable">
                <Heading headingLevel={2} appearance="h3">
                    Coming Soon
                </Heading>
            </FlexCol>
        </FlexRow>
    );

    return (
        <div className="nav-faq-page">
            <HelmetWrapper title="Program & Contract FAQs" />

            <HeroImageSectionFront
                className="mb-5"
                title={'Program & Contract FAQs'}
                backgroundImagePath="/img/faq/hero/faq-hero"
                backgroundImageAltText="A valley covered with trees between mountains at sunrise"
                backgroundImageColorOverlay="#016580"
                backgroundImageColorOverlayOpacity={0.3}
            />

            <SingleColumnContainer role="main" className="mb-8">
                <FeatureFlag flag="programAndContractFaqFooterLink" fallback={flagOff}>
                    <Heading
                        headingLevel={2}
                        appearance="h4"
                        hasUnderline="left"
                        className="mb-1">
                        This is Recreation.gov
                    </Heading>

                    <TextBlock width="xl" className="mb-8">
                        <p>
                            Recreation.gov is the government&apos;s centralized travel
                            planning platform and reservation system of choice for 13
                            federal agencies, offering the tools, tips, and information
                            needed for visitors to find destinations and activities, plan
                            a trip, and experience thousands of federal recreation sites
                            across the country.
                        </p>
                    </TextBlock>

                    <Heading
                        headingLevel={2}
                        appearance="h4"
                        hasUnderline="left"
                        className="mb-1">
                        Common Questions & Facts About the Program
                    </Heading>

                    <Accordion id="with-children" shouldAllowMultipleOpen>
                        <AccordionItem>
                            <AccordionHeading>
                                Q: What is Recreation.gov? Is it a federal agency, a
                                government service, or a private company?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> Recreation.gov is, and always has
                                    been, a government service. It is designed to serve as
                                    the federal government&apos;s travel-planning solution
                                    for the public to discover and experience their public
                                    lands and waters.
                                </p>
                                <ul>
                                    <li>
                                        In 1995, on the heels of a nationwide outdoor
                                        recreation study, the Forest Service and US Army
                                        Corps of Engineers conceived a system to provide
                                        one source for visitors to find and reserve
                                        campsites. They also acknowledged that they did
                                        not have the capacity or the expertise to develop
                                        and maintain the system and turned to private
                                        industry through a competitive bid process for
                                        these services.
                                    </li>
                                    <li>
                                        Today, Recreation.gov is managed under an existing
                                        Memorandum of Understanding (MOU) by the
                                        Recreation One Stop (R1S) Program, an interagency
                                        group of federal agency experts, with oversight by
                                        a Recreation Management Committee.
                                    </li>
                                    <li>
                                        Recreation.gov helps federal agencies manage
                                        recreation resources and visitation by providing
                                        access to secure and compliant technical solutions
                                        as well as dedicated resources and support with
                                        training, communications, technical help, data
                                        requests, and daily operational needs.
                                    </li>
                                </ul>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeading>
                                Q: But isn&apos;t there a private company that provides
                                support for Recreation.gov?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> Recreation.gov is led by a
                                    dedicated government team with staff from the
                                    participating agencies. A team of technology,
                                    marketing and customer engagement experts are also
                                    contracted to assist with building and maintaining
                                    what&apos;s become a highly complex and high-quality
                                    online reservation system, backed by a call center
                                    that fills the gap for visitors who need additional
                                    assistance.
                                </p>
                                <ul>
                                    <li>
                                        From the beginning, the federal agencies
                                        recognized the need to bring in technical and
                                        operational expertise to build and maintain a
                                        commercial-scale online service and turned to the
                                        private sector for support.
                                    </li>
                                    <li>
                                        Over the last two decades, the government has
                                        worked with a series of vendors to deliver this
                                        valuable service. In all cases, the contract
                                        service providers have been selected using full
                                        and open competition (41 U.S.C.§3301) to ensure
                                        the best and brightest technical solutions were
                                        considered, and all resulting contracts have
                                        included fixed unit prices to minimize costs for
                                        customers. The contractor is subject to quality
                                        and performance controls under the contract and
                                        this agreement between the government and the
                                        contractor undergoes a continuous review process
                                        to determine and award ongoing support for
                                        Recreation.gov.
                                    </li>
                                    <li>
                                        This public-private partnership drives innovation,
                                        collaboration, and solutions to field and visitor
                                        management challenges. The system has continued to
                                        scale to meet increasing demand and has expanded
                                        the portfolio of inventory and services on the
                                        site.
                                    </li>
                                </ul>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeading>
                                Q: Why is a contractor needed to support a government
                                reservation system?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> The simple answer is that the
                                    government does not have the expertise, nor the
                                    capacity to develop and maintain such a complex
                                    system, and private industry does. It is common
                                    practice for government services to be developed and
                                    supported through contract vendors.
                                </p>
                                <ul>
                                    <li>
                                        For the Recreation.gov system, the contractor
                                        built a custom platform, maintains, and improves
                                        the external / public-facing reservation system
                                        for almost 50 million site visitors per year as
                                        well as the internal / field system for
                                        administrative, operational, and financial
                                        management at over 4,500 federal recreation
                                        locations.
                                    </li>
                                    <li>
                                        The system has similar levels of technical
                                        complexity, security, infrastructure requirements,
                                        financial processing, and development needs as
                                        other large-scale commercial reservation and
                                        travel platforms.
                                    </li>
                                    <li>
                                        Fees for covering these development costs were
                                        established during a full and open procurement
                                        process driven by government requirements and the
                                        vision for a one-stop travel planning tool.
                                    </li>
                                    <li>
                                        Multiple vendors submitted proposals for the
                                        chance to secure the competitively awarded
                                        contract. A team of government experts selected
                                        the contractor based on several evaluation factors
                                        including overall quality of the technical
                                        solution, level and scope of program support, and
                                        estimated costs for building and managing the
                                        solution.
                                    </li>
                                </ul>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeading>
                                Q: Are the participating agencies or facilities required
                                to use Recreation.gov?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> There is no mandate for use of
                                    Recreation.gov and the agencies all choose to
                                    participate in the program.
                                </p>
                                <ul>
                                    <li>
                                        Recreation.gov is the reservation system of choice
                                        for 13 participating federal agencies that rely on
                                        the platform&apos;s flexibility to accommodate and
                                        enforce the sometimes complex and unique
                                        requirements necessary to welcome visitors and
                                        support federal land management.
                                    </li>
                                    <li>
                                        Individual facilities often transition to using
                                        Recreation.gov because of the value the platform
                                        offers in addressing issues related to visitation
                                        management, staffing and resourcing, secure
                                        financial transactions, and/or the opportunity to
                                        deliver facility cost savings and equitable access
                                        through an online service.
                                    </li>
                                </ul>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeading>
                                Q: Who sets the policies and business rules through the
                                system?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> The participating agencies set the
                                    policies for their campgrounds, sites, and activities
                                    featured on the Recreation.gov platform. The
                                    facilities that choose Recreation.gov set their own
                                    booking windows and update content, fees, reservation
                                    details, and business rules based on their local
                                    needs.
                                </p>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeading>
                                Q: How are the fees charged through Recreation.gov set and
                                where does the money go?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> The participating agencies set the
                                    recreation or use fees for their campgrounds, sites,
                                    and activities featured on the Recreation.gov
                                    platform. Recreation.gov is not funded by
                                    Congressional appropriations or any other portion of
                                    the agency budgets and relies on reservation fees to
                                    fund the program. The majority of funds collected
                                    through Recreation.gov go back to the agencies and
                                    facilities.
                                </p>
                                <ul>
                                    <li>
                                        Recreation, use, or special amenity fees are
                                        charged for experiences like camping, hiking,
                                        rafting, and more, and are set by the agency
                                        and/or facility. These fees are distributed to the
                                        federal agencies to cover expenses for
                                        maintenance, staffing, and upkeep of activities
                                        and locations.
                                    </li>
                                    <li>
                                        Visitors also pay a reservation fee to make a
                                        reservation and guarantee a spot for the
                                        activities available on the site; this fee is used
                                        to fund the Recreation.gov government program and
                                        a portion of the fee reimburses the contractor for
                                        investment in building and managing this complex
                                        technical system.
                                    </li>
                                    <li>
                                        The contractor operates under a competitively
                                        awarded contract with fixed-unit pricing to
                                        minimize customer costs and is subject to quality
                                        and performance controls to ensure this public
                                        private partnership drives innovation,
                                        collaboration, and solutions to facility and
                                        visitor management challenges.
                                    </li>
                                </ul>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeading>
                                Q: It seems like bots are making all the reservations at
                                popular destinations - what actions does Recreation.gov
                                take to prevent bots?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> Recreation.gov has multiple
                                    defenses in place to detect, prevent, and mitigate
                                    bots that attempt to take advantage of the system or
                                    make reservations. There is no credible evidence to
                                    support the notion that bots are actively securing
                                    reservations on Recreation.gov.
                                </p>
                                <ul>
                                    <li>
                                        It&apos;s important to first understand the
                                        different types of bots that can affect
                                        Recreation.gov. Bots can be classified into three
                                        categories:
                                        <ol type="1">
                                            <li>
                                                <strong>Malicious:</strong> bots that have
                                                no other objective than to disrupt the
                                                site&apos;s overall stability and
                                                functions
                                            </li>
                                            <li>
                                                <strong>Scrapers:</strong> bots designed
                                                to aggregate publicly available data from
                                                interfaces designed for individual access
                                            </li>
                                            <li>
                                                <strong>Cheaters:</strong> bots with the
                                                sole objective of gaining an advantage in
                                                completing a purchase / making a
                                                reservation
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        The Recreation.gov technical team partners with
                                        industry leading vendors, such as Amazon and
                                        Google, to leverage advanced machine
                                        learning-based algorithms to block bots from
                                        making reservations.
                                    </li>
                                    <li>
                                        Recreation.gov has multiple defenses in place to
                                        detect, prevent, and mitigate bots that attempt to
                                        take advantage of the system or make reservations.
                                        While there is no credible evidence that bots are
                                        actively securing reservations, the security team
                                        takes measures to block bots, such as: preventing
                                        a single user from having multiple transactions at
                                        one time, a single user from making more than a
                                        set number of requests in a defined time period,
                                        and a single location (based on IP address) from
                                        making more than a set number of requests in a
                                        defined time period.
                                    </li>
                                    <li>
                                        Recreation.gov strives to minimize the effect of
                                        bots as much as possible and the government and
                                        contractor team are taking every action to
                                        maintain the integrity of the Recreation.gov
                                        system for all visitors by constantly introducing
                                        new defenses to prevent the latest bot trends and
                                        integrating industry-leading bot defense
                                        technologies and processes as they become
                                        available.
                                    </li>
                                </ul>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </FeatureFlag>
            </SingleColumnContainer>
        </div>
    );
}
