/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './MobileAppPage.scss';

import { HelmetWrapper, Icons } from 'sarsaparilla';

import { MobileAppHero } from './components/MobileAppHero';
import { MobileAppOverview } from './components/MobileAppOverview';
import { MobileAppFeature } from './components/MobileAppFeature';
import { MobileAppFeatureItem } from './components/MobileAppFeatureItem';
import { MobileAppMarketing } from './components/MobileAppMarketing';

import campingFeatureImageWebp from './images/camping-feature-image.webp';
import campingFeatureImagePng from './images/camping-feature-image.png';

import ticketFeatureImageWebp from './images/ticket-feature-image.webp';
import ticketFeatureImagePng from './images/ticket-feature-image.png';

import locationFeatureImageWebp from './images/location-feature-image.webp';
import locationFeatureImagePng from './images/location-feature-image.png';

import scanPayFeatureImageWebp from './images/scan-and-pay-feature-image.webp';
import scanPayFeatureImagePng from './images/scan-and-pay-feature-image.png';

const campingFeatures = [
    {
        title: 'Easily Compare Campsites',
        description:
            'Swipe through campsites to review rates and location details, then click individual locations to learn more about them.',
        icon: <Icons.IconCampsite />,
    },
    {
        title: 'Check Real-Time Availability & Site Information',
        description:
            'Find available campsites based on the dates for your trip and review important information on season dates and reserving the site.',
        icon: <Icons.IconPhoneAndroid />,
        secondaryIcon: <Icons.IconAnnouncement />,
        secondaryIconClassName: 'nav-secondary-icon-availability',
    },
    {
        title: 'Book Your Ideal Campsite',
        description:
            'Log in to your Recreation.gov account, complete the order details, and reserve campsites from your phone or tablet.',
        icon: <Icons.IconTabletMac />,
    },
];

const ticketsFeatures = [
    {
        title: 'Find Unique Activities Across the Country',
        description:
            'Search for tours in your area or as part of an upcoming trip and review a range of events and outdoor recreation experiences for different interests, skills, ages, and accessibility levels.',
        icon: <Icons.IconSearch />,
    },
    {
        title: 'Compare Activity Details & Ticket Times',
        description:
            'Check real-time ticket availability, review activity details and reservable times, and find the ideal adventure for you and your trip.',
        icon: <Icons.IconCalendarAvailable />,
    },
    {
        title: 'Book Your Tickets On The Go',
        description:
            'Log in to your Recreation.gov account, complete the order details, and easily purchase and add tickets to your travel plans right from your phone.',
        icon: <Icons.IconTicket />,
    },
];

const locationFeatures = [
    {
        title: 'Manage All Your Information',
        description:
            'Conveniently access your account as well as past and upcoming camping reservations, tickets, permits, and passes.',
        icon: <Icons.IconAccountCircle />,
    },
    {
        title: 'Prepare for Your Trip',
        description:
            "Review important arrival, need to know, and reservation information for your trip, even when you're offline.",
        icon: <Icons.IconInfoOutline />,
    },
    {
        title: 'Save Your Favorite Places',
        description:
            'Easily plan for your next trip or reserve your go-to campsite by saving locations to your Recreation.gov account.',
        icon: <Icons.IconFavoriteBorder />,
    },
];

const scanAndPayFeatures = [
    {
        title: 'Easy Mobile Payment',
        description:
            'Claim your spot by placing your equipment down and use the camera on your phone to scan the unique campground QR code to pay for the campsite.',
        icon: <Icons.IconQrCode />,
    },
    {
        title: 'No Need to Wait or Carry Cash',
        description:
            'Payment is conveniently made right on your phone, without the hassle of waiting in line, carrying cash, or writing a check and dropping it in a fee tube.',
        icon: <Icons.IconPayment />,
    },
    {
        title: 'No Signal, No Problem',
        description:
            'Even in remote areas with no cell service, Scan & Pay allows you to pay when you’re offline and then processes your transaction once you’re back online.',
        icon: <Icons.IconCellService className="no-cell-service" />,
    },
];

export function MobileAppPage() {
    return (
        <main className="nav-mobile-app-page">
            <HelmetWrapper title="Mobile App" />
            <MobileAppHero />
            <MobileAppOverview />

            <MobileAppFeature
                id="explore-options-and-reserve-campsite"
                title="Explore Your Options & Reserve a Campsite"
                src={campingFeatureImageWebp}
                fallbackSrc={campingFeatureImagePng}
                imageAlt="mobile app screens showing search results for Yosemite National Park"
            >
                <div className="nav-mobile-app-feature-description">
                    <p>
                        Whether you&#39;re looking for a campsite in your zip code or
                        across the country, the Recreation.gov app allows you to compare
                        and book the perfect spot for your next camping trip.
                    </p>
                </div>
                {campingFeatures.map((campingFeature, index) => (
                    <MobileAppFeatureItem
                        key={index}
                        icon={campingFeature.icon}
                        title={campingFeature.title}
                        secondaryIcon={campingFeature.secondaryIcon}
                        secondaryIconClassName={campingFeature.secondaryIconClassName}
                    >
                        <p>{campingFeature.description}</p>
                    </MobileAppFeatureItem>
                ))}
            </MobileAppFeature>

            <MobileAppFeature
                id="search-amazing-experiences-and-purchase-tickets"
                title="Search Amazing Experiences &amp; Purchase Tickets"
                hasMediaFirst
                src={ticketFeatureImageWebp}
                fallbackSrc={ticketFeatureImagePng}
                imageAlt="mobile app screens showing ticketing Experience"
                className="mb-0 pb-lg-4"
            >
                <div className="nav-mobile-app-feature-description">
                    <p>
                        Whether you&#39;re looking for tours of Independence Hall, tickets
                        to the top of the Washington Monument, or access to other outdoor
                        recreation experiences and special events, the Recreation.gov app
                        allows you to find and purchase tickets for activities near you or
                        along your travel route.
                    </p>
                </div>

                {ticketsFeatures.map((ticketsFeature, index) => (
                    <MobileAppFeatureItem
                        key={index}
                        icon={ticketsFeature.icon}
                        title={ticketsFeature.title}
                    >
                        <p>{ticketsFeature.description}</p>
                    </MobileAppFeatureItem>
                ))}
            </MobileAppFeature>

            <MobileAppFeature
                id="access-your-reservations-and-favorite-locations"
                title="Access Your Reservations &amp; Favorite Locations"
                src={locationFeatureImageWebp}
                fallbackSrc={locationFeatureImagePng}
                imageAlt="mobile app screens showing user reservations and favorites"
                hasBackgroundColor
                className="mb-6 pb-6"
            >
                <div className="nav-mobile-app-feature-description">
                    <p>
                        With the Recreation.gov mobile app, your account information,
                        important travel planning details, and your reservations and
                        favorite locations are now available anytime and anywhere - all in
                        one place.
                    </p>
                </div>

                {locationFeatures.map((locationFeature, index) => (
                    <MobileAppFeatureItem
                        key={index}
                        icon={locationFeature.icon}
                        title={locationFeature.title}
                    >
                        <p>{locationFeature.description}</p>
                    </MobileAppFeatureItem>
                ))}
            </MobileAppFeature>

            <MobileAppFeature
                id="scanAndPay"
                title="Claim a Campsite When You Arrive with Scan &amp; Pay"
                hasMediaFirst
                src={scanPayFeatureImageWebp}
                fallbackSrc={scanPayFeatureImagePng}
                imageAlt="mobile app screens showing Scan & Pay Experience"
                className="mb-4 pb-0 pb-lg-4"
            >
                <div className="nav-mobile-app-feature-description">
                    <p>
                        Didn’t make a reservation in advance and looking to grab a
                        campsite when you arrive? Available at select campgrounds, the
                        Recreation.gov mobile app now features Scan & Pay to pay directly
                        from your mobile device for first come, first served campsites.
                    </p>
                </div>

                {scanAndPayFeatures.map((scanAndPayFeature, index) => (
                    <MobileAppFeatureItem
                        key={index}
                        icon={scanAndPayFeature.icon}
                        title={scanAndPayFeature.title}
                    >
                        <p>{scanAndPayFeature.description}</p>
                    </MobileAppFeatureItem>
                ))}
            </MobileAppFeature>
            <MobileAppMarketing />
        </main>
    );
}
