/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import { getReservationsUrl } from 'ui-account/src/helpers';

export const INDEX = 'INDEX';

export const COUNTRIES = [
    { label: '', value: '' },
    { label: 'United States', value: 'USA' },
    { label: 'Canada', value: 'CAN' },
    { label: 'Afganistan', value: 'AFG' },
    { label: 'Albania', value: 'ALB' },
    { label: 'Algeria', value: 'DZA' },
    { label: 'American Samoa', value: 'ASM' },
    { label: 'Andorra', value: 'AND' },
    { label: 'Angola', value: 'AGO' },
    { label: 'Anguilla', value: 'AIA' },
    { label: 'Antarctica', value: 'ATA' },
    { label: 'Antigua And Barbuda', value: 'ATG' },
    { label: 'Argentina', value: 'ARG' },
    { label: 'Armenia', value: 'ARM' },
    { label: 'Aruba', value: 'ABW' },
    { label: 'Australia', value: 'AUS' },
    { label: 'Austria', value: 'AUT' },
    { label: 'Azerbaijan', value: 'AZE' },
    { label: 'Bahamas', value: 'BHS' },
    { label: 'Bahrain', value: 'BHR' },
    { label: 'Bangladesh', value: 'BGD' },
    { label: 'Barbados', value: 'BRB' },
    { label: 'Belarus', value: 'BLR' },
    { label: 'Belgium', value: 'BEL' },
    { label: 'Belize', value: 'BLZ' },
    { label: 'Benin', value: 'BEN' },
    { label: 'Bermuda', value: 'BMU' },
    { label: 'Bhutan', value: 'BTN' },
    { label: 'Bolivia', value: 'BOL' },
    { label: 'Bosnia And Herzegowina', value: 'BIH' },
    { label: 'Botswana', value: 'BWA' },
    { label: 'Bouvet Island', value: 'BVT' },
    { label: 'Brazil', value: 'BRA' },
    { label: 'British Indian Ocean Territory', value: 'IOT' },
    { label: 'Brunei Darussalam', value: 'BRN' },
    { label: 'Bulgaria', value: 'BGR' },
    { label: 'Burkina Faso', value: 'BFA' },
    { label: 'Burundi', value: 'BDI' },
    { label: 'Cambodia', value: 'KHM' },
    { label: 'Cameroon', value: 'CMR' },
    { label: 'Cape Verde', value: 'CPV' },
    { label: 'Cayman Islands', value: 'CYM' },
    { label: 'Central African Republic', value: 'CAF' },
    { label: 'Chad', value: 'TCD' },
    { label: 'Chile', value: 'CHL' },
    { label: 'China', value: 'CHN' },
    { label: 'Christmas Island', value: 'CXR' },
    { label: 'Cocos (Keeling) Islands', value: 'CCK' },
    { label: 'Colombia', value: 'COL' },
    { label: 'Comoros', value: 'COM' },
    { label: 'Congo', value: 'COG' },
    { label: 'Congo, The Democratic Republic', value: 'COD' },
    { label: 'Cook Islands', value: 'COK' },
    { label: 'Costa Rica', value: 'CRI' },
    { label: 'Cote D`ivoire', value: 'CIV' },
    { label: 'Croatia (Local Name: Hrvatska)', value: 'HRV' },
    { label: 'Cuba', value: 'CUB' },
    { label: 'Cyprus', value: 'CYP' },
    { label: 'Czech Republic', value: 'CZE' },
    { label: 'Denmark', value: 'DNK' },
    { label: 'Djibouti', value: 'DJI' },
    { label: 'Dominica', value: 'DMA' },
    { label: 'Dominican Republic', value: 'DOM' },
    { label: 'East Timor', value: 'TMP' },
    { label: 'Ecuador', value: 'ECU' },
    { label: 'Egypt', value: 'EGY' },
    { label: 'El Salvador', value: 'SLV' },
    { label: 'England', value: 'ENG' },
    { label: 'Equatorial Guinea', value: 'GNQ' },
    { label: 'Eritrea', value: 'ERI' },
    { label: 'Estonia', value: 'EST' },
    { label: 'Ethiopia', value: 'ETH' },
    { label: 'Falkland Islands (Malvinas)', value: 'FLK' },
    { label: 'Faroe Islands', value: 'FRO' },
    { label: 'Fiji', value: 'FJI' },
    { label: 'Finland', value: 'FIN' },
    { label: 'France', value: 'FRA' },
    { label: 'France, Metropolitan', value: 'FXX' },
    { label: 'French Guiana', value: 'GUF' },
    { label: 'French Polynesia', value: 'PYF' },
    { label: 'French Southern Territories', value: 'ATF' },
    { label: 'Gabon', value: 'GAB' },
    { label: 'Gambia', value: 'GMB' },
    { label: 'Georgia', value: 'GEO' },
    { label: 'Germany', value: 'DEU' },
    { label: 'Ghana', value: 'GHA' },
    { label: 'Gibraltar', value: 'GIB' },
    { label: 'Greece', value: 'GRC' },
    { label: 'Greenland', value: 'GRL' },
    { label: 'Grenada', value: 'GRD' },
    { label: 'Guadeloupe', value: 'GLP' },
    { label: 'Guam', value: 'GUM' },
    { label: 'Guatemala', value: 'GTM' },
    { label: 'Guinea', value: 'GIN' },
    { label: 'Guinea-Bissau', value: 'GNB' },
    { label: 'Guyana', value: 'GUY' },
    { label: 'Haiti', value: 'HTI' },
    { label: 'Heard And Mc Donald Islands', value: 'HMD' },
    { label: 'Honduras', value: 'HND' },
    { label: 'Hong Kong', value: 'HKG' },
    { label: 'Hungary', value: 'HUN' },
    { label: 'Iceland', value: 'ISL' },
    { label: 'India', value: 'IND' },
    { label: 'Indonesia', value: 'IDN' },
    { label: 'Iran (Islamic Republic Of)', value: 'IRN' },
    { label: 'Iraq', value: 'IRQ' },
    { label: 'Ireland', value: 'IRL' },
    { label: 'Israel', value: 'ISR' },
    { label: 'Italy', value: 'ITA' },
    { label: 'Jamaica', value: 'JAM' },
    { label: 'Japan', value: 'JPN' },
    { label: 'Jordan', value: 'JOR' },
    { label: 'Kazakhstan', value: 'KAZ' },
    { label: 'Kenya', value: 'KEN' },
    { label: 'Kiribati', value: 'KIR' },
    { label: 'Korea, Democratic People`s Rep', value: 'PRK' },
    { label: 'Korea, Republic Of', value: 'KOR' },
    { label: 'Kuwait', value: 'KWT' },
    { label: 'Kyrgyzstan', value: 'KGZ' },
    { label: 'Lao People`s Democratic Republic', value: 'LAO' },
    { label: 'Latvia', value: 'LVA' },
    { label: 'Lebanon', value: 'LBN' },
    { label: 'Lesotho', value: 'LSO' },
    { label: 'Liberia', value: 'LBR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LBY' },
    { label: 'Liechtenstein', value: 'LIE' },
    { label: 'Lithuania', value: 'LTU' },
    { label: 'Luxembourg', value: 'LUX' },
    { label: 'Macau', value: 'MAC' },
    { label: 'Macedonia, The Former Yugoslav', value: 'MKD' },
    { label: 'Madagascar', value: 'MDG' },
    { label: 'Malawi', value: 'MWI' },
    { label: 'Malaysia', value: 'MYS' },
    { label: 'Maldives', value: 'MDV' },
    { label: 'Mali', value: 'MLI' },
    { label: 'Malta', value: 'MLT' },
    { label: 'Marshall Islands', value: 'MHL' },
    { label: 'Martinique', value: 'MTQ' },
    { label: 'Mauritania', value: 'MRT' },
    { label: 'Mauritius', value: 'MUS' },
    { label: 'Mayotte', value: 'MYT' },
    { label: 'Mexico', value: 'MEX' },
    { label: 'Micronesia, Federated States Of', value: 'FSM' },
    { label: 'Moldova, Republic Of', value: 'MDA' },
    { label: 'Monaco', value: 'MCO' },
    { label: 'Mongolia', value: 'MNG' },
    { label: 'Montenegro', value: 'MNE' },
    { label: 'Montserrat', value: 'MSR' },
    { label: 'Morocco', value: 'MAR' },
    { label: 'Mozambique', value: 'MOZ' },
    { label: 'Myanmar', value: 'MMR' },
    { label: 'Namibia', value: 'NAM' },
    { label: 'Nauru', value: 'NRU' },
    { label: 'Nepal', value: 'NPL' },
    { label: 'Netherlands', value: 'NLD' },
    { label: 'Netherlands Antilles', value: 'ANT' },
    { label: 'New Caledonia', value: 'NCL' },
    { label: 'New Zealand', value: 'NZL' },
    { label: 'Nicaragua', value: 'NIC' },
    { label: 'Niger', value: 'NER' },
    { label: 'Nigeria', value: 'NGA' },
    { label: 'Niue', value: 'NIU' },
    { label: 'Norfolk Island', value: 'NFK' },
    { label: 'Northern Mariana Islands', value: 'MNP' },
    { label: 'Norway', value: 'NOR' },
    { label: 'Oman', value: 'OMN' },
    { label: 'Pakistan', value: 'PAK' },
    { label: 'Palau', value: 'PLW' },
    { label: 'Panama', value: 'PAN' },
    { label: 'Papua New Guinea', value: 'PNG' },
    { label: 'Paraguay', value: 'PRY' },
    { label: 'Peru', value: 'PER' },
    { label: 'Philippines', value: 'PHL' },
    { label: 'Pitcairn', value: 'PCN' },
    { label: 'Poland', value: 'POL' },
    { label: 'Portugal', value: 'PRT' },
    { label: 'Puerto Rico', value: 'PRI' },
    { label: 'Qatar', value: 'QAT' },
    { label: 'Reunion', value: 'REU' },
    { label: 'Romania', value: 'ROM' },
    { label: 'Russian Federation', value: 'RUS' },
    { label: 'Rwanda', value: 'RWA' },
    { label: 'Saint Kitts And Nevis', value: 'KNA' },
    { label: 'Saint Lucia', value: 'LCA' },
    { label: 'Saint Vincent And The Grenadin', value: 'VCT' },
    { label: 'Samoa', value: 'WSM' },
    { label: 'San Marino', value: 'SMR' },
    { label: 'Sao Tome And Principe', value: 'STP' },
    { label: 'Saudi Arabia', value: 'SAU' },
    { label: 'Scotland', value: 'SCO' },
    { label: 'Senegal', value: 'SEN' },
    { label: 'Serbia', value: 'SRB' },
    { label: 'Seychelles', value: 'SYC' },
    { label: 'Sierra Leone', value: 'SLE' },
    { label: 'Singapore', value: 'SGP' },
    { label: 'Slovakia (Slovak Republic)', value: 'SVK' },
    { label: 'Slovenia', value: 'SVN' },
    { label: 'Solomon Islands', value: 'SLB' },
    { label: 'Somalia', value: 'SOM' },
    { label: 'South Africa', value: 'ZAF' },
    { label: 'South Georgia And The South Sa', value: 'SGS' },
    { label: 'Spain', value: 'ESP' },
    { label: 'Sri Lanka', value: 'LKA' },
    { label: 'St. Helena', value: 'SHN' },
    { label: 'St. Pierre And Miquelon', value: 'SPM' },
    { label: 'Sudan', value: 'SDN' },
    { label: 'Suriname', value: 'SUR' },
    { label: 'Svalbard And Jan Mayen Islands', value: 'SJM' },
    { label: 'Swaziland', value: 'SWZ' },
    { label: 'Sweden', value: 'SWE' },
    { label: 'Switzerland', value: 'CHE' },
    { label: 'Syrian Arab Republic', value: 'SYR' },
    { label: 'Taiwan', value: 'TWN' },
    { label: 'Tajikistan', value: 'TJK' },
    { label: 'Tanzania, United Republic Of', value: 'TZA' },
    { label: 'Thailand', value: 'THA' },
    { label: 'Togo', value: 'TGO' },
    { label: 'Tokelau', value: 'TKL' },
    { label: 'Tonga', value: 'TON' },
    { label: 'Trinidad And Tobago', value: 'TTO' },
    { label: 'Tunisia', value: 'TUN' },
    { label: 'Turkey', value: 'TUR' },
    { label: 'Turkmenistan', value: 'TKM' },
    { label: 'Turks And Caicos Islands', value: 'TCA' },
    { label: 'Tuvalu', value: 'TUV' },
    { label: 'Uganda', value: 'UGA' },
    { label: 'Ukraine', value: 'UKR' },
    { label: 'United Arab Emirates', value: 'ARE' },
    { label: 'United Kingdom', value: 'GBR' },
    { label: 'United States Minor Outlying', value: 'UMI' },
    { label: 'Uruguay', value: 'URY' },
    { label: 'Uzbekistan', value: 'UZB' },
    { label: 'Vanuatu', value: 'VUT' },
    { label: 'Vatican City State (Holy See)', value: 'VAT' },
    { label: 'Venezuela', value: 'VEN' },
    { label: 'Viet Nam', value: 'VNM' },
    { label: 'Virgin Islands (British)', value: 'VGB' },
    { label: 'Virgin Islands (U.S.)', value: 'VIR' },
    { label: 'Wallis And Futuna Islands', value: 'WLF' },
    { label: 'Western Sahara', value: 'ESH' },
    { label: 'Yemen', value: 'YEM' },
    { label: 'Yugoslavia', value: 'YUG' },
    { label: 'Zaire', value: 'ZAR' },
    { label: 'Zambia', value: 'ZMB' },
    { label: 'Zimbabwe', value: 'ZWE' },
];

export const US_STATES = [
    { label: '', value: '' },
    { label: 'AL', value: 'AL' },
    { label: 'AK', value: 'AK' },
    { label: 'AZ', value: 'AZ' },
    { label: 'AR', value: 'AR' },
    { label: 'CA', value: 'CA' },
    { label: 'CO', value: 'CO' },
    { label: 'CT', value: 'CT' },
    { label: 'DE', value: 'DE' },
    { label: 'DC', value: 'DC' },
    { label: 'FL', value: 'FL' },
    { label: 'GA', value: 'GA' },
    { label: 'HI', value: 'HI' },
    { label: 'ID', value: 'ID' },
    { label: 'IL', value: 'IL' },
    { label: 'IN', value: 'IN' },
    { label: 'IA', value: 'IA' },
    { label: 'KS', value: 'KS' },
    { label: 'KY', value: 'KY' },
    { label: 'LA', value: 'LA' },
    { label: 'ME', value: 'ME' },
    { label: 'MD', value: 'MD' },
    { label: 'MA', value: 'MA' },
    { label: 'MI', value: 'MI' },
    { label: 'MN', value: 'MN' },
    { label: 'MS', value: 'MS' },
    { label: 'MO', value: 'MO' },
    { label: 'MT', value: 'MT' },
    { label: 'NE', value: 'NE' },
    { label: 'NV', value: 'NV' },
    { label: 'NH', value: 'NH' },
    { label: 'NJ', value: 'NJ' },
    { label: 'NM', value: 'NM' },
    { label: 'NY', value: 'NY' },
    { label: 'NC', value: 'NC' },
    { label: 'ND', value: 'ND' },
    { label: 'OH', value: 'OH' },
    { label: 'OK', value: 'OK' },
    { label: 'OR', value: 'OR' },
    { label: 'PA', value: 'PA' },
    { label: 'PR', value: 'PR' },
    { label: 'RI', value: 'RI' },
    { label: 'SC', value: 'SC' },
    { label: 'SD', value: 'SD' },
    { label: 'TN', value: 'TN' },
    { label: 'TX', value: 'TX' },
    { label: 'UT', value: 'UT' },
    { label: 'VT', value: 'VT' },
    { label: 'VI', value: 'VI' },
    { label: 'VA', value: 'VA' },
    { label: 'WA', value: 'WA' },
    { label: 'WV', value: 'WV' },
    { label: 'WI', value: 'WI' },
    { label: 'WY', value: 'WY' },
];

export const CREATE_USER_STARTED = 'CREATE_USER_STARTED';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RECEIVE_PROTECTED_DATA = 'RECEIVE_PROTECTED_DATA';
export const NOT_LOGGED_IN = 'NOT_LOGGED_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';

export const TEXTBOX_UPDATE = 'TEXTBOX_UPDATE';
export const CHECKBOX_UPDATE = 'CHECKBOX_UPDATE';
export const DROPDOWN_UPDATE = 'DROPDOWN_UPDATE';

export const VALIDATE_SUCCESS = 'VALIDATE_SUCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_ERROR = 'RECOVER_PASSWORD_ERROR';

// Launch Darkly Flags
export const ALLOW_USER_LOGIN = 'allow-user-login';
export const ALLOW_NEW_USER_REGISTRATION = 'allow-new-user-registration';

// inventory types
export const INVENTORY_CAMPING = 'CAMPING';
export const INVENTORY_PERMIT = 'PERMIT';
export const INVENTORY_VEHICLE_PERMIT = 'VEHICLE_PERMIT';
export const INVENTORY_TICKET = 'TICKET';
export const INVENTORY_VENUE_RESERVATIONS = 'VENUE_RESERVATIONS';
export const INVENTORY_VENUE_APPLICATION = 'VENUE_APPLICATION';
export const INVENTORY_TIMED_ENTRY = 'TIMED_ENTRY';
export const INVENTORY_LOTTERY_CAMPING = 'LOTTERY_CAMPING';
export const INVENTORY_LOTTERY_TICKET = 'LOTTERY_TICKET';
export const INVENTORY_LOTTERY_PERMIT = 'LOTTERY_PERMIT';
export const INVENTORY_QUEUE_LOTTERY = 'QUEUE_LOTTERY';
export const INVENTORY_PARKPASS = 'PARKPASS';
export const INVENTORY_PASS = 'PASS';
export const INVENTORY_ACTIVITYPASS = 'ACTIVITYPASS';
export const INVENTORY_TREE_PERMIT = 'TREE_PERMIT';
export const INVENTORY_RENTAL = 'RENTAL';
export const INVENTORY_DONATION = 'DONATION';

// profile
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ACCOUNT_UPDATED = 'ACCOUNT_UPDATED';
export const UPDATE_EMAIL_SENT = 'ACCOUNT_UPDATE_EMAIL_SENT';
export const ACCOUNT_UPDATE_FAILED = 'ACCOUNT_UPDATE_FAILED';
export const RESET_PROFILE_MESSAGE_FLAGS = 'RESET_PROFILE_MESSAGE_FLAGS';
export const SHOW_REVIEW_SUCCESS_RIBBON = 'SHOW_REVIEW_SUCCESS_RIBBON';
export const HIDE_REVIEW_SUCCESS_RIBBON = 'HIDE_REVIEW_SUCCESS_RIBBON';

// rating
export const FETCH_ACCOUNT_RATINGS_START = 'FETCH_ACCOUNT_RATINGS_START';
export const FETCH_ACCOUNT_RATINGS_SUCCESS = 'FETCH_ACCOUNT_RATINGS_SUCCESS';
export const FETCH_ACCOUNT_RATINGS_FAILED = 'FETCH_ACCOUNT_RATINGS_FAILED';

// Account Primary Navigation
const { sitepasses: sitepassAllowed } = process.env.ALLOWED_INVENTORY_TYPES || false;
const giftCardsAllowed = process.env.GIFT_CARDS_ENABLED;
export const PRIMARY_NAVIGATION_ACCOUNT = [
    {
        url: '/account/profile',
        title: 'Account',
        isCommercial: false,
    },
    {
        url: getReservationsUrl(),
        title: 'Reservations',
        isCommercial: false,
    },
    {
        url: sitepassAllowed ? '/account/passes' : '/account/activity-passes',
        title: 'Passes',
        isCommercial: false,
    },
    {
        url: '/account/gift-cards/manage',
        title: 'Gift Cards',
        enabled: giftCardsAllowed,
        ldFlagEnabled: 'enableGiftCards',
    },
    {
        url: '/account/favorites',
        title: 'Favorites',
        isCommercial: false,
    },
    {
        url: '/account/trips',
        title: 'Saved Trips',
        isCommercial: false,
    },
    {
        url: '/account/alerts',
        title: 'Availability Alerts (Beta)',
    },
    {
        url: '/account/facilities',
        title: 'Commercial',
        isCommercial: true,
    },
];

// Account Profile Secondary Navigation
export const SECONDARY_NAVIGATION_ACCOUNT_PROFILE = [
    {
        url: '/account/profile',
        title: 'Profile',
        isCommercial: false,
    },
    {
        url: '/account/profile/password',
        title: 'Change Password',
        isCommercial: false,
    },
    // {
    //     url: '/account/profile/saved_payment',
    //     title: 'Saved Payment',
    //     isCommercial: true
    // },
    {
        url: '/account/trip-preferences',
        title: 'Trip Preferences',
        isCommercial: false,
    },
    {
        url: '/account/communication-preferences',
        title: 'Communications',
        isCommercial: false,
    },
    {
        url: '/account/security',
        title: 'Multi-factor Authentication',
        isCommercial: false,
    },
    {
        url: '/account/delete-account',
        title: 'Delete Account',
        isCommercial: false,
    },
];

// Account Reservation Secondary Navigation
export const SECONDARY_NAVIGATION_ACCOUNT_RESERVATIONS = [
    {
        url: '/account/reservations',
        title: 'Reservations',
    },
    {
        url: '/account/lotteries',
        title: 'Lotteries',
    },
    {
        url: '/account/donations',
        title: 'Lotteries',
    },
];

// Account Reservation Secondary Navigation
export const SECONDARY_NAVIGATION_ACCOUNT_RESERVATIONS_LISTING = [
    {
        url: '/account/reservations/upcoming',
        title: 'Upcoming',
    },
    {
        url: '/account/reservations/past',
        title: 'Past',
    },
    {
        url: '/account/reservations/cancelled',
        title: 'Cancelled',
    },
    {
        url: '/account/lotteries',
        title: 'Lotteries',
    },
    {
        url: '/account/donations',
        title: 'Donations',
    },
].filter((item) => {
    if (item.url === '/account/lotteries') {
        return process.env.ALLOWED_INVENTORY_TYPES?.lotteries;
    }

    return true;
});

// Account Commercial Secondary Navigation
export const SECONDARY_NAVIGATION_ACCOUNT_COMMERCIAL = [
    {
        url: '/account/facilities',
        title: 'Facilities',
    },
    {
        url: '/account/employees',
        title: 'Employees',
    },
];

// Gift Cards Secondary Navigation
export const SECONDARY_NAVIGATION_ACCOUNT_GIFT_CARDS = [
    {
        url: '/account/gift-cards/manage',
        title: 'Manage Gift Cards',
    },
    {
        url: '/account/gift-cards/purchase',
        title: 'Purchase a Gift Card',
    },
    {
        url: '/account/gift-cards/purchased',
        title: 'Purchased Gift Cards',
    },
];

// Cancel
export const SHOW_CANCEL_SUCCESS_MODAL = 'SHOW_CANCEL_SUCCESS_MODAL';
export const RESET_SHOW_CANCEL_SUCCESS_MODAL = 'RESET_SHOW_CANCEL_SUCCESS_MODAL';
export const SHOW_CANCEL_LOTTERY_SUCCESS_MODAL = 'SHOW_CANCEL_LOTTERY_SUCCESS_MODAL';
export const RESET_SHOW_CANCEL_LOTTERY_SUCCESS_MODAL =
    'RESET_SHOW_CANCEL_LOTTERY_SUCCESS_MODAL';

// Print
export const SHOW_PERMIT_PRINT_MODAL = 'SHOW_PERMIT_PRINT_MODAL';
export const HIDE_PERMIT_PRINT_MODAL = 'HIDE_PERMIT_PRINT_MODAL';

export const SHOW_TICKET_PRINT_MODAL = 'SHOW_TICKET_PRINT_MODAL';
export const HIDE_TICKET_PRINT_MODAL = 'HIDE_TICKET_PRINT_MODAL';

export const GET_TICKET_PDF = 'GET_TICKET_PDF';
export const GET_TICKET_PDF_ERROR = 'GET_TICKET_PDF_ERROR';
export const GET_TICKET_PDF_REQUEST = 'GET_TICKET_PDF_REQUEST';
export const GET_TICKET_PDF_SUCCESS = 'GET_TICKET_PDF_SUCCESS';

export const SET_PRINT_TICKET_URL = 'SET_PRINT_TICKET_URL';

// Camping
export const GET_CAMPING_PERMIT_PDF_ERROR = 'GET_CAMPING_PERMIT_PDF_ERROR';

// Account Trips Secondary Navigation
export const SECONDARY_NAVIGATION_ACCOUNT_TRIPS = [
    {
        url: '/account/favorites',
        title: 'My Favorites',
    },
    {
        url: '/account/trips',
        active: 'true',
        title: 'Saved Trips',
    },
];

// Trip Preferences ACTIVITIES (TODO: Need API to get all trip preferences)
export const TRIP_PREFERENCES_ACTIVITIES = [
    { name: 'Auto Touring', value: 'auto_touring' },
    { name: 'Biking', value: 'biking' },
    { name: 'Boating', value: 'boating' },
    { name: 'Camping', value: 'camping' },
    { name: 'Climbing', value: 'climbing' },
    { name: 'Day Use Area', value: 'day_use_area' },
    { name: 'Diving', value: 'diving' },
    { name: 'Documentary Site', value: 'documentary_site' },
    { name: 'Environment Education', value: 'environmental_education' },
    { name: 'Fire Lookouts', value: 'fire_lookouts' },
    { name: 'Fish Hatchery', value: 'fish_hatchery' },
    { name: 'Fish Viewing', value: 'fish_viewing' },
    { name: 'Fishing', value: 'fishing' },
    { name: 'Hiking', value: 'hiking' },
    { name: 'Historic Site', value: 'historic_site' },
    { name: 'Horse Camping', value: 'horse_camping' },
    { name: 'Horseback Riding', value: 'horseback_riding' },
    { name: 'Hotel Lodge Resort FS Owned', value: 'hotel_lodge_resort_fs_owned' },
    { name: 'Hotel Lodge Resort Private', value: 'hotel_lodge_resort_private' },
    { name: 'Hunting', value: 'hunting' },
];

// Trip Preferences Camping/Lodging and Day Use (TODO: Need API to get all trip preferences)
export const TRIP_PREFERENCES_CAMPING_LODGING_DAY_USE = [
    { name: 'Tent', value: 'tent' },
    { name: 'Small Tent', value: 'small_tent' },
    { name: 'Large Tent', value: 'large_tent' },
    { name: 'RV', value: 'rv' },
    { name: 'Pop Up', value: 'pop_up' },
    { name: 'Trailer', value: 'trailer' },
    { name: 'Pickup Camper', value: 'pickup_camper' },
    { name: 'Caravan', value: 'caravan' },
    { name: 'Fifth Wheel', value: 'fifth_wheel' },
    { name: 'Boat', value: 'boat' },
];

// Lottery action types
export const FETCH_LOTTERIES_SUCCESS = 'FETCH_LOTTERIES_SUCCESS';
export const FETCH_LOTTERIES_ERROR = 'FETCH_LOTTERIES_ERROR';

// Permit cancellation refund types
export const PERMIT_REFUND_TYPES = new Map();
PERMIT_REFUND_TYPES.set(1, 'UseFeeAlways');
PERMIT_REFUND_TYPES.set(2, 'UseFeeOutsideWindowNoneInside');
PERMIT_REFUND_TYPES.set(3, 'NoRefund');
PERMIT_REFUND_TYPES.set(4, 'UseFeeUntilPrintingNoneAfter');

export const PERMIT_REFUND_TEXT =
    'Customers will receive use fee upon reservation cancellation.';
export const PERMIT_NO_REFUND_TEXT =
    'Customers who cancel any reservation after the times stated or no-shows will forfeit the use fee.';
export const PERMIT_PRINTING_REFUND_TEXT =
    'Customers will receive use fee upon reservation cancellation unless their permit has been printed. If so they will forfeit the use fee.';

// Reservation Status constants
export const RESERVATION_CANCELLED_STATUS = 'CANCELLED';
export const RESERVATION_DENIED_STATUS = 'DENIED';
export const RESERVATION_CLOSURE_STATUS = 'CLOSURE';
export const RESERVATION_VOIDED_STATUS = 'VOIDED';
export const RESERVATION_COMPLETE_STATUS = 'COMPLETED';
export const RESERVATION_MODIFIED_STATUS = 'MODIFIED';
export const RESERVATION_STATUS_MAPPING = {
    [RESERVATION_CANCELLED_STATUS]: 'Cancelled',
    [RESERVATION_DENIED_STATUS]: 'Denied',
    [RESERVATION_CLOSURE_STATUS]: 'Closure',
    [RESERVATION_VOIDED_STATUS]: 'Voided',
};

export const RESERVATION_ISSUED_STATUS_CD = 11;

// Allocation Status constants
export const ALLOCATION_ISSUED = 'Issued';
export const ALLOCATION_NOT_CONFIRMED = 'Not Confirmed';
export const ALLOCATION_DECLINED = 'Declined';
export const ALLOCATION_RESERVED = 'Reserved';
export const ALLOCATION_NONE = 'None';

// Pass Types
export const GOLDEN_AGE_ACCESS_PASS = 'Golden Age Access Pass';
export const GOLDEN_AGE_SENIOR_PASS = 'Golden Age Senior Pass';
export const LIFETIME_SENIOR_PASS = 'Lifetime Senior Pass';
export const INTERAGENCY_ACCESS_PASS = 'Interagency Access Pass';
export const INTERAGENCY_ANNUAL_PASS = 'Interagency Annual Pass';
export const INTERAGENCY_MILITARY_PASS = 'Interagency Military Pass';
export const INTERAGENCY_SENIOR_PASS = 'Interagency Senior Pass';
export const INTERAGENCY_VOLUNTEER_PASS = 'Interagency Volunteer Pass';
export const INTERAGENCY_FOURTH_GRADE_PASS = 'Interagency 4th Grade Pass';
export const PASS_TYPES = [
    GOLDEN_AGE_ACCESS_PASS,
    GOLDEN_AGE_SENIOR_PASS,
    LIFETIME_SENIOR_PASS,
    INTERAGENCY_ACCESS_PASS,
    INTERAGENCY_ANNUAL_PASS,
    INTERAGENCY_MILITARY_PASS,
    INTERAGENCY_SENIOR_PASS,
    INTERAGENCY_VOLUNTEER_PASS,
    INTERAGENCY_FOURTH_GRADE_PASS,
];

// Reservation Alerts
export const RESERVATION_ALERT_TYPES = {
    REQUEST: 1,
    DECLINE: 2,
};

// This is used to determine to show print ticket - only one location needs it.
export const CHRISTMAS_TREE_LOTTERY_FACILITY_ID = '250006';
export const FIREFLY_EVENT_LOTTERY_FACILITY_ID = '233374';
export const CONGAREE_FIREFLIES_FESTIVAL_LOTTERY_FACILITY_ID = '300008';

// This is used to determine to the trasition timeout speed of the mobile details panel.
export const TRANSITION_TIMEOUT = 300;
export const PERMIT_NO_PRINT = [
    // '273374', Cedar Mesa asked for printing to be allowed R1S-40333
    '249986',
    '234625',
    '234622',
    '234623',
    '251982',
    '250849',
    '233396',
    '445857',
    '445858',
    '445859',
    '74984',
    '234652',
    '233304',
    '274309',
    '4675314',
    '4675315',
    '4675317',
    '249991',
    '4251909',
    '4675310',
    '4675324',
    '4675325',
    '4675326',
    '4251911',
    '4251912',
];
export const TILLY_JANE_ID = '233326';
export const HALF_DOME_ID = '234652';

// Ticket Lottery statuses
export const TICKET_LOTTERY_UNKNOWN_STATUS = 'UNKNOWN';
export const TICKET_LOTTERY_PENDING_STATUS = 'PENDING';
export const TICKET_LOTTERY_REJECTED_STATUS = 'REJECTED';
export const TICKET_LOTTERY_ACCEPTED_STATUS = 'ACCEPTED';
export const TICKET_LOTTERY_CANCELED_STATUS = 'CANCELED';
export const TICKET_LOTTERY_SUBMITTED_STATUS = 'SUBMITTED';

// Permit Division Type
export const DAY_USE_MOTOR = 'Day Use Motor';

// Alert Types
export const ALTERNATE_PERMIT_HOLDER_ACCEPT = 1;
export const ALTERNATE_PERMIT_HOLDER_DECLINE = 2;

// Golang empty/null dates
export const EMPTY_DATE = '0001-01-01T00:00:00Z';

// ticket printing
export const NATIONAL_CHRISTMAS_TREE_LIGHTING_CEREMONY = '234635';
export const WASHINGTON_MONUMENT_ID = '234635';
export const THE_WHITE_HOUSE_EASTER_EGG_ROLL = '250029';
export const CENTRAL_CASCADE_ID = '300009';
export const ROCKY_MOUNTAIN = '300013';
export const DENALI_NATIONAL_PARK = '300014';
export const BLANCHARD_SPRING_CAVERN = '233266';
export const MAMMOTH_CAVE_NATIONAL_PARK = '234640';
export const YOSEMITE_NATIONAL_PARK = '300015';
export const MT_RUSHMORE_LOTTERY = '300010';
export const PEARL_HARBOR_HISTORIC_SITES = '233338';
export const ZION_NATIONAL_PARK = '300016';
export const LA_MINA_IN_EL_YUNQUE_NATIONAL_FOREST = '300017';
export const KILAUEA = '300018';
export const DINOSAUR_NATIONAL_MONUMENT = '300020';
export const SAN_FRANCISCO_NATIONAL_PARK = '10066974';
export const KASHA_KATUWE_TIMED_ENTRY = '10065448';
export const ARLINGTON_HOUSE_THE_ROBERT_ELEE_MEMORIAL_TICKETS = '10073370';
export const MULTNOMAH_FALLS_TIMED_RESERVATION_TICKETS = '10073376';
export const CANYONS_OF_THE_ANCIENTS_MUSEUM_TOURS = '10086548';
export const GOLDEN_SPIKE_WINTER_STEAM_FESTIVAL_TICKETS = '10086684';

export const TICKET_PDF_ENDPOINT = (reservation) => {
    const facilityId = get(reservation, 'order_details.facility_id', '');
    return `${process.env.API}/ticket/facility/${facilityId}/reservations/${reservation.reservation_id}/tickets/pdf`;
};

export const TICKET_LARGE_GROUP_PDF_ENDPOINT = (reservation) => {
    return `${process.env.API}/ticket/reservations/${reservation.reservation_id}/pdf`;
};

// Passes action types
export const FETCH_PASSES_SUCCESS = 'FETCH_PASSES_SUCCESS';
export const FETCH_PASSES_ERROR = 'FETCH_PASSES_ERROR';
export const FETCH_PASSES_START = 'FETCH_PASSES_START';
export const FETCH_ACTIVITYPASSES_SUCCESS = 'FETCH_ACTIVITYPASSES_SUCCESS';
export const FETCH_ACTIVITYPASSES_ERROR = 'FETCH_ACTIVITYPASSES_ERROR';
export const FETCH_ACTIVITYPASSES_START = 'FETCH_ACTIVITYPASSES_START';

// Camping Reservation types
export const SET_NO_MOD_RULE_FOR_RESERVATION = 'SET_NO_MOD_RULE_FOR_RESERVATION';
export const SET_CAMPING_RESERVATION = 'SET_CAMPING_RESERVATION';
export const SET_CAMPING_ORDER_RESERVATION = 'SET_CAMPING_ORDER_RESERVATION';
export const CLEAR_NO_MOD_RULE_FOR_RESERVATION = 'CLEAR_NO_MOD_RULE_FOR_RESERVATION';
export const START_FETCHING_CAMPGROUND_RESERVATION =
    'START_FETCHING_CAMPGROUND_RESERVATION';
export const FINISHED_FETCHING_CAMPGROUND_RESERVATION =
    'FINISHED_FETCHING_CAMPGROUND_RESERVATION';

// Camping Fee types
export const CAMPING_USE_FEE = 'Camping Use Fee';

// Query params
export const INVENTORY_TYPE_QUERY_PARAM = 'inventory_type';

// Ticket Reservation types
export const SET_TICKET_RESERVATION = 'SET_TICKET_RESERVATION';
export const SET_TICKET_INVENTORY_RESERVATION = 'SET_TICKET_INVENTORY_RESERVATION';
export const UPDATE_FETCH_TICKET_RESERVATION_STATUS =
    'UPDATE_FETCH_TICKET_RESERVATION_STATUS';
export const UPDATE_FETCH_TICKET_INVENTORY_RESERVATION_STATUS =
    'UPDATE_FETCH_TICKET_INVENTORY_RESERVATION_STATUS';

// Tickets Reservation tabs
export const TICKETS_RESERVATION_TABS = {
    viewReservation: { value: 'viewReservation', title: 'View/Print Reservation' },
    orderDetails: { value: 'orderDetails', title: 'Modify Order Details' },
    modifyTicketDates: {
        value: 'modifyTicketDates',
        title: 'Modify Dates/Times/Quantity',
    },
    cancelReservation: { value: 'cancelReservation', title: 'Cancel Reservation' },
    viewReceipt: { value: 'viewReceipt', title: 'View/Print Receipt' },
};

// Favorites List and Map related action types
export const FAVORITES_HANDLE_SELECTED_MAP_FEATURE =
    'FAVORITES_HANDLE_SELECTED_MAP_FEATURE';
export const FAVORITES_HANDLE_SELECTED_CARD = 'FAVORITES_HANDLE_SELECTED_CARD';
export const FAVORITES_HANDLE_SEARCH_ON_MOVE = 'FAVORITES_HANDLE_SEARCH_ON_MOVE';

// trip preferences keys
export const preferencesOptions = [
    {
        Name: 'Only show locations with accessible campsites',
        Value: 'campsite_accessible',
        Category: 'accessibility',
    },
    {
        Name: 'Tent',
        Value: 'campsite-equipment-tent',
        Category: 'allowed_equipment',
    },
    {
        Name: 'RV / Motorhome',
        Value: 'campsite-equipment-rv',
        Category: 'allowed_equipment',
    },
    {
        Name: 'Trailer',
        Value: 'campsite-equipment-trailer',
        Category: 'allowed_equipment',
    },
    {
        Name: 'Hide unavailable',
        Value: 'include_unavailable',
        Category: 'booking_preferences',
    },
    {
        Name: 'Hide partially available',
        Value: 'include_partially_available',
        Category: 'booking_preferences',
    },
    {
        Name: 'Hide first come, first served',
        Value: 'include_notreservable',
        Category: 'booking_preferences',
    },
    {
        Name: 'Electric hookup',
        Value: 'campsite-amenities-electricity-hookup',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Water hookup',
        Value: 'campsite-amenities-water-hookup',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Sewer hookup',
        Value: 'campsite-amenities-sewer-hookup',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Pull-through driveway',
        Value: 'campsite-amenities-pull-through-driveway',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Shade',
        Value: 'campsite-amenities-shade',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Pets allowed',
        Value: 'campsite-amenities-pets-allowed',
        Category: 'site_features_amenities',
    },
];

// MFA action types
export const FETCH_MFA_STATUS_SUCCESS = 'FETCH_MFA_STATUS_SUCCESS';
export const FETCH_MFA_STATUS_ERROR = 'FETCH_MFA_STATUS_ERROR';
export const FETCH_MFA_STATUS_START = 'FETCH_MFA_STATUS_START';
export const ENABLE_MFA_STATUS_SUCCESS = 'ENABLE_MFA_STATUS_SUCCESS';
export const ENABLE_MFA_STATUS_ERROR = 'ENABLE_MFA_STATUS_ERROR';
export const ENABLE_MFA_STATUS_START = 'ENABLE_MFA_STATUS_START';

// availability alert types
export const AVAILABILITY_ALERT_CAMPING = 'camping';
export const AVAILABILITY_ALERT_TICKET = 'tickets';
export const AVAILABILITY_ALERT_TIMED_ENTRY = 'timedentry';
