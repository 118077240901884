/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleLandingBlockItem.jsx
 * Created by Destin Frasier on 03/21/18.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { FlexCol, Icons, HtmlParser } from 'sarsaparilla';
import { ARTICLE } from '../../../constants/propTypes';
import { getContentTypeLabel, parseArticleImage } from '../../../utils/articlesHelper';
import Content from '../article/Content';

import { usePrefetch } from '../../../redux';

const propTypes = {
    article: ARTICLE,
};

function ArticleLandingBlockItem({ article }) {
    const prefetch = usePrefetch('fetchArticleById');

    function prefetchArticle(articleId) {
        if (articleId) {
            prefetch(articleId);
        }
    }

    return (
        <FlexCol xs={12} md={6} xl={4}>
            <div className="article-landing-article-block-wrap">
                <Link
                    to={article.url}
                    alt={article.article_title}
                    onMouseEnter={() => {
                        prefetchArticle(article?.id);
                    }}
                >
                    <div className="article-landing-article-block-image">
                        <img
                            src={parseArticleImage(article)}
                            alt={article.hero_image_alt_text}
                        />
                    </div>
                    <div className="article-landing-article-block-overlay">
                        <div className="article-landing-article-block-overlay-content">
                            <div className="block-overlay-content-primary-text">
                                {getContentTypeLabel(article.content_type)}
                            </div>
                            <span className="read-more">
                                Read More <Icons.IconArrowForward />
                            </span>
                        </div>
                    </div>
                </Link>
                <div className="article-landing-article-block-content-wrap">
                    <div className="article-block-title">
                        <HtmlParser html={article.article_title} />
                    </div>
                    <Content content={article.article_overview} />
                </div>
            </div>
        </FlexCol>
    );
}

ArticleLandingBlockItem.propTypes = propTypes;

export default ArticleLandingBlockItem;
