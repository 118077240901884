/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FlexCol, FlexRow, Icons, Alert } from 'sarsaparilla';
import { evAdapters, evLevels, evNetworks } from '../constants';

function LayersPanel({
    onTrailsClick,
    onEvClick,
    onMapStyleClick,
    hasTrails,
    hasEv,
    showPanel,
    setShowPanel,
    isTrailVisible,
    isEvVisible,
    updateReservableLocationsLayer,
    updateEvLayer,
    mapEvFilters,
    mapFacilitiesToDisplay,
    displayTrailsMessage,
    setDisplayTrailsMessage,
    isMobile,
}) {
    const hasPointsOfInterest = hasEv || hasTrails;
    const onButtonClick = () => {
        setShowPanel(!showPanel);
        if (displayTrailsMessage) setDisplayTrailsMessage(false);
    };

    const onCloseLayerPanel = () => {
        setShowPanel(false);
    };

    const getEvOptions = (evOptions) => {
        const resultOptions = Object.keys(evOptions).map((option) => {
            const checkedStatus = mapEvFilters?.current?.[option].shouldDisplay;
            return (
                <FlexCol xs={isMobile ? 12 : 4} key={`${option}-flex-col`}>
                    <Checkbox
                        id={option}
                        label={evOptions[option].label}
                        onChange={updateEvLayer}
                        isChecked={checkedStatus}
                    />
                </FlexCol>
            );
        });

        return resultOptions;
    };

    const evFilter = (
        <FlexRow className="ev-panel" xs={12}>
            <FlexRow xs={12} className="level-charger-container">
                <FlexCol xs={12}>
                    <div className="map-layers-title mt-2">LEVEL CHARGER</div>
                </FlexCol>
                {getEvOptions(evLevels)}
            </FlexRow>

            <FlexRow xs={12} className="preferred-charger-adapter">
                <FlexCol xs={12}>
                    <div className="map-layers-title mt-2">PREFERRED CHARGER ADAPTER</div>
                </FlexCol>
                {getEvOptions(evAdapters)}
            </FlexRow>

            <FlexRow xs={12} className="preferred-charger-network">
                <FlexCol xs={12}>
                    <div className="map-layers-title mt-2">
                        PREFERRED CHARGING NETWORK
                    </div>
                </FlexCol>
                {getEvOptions(evNetworks)}
            </FlexRow>
        </FlexRow>
    );

    const evDisclaimer = (
        <Alert type="info">
            EV charging station data is sourced from the Department of Energy. It is
            updated periodically and may not reflect the current status of EV charging
            availability
        </Alert>
    );

    const pointsOfInterestSegment = (
        <FlexRow xs={12} className="map-poi-layers-container">
            <FlexCol>
                <div className="map-layers-title mt-2">POINTS OF INTEREST</div>
                {hasTrails && (
                    <Checkbox
                        id="trails"
                        label="Trails"
                        isChecked={isTrailVisible}
                        onChange={onTrailsClick}
                        isDisabled={!hasTrails}
                    />
                )}
                {hasEv && (
                    <Checkbox
                        id="ev"
                        label="EV"
                        isChecked={isEvVisible}
                        onChange={onEvClick}
                        isDisabled={!hasEv}
                    />
                )}
                {isEvVisible && evFilter}
                {isEvVisible && evDisclaimer}
            </FlexCol>
        </FlexRow>
    );

    const reservableLocationsSegment = (
        <FlexRow className="map-reservable-locations" xs={12}>
            <FlexCol xs={12}>
                <div className="map-layers-title mt-2">RESERVABLE LOCATIONS</div>
            </FlexCol>
            {!!mapFacilitiesToDisplay?.current &&
                Object.keys(mapFacilitiesToDisplay?.current).map((element) => {
                    const facilityItem = mapFacilitiesToDisplay?.current?.[element];
                    return (
                        <FlexCol
                            xs={isMobile ? 12 : 4}
                            key={`${facilityItem?.id}-flex-col`}
                        >
                            <Checkbox
                                id={facilityItem?.id}
                                label={facilityItem?.label}
                                onChange={updateReservableLocationsLayer}
                                isChecked={facilityItem?.shouldDisplay}
                            />
                        </FlexCol>
                    );
                })}
        </FlexRow>
    );

    const panel = (
        <FlexRow className={isMobile ? "mobile-map-layer-panel" : "map-layer-panel"}>
            <FlexCol className="map-layers-container">
                <FlexCol className="map-layer-panel-close-button-container">
                    <Button
                        iconBeforeElement={<Icons.IconClose />}
                        appearance="subtle"
                        onClick={onCloseLayerPanel}
                        className="close-button"
                        size="sm"
                        aria-label="Close Map Layers panel"
                    />
                </FlexCol>
                <FlexRow className="map-style-layers-container">
                    <div className="map-style-layers">
                        <div className="map-layers-title">MAP VIEWS</div>
                        <FlexRow alignItems="center">
                            <FlexCol>
                                <button
                                    type="button"
                                    className="map-layers-style-button"
                                    onClick={() => onMapStyleClick(false)}
                                    aria-label="Show default map"
                                >
                                    <img
                                        src={`${process.env.CDN_ROOT}/app/map-icons/map-view.jpg`}
                                        alt=""
                                    />
                                    Default
                                </button>
                            </FlexCol>
                            <FlexCol>
                                <button
                                    type="button"
                                    className="map-layers-style-button"
                                    onClick={() => onMapStyleClick(true)}
                                    aria-label="Show satellite map"
                                >
                                    <img
                                        src={`${process.env.CDN_ROOT}/app/map-icons/satellite-view.jpg`}
                                        alt=""
                                    />
                                    Satellite
                                </button>
                            </FlexCol>
                        </FlexRow>
                    </div>
                </FlexRow>
            </FlexCol>
            {reservableLocationsSegment}
            {hasPointsOfInterest && pointsOfInterestSegment}
        </FlexRow>
    );

    const layerButton = (
        <Button
            iconBeforeElement={<Icons.IconLayers />}
            className="map-layer-button"
            appearance="subtle"
            onClick={onButtonClick}
        >
            Map Layers
        </Button>
    );

    const getLayerNotificationMessage = (message) => (
        <div className="layers-notification-message-container">
            <div className="layers-notification-message-triangle" />
            <div className="layers-notification-message-box">{message}</div>
        </div>
    );

    const trailsMessage = getLayerNotificationMessage(
        'New! Click here to show Trails...'
    );

    return (
        <>
            {hasTrails && displayTrailsMessage && trailsMessage}
            {layerButton}
            {showPanel && panel}
        </>
    );
}

export default LayersPanel;

LayersPanel.propTypes = {
    onTrailsClick: PropTypes.func,
    onEvClick: PropTypes.func,
    onMapStyleClick: PropTypes.func,
    hasTrails: PropTypes.bool,
    hasEv: PropTypes.bool,
    showPanel: PropTypes.bool,
    setShowPanel: PropTypes.func,
    isTrailVisible: PropTypes.bool,
    isEvVisible: PropTypes.bool,
    updateReservableLocationsLayer: PropTypes.func,
    updateEvLayer: PropTypes.func,
    displayTrailsMessage: PropTypes.bool,
    setDisplayTrailsMessage: PropTypes.func,
};
