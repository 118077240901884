/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// issuance statuses
export const PERMIT_PRELIMINARY = 'PermitPreliminary';
export const PERMIT_IN_PROGRESS = 'PermitInProgress';
export const LOTTERY_IN_PROGRESS = 'In Progress';
export const LOTTERY_APPLIED = 'Applied';
export const LOTTERY_AWARDED = 'Awarded';
export const LOTTERY_AWARDED_IN_PROGRESS = 'Awarded In Progress';
export const LOTTERY_LOST = 'Unsuccessful';
export const LOTTERY_DECLINED = 'Declined';
export const LOTTERY_ACCEPTED = 'Accepted';
export const LOTTERY_NO_RESPONSE = 'No Response';
export const PERMIT_COMPLETE = 'PermitComplete';
export const PERMIT_ISSUED = 'PermitIssued';
export const CANCELLED_NO_RELEASE = 'CancelledNoRelease';
export const PERMIT_CANCELLED_BY_USER = 'PermitCancelledByUser';
export const LOTTERY_CANCELLED = 'Cancelled';
export const PERMIT_REFUNDED = 'PermitRefunded';
export const LOTTERY_VOIDED = 'Voided';
export const LOTTERY_DISQUALIFIED = 'Disqualified';
export const PERMIT_NO_SHOW = 'PermitNoShow';
export const LOTTERY_PENDING = 'Pending';

export const GROUP_AVAILABILITY_DAILY_KEY_NAME = 'ConstantQuotaUsageDaily';
export const GROUP_AVAILABILITY_KEY_NAME = 'ConstantQuotaUsageByStartDate';
export const MEMBER_AVAILABILITY_KEY_NAME = 'QuotaUsageByMember';
export const MEMBER_AVAILABILITY_DAILY_KEY_NAME = 'QuotaUsageByMemberDaily';

export const DAY_BLOCKED_DATE_IN_THE_PAST = 'DAY_BLOCKED_DATE_IN_THE_PAST';
export const DAY_BLOCKED_OUTSIDE_SEASON = 'DAY_BLOCKED_OUTSIDE_SEASON';
export const DAY_BLOCKED_OUTSIDE_LOTTERY_SEASON = 'DAY_BLOCKED_OUTSIDE_LOTTERY_SEASON';
export const DAY_BLOCKED_BY_LOTTERY = 'DAY_BLOCKED_BY_LOTTERY';
export const DAY_BLOCKED_MISSING_QUOTA = 'DAY_BLOCKED_MISSING_QUOTA';
export const DAY_BLOCKED_NO_QUOTA = 'DAY_BLOCKED_NO_QUOTA';
export const DAY_UNBLOCKED = 'DAY_UNBLOCKED';

export const FILTER_NUMERICAL = 'numerical';
