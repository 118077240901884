/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * PlanContainer.jsx
 * Created by Destin Frasier on 08/02/18
 * Updated by Aaron Luna on 02/14/22
 */

import './PlanYourTripPromo.scss';

import {
    Heading,
    InventoryPromoLinkTall,
    ContentBlock,
    Stack,
    Text,
    useWindowSize,
    Button,
} from 'sarsaparilla';

import passesImage from './images/passes_bg_760.jpg';
import passesMobileImage from './images/passes_bg_mobile_90.jpg';
import mobileAppImage from './images/mobile-app_bg_370.jpg';
import mobileAppMobileImage from './images/mobile-app_bg_mobile_90.jpg';

import tripBuilderImage from './images/trip-builder_bg_370.jpg';
import tripBuilderMobileImage from './images/trip-builder_bg_mobile_90.jpg';
import huntingImage from './images/rec-activities_bg_370.jpg';
import huntingMobileImage from './images/rec-activities_bg_mobile_90.jpg';
import lotteriesImage from './images/lotteries_bg_370.jpg';
import lotteriesMobileImage from './images/lotteries_bg_mobile_90.jpg';

const clickTagCategory = 'Homepage';
const clickTagLabel = 'Plan for Your Trip Section';
const imageHeight = '179px';

const planPromos = [
    {
        title: 'All About Passes',
        image: passesImage,
        mobileImage: passesMobileImage,
        imageAlt: 'Learn More About Passes',
        url: '/pass',
        clickTagCategory: { clickTagCategory },
        clickTagLabel: { clickTagLabel },
        height: '382px',
    },
    {
        title: `Checkout the ${process.env.SITE_NAME} Mobile App`,
        image: mobileAppImage,
        mobileImage: mobileAppMobileImage,
        imageAlt: 'Mobile Apps',
        url: '/mobile-app',
        clickTagCategory: { clickTagCategory },
        clickTagLabel: { clickTagLabel },
        height: `${imageHeight}`,
    },
    {
        title: 'Plan Your Vacation with Trip Builder',
        image: tripBuilderImage,
        mobileImage: tripBuilderMobileImage,
        imageAlt: 'Trip Builder',
        url: '/trips/new',
        clickTagCategory: { clickTagCategory },
        clickTagLabel: { clickTagLabel },
        height: `${imageHeight}`,
    },
    {
        title: 'Hunting, Fishing and Recreational Shooting',
        image: huntingImage,
        mobileImage: huntingMobileImage,
        imageAlt: 'Recreation Activities',
        url: '/articles/location-spotlight/hunting-fishing-and-recreational-shooting/148',
        clickTagCategory: { clickTagCategory },
        clickTagLabel: { clickTagLabel },
        height: `${imageHeight}`,
    },
    {
        title: 'All About Lotteries',
        image: lotteriesImage,
        mobileImage: lotteriesMobileImage,
        imageAlt: 'Upcoming Lotteries',
        url: '/lottery/available',
        clickTagCategory: { clickTagCategory },
        clickTagLabel: { clickTagLabel },
        height: `${imageHeight}`,
    },
];

const planSectionContent = () => {
    return (
        <section className="r1s-nav-plan-outer-wrap">
            <Stack space="gutter">
                <Stack space={'lg'}>
                    <Heading headingLevel={2} appearance="h4" hasUnderline="left">
                        Plan Your Trip
                    </Heading>
                </Stack>

                <div className="r1s-nav-plan-grid">
                    {planPromos.map((promo, idx) => {
                        return (
                            <div className="r1s-nav-plan-column" key={idx}>
                                <InventoryPromoLinkTall
                                    height={promo.height}
                                    imagePath={promo.image}
                                    mobileImagePath={promo.mobileImage}
                                    imageAlt={promo.imageAlt}
                                    heading={promo.title}
                                    href={promo.url}
                                    clickTagCategory={clickTagCategory}
                                    clickTagAction={`${promo.title} link`}
                                    clickTagLabel={clickTagLabel}
                                    headingPosition="headingCenter"
                                    hasReducedHeightForMobile
                                    maxHeadingLength={60}
                                />
                            </div>
                        );
                    })}
                </div>
            </Stack>
        </section>
    );
};

const planContainerMobile = () => {
    return (
        <section className="r1s-nav-plan-outer-wrap">
            <Stack space="gutter">
                <Stack space="lg">
                    <Heading
                        headingLevel={2}
                        appearance="h4"
                        hasUnderline="left"
                        className="r1s-nav-plan-mobile-title"
                    >
                        Plan for Your Trip
                    </Heading>
                </Stack>

                <div className="r1s-nav-plan-mobile-promos">
                    {planPromos.map((promo, idx) => {
                        return (
                            <Button
                                isUnstyled
                                shouldFitContainer
                                key={idx}
                                className="r1s-nav-plan-promo"
                                href={promo.url}
                                clickTagCategory={clickTagCategory}
                                clickTagAction={`${promo.title} link`}
                                clickTagLabel={clickTagLabel}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${promo.mobileImage})`,
                                    }}
                                    className="r1s-nav-plan-promo-image"
                                />

                                <Text
                                    fontWeight="bold"
                                    className="r1s-nav-plan-promo-title"
                                >
                                    {promo.title}
                                </Text>
                            </Button>
                        );
                    })}
                </div>
            </Stack>
        </section>
    );
};

export function PlanYourTripPromo() {
    const { width = 0 } = useWindowSize();

    return width <= 768 ? (
        <ContentBlock>{planContainerMobile()}</ContentBlock>
    ) : (
        <ContentBlock>{planSectionContent()}</ContentBlock>
    );
}
