/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

module.exports = {
    /* Populates the conte of a single article (type = article, location-spotlight, journal)
        /version/content/type/id
    */
    ARTICLE_VERSION: 'g_ba2',

    /* Populates the articles on the Article Landing page, 9 per page (limit = Max 9, and offset = 0, 9, 18, 27...)
        /version/content/all?offset=0&limit=9
    */
    ARTICLES_VERSION: 'g_ba2',

    /* Populates the featured article at the top of the Article Landing page
        /version/content/featured
    */
    FEATURED_VERSION: 'g_ba2',

    /* Populates the 10 articles on the Homepage
        /version/content/homepage
    */
    HOMEPAGE_FEATURED_VERSION: 'g_ba2'
};
