/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Checkbox, Icons } from 'sarsaparilla';
import * as mapActions from '../../../actions/mapActions';
import { inventory } from '../../../constants/mapValues';

export function HomepageFilter({ uiFilters, counts, filterRef }) {
    const dispatch = useDispatch();
    const [activateAllFilters, setActivateAllFilters] = useState(false);

    const onFilterToggle = (item) => {
        dispatch(mapActions.toggleFilter(item));
    };

    const onApplyFilters = () => {
        dispatch(mapActions.filterMapInventory());
    };

    const onInventoryFilterChange = (_, item) => {
        onFilterToggle(item);
        onApplyFilters();
    };

    const onActiveAllFiltersToggle = () => {
        if (!activateAllFilters) {
            dispatch(mapActions.addFilters());
        } else {
            dispatch(mapActions.removeFilters());
        }
        setActivateAllFilters(!activateAllFilters);
        onApplyFilters();
    };

    const onClearAllFiltersToggle = () => {
        setActivateAllFilters(false);
        dispatch(mapActions.removeFilters());
    };

    const renderInventoryCheckbox = (item, filterCounts) => {
        if (item && filterCounts) {
            const key = item.key;
            const val = item.value;
            const types = item.entity_type.split(',');

            const filterInv = Object.keys(filterCounts).filter((filter) =>
                types.includes(filter)
            );

            let count = 0;
            const invCount = filterCounts?.[filterInv[0]] || {};
            if (filterInv[0] === 'campground') {
                const useTypes = item.campsite_type_of_use.split(',');
                const dayTypeCount = invCount?.Day?.length || 0;
                const isDayUse = useTypes.includes('Day');
                count = isDayUse ? invCount?.Day?.length : parseInt(invCount?.size, 10) - parseInt(dayTypeCount, 10);
            } else {
                count = filterInv[0] ? invCount?.length : count;
            }
            const label = count ? `${val} (${count})` : val;
            const disableIcon = count ? '' : 'is-disabled';

            return (
                <div
                    className={`nav-filter-check-item hasIcon ${disableIcon}`}
                    htmlFor={key}
                    key={key}
                >
                    <Checkbox
                        onChange={(e) => onInventoryFilterChange(e, item)}
                        id={key}
                        label={label}
                        value={val}
                        isDisabled={count < 1}
                        isChecked={!!uiFilters[key] && count > 0}
                        gaTrackingId="143678716684"
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <div className="nav-map-v2-filters-container" ref={filterRef}>
            <div className="nav-map-v2-show-all-filters">
                <Checkbox
                    onChange={onActiveAllFiltersToggle}
                    id="show-all-checkbox"
                    label="Show All"
                    value="show-all-filters"
                    isChecked={activateAllFilters}
                    gaTrackingId="143678716684"
                />
            </div>

            <div className="nav-map-v2-inventory-filters-container">
                {Object.keys(inventory).map((item) =>
                    renderInventoryCheckbox(inventory[item], counts)
                )}
            </div>
            <div className="nav-map-v2-clear-all-filters">
                <Button
                    appearance="link"
                    className="nav-map-v2-clear-all-filters-button"
                    iconBeforeElement={<Icons.IconCloseCircle />}
                    aria-label="Clear Selections"
                    onClick={onClearAllFiltersToggle}
                >
                    {'Clear Selections'}
                </Button>
            </div>
        </div>
    );
}

HomepageFilter.propTypes = {
    uiFilters: PropTypes.object,
    counts: PropTypes.object,
    filterRef: PropTypes.object,
    
};

HomepageFilter.defaultProps = {
    uiFilters: {},
    counts: {},
};
