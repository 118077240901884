/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as PartnerIcons from '../Icons/Partner/PartnerIcons';

export const PARTNERS = {
    BEP: {
        icon: <PartnerIcons.BEP />,
        name: 'Bureau of Engraving and Printing (BEP)',
    },
    BLM: {
        icon: <PartnerIcons.BLM />,
        name: 'Bureau of Land Management (BLM)',
    },
    BOR: { icon: <PartnerIcons.DOI />, name: 'Bureau of Reclamation' },
    DOT: {
        icon: <PartnerIcons.DOT />,
        name: 'U.S. Department of Transportation (USDOT)',
    },
    NPS: { icon: <PartnerIcons.NPS />, name: 'National Park Service (NPS)' },
    NOAA: {
        icon: <PartnerIcons.NOAA />,
        name: 'National Oceanic and Atmospheric Administration (NOAA)',
    },
    Presidio: { icon: <PartnerIcons.PT />, name: 'Presidio Trust' },
    Smithsonian: { icon: <PartnerIcons.Smithsonian />, name: 'Smithsonian' },
    TRE: { icon: <PartnerIcons.TRE />, name: 'Department of The Treasury' },
    TVA: { icon: <PartnerIcons.TVA />, name: 'Tennessee Valley Authority' },
    USACE: {
        icon: <PartnerIcons.USACE />,
        name: 'U.S. Army Corps of Engineers (USACE)',
    },
    USFS: {
        icon: <PartnerIcons.ForestService />,
        name: 'U.S. Forest Service (USFS)',
    },
    FS: {
        icon: <PartnerIcons.ForestService />,
        name: 'U.S. Forest Service (USFS)',
    },
    USFWS: { icon: <PartnerIcons.FWS />, name: 'U.S. Fish & Wildlife (USFWS)' },
    FWS: { icon: <PartnerIcons.FWS />, name: 'U.S. Fish & Wildlife (USFWS)' },
} as const;

// cSpell:ignore NOAA, USACE, USDOT, USFS, USFWS
