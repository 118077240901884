/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Badge, Button, Icons, Tooltip } from 'sarsaparilla';
import moment from 'moment-timezone';
import { storeCampsiteClicked } from 'ui-camping/src/camping/utils/campground';
import { CAMPSITE_AVAILABLE } from 'ui-internal-camping/src/constants';
import {
    CAMPSITE_EARLY_ACCESS_AVAILABLE,
    ENTER_DATES,
} from 'ui-camping/src/shared/constants/availability';
import { ACCESSIBLE_CAMPSITE_TOOLTIP } from 'ui-camping/src/shared/constants/campsite';
import { createComponent, handleClosePopUp } from './common';

export default function campingMapPopup(
    {
        imageUrl,
        title,
        location,
        attributes,
        targetUri,
        featureId,
        entityId,
        availabilityStatus,
        isItinerary,
        onClickFunction,
        accessible,
        campsite_type,
        isInternal,
        startDate,
        endDate,
        isSiteView,
        siteAvailability,
        gridStartDate,
        gridEndDate,
        isAvailableGrid,
    },
    deselectFeature
) {
    const isMobile = window.innerWidth < 768;
    const isEnterDates = availabilityStatus === ENTER_DATES;
    let campgroundId = null;

    if (featureId) {
        [campgroundId] = featureId.split('_');
    }

    const isAccessible =
        typeof accessible === 'string' ? JSON.parse(accessible) : accessible;
    let imgUrl = imageUrl === 'null' ? null : imageUrl;

    if (isSiteView && !!siteAvailability && !imgUrl) {
        imgUrl = `${process.env.CDN}/img/global/unavailable/image-unavailable.jpg`;
    }

    const siteAttributes =
        attributes && typeof attributes === 'string'
            ? JSON.parse(attributes)
            : attributes;
    const targetUrl = targetUri === 'null' ? null : targetUri;

    const formatDate = (date) => {
        return moment(date).utc().format('MM/DD/YY');
    };

    const getDateLabel = (start, end) => {
        if (!start || !end) return '';
        return `Dates: ${formatDate(start)} - ${formatDate(end)}`;
    };

    const gridDatesText = getDateLabel(gridStartDate, gridEndDate);
    const datesText = getDateLabel(startDate, endDate);
    const dateTextContent = datesText || gridDatesText;

    const hasAvailability = siteAvailability === 'Available';
    const titleClassName = imgUrl ? '' : ' popup-title-no-image';
    const siteAvailabilityClassName = `map-popup-${hasAvailability ? '' : 'un'}availability-info`;
    const availability = (
        <div className={siteAvailabilityClassName}>{siteAvailability}</div>
    );

    const isAvailable =
        availabilityStatus === CAMPSITE_AVAILABLE ||
        availabilityStatus === CAMPSITE_EARLY_ACCESS_AVAILABLE ||
        isAvailableGrid ||
        (startDate && endDate) ||
        (gridStartDate && gridEndDate);

    const getAvailabilitySection = () => {
        return (
            <div>
                {!isAvailable && !isAvailableGrid && !gridDatesText && (
                    <li key="availability">Unavailable</li>
                )}
                {isAvailable && <li key="availability">Available</li>}
                {!!dateTextContent && <li key="availability-date">{dateTextContent}</li>}
            </div>
        );
    };

    const onClosePopup = () => {
        if (deselectFeature) deselectFeature();
        handleClosePopUp();
    };

    const popup = (
        <div className="map-popup">
            <div className="map-popup-message">
                <Button
                    className="close-pop-up-btn"
                    appearance="subtle"
                    size="sm"
                    shouldFitContainer={false}
                    onClick={onClosePopup}
                    iconBeforeElement={<Icons.IconClose />}
                    screenReaderTextBefore="Close the modal"
                    gaTrackingId="681762123001"
                />

                {imgUrl ? (
                    <div
                        aria-hidden="true"
                        className="map-popup-preview-image"
                        style={{ backgroundImage: `url("${imgUrl}")` }}
                    >
                        {isSiteView && !!siteAvailability && availability}
                    </div>
                ) : null}

                <div className={`map-popup-title${titleClassName}`}>{title}</div>

                {location ? <p className="map-popup-text">{location}</p> : null}

                {isAccessible || !!siteAttributes?.length ? (
                    <ul>
                        {isAccessible && (
                            <li className="accessible-icon-list">
                                <Tooltip content={ACCESSIBLE_CAMPSITE_TOOLTIP}>
                                    <span>
                                        <Icons.IconAccessible
                                            size="sm"
                                            className="accessible-icon"
                                        />
                                    </span>
                                </Tooltip>
                            </li>
                        )}
                        {isInternal && campsite_type ? <li>{campsite_type}</li> : null}
                        {siteAttributes
                            ? siteAttributes.map(({ key, value }) => {
                                  const hasAvailabilityAttribute = key === 'availability';
                                  if (hasAvailabilityAttribute) return null;
                                  return <li key={key}>{value}</li>;
                              })
                            : null}
                        {!isSiteView && !isItinerary && getAvailabilitySection()}
                    </ul>
                ) : null}

                {((!isItinerary && !isSiteView && targetUrl) || isAvailable) &&
                !isInternal ? (
                    <div className="map-popup-button-wrap">
                        <div className="map-popup-buttons">
                            {!isItinerary && !isSiteView && targetUrl && (
                                <div>
                                    <Button
                                        className={isEnterDates ? 'align-right' : ''}
                                        href={targetUrl}
                                        appearance="link"
                                        onClick={() =>
                                            storeCampsiteClicked(campgroundId, entityId)
                                        }
                                        rel="noopener noreferrer"
                                        size="md"
                                        gaTrackingId="317756999568"
                                    >
                                        View Details
                                    </Button>
                                    {featureId === '250860_583320' && (
                                        <Badge appearance="info">Portage required</Badge>
                                    )}
                                </div>
                            )}

                            {isAvailable && (
                                <div>
                                    <Button
                                        id={`book-now-button-${entityId}`}
                                        className={`list-map-book-now-button-tracker ${
                                            isMobile ? 'mobile' : 'desktop'
                                        }`}
                                        onClick={() =>
                                            onClickFunction({
                                                [entityId]: {
                                                    startDate: startDate || gridStartDate,
                                                    endDate: endDate || gridEndDate,
                                                },
                                            })
                                        }
                                        appearance="primary"
                                        size="xs"
                                        rel="noopener noreferrer"
                                        gaTrackingId="253459600897"
                                    >
                                        Add to Cart
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );

    return createComponent(popup);
}
