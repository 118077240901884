/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { MapHelper } from 'sarsaparilla';
import {
    POINT,
    services,
    recColors,
    facilityIcons,
    campingSources,
    permitSources,
    tripPlannerSources,
} from '../constants';
import { campingIconTypes } from '../helpers/utils';

const campsiteDynamicLabelSize = ['interpolate', ['linear'], ['zoom'], 16, 10, 20, 15];

const getCircleLayer = (props, service) => {
    const color = services[service].color;
    return {
        id: 'pointsLayer',
        paint: {
            'circle-color': color,
            'circle-radius': 7.5,
            'circle-stroke-color': color,
            'circle-stroke-opacity': 0.3,
            'circle-stroke-width': 3.5,
        },
        source: props.source,
        type: 'circle',
    };
};

const getSymbolLayer = (props, service) => {
    if (props.ignoreLayer) return null;
    const layer = {
        id: `${props.source}PointsLayer`,
        layout: {
            'icon-allow-overlap': true,
            'icon-image': props.icon || [
                'coalesce',
                ['get', 'symbol'],
                services[service].defaultMapboxIcon,
            ],
        },
        source: props.source,
        type: 'symbol',
    };

    if (props.paint) {
        layer.paint = {
            'text-halo-color': props.paint.color || 'rgba(255, 255, 255, 0.75)',
            'text-halo-width': props.paint.width || 2,
            'text-halo-blur': props.paint.blur || 1,
        };
    }

    if (props.layout?.offset) {
        layer.layout['icon-offset'] = props.layout.offset;
    }

    return layer;
};

const getFillLayer = (props) => {
    return {
        id: `${props.source}FillLayer`,
        paint: {
            'fill-color': props.color,
            'fill-opacity': props.opacity || 0.2,
            'fill-outline-color': props.outlineColor || props.color,
        },
        source: props.source,
        type: 'fill',
        ignoreSelection: props.ignoreSelection,
        firstLayer: props.firstLayer,
    };
};

const getFillLayerLicensing = (props) => {
    return {
        id: `${props.source}FillLayer`,
        paint: {
            'fill-color': props.color || '#FFFFFF',
            'fill-opacity': props.opacity || 0.2,
            'fill-outline-color': props.outlineColor || props.color,
        },
        source: props.source,
        type: 'fill',
        ignoreSelection: props.ignoreSelection,
        firstLayer: props.firstLayer,
    };
};

const getLineLayer = (props) => {
    const lineOpacity = ['case', ['has', 'opacity'], ['get', 'opacity'], 1];
    const layer = {
        id: props.id || `${props.source}LineLayer`,
        source: props.source,
        type: 'line',
        paint: {
            'line-color': props.color,
            'line-width': props.width,
            'line-opacity': lineOpacity,
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        ignoreSelection: props.ignoreSelection,
        firstLayer: props.firstLayer,
        isHiddenByDefault: props.isHiddenByDefault,
    };

    if (props.paint) layer.paint = { ...layer.paint, ...props.paint };

    return layer;
};

const getSkyLayer = () => {
    return {
        id: 'sky',
        type: 'sky',
        paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
        },
        ignoreSelection: true,
    };
};

const getCampsiteSymbolLayer = (props) => {
    const campingSiteIcon = [
        'match',
        ['get', 'campsite_type'],
        ...campingIconTypes.flat(),
        'rec-icon-tent',
    ];
    const amenitySiteIcon = ['get', 'icon'];
    const siteIcon = ['case', ['has', 'icon'], amenitySiteIcon, campingSiteIcon];
    const availabilitySuffix = [
        'match',
        ['get', 'suffix'],
        '_DISABLED',
        '-disabled',
        ['_ACTIVE', '_LIGHT_ACTIVE'],
        '-active',
        '',
    ];
    const isAccessible = ['==', ['get', 'accessible'], 'true'];

    const layerParams = {
        id: `${props.source}Layer`,
        source: props.source,
        type: 'symbol',
        minzoom: props.minZoom || 10,
        filter: ['!has', 'point_count'],
        paint: {
            'text-color': [
                'match',
                ['get', 'suffix'],
                ['_LIGHT', '_LIGHT_ACTIVE'],
                'white',
                '_DISABLED',
                'grey',
                'black',
            ],
            'text-opacity': {
                type: 'identity',
                property: 'opacity',
            },
            'text-halo-color': [
                'match',
                ['get', 'suffix'],
                ['_LIGHT', '_LIGHT_ACTIVE'],
                'black',
                '_DISABLED',
                'darkgrey',
                'white',
            ],
            'text-halo-width': [
                'match',
                ['get', 'suffix'],
                ['_ACTIVE', '_LIGHT_ACTIVE'],
                2,
                1,
            ],
        },
        layout: {
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-image': [
                'concat',
                siteIcon,
                ['case', isAccessible, '-accessible', ''],
                [
                    'case',
                    ['all', ['!', ['has', 'isValidDateRange']], ['has', 'category']],
                    '-disabled',
                    availabilitySuffix,
                ],
            ],
            'text-allow-overlap': false,
            'text-anchor': 'top',
            'text-field': [
                'case',
                ['==', ['get', 'campsite_reserve_type'], 'Non Site-Specific'],
                '',
                ['==', ['get', 'entityType'], 'amenity'],
                '',
                ['get', 'label'],
            ],
            'text-font': [
                'Open Sans Bold',
                'DIN Offc Pro Medium',
                'Arial Unicode MS Bold',
            ],
            'text-offset': [
                'case',
                isAccessible,
                ['literal', [0, 2]],
                ['literal', [0, 1.7]],
            ],
            'text-size': campsiteDynamicLabelSize,
            'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                props.minZoom || 16,
                1.05,
                20,
                1.2,
            ],
        },
    };

    return layerParams;
};

const getAmenitiesSymbolLayer = (props) => {
    return {
        id: `${props.source}Layer`,
        layout: {
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-image': '{icon}',
            'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                props.minZoom || 16,
                1,
                20,
                1.2,
            ],
        },
        minzoom: props.minZoom || 16,
        source: props.source,
        type: 'symbol',
    };
};

const getCampsitesChildrenSymbolLayer = (props) => {
    return {
        id: `${props.source}Layer`,
        get: ['!has', 'point_count'],
        minzoom: props.minZoom || 10,
        layout: {
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-image': [
                'concat',
                'rec-icon-tent-nss',
                ['match', ['get', 'suffix'], '_DISABLED', '-disabled', ''],
            ],
            'text-allow-overlap': false,
            'text-anchor': 'top',
            'text-field': [
                'case',
                ['==', ['get', 'campsite_reserve_type'], 'Non Site-Specific'],
                '',
                ['get', 'label'],
            ],
            'text-font': [
                'Open Sans Bold',
                'DIN Offc Pro Medium',
                'Arial Unicode MS Bold',
            ],
            'text-offset': [0, 1.7],
            'text-size': campsiteDynamicLabelSize,
            'icon-size': ['interpolate', ['linear'], ['zoom'], 16, 1.05, 20, 1.2],
        },
        paint: {
            'text-color': [
                'match',
                ['get', 'suffix'],
                '_LIGHT',
                'white',
                '_DISABLED',
                'grey',
                'black',
            ],
            'text-opacity': {
                type: 'identity',
                property: 'opacity',
            },
            'text-halo-color': [
                'match',
                ['get', 'suffix'],
                '_LIGHT',
                'black',
                '_DISABLED',
                'darkgrey',
                'white',
            ],
            'text-halo-width': 1,
        },
        source: props.source,
        type: 'symbol',
    };
};

const getInventorySymbolLayer = (props) => {
    const layer = {
        id: `${props.source}Layer`,
        filter: ['in', 'icon', ...facilityIcons],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': props.icon || ['concat', ['get', 'icon'], ['get', 'suffix']],
        },
        minzoom: props.minZoom,
        source: props.source,
        type: 'symbol',
    };

    if (props.setSortKey) {
        layer.layout['symbol-sort-key'] = ['to-number', ['get', 'priority']];
    }

    return layer;
};

const getCustomSymbolLayer = (props) => {
    const layer = {
        id: `${props.source}Layer`,
        layout: {
            'icon-allow-overlap': true,
            'icon-image': ['get', 'icon'],
        },
        source: props.source,
        minzoom: props.minZoom,
        type: 'symbol',
        isHiddenByDefault: props.isHiddenByDefault,
    };

    if (props.setSortKey) {
        layer.layout['symbol-sort-key'] = ['to-number', ['get', 'priority']];
    }

    return layer;
};

const getInventoryCircleLayer = (props) => {
    return {
        id: `${props.source}CircleLayer`,
        filter: ['in', 'icon', ...facilityIcons],
        maxzoom: props.maxZoom,
        paint: {
            'circle-color': {
                property: 'icon',
                stops: MapHelper.circleIconsColors,
                type: 'categorical',
            },
            'circle-radius': 6,
            'circle-stroke-color': '#fff',
            'circle-stroke-width': 1,
        },
        source: props.source,
        type: 'circle',
    };
};

const getCustomCircleLayer = (props) => {
    const layer = {
        id: props.id || `${props.source}CircleLayer`,
        paint: {
            'circle-color': props.fillColor || '#000000',
            'circle-radius': props.radius || 10,
            'circle-stroke-color': props.strokeColor || '#fff',
            'circle-stroke-width': 1,
        },
        source: props.source,
        type: 'circle',
        ignoreSelection: true,
        isHiddenByDefault: props.isHiddenByDefault,
    };

    if (props.filter) layer.filter = props.filter;
    if (props.minZoom) layer.minzoom = props.minZoom;
    if (props.maxZoom) layer.maxzoom = props.maxZoom;
    if (props.paint) layer.paint = { ...layer.paint, ...props.paint };

    return layer;
};

const getClusterCircleLayer = (props) => {
    return {
        id: `${props.source}ClusterLayer`,
        filter: ['has', 'point_count'],
        maxzoom: props.maxZoom || 17,
        paint: {
            'circle-color': recColors.clusterCircle,
            'circle-radius': ['step', ['get', 'point_count'], 10, 10, 15, 100, 20],
            'circle-stroke-color': recColors.strokeClusterCircle,
            'circle-stroke-width': 3.5,
        },
        source: props.source,
        type: 'circle',
        ignoreSelection: true,
    };
};

const getClusterLabelLayer = (props) => {
    return {
        id: `${props.source}ClusterLabelLayer`,
        source: props.source,
        type: 'symbol',
        filter: ['has', 'point_count'],
        maxzoom: props.maxZoom || 17,
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': [
                'Open Sans Bold',
                'DIN Offc Pro Medium',
                'Arial Unicode MS Bold',
            ],
            'text-size': 12,
        },
        paint: {
            'text-color': recColors.text,
        },
        ignoreSelection: true,
    };
};

const getCustomLabelLayer = (props) => {
    const layer = {
        id: props.id || `${props.source}CustomLabelLayer`,
        source: props.source,
        type: 'symbol',
        layout: {
            'text-font': [
                'Open Sans Bold',
                'DIN Offc Pro Medium',
                'Arial Unicode MS Bold',
            ],
            'text-size': 11,
        },
        paint: {
            'text-color': recColors.text,
        },
        ignoreSelection: true,
        isHiddenByDefault: props.isHiddenByDefault,
    };

    if (props.filter) layer.filter = props.filter;
    if (props.maxZoom) layer.maxzoom = props.maxZoom;
    if (props.minZoom) layer.minzoom = props.minZoom;
    if (props.layout) layer.layout = { ...props.layout, ...layer.layout };
    return layer;
};

const getLabelLayer = (props) => {
    if (props.ignoreLayer) return null;
    return {
        id: `${props.source}LabelLayer`,
        source: props.source,
        type: 'symbol',
        layout: {
            'text-field': ['get', 'name'],
            'text-padding': 10,
        },
        paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.75)',
            'text-halo-width': 2,
            'text-halo-blur': 1,
        },
    };
};

const getLicensingLabelLayer = (props) => {
    if (props.ignoreLayer) return null;
    return {
        id: `${props.source}LabelLayer`,
        source: props.source,
        type: 'symbol',
        layout: {
            'text-font': [
                'Open Sans Bold',
                'DIN Offc Pro Medium',
                'Arial Unicode MS Bold',
            ],
            'text-field': ['get', 'name'],
            'text-padding': 10,
        },
        paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.75)',
            'text-halo-width': 2,
            'text-halo-blur': 1,
        },
    };
};

const getGeofenceLabelLayer = (props) => {
    return {
        id: `${props.source}GeofenceLabelLayer`,
        source: props.source,
        filter: ['==', ['get', 'type'], 'Geofence'],
        type: 'symbol',
        layout: {
            'text-field': ['format', ['upcase', ['get', 'name']]],
            'text-padding': 10,
        },
        paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.75)',
            'text-halo-width': 2,
            'text-halo-blur': 1,
        },
    };
};

const getItineraryLineDirectionLayer = (props) => {
    return {
        id: `${props.source}LineDirectionLayer`,
        type: 'symbol',
        source: props.source,
        layout: {
            'symbol-placement': 'line',
            'text-field': '▶',
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
            'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 30, 22, 160],
            'text-keep-upright': false,
        },
        paint: {
            'text-color': ['get', 'stroke'],
            'text-halo-color': '#CAE5BA',
            'text-halo-width': 6,
        },
    };
};

const getCampingSymbolLayers = (props) => {
    if (!props?.source?.length || !Array.isArray(props?.source)) return [];
    return props.source.map((source) => {
        const { name } = source;
        if (name === campingSources.itinerary)
            return getItineraryLineDirectionLayer({ source: name });
        if (name === campingSources.campsites)
            return getCampsiteSymbolLayer({ source: name, minZoom: props.minZoom || 8 });
        if (name === campingSources.amenities)
            return getAmenitiesSymbolLayer({ source: name, minZoom: 17 });
        if (name === campingSources.children)
            return getCampsitesChildrenSymbolLayer({ source: name });
        if ([campingSources.inventory, campingSources.nearbyCampgrounds].includes(name))
            return getInventorySymbolLayer({ source: name, minZoom: 7 });
        return null;
    });
};

const getCampingCircleLayers = (props) => {
    if (!props?.source?.length || !Array.isArray(props?.source)) return [];
    return props.source.map((source) => {
        const { name } = source;
        if ([campingSources.inventory, campingSources.nearbyCampgrounds].includes(name))
            return getInventoryCircleLayer({ source: name, maxZoom: 7 });
        return null;
    });
};

const getClusterCircleLayers = (props) => {
    if (!props?.source?.length || !Array.isArray(props?.source)) return [];
    return props.source
        .filter((source) => source.isClustered)
        .map((source) =>
            getClusterCircleLayer({
                ...props,
                source: source.name,
            })
        );
};

const getClusterLabelLayers = (props) => {
    if (!props?.source?.length || !Array.isArray(props?.source)) return [];
    return props.source
        .filter((source) => source.isClustered)
        .map((source) =>
            getClusterLabelLayer({
                ...props,
                source: source.name,
            })
        );
};

const getItineraryLineLayer = (props) => {
    return {
        id: `${props.source}LineLayer`,
        type: 'line',
        source: props.source,
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': ['get', 'stroke'],
            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 12],
            'line-offset': ['case', ['has', 'is_return'], 4, 0],
        },
    };
};

const getItineraryEntranceLayer = (props) => {
    return {
        id: `${props.source}SymbolLayer`,
        type: 'symbol',
        source: props.source,
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'match',
                ['get', 'type'],
                'entrance',
                'entrance_pin',
                'exit',
                'exit_pin',
                'entrance_pin',
            ],
            'icon-anchor': 'bottom',
            'icon-size': ['interpolate', ['linear'], ['zoom'], 16, 1.0, 20, 1.2],
        },
    };
};

const zoomLineWidth = ['interpolate', ['linear'], ['zoom'], 5, 1, 15, 4];

export const getLayersPerService = (props) => {
    const layersByService = {
        pass: {
            dataType: POINT,
            layers: [getCircleLayer(props, 'pass')],
        },
        destination: {
            dataType: POINT,
            layers: [getCircleLayer(props, 'pass')],
        },
        venue: {
            dataType: POINT,
            layers: [getSymbolLayer({ ...props, icon: '' }, 'venue')],
        },
        treepermit: {
            dataType: POINT,
            layers: [getCircleLayer(props, 'treepermit')],
        },
        ticket: {
            dataType: POINT,
            layers: [getSymbolLayer(props, 'ticket')],
        },
        timedentry: {
            dataType: POINT,
            layers: [getSymbolLayer(props, 'timedentry')],
        },
        campingpoi: {
            dataType: POINT,
            layers: [getSymbolLayer(props, 'campingpoi')],
        },
        internalcampingfieldsales: {
            dataType: POINT,
            layers: [
                getCampsiteSymbolLayer(props),
                getClusterCircleLayer(props),
                getClusterLabelLayer(props),
            ],
            hasClusterLayer: true,
        },
        navigation: {
            dataType: POINT,
            layers: [
                getFillLayer({
                    source: props.maskSource,
                    color: 'white',
                    opacity: 0.4,
                    outlineColor: 'transparent',
                    ignoreSelection: true,
                }),
                getFillLayer({
                    source: props.boundarySource,
                    color: recColors.recAreas,
                    opacity: 0.2,
                    outlineColor: 'transparent',
                }),
                getLineLayer({
                    source: props.boundaryLineSource,
                    color: recColors.outline,
                    width: zoomLineWidth,
                    ignoreSelection: true,
                }),
                getFillLayer({
                    source: props.radiusSource,
                    color: recColors.radiusFill,
                    opacity: 0.2,
                    outlineColor: recColors.radiusFill,
                    ignoreSelection: true,
                }),
                getLineLayer({
                    source: props.radiusLineSource,
                    color: recColors.radiusOutline,
                    width: 2,
                    ignoreSelection: true,
                }),
                getInventorySymbolLayer({ ...props, minZoom: 9 }),
                getInventoryCircleLayer({ ...props, maxZoom: 9 }),
            ],
        },
        gateway: {
            dataType: POINT,
            layers: [
                getFillLayer({
                    source: props.boundarySource,
                    color: recColors.recAreas,
                    opacity: 0.2,
                    outlineColor: 'transparent',
                }),
                getLineLayer({
                    source: props.boundaryLineSource,
                    color: recColors.outline,
                    width: zoomLineWidth,
                    ignoreSelection: true,
                }),
                getLineLayer({
                    id: `${props.trailsSource}BorderLineLayer`,
                    source: props.trailsSource,
                    color: [
                        'case',
                        ['has', 'color'],
                        ['get', 'color'],
                        recColors.borderTrailLine,
                    ],
                    width: ['interpolate', ['linear'], ['zoom'], 5, 4, 15, 7],
                    isHiddenByDefault: true,
                    paint: {
                        'line-opacity': [
                            'case',
                            ['has', 'borderOpacity'],
                            ['get', 'borderOpacity'],
                            1,
                        ],
                        'line-color': recColors.borderTrailLine,
                    },
                }),
                getLineLayer({
                    source: props.trailsSource,
                    color: [
                        'case',
                        ['has', 'color'],
                        ['get', 'color'],
                        recColors.trailsLine,
                    ],
                    width: zoomLineWidth,
                    isHiddenByDefault: true,
                    paint: {
                        'line-opacity': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            1,
                            ['case', ['has', 'opacity'], ['get', 'opacity'], 1],
                        ],
                    },
                }),
                getInventorySymbolLayer({ ...props, minZoom: 9 }),
                getInventoryCircleLayer({ ...props, maxZoom: 9 }),
            ],
        },
        search: {
            dataType: POINT,
            layers: [
                getFillLayer({
                    source: props.maskSource,
                    color: 'white',
                    opacity: 0.4,
                    outlineColor: 'transparent',
                    ignoreSelection: true,
                    firstLayer: 'poi-outdoor-features-2',
                }),
                getFillLayer({
                    source: props.boundarySource,
                    color: recColors.recAreas,
                    opacity: 0.2,
                    outlineColor: 'transparent',
                    firstLayer: 'poi-outdoor-features-2',
                }),
                getLineLayer({
                    source: props.boundaryLineSource,
                    color: recColors.outline,
                    width: zoomLineWidth,
                    ignoreSelection: true,
                    firstLayer: 'poi-outdoor-features-2',
                }),
                getInventorySymbolLayer({ ...props, minZoom: 8, setSortKey: true }),
                getCustomSymbolLayer({
                    source: props.evSource,
                    minZoom: 8,
                    isHiddenByDefault: true,
                }),
                getCustomCircleLayer({
                    source: props.evSource,
                    isHiddenByDefault: true,
                    minZoom: 14,
                    paint: {
                        'circle-translate': [10, 10],
                    },
                }),
                getCustomLabelLayer({
                    source: props.evSource,
                    isHiddenByDefault: true,
                    minZoom: 14,
                    layout: {
                        'text-offset': [0.9, 0.9],
                        'text-field': ['coalesce', ['get', 'levelPorts'], ''],
                    },
                }),
                getCustomCircleLayer({
                    id: `${props.evSource}ClusterCircleLayer`,
                    source: props.evSource,
                    isHiddenByDefault: true,
                    filter: ['has', 'point_count'],
                    fillColor: recColors.evClusterFill,
                    strokeColor: recColors.evLevelsStrokeFill,
                    radius: ['step', ['get', 'point_count'], 10, 10, 15, 100, 20],
                }),
                getCustomLabelLayer({
                    id: `${props.evSource}ClusterLabelLayer`,
                    source: props.evSource,
                    filter: ['has', 'point_count'],
                    isHiddenByDefault: true,
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'icon-allow-overlap': true,
                    },
                }),
                getInventoryCircleLayer({ ...props, maxZoom: 8 }),
            ],
        },
        camping: {
            layers: [
                getFillLayer({
                    source: campingSources.nearbyRadius,
                    ignoreSelection: true,
                    color: recColors.radiusFill,
                    outlineColor: recColors.radiusFill,
                }),
                getLineLayer({
                    source: campingSources.nearbyRadius,
                    ignoreSelection: true,
                    color: recColors.radiusOutline,
                    width: 2,
                }),
                ...getCampingSymbolLayers(props),
                ...getCampingCircleLayers(props),
                ...getClusterCircleLayers(props),
                ...getClusterLabelLayers(props),
                getSkyLayer(),
            ].filter((l) => !!l),
        },
        campinginternal: {
            layers: [
                getCampsiteSymbolLayer({ ...props, minZoom: 16 }),
                getClusterCircleLayer({ ...props, maxZoom: 18 }),
                getClusterLabelLayer({ ...props, maxZoom: 18 }),
            ],
        },
        campingitinerary: {
            layers: [
                getItineraryLineLayer({ source: campingSources.itinerary }),
                getItineraryEntranceLayer({ source: campingSources.entrances }),
                ...getCampingSymbolLayers({ ...props, minZoom: 5 }),
                ...getClusterCircleLayers({ ...props, maxZoom: 18 }),
                ...getClusterLabelLayers({ ...props, maxZoom: 18 }),
            ].filter((l) => !!l),
        },
        campingsinglepoint: {
            layers: [getInventorySymbolLayer({ ...props, minZoom: 1 })],
        },
        permit: {
            layers: [
                getLineLayer({
                    source: permitSources.polygons,
                    color: '#1c1d1f',
                    width: 1.2,
                }),
                getFillLayer({
                    source: permitSources.polygons,
                    color: { property: 'color', type: 'identity' },
                    opacity: { property: 'fillOpacity', type: 'identity' },
                    outlineColor: '#2a2922',
                }),
                getLabelLayer({
                    source: permitSources.polygons,
                    ignoreLayer: props.ignoreLayer,
                }),
                getGeofenceLabelLayer({ source: permitSources.polygons }),
                getLineLayer({
                    source: permitSources.highlightedLines,
                    color: { property: 'color', type: 'identity' },
                    width: 4,
                }),
                getLineLayer({
                    source: permitSources.lines,
                    color: { property: 'color', type: 'identity' },
                    width: 8,
                }),
                getSymbolLayer({ source: permitSources.point }, 'permit'),
                getSymbolLayer(
                    {
                        source: permitSources.points,
                        icon: '{symbol}',
                        layout: { offset: [0, -50 / 2] },
                    },
                    'permit'
                ),
            ].filter((l) => !!l),
        },
        tripplanner: {
            layers: [
                getLineLayer({
                    source: tripPlannerSources.lineRoute,
                    color: '#a00a08',
                    ignoreSelection: true,
                    width: 2,
                }),
                getFillLayer({
                    source: tripPlannerSources.polygonRoute,
                    color: '#348cdb',
                    opacity: 0.3,
                    ignoreSelection: true,
                }),
                getInventorySymbolLayer({
                    source: tripPlannerSources.recreation,
                    minZoom: 8,
                    icon: '{icon}',
                }),
                getInventoryCircleLayer({
                    source: tripPlannerSources.recreation,
                    maxZoom: 8,
                }),
                getSymbolLayer({ source: tripPlannerSources.tripItems, icon: '{icon}' }),
                getSymbolLayer({
                    source: tripPlannerSources.tripDestinations,
                    icon: '{icon}',
                }),
            ],
        },
        licensingColorado: {
            layers: [
                getLicensingLabelLayer({
                    source: 'polygons',
                    ignoreLayer: props.ignoreLayer,
                }),
                getFillLayerLicensing({
                    source: 'polygons',
                    opacity: 0.3,
                    ignoreSelection: true,
                    outlineColor: '#000000',
                }),
                getLineLayer({
                    source: 'polygons',
                    color: '#55712f',
                    width: 2,
                    ignoreSelection: true,
                }),
            ],
        },
        licensingColoradoSingle: {
            layers: [
                getLicensingLabelLayer({
                    source: 'polygons',
                    ignoreLayer: props.ignoreLayer,
                }),
                getFillLayerLicensing({
                    source: 'polygons',
                    opacity: 0.2,
                    color: recColors.recAreas,
                    ignoreSelection: true,
                    outlineColor: 'transparent',
                }),

                getLineLayer({
                    source: 'polygons',
                    color: recColors.outline,
                    width: 2,
                    ignoreSelection: true,
                }),
            ],
        },
    };
    return layersByService[props.service];
};

// cSpell:ignore campinginternal, campingitinerary, campingpoi, campingsinglepoint, internalcampingfieldsales, maxzoom, darkgrey, minzoom, Offc, timedentry, treepermit, tripplanner
