/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleFeaturedImage.jsx
 * Created by Destin Frasier on 03/27/18.
 */
import React from 'react';
import PropTypes from 'prop-types';

export default class ArticleFeaturedImage extends React.PureComponent {
    static propTypes = {
        imageSource: PropTypes.string,
        imageAlt: PropTypes.string,
        inventoryUrl: PropTypes.string,
        mediaLocation: PropTypes.string,
        videoSource: PropTypes.string,
    };

    render() {
        const { imageSource, imageAlt, inventoryUrl, mediaLocation, videoSource } = this.props;

        let mediaItem = null;

        if (imageSource !== null) {
            mediaItem = (
                <img
                    src={imageSource}
                    alt={imageAlt} />
            );
        }
        else if (videoSource !== null) {
            // Todo - Mike Bray: Make this better
            mediaItem = (
                <iframe
                    width="100%"
                    title={this.props.imageAlt}
                    src={videoSource} />
            );
        }
        else {
            return null;
        }

        return (
            <figure>
                <a
                    href={inventoryUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    {mediaItem}
                </a>
                <figcaption>
                    {mediaLocation}
                </figcaption>
            </figure>
        );
    }
}
