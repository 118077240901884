/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useRef } from 'react';
import { DividerPromo, ButtonGroup, Button } from 'sarsaparilla';

export default function UGCClosedPromoContainer() {
    const targetRef = useRef();
    return (
        <DividerPromo
            heading="Read stories from our recent contest to find inspiration for your next adventure!"
            backgroundImagePath={`${process.env.CDN}/img/homepage/divider-promo-section/ugc-promo-banner-closed-bg.jpg`}
            height="md"
            contentContainerRight
            contentCopyAlignRight
            showOverlayTopOnMobile
            contentCopyAlignCenterOnMobile
            overlayImagePath={`${process.env.CDN}/img/homepage/divider-promo-section/share_your_story_large`}
            overlayImageAlt="Share Your Story Contest Closed"
            className="homepage-ugc-promo"
            refForIntersection={targetRef}
        >
            <ButtonGroup isFullWidthOnMobile={false}>
                <Button
                    appearance="tertiary-white"
                    href="/shareyourstory/story-gallery"
                    aria-label="Learn more about the share your story contest"
                    gaReportName={"UGC Closed Promo Container"}
                    gaReportCreative={"ugc closed promo"}
                    gaReportPosition={"HomePage"}
                    shouldReportToGA
                >
                    Learn More
                </Button>
            </ButtonGroup>
        </DividerPromo>
    );
}
