/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export const CAMPSITE_AVAILABLE = 'Available';
export const CAMPSITE_NOT_AVAILABLE = 'Not Available';
export const CAMPSITE_NOT_RESERVABLE = 'Not Reservable';
export const CAMPSITE_NOT_RESERVABLE_MNG = 'Not Reservable Management';
export const CAMPSITE_RESERVED = 'Reserved';
export const CAMPSITE_CURRENT = 'Current';
export const CAMPSITE_OPEN = 'Open';
export const CAMPSITE_UNAVAILABLE = 'Unavailable';
export const CAMPSITE_CLOSED = 'Closed';
export const CAMPSITE_LOTTERY = 'Lottery';
export const CAMPSITE_LOTTERY_OPEN = 'Lottery - Open';
export const CAMPSITE_EARLY_ACCESS = 'Early Access';
export const CAMPSITE_NOT_YET_RELEASED = 'NYR';
export const CAMPSITE_RESERVABLE_TABLE_VALUE = 'Reservable';
// Early access available is required for dates outside of the booking window
export const CAMPSITE_EARLY_ACCESS_AVAILABLE = 'Early Access - Available';

export const ENTER_DATES = 'Enter Dates';
export const PARTIALLY_AVAILABLE = 'Partially Available';
export const FIRST_COME_FIRST_SERVED = 'First-come, First-served';
export const NOT_YET_RELEASED = 'Not Yet Released';

// this is not a valid availability status in the DB; used for rendering FE checkout-only cell
export const CAMPSITE_RESERVED_AVAILABLE = 'Reserved Available';
export const CAMPSITE_CHECKOUT_ONLY = 'Checkout';
export const CAMPSITE_WALK_UP = 'Walk-up';

export const POTENTIALLY_RESERVABLE_STATUSES = [
    CAMPSITE_AVAILABLE,
    CAMPSITE_EARLY_ACCESS_AVAILABLE,
    CAMPSITE_RESERVED_AVAILABLE,
    CAMPSITE_OPEN,
    CAMPSITE_NOT_RESERVABLE, // reservable by internal non-CSR and non-host users
    CAMPSITE_NOT_RESERVABLE_MNG, // reservable by internal non-CSR and non-host users
    CAMPSITE_CHECKOUT_ONLY,
];

export const CAMPSITE_LIST_AVAILABILITY_SORT_PRIORITY = {
    [CAMPSITE_AVAILABLE]: 4,
    [CAMPSITE_EARLY_ACCESS_AVAILABLE]: 4,
    [PARTIALLY_AVAILABLE]: 3,
    [FIRST_COME_FIRST_SERVED]: 2,
    [CAMPSITE_UNAVAILABLE]: 1,
};

// Defines the weight of each availability status for sorting purposes
export const AVAILABILITY_STATUS_SORT_PRIORITY = {
    [CAMPSITE_AVAILABLE]: 4,
    [CAMPSITE_EARLY_ACCESS_AVAILABLE]: 4,
    [CAMPSITE_RESERVED_AVAILABLE]: 4,
    [CAMPSITE_NOT_RESERVABLE]: 3,
    [CAMPSITE_NOT_RESERVABLE_MNG]: 3,
    [CAMPSITE_OPEN]: 2,
    [CAMPSITE_RESERVED]: 1,
    [CAMPSITE_NOT_AVAILABLE]: 0,
    [CAMPSITE_UNAVAILABLE]: 0,
    [CAMPSITE_CLOSED]: 0,
};

// list of potentially reservable statuses
export const RESERVABLE_AVAILABILITY_STATUSES = [
    CAMPSITE_AVAILABLE,
    CAMPSITE_EARLY_ACCESS_AVAILABLE,
    CAMPSITE_RESERVED_AVAILABLE,
    CAMPSITE_OPEN,
    CAMPSITE_NOT_RESERVABLE,
];

export const CAMPSITE_LOOP_NAME_LENGTH = 8;

export const SITE = 'site';
export const LOOP = 'loop';

export const HEADERS = {
    col1: {
        name: 'Site',
        id: 'site',
    },
    col2: {
        name: 'Loop',
        id: 'loop',
    },
};

export const RETRY_DELAY = 4000; // 4 sec
export const MAX_RETRIES = 3; // maximum 3 resubmissions in case of status: 429
export const ERR_RATE_LIMIT_EXCEEDED =
    'retry: We are currently experiencing heavy traffic on our site. Please try again.';

export const RETRY_MODAL_MESSAGES = [
    'We’re experiencing heavy traffic, but we’re still trying to secure your spot.',
    'To increase your chance of getting through, do not refresh or attempt multiple bookings from different browsers or tabs.',
];
