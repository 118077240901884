/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// Define a unique identifier per facility type
export const services = {
    pass: {
        name: 'pass',
        color: '#1F3B94',
        pdfTitle: 'PASS MAP',
    },
    destination: {
        name: 'destination',
        color: '#1F3B94',
        pdfTitle: 'DESTINATION MAP',
    },
    venue: {
        name: 'venue',
        color: '#731F44',
        defaultMapboxIcon: 'rec-icon-venue-other',
        pdfTitle: 'VENUE MAP',
    },
    treepermit: {
        name: 'treepermit',
        color: '#c3512c',
        pdfTitle: 'TREE PERMIT MAP',
    },
    ticket: {
        name: 'ticket',
        color: '#93348c',
        defaultMapboxIcon: 'rec_icon_ticket',
        pdfTitle: 'TICKET MAP',
    },
    timedentry: {
        name: 'timedentry',
        color: '#93348c',
        defaultMapboxIcon: 'rec_icon_ticket',
        pdfTitle: 'TIME ENTRY MAP',
    },
    campingpoi: {
        name: 'campingpoi',
        color: '#5abf95',
        defaultMapboxIcon: 'poi_pin',
        pdfTitle: 'CAMPING MAP',
    },
    internalcampingfieldsales: {
        name: 'internalcampingfieldsales',
        color: '#4a77b4',
    },
    camping: {
        name: 'camping',
        color: '#4a77b4',
        pdfTitle: 'CAMPING MAP',
    },
    permit: {
        name: 'permit',
        defaultMapboxIcon: 'permit_pin',
        pdfTitle: 'PERMIT MAP',
    },
    navigation: {
        name: 'navigation',
        pdfTitle: 'HOMEPAGE MAP',
    },
    search: {
        name: 'search',
        pdfTitle: 'SEARCH MAP',
    },
    gateway: {
        name: 'gateway',
        pdfTitle: 'GATEWAY MAP',
    },
    tripplanner: {
        name: 'tripplanner',
        pdfTitle: 'TRIP PLANNER MAP',
    },
};

export const facilityIcons = [
    'camping_pin',
    'camping_pin_disabled',
    'camping_pin_active',
    'dayUse_pin',
    'dayUse_pin_disabled',
    'permit_pin',
    'tree_permit_pin',
    'poi_pin',
    'recArea_pin',
    'pass_pin',
    'ticketFacility_pin',
    'tourPin',
    'venue_wedding_pin',
    'venue_small_gathering_pin',
    'venue_shooting_pin',
    'venue_other_pin',
    'venue_large_reception_pin',
    'venue_hunting_pin',
    'venue_golfing_pin',
    'venue_athletic_activity_pin',
];

export const facilityTypes = {
    campground: {
        symbol: 'camping_pin',
    },
    campsite: {
        symbol: 'camping_pin',
    },
    gateway: {
        symbol: 'gateway_pin',
    },
    'permit office': {
        symbol: 'permit_pin',
    },
    'picnic area': {
        symbol: 'dayUse_pin',
    },
    'recreation area': {
        symbol: 'recArea_pin',
    },
    restroom: {
        symbol: 'restroom_pin',
    },
    trailhead: {
        symbol: 'trailhead_pin',
    },
    ticket: {
        symbol: 'tour_pin',
    },
    tour: {
        symbol: 'tour_pin',
    },
    'water source': {
        symbol: 'drinking_water_pin',
    },
    venue: {
        symbol: 'venue_other_pin',
    },
    default: {
        symbol: 'poi_pin',
    },
};

// Geojson data types
export const POINT = 'point';

// Rec.gov colors
export const recColors = {
    clusterCircle: '#295a0b',
    strokeClusterCircle: '#97c273',
    text: '#ffffff',
    outline: '#2F4A0B',
    recAreas: '#466C04',
    radiusOutline: '#E7AB09',
    radiusFill: '#FFC72E',
    trailsLine: '#008480',
    selectedTrailLine: '#0F6460',
    hoveredTrailLine: '#00A398',
    borderTrailLine: '#E0F7F6',
    evClusterFill: '#2E5E4D',
    evLevelsStrokeFill: '#5ABF95',
};

// Camping map
export const MAX_NEARBY_RADIUS = 161; // 100 miles > 161 km
export const campingSources = {
    campsites: 'campsites',
    amenities: 'amenities',
    children: 'children',
    inventory: 'inventory',
    nearbyCampgrounds: 'nearbyCampgrounds',
    nearbyRadius: 'nearbyRadius',
    itinerary: 'itinerary',
    entrances: 'entrances',
};

export const tripPlannerSources = {
    lineRoute: 'lineRoute',
    polygonRoute: 'polygonRoute',
    recreation: 'recreation',
    tripDestinations: 'tripDestinations',
    tripItems: 'tripItems',
};

export const MAP_LAYER_CONTROL_CONTENT = [
    {
        icons: ['poi_pin'],
        label: 'Points of Interest',
        id: 'poi',
    },
    {
        icons: ['recArea_pin'],
        label: 'Recreation Areas',
        id: 'recreation-areas',
    },
    {
        icons: ['dayUse_pin', 'dayUse_pin_disabled'],
        label: 'Day Use',
        id: 'day-use',
    },
    {
        icons: ['camping_pin', 'camping_pin_disabled'],
        label: 'Campgrounds',
        id: 'campground',
    },
    {
        icons: ['permit_pin'],
        label: 'Permits',
        id: 'permits',
    },
    {
        icons: ['tree_permit_pin'],
        label: 'Tree Permits',
        id: 'tree-permits',
    },
    {
        icons: ['pass_pin', 'pass_pin_disabled'],
        label: 'Activity Passes',
        id: 'activity-passes',
    },
    {
        icons: ['ticketFacility_pin', 'tourPin'],
        label: 'Tours',
        id: 'tours',
    },
];

export const permitSources = {
    point: 'point',
    points: 'points',
    lines: 'lines',
    polygons: 'polygons',
    highlightedLines: 'highlightedLines',
};

export const zoomStops = {
    1: 200,
    2: 200,
    3: 200,
    4: 200,
    5: 190,
    6: 180,
    7: 70,
    8: 40,
    9: 20,
    10: 10,
    11: 7,
    12: 3,
    13: 1,
};

// EV levels
export const evLevels = {
    'level-01': {
        name: 'Level 1',
        label: 'Level 1 Stations',
        level: 'level-01',
        shouldDisplay: true,
    },
    'level-02': {
        name: 'Level 2',
        label: 'Level 2 Stations',
        level: 'level-02',
        shouldDisplay: true,
    },
    'level-03': {
        name: 'Level 3',
        label: 'DCFast Stations',
        level: 'level-03',
        shouldDisplay: true,
    },
    'other-level': { label: 'Other', level: 'other-level', shouldDisplay: true },
};

export const evAdapters = {
    chademo: { label: 'CHADeMO', adapter: 'chademo', shouldDisplay: true },
    j1772: { label: 'J1772', adapter: 'j1772', shouldDisplay: true },
    j1772combo: { label: 'j1772-combo', adapter: 'j1772combo', shouldDisplay: true },
    'tesla-adapter': { label: 'Tesla', adapter: 'tesla-adapter', shouldDisplay: true },
    'other-adapter': { label: 'Other', adapter: 'other-adapter', shouldDisplay: true },
};

export const evNetworks = {
    'chargepoint-network': {
        label: 'ChargePoint',
        network: 'chargepoint-network',
        shouldDisplay: true,
    },
    'tesla-network': { label: 'Tesla', network: 'tesla-network', shouldDisplay: true },
    'blink-network': { label: 'Blink', network: 'blink-network', shouldDisplay: true },
    'electrify-america': {
        label: 'Electrify America',
        network: 'electrify-america',
        shouldDisplay: true,
    },
    'ev-connect': { label: 'EV Connect', network: 'ev-connect', shouldDisplay: true },
    volta: { label: 'Volta', network: 'volta', shouldDisplay: true },
    'evgo-network': { label: 'Evgo', network: 'evgo-network', shouldDisplay: true },
    'other-network': { label: 'Other', network: 'other-network', shouldDisplay: true },
};

// Layer visibility
export const VISIBLE = 'visible';
export const NONE = 'none';
export const VISIBILITY = 'visibility';

// cSpell:ignore treepermit, timedentry, campingpoi, internalcampingfieldsales, tripplanner
