/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// inventory
export const GET_CAMPGROUND = 'GET_CAMPGROUND';
export const GET_CAMPSITE = 'GET_CAMPSITE';

export const CAMPSITE_ITEM = 'CAMPSITE_ITEM';

// cart
export const GET_CART = 'GET_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const ADD_ITEM = 'ADD_ITEM';
export const CART_ERROR = 'CART_ERROR';
export const ADD_ITEM_ERROR = 'ADD_ITEM_ERROR';
export const REMOVE_ALL_ITEMS = 'REMOVE_ALL_ITEMS';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const EXTEND_EXPIRATION_ERROR = 'EXTEND_EXPIRATION_ERROR';

// order details
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';

export const UPDATE_CHECKIN_DATE = 'UPDATE_CHECKIN_DATE';
export const UPDATE_CHECKOUT_DATE = 'UPDATE_CHECKOUT_DATE';

export const ORDER_CHECKOUT_STEPS = [
    'Order Detail',
    'Shopping Cart',
    'Payment',
    'Confirmation',
];

// checkout
export const GET_CHECKOUT = 'GET_CHECKOUT';

// reservation
export const ADD_RESERVATION = 'ADD_RESERVATION';
export const ADD_RESERVATION_ERROR = 'ADD_RESERVATION_ERROR';
export const FINALIZE_CHECKOUT = 'FINALIZE_CHECKOUT';
export const FINALIZE_NOFEE = 'FINALIZE_NOFEE';

export const FETCH_CHECKOUT_ERROR = 'FETCH_CHECKOUT_ERROR';
export const FINALIZE_CHECKOUT_ERROR = 'FINALIZE_CHECKOUT_ERROR';
export const INITIATE_ALREADY_PAID = 'INITIATE_ALREADY_PAID';
export const RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED';
export const REMOVE_RESERVATION_ERROR = 'REMOVE_RESERVATION_ERROR';

// order details
export const SET_FLAGS_ORDER_DETAILS = 'SET_FLAGS_ORDER_DETAILS';

// Launch Darkly Flags
export const ALLOW_MAKE_RESERVATIONS = 'allow-make-reservations';

// inventory types
export const INVENTORY_CAMPING = 'CAMPING';
export const INVENTORY_PERMIT = 'PERMIT';
export const INVENTORY_VEHICLE_PERMIT = 'VEHICLE_PERMIT';
export const INVENTORY_TICKET = 'TICKET';
export const INVENTORY_TIMED_ENTRY = 'TIMED_ENTRY';
export const INVENTORY_LOTTERY_TICKET = 'LOTTERY_TICKET';
export const INVENTORY_LOTTERY_PERMIT = 'LOTTERY_PERMIT';
export const INVENTORY_PASS = 'PASS';
export const INVENTORY_PARKPASS = 'PARKPASS';
export const INVENTORY_ACTIVITYPASS = 'ACTIVITYPASS';
export const INVENTORY_VENUE_RESERVATIONS = 'VENUE_RESERVATIONS';
export const INVENTORY_VENUE_APPLICATION = 'VENUE_APPLICATION';
export const INVENTORY_TREE_PERMIT = 'TREE_PERMIT';
export const INVENTORY_RENTAL = 'RENTAL';
export const INVENTORY_LOTTERY_CAMPING = 'LOTTERY_CAMPING';
export const INVENTORY_QUEUE_LOTTERY = 'QUEUE_LOTTERY';
export const INVENTORY_DONATION = 'DONATION';
export const INVENTORY_GIFT_CARD = 'GIFT_CARD';

// session constants
export const MAX_SESSION_HOURS = 2;

// Nav Bar Actions
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// pages
export const CART_PAGE = 'CART';
export const CHECKOUT_PAGE = 'CHECKOUT';
export const SELECT_PAYMENT = 'SELECT_PAYMENT';

// payment types
export const PAYMENT_TYPE_CARD = 'CARD';
export const PAYMENT_TYPE_MOBILE = 'MOBILE'; // currently PRS only understands apple pay using mobile payment type

// cSpell:ignore ACTIVITYPASS, CHECKIN, NOFEE, PARKPASS
