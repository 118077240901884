/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import feesjsLocation from './feesjs-location.json';

export * from '../../shared-components/constants';

export const { GENERATED_FEES_SCRIPT_URL, GENERATED_FEES_SCRIPT_FILENAME } =
    feesjsLocation;

// permit categories enum
export const PERMIT_CATEGORY_RIVER = 'PERMIT_CATEGORY_RIVER';
export const PERMIT_CATEGORY_HIKING = 'PERMIT_CATEGORY_HIKING';
export const PERMIT_CATEGORY_CAMPING = 'PERMIT_CATEGORY_CAMPING';
export const PERMIT_CATEGORY_CLIMBING = 'PERMIT_CATEGORY_CLIMBING';
export const PERMIT_CATEGORY_WILDLIFE = 'PERMIT_CATEGORY_WILDLIFE';
export const PERMIT_CATEGORY_ROADENTRY = 'PERMIT_CATEGORY_ROADENTRY';
export const PERMIT_CATEGORY_EVENT = 'PERMIT_CATEGORY_EVENT';
export const PERMIT_CATEGORY_BOAT = 'PERMIT_CATEGORY_BOAT';
export const PERMIT_CATEGORY_SNOWMOBILE = 'PERMIT_CATEGORY_SNOWMOBILE';
export const PERMIT_CATEGORY_WILDERNESS = 'PERMIT_CATEGORY_WILDERNESS';

// division status enum
export const DIVISION_STATUS = {
    CLOSED: 0,
    OPEN: 1,
};

// detailed availability
export const AVAILABILITY_UNSELECTED = 'AVAILABILITY_UNSELECTED';
export const AVAILABILITY_START_MULTIDAY = 'AVAILABILITY_START_MULTIDAY';
export const AVAILABILITY_CONTINUE_MULTIDAY = 'AVAILABILITY_CONTINUE_MULTIDAY';
export const AVAILABILITY_END_MULTIDAY = 'AVAILABILITY_END_MULTIDAY';
export const AVAILABILITY_SINGLE_DAY = 'AVAILABILITY_SINGLE_DAY';

export const GROUP_AVAILABILITY_DAILY_KEY_NAME = 'ConstantQuotaUsageDaily';
export const GROUP_AVAILABILITY_KEY_NAME = 'ConstantQuotaUsageByStartDate';
export const GROUP_AVAILABILITY_WEEKLY_KEY_NAME = 'ConstantWeeklyQuotaUsageByStartDate';
export const MEMBER_AVAILABILITY_KEY_NAME = 'QuotaUsageByMember';
export const MEMBER_AVAILABILITY_DAILY_KEY_NAME = 'QuotaUsageByMemberDaily';
export const AGGREGATE_AVAILABILITY_KEY_NAME = 'AggregateQuota';
export const WATERCRAFT_AVAILABILITY_KEY_NAME = 'QuotaUsageByWatercraft';
export const MAX_STOCK_KEY_NAME = 'MaxStock';
export const MAX_PETS_KEY_NAME = 'MaxPets';
export const MAX_VEHICLES_KEY_NAME = 'MaxVehicles';
export const MAX_PARTY_SIZE_KEY_NAME = 'MaxPartySize';
export const MAX_GROUP_SIZE_KEY_NAME = 'MaxGroupSize';
export const MIN_GROUP_SIZE_KEY_NAME = 'MinGroupSize';
export const REDUCIBLE_GROUP_SIZE_KEY_NAME = 'ReducibleGroupSize';
export const MAX_GROUP_SIZE_COMMERCIAL_KEY_NAME = 'MaxGroupSizeCommercial';
export const GUIDES_COUNT_MAX_GROUP_SIZE_KEY_NAME = 'GuidesCountTowardsGroupSize';
export const ONLINE_PRINTING_KEY_NAME = 'OnlinePrinting';
export const ONLINE_PRINTING_ALT_KEY_NAME = 'ExternalOnlinePrinting';

export const FILTER_NUMERICAL = 'numerical';
export const FILTER_WATERCRAFT = 'watercraft';

export const DAY_BLOCKED_DATE_IN_THE_PAST = 'DAY_BLOCKED_DATE_IN_THE_PAST';
export const DAY_BLOCKED_OUTSIDE_SEASON = 'DAY_BLOCKED_OUTSIDE_SEASON';
export const DAY_BLOCKED_OUTSIDE_LOTTERY_SEASON = 'DAY_BLOCKED_OUTSIDE_LOTTERY_SEASON';
export const DAY_BLOCKED_BY_LOTTERY = 'DAY_BLOCKED_BY_LOTTERY';
export const DAY_BLOCKED_MISSING_QUOTA = 'DAY_BLOCKED_MISSING_QUOTA';
export const DAY_BLOCKED_NO_QUOTA = 'DAY_BLOCKED_NO_QUOTA';
export const DAY_UNBLOCKED = 'DAY_UNBLOCKED';

// fee types
export const ADULT_FEE = 1;
export const JUNIOR_FEE = 2;
export const SENIOR_FEE = 3;

export const COMMERCIAL_GUIDES = 100; // Pass Type only for fee

// disallow overnight stock rule
export const DISALLOW_OVERNIGHT_STOCK = 'DisallowOvernightStock';
export const DISABLE_STOCK_LIST = 'DisableStockList';
export const DISABLE_PET_LIST = 'DisablePetList';
export const DISABLE_GROUP_LIST = 'DisableGroupList';

// Granular component control rules
export const WATERCRAFT_FOR_DIVISION = 'WatercraftForDivision';
