/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { HelmetWrapper, SingleColumnContainer, Heading } from 'sarsaparilla';

export default class DonationTACUSFS extends React.Component {
    render() {
        return (
            <SingleColumnContainer role="main">
                <HelmetWrapper title="U.S. Forest Service Terms & Conditions for Donations" />

                <section className="rules-reservation-policies-wrap mb-8">
                    <Heading headingLevel={1} appearance="h1">
                        U.S. Forest Service Terms &amp; Conditions for Donations
                    </Heading>

                    <p>
                        By making a donation to the U.S. Forest Service, you are agreeing
                        to the following terms and conditions:
                        <ol>
                            <li>
                                Three percent of your donation will be used to cover a
                                transaction fee for {process.env.SITE_NAME}’s online services.
                            </li>
                            <li>
                                One-time donations are limited to a maximum of $50. If
                                your desired one-time donation amount exceeds $50, please
                                contact the{' '}
                                <a href="https://www.fs.usda.gov/visit/forests-and-grasslands">
                                    U.S. Forest Service office
                                </a>{' '}
                                of your choice.
                            </li>
                            <li>Donations and associated fees are not refundable.</li>
                            <li>
                                For more information regarding donations to the U.S.
                                Forest Service, visit:{' '}
                                <a href="https://www.fs.usda.gov/working-with-us/donations">
                                    https://www.fs.usda.gov/working-with-us/donations
                                </a>
                            </li>
                        </ol>
                    </p>
                </section>
            </SingleColumnContainer>
        );
    }
}
