/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { LEGACY_RangeSlider } from 'sarsaparilla';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { isEqual, groupBy } from 'lodash';
import { setupClusteredSource } from './utils';
import { MAP_LAYER_CONTROL_CONTENT } from '../constants';

export const cursorToDefault = (map) => {
    /* eslint-disable no-param-reassign */
    map.current.getCanvas().style.cursor = '';
};

export const cursorToPointer = (map) => {
    /* eslint-disable no-param-reassign */
    map.current.getCanvas().style.cursor = 'pointer';
};

export const fitMapBounds = (map, features, zoom) => {
    const invalidFeatures =
        !features?.features?.length ||
        (features?.features?.length === 1 && !features?.features?.[0]);

    if (invalidFeatures || !map?.current) return;
    const coords = turf.coordAll(features);
    const bounds = new mapboxgl.LngLatBounds();
    coords.forEach((c) => bounds.extend(c));

    const boundParams = {
        duration: 0,
        padding: 100,
    };

    if (zoom) boundParams.zoom = zoom;
    map.current.fitBounds(bounds, boundParams);
};

export const setMapSource = (props) => {
    if (!props.map.current) return;
    const mapSource = props.map.current.getSource(props.sourceName);
    if (!mapSource) return;
    mapSource.setData(props.data);
};

export const updateMapPosition = (props) => {
    const { center, map, maxZoom, zoom } = props;
    const jumpProps = { center };
    if (maxZoom && zoom && map.current.getZoom() < maxZoom) {
        jumpProps.zoom = zoom;
    }
    map?.current?.jumpTo(jumpProps);
};

export const setMapCanvasAttributes = (map) => {
    if (!map?.current?._canvas) return;
    map.current._canvas.setAttribute('role', 'application');
};

export const updateIconToDisplay = ({
    selectedId,
    geojsonData,
    isValidDateRange,
    isSatelliteView,
    skipAvailabilityStyling,
    selectedCampsites = [],
    isSiteView,
    siteAvailability,
    gridAvailability = {},
}) => {
    if (!geojsonData?.features?.length) return geojsonData;

    const updatedCampsites = geojsonData?.features?.map((feature) => {
        const {
            featureId,
            asset_id,
            isAvailable,
            id: campsiteId,
        } = feature?.properties || {};

        const isIdMatched =
            (selectedId && selectedId === featureId) ||
            featureId === `${asset_id}_${selectedId}`;

        const isIdMatchedGrid = selectedCampsites.includes(campsiteId);
        feature.properties.isSelectedOnGrid = isIdMatchedGrid;

        const featureOnlyId = featureId.includes('_')
            ? featureId.split('_')[1]
            : featureId;

        const getAvailabilityGrid = () => {
            if (featureId in gridAvailability) return gridAvailability[featureId];
            if (featureOnlyId in gridAvailability) return gridAvailability[featureOnlyId];
            return null;
        };

        const isAvailableGrid = getAvailabilityGrid();
        const isNotAvailable =
            (isValidDateRange && !isAvailable) ||
            (isAvailableGrid !== null && !isAvailableGrid && !isValidDateRange);

        feature.properties.isGridAndPickerAvailable = !isNotAvailable;

        let suffix = '';
        if (!isIdMatchedGrid && isNotAvailable && !skipAvailabilityStyling && !isSiteView)
            suffix = '_DISABLED';
        else if (!isIdMatched && isSatelliteView) suffix = '_LIGHT';
        else if (isIdMatched || isIdMatchedGrid)
            suffix = `${isSatelliteView ? '_LIGHT' : ''}_ACTIVE`;

        feature.properties.suffix = suffix;
        if (!isValidDateRange) feature.properties.isValidDateRange = isValidDateRange;
        else delete feature.properties.isValidDateRange;

        feature.properties.isSiteView = isSiteView;
        if (isSiteView && isIdMatched)
            feature.properties.siteAvailability = siteAvailability;
        return feature;
    });

    return turf.featureCollection(updatedCampsites);
};

export const updateFacilityIcons = (featureId, geojson) => {
    /* eslint-disable no-param-reassign */
    if (!featureId) {
        const cleanData = geojson?.features?.map((feature) => {
            feature.properties.suffix = '';
            return feature;
        });
        return turf.featureCollection(cleanData);
    }

    const updatedData = geojson?.features?.map((feature) => {
        const standardId = featureId?.split('_').pop();
        let hasMatched = feature.properties.featureId?.split('_').pop() === standardId;
        const isDisabled = feature?.properties?.icon?.includes('disabled');
        if (!hasMatched)
            hasMatched = feature.properties.entityId?.split('_').pop() === standardId;
        feature.properties.suffix = hasMatched && !isDisabled ? '_active' : '';
        return feature;
    });
    /* eslint-enable no-param-reassign */
    return turf.featureCollection(updatedData);
};

export const addMapSources = (map, sources) => {
    const sourceParams = Object.keys(sources);

    if (map.current && sourceParams.length) {
        sourceParams.forEach((source) => {
            if (sources[source].sourceName) {
                map.current.addSource(
                    sources[source].sourceName,
                    setupClusteredSource({
                        cluster: sources[source].isClustered,
                        clusterMaxZoom: sources[source].clusterMaxZoom,
                    })
                );
            }
        });
    }
};

export const addMapLayers = (map, layerSetup) => {
    if (!map.current) return;
    const layers = layerSetup.current.layers;

    layers?.forEach((layer) => {
        if (layer && !Array.isArray(layer)) map.current.addLayer(layer);
    });
};

export const handleClosePopUp = () => {
    const popup = document.getElementsByClassName('mapboxgl-popup');
    if (popup.length) {
        const [element] = popup;
        element.parentNode.removeChild(element);
    }
};

export const createComponent = (content) => {
    const container = document.createElement('div');
    const root = createRoot(container);
    root.render(content);
    return container;
};

export const trackIsMapExtended = (controls, setIsMapExtended) => {
    if ((!controls, !setIsMapExtended)) return;
    controls.fullscreen?._updateOptions({
        onShrinkedScreen: () => {
            setIsMapExtended(false);
        },
        onExpandedScreen: () => {
            setIsMapExtended(true);
        },
    });
};

export function downloadMapImageFile(props = {}) {
    if (!props.data) return;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(props.data);
    link.download = props.fileName || 'map.pdf';
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

downloadMapImageFile.propTypes = {
    data: PropTypes.object,
    fileName: PropTypes.string,
};

export function updateMapImage(props) {
    const { map, mapImageRef, setMapImage } = props;
    if (!map.current) return;
    const imageData = map.current.getCanvas().toDataURL();
    if (!imageData) return;
    if (!isEqual(mapImageRef.current, imageData)) {
        mapImageRef.current = imageData;
        setMapImage(imageData);
    }
}

updateMapImage.propTypes = {
    map: PropTypes.object,
    mapImageRef: PropTypes.object,
    setMapImage: PropTypes.func,
};

export const createRangeSlider = (nearbyRadius, setNearbyRadius, params = {}) => {
    const onRangeSliderChange = (val) => {
        const kmVal = val * 1.61;
        if (nearbyRadius !== kmVal) {
            setNearbyRadius(kmVal);
            /* eslint-disable no-param-reassign */
            if (!params.isSliderDragged.current) params.isSliderDragged.current = true;
            /* eslint-enable no-param-reassign */
        }
    };

    return (
        <LEGACY_RangeSlider
            label={params.label || 'Distance'}
            minValue={params.minValue || 0}
            maxValue={params.maxValue || 100}
            value={params.value}
            defaultValue={params.defaultValue || 10}
            onChange={(val) => onRangeSliderChange(val)}
            valueUnit="mi"
        />
    );
};

export const getLayerIconsPerId = () => {
    const iconsToDisplay = MAP_LAYER_CONTROL_CONTENT.map((item) => {
        return { ...item, shouldDisplay: true };
    });
    const iconsPerId = groupBy(iconsToDisplay, (item) => item.id);

    Object.keys(iconsPerId).forEach((id) => {
        iconsPerId[id] = iconsPerId[id][0];
    });
    return iconsPerId;
};

export const getMapBoundingBox = (map) => {
    const mapBounds = map.getBounds();
    let minLat = mapBounds._sw.lat;
    let minLng = mapBounds._sw.lng;
    let maxLat = mapBounds._ne.lat;
    let maxLng = mapBounds._ne.lng;

    if (minLat < -90) minLat = -90;
    if (minLat > 90) minLat = 90;

    if (maxLat < -90) maxLat = -90;
    if (maxLat > 90) maxLat = 90;

    if (minLng < -180) minLng = -180;
    if (minLng > 180) minLng = 180;

    if (maxLng < -180) maxLng = -180;
    if (maxLng > 180) maxLng = 180;

    const boundingBox = { minLat, minLng, maxLat, maxLng };
    return boundingBox;
};

// cSpell:ignore shrinked
