/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { QueryRouter, LaunchDarklyProvider } from 'sarsaparilla';

import App from './App';
import { store } from '../src/redux/store';
import './index.scss';

const root = createRoot(document.getElementById('recApp'));

root.render(
    <Provider store={store}>
        <QueryRouter shouldRestoreScroll>
            <CompatRouter>
                <LaunchDarklyProvider>
                    <App />
                </LaunchDarklyProvider>
            </CompatRouter>
        </QueryRouter>
    </Provider>
);
