/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import moment from 'moment-timezone';
import { isEmpty, filter, get, isNil, flatten, size as loSize, remove } from 'lodash';
import {
    formatMoney as formatMoneySarsa,
    RequiredValidation,
    ScrollToHelper,
    SecurityHelper,
} from 'sarsaparilla';
import axios from 'axios';
import * as types from '../constants';
import * as vActions from '../actions/validation';
import * as SharedConstants from '../constants/shared-constants';
import { getHuntingDivisions } from '../../../ui-hunting-permit/src/utils';

// Remember to Create tests if you add to this file

// pass this constant into the readRule() methods as the divisionId
// to find specifically the rules that don't have a divisionId
export const RULE_NOT_SPECIFIC_TO_DIVISION = '';
export const NEW_PERMITS_RULE_ALL_DIVISIONS = 'ALL_DIVISIONS';

export const SEASONALITY_PRESEASON = 0;
export const SEASONALITY_HIGHUSESEASON = 1;
export const SEASONALITY_POSTSEASON = 2;

export const convertCentsToCartValue = (centAmount) => {
    return centAmount * 1000; // cent to thousandths of a cent
};

export const convertCartValueToCents = (cartAmount) => {
    return cartAmount / 1000; // thousandths of a cent to cent
};

export const dateDiffs = (entryDate, exitDate, noCountEnd) => {
    if (entryDate && exitDate) {
        let start = entryDate;
        let end = exitDate;
        if (!moment.isMoment(end)) {
            end = moment(end);
        }
        if (!moment.isMoment(start)) {
            start = moment(start);
        }
        // adding a day for counting exit date
        if (noCountEnd) {
            return end.diff(start, 'days');
        }
        return end.diff(start, 'days') + 1;
    }
    return 0;
};

export const daysInRange = (entryDate, exitDate) => {
    const days = dateDiffs(entryDate, exitDate);
    const dates = [];

    for (let i = 0; i < days; i++) {
        const day = moment(entryDate).add(i, 'days');
        dates.push(day);
    }

    return dates;
};

export const nightsInRange = (entryDate, exitDate) => {
    const days = dateDiffs(entryDate, exitDate);
    const nights = days - 1;
    const dates = [];

    for (let i = 0; i < nights; i++) {
        const night = moment(entryDate).add(i, 'days');
        dates.push(night);
    }

    return dates;
};

export const getLaunchWindowOptions = (permit) => {
    const windows = [];

    if (permit.components.launch_time) {
        const close = moment(permit.launch_window_close, 'HH:mm:ss');
        let current = moment(permit.launch_window_open, 'HH:mm:ss');

        let vernacularFormat = 'hh:mm A';
        if (permit.launch_window_step % 60 !== 0) {
            // We aren't an even multiple of seconds
            vernacularFormat = 'h:mm:ss A';
        }

        while (current.isBefore(close) || current.isSame(close)) {
            const name = `${current.format(vernacularFormat)} ${permit.time_zone}`;
            windows.push({ id: current.format('HH:mm:ss'), name });
            current = current.add(permit.launch_window_step, 'seconds');
        }
    }

    return windows;
};

// find all days in the datemap that fall between startDate and endDate
// returns the days in sorted order
// inclusivityFlags - https://momentjs.com/docs/#/query/is-between/
// [] is inclusive, () is exclusive; in math this is called interval notation
export const getDaysBetween = (dateMap, startDate, endDate, inclusivityFlags = '[]') => {
    const matchingKeys = [];

    const startString = moment(startDate).format(types.AVAILABILITY_KEY_MAP_FORMAT);
    const endString = moment(endDate).format(types.AVAILABILITY_KEY_MAP_FORMAT);

    switch (inclusivityFlags) {
        case '()':
            Object.keys(dateMap).forEach((date) => {
                if (date > startString && date < endString) {
                    matchingKeys.push(date);
                }
            });
            break;

        case '(]':
            Object.keys(dateMap).forEach((date) => {
                if (date > startString && date <= endString) {
                    matchingKeys.push(date);
                }
            });
            break;

        case '[)':
            Object.keys(dateMap).forEach((date) => {
                if (date >= startString && date < endString) {
                    matchingKeys.push(date);
                }
            });
            break;

        case '[]':
        default:
            Object.keys(dateMap).forEach((date) => {
                if (date >= startString && date <= endString) {
                    matchingKeys.push(date);
                }
            });
            break;
    }
    matchingKeys.sort();
    return matchingKeys;
};

// get all rules with given name regardless of any other fields
export const readRules = (ruleName, rules = []) => {
    return isEmpty(rules) ? [] : rules.filter((rule) => rule.name === ruleName);
};

export const readRule = (ruleName, rules, divisionId = '') => {
    // check if divisionId, read matching rule.
    if (!isEmpty(rules) && divisionId) {
        const foundRules = rules.filter((rule) => {
            return rule.division_id === divisionId && rule.name === ruleName;
        });

        if (foundRules.length) {
            // hopefully there aren't more than one rule for a permit/division/name
            return foundRules[0];
        }
    }

    // otherwise, filter through all the rules and see if there's a match with RULE_NOT_SPECIFIC_TO_DIVISION or NEW_PERMITS_RULE_ALL_DIVISIONS
    if (!isEmpty(rules) && Array.isArray(rules)) {
        const foundRules = rules.filter((rule) => {
            const sameDivision = rule.division_id === divisionId;
            const notSpecificToDivision =
                rule.divisionId === RULE_NOT_SPECIFIC_TO_DIVISION ||
                rule.division_id === RULE_NOT_SPECIFIC_TO_DIVISION;
            const allDivisions =
                rule.divisionId === NEW_PERMITS_RULE_ALL_DIVISIONS ||
                rule.division_id === NEW_PERMITS_RULE_ALL_DIVISIONS;
            const sameName = rule.name === ruleName;
            return (sameDivision || notSpecificToDivision || allDivisions) && sameName;
        });
        if (foundRules.length > 1) {
            // grab the one that MATCHES THE DIVISION.
            const matchedRule = foundRules.find(
                (rule) => rule.division_id === divisionId
            );
            if (matchedRule) {
                return matchedRule;
            }
        }
        if (foundRules.length) {
            return foundRules[0];
        }
    }

    // and if a rule still isn't found, return a missing rule object
    return {
        division_id: '',
        end_date: moment.invalid(),
        operation: '',
        permit_id: '',
        start_date: moment.invalid(),
        target: '',
        type: -1,
        value: '',
        isMissing: true,
        metadata: '',
    };
};

export const hasRule = (ruleName, rules, divisionId) => {
    return !readRule(ruleName, rules, divisionId).isMissing;
};

// convert tells this method whether to convert the time or simply force the timezone
// convert = true:  05:00:00 UTC -> 00:00:00 -5:00
// convert = false: 05:00:00 UTC -> 05:00:00 -5:00
export const parseRuleAsMoment = (rule, timeZone, convert) => {
    if (rule.moment) {
        return rule.moment.tz(timeZone, !convert);
    }

    if (rule.value) {
        return moment.unix(rule.value).tz(timeZone, !convert);
    }

    // return an invalid date
    return moment('');
};

export const parseUnixAsMoment = (unixVal, timeZone, convert) => {
    return moment.unix(unixVal).tz(timeZone, !convert);
};

export const parseRuleAsDateString = (rule) => {
    if (rule.isMissing) {
        return undefined;
    }
    if (rule.string) {
        return rule.string;
    }
    return moment.unix(rule.value).utc().format(types.AVAILABILITY_KEY_MAP_FORMAT);
};

export const readRuleAsMoment = (ruleName, rules, timeZone, divisionId, convert) => {
    const rule = readRule(ruleName, rules, divisionId);
    return parseRuleAsMoment(rule, timeZone, convert);
};

export const readHighUseSeasonDatesAsMoment = (rules, timeZone, convert) => {
    // startRules/endRules are all rules with the name regardless of division id
    const highUseStart = 'HighUseSeasonStart';
    const highUseEnd = 'HighUseSeasonEnd';
    const startRules = readRules(highUseStart, rules);
    const endRules = readRules(highUseEnd, rules);

    // if no rules found return undefined
    if (!startRules.length || !endRules.length) return {};

    // if there's only one or we can find one w/o a division_id
    let startRule =
        startRules.length === 1
            ? startRules[0]
            : startRules.find((rule) => rule.division_id === '');
    let endRule =
        endRules.length === 1
            ? endRules[0]
            : endRules.find((rule) => rule.division_id === '');
    if (startRule && endRule) {
        return {
            start: parseRuleAsMoment(startRule, timeZone, convert),
            end: parseRuleAsMoment(endRule, timeZone, convert),
        };
    }

    // some don't have a general rule where there's no division_id so gotta calculate the bounds
    if (!startRule) {
        const isMomentBefore = (momentDayOne, momentDayTwo) =>
            momentDayOne.isBefore(momentDayTwo) ? momentDayOne : momentDayTwo;
        startRule = startRules.reduce((ruleOne, ruleTwo) => {
            const momentOne = parseRuleAsMoment(ruleOne, timeZone, convert);
            const momentTwo = parseRuleAsMoment(ruleTwo, timeZone, convert);
            return isMomentBefore(momentOne, momentTwo);
        });
    }

    if (!endRule) {
        const isMomentAfter = (momentDayOne, momentDayTwo) =>
            momentDayOne.isAfter(momentDayTwo) ? momentDayOne : momentDayTwo;
        endRule = endRules.reduce((ruleOne, ruleTwo) => {
            const momentOne = parseRuleAsMoment(ruleOne, timeZone, convert);
            const momentTwo = parseRuleAsMoment(ruleTwo, timeZone, convert);
            return isMomentAfter(momentOne, momentTwo);
        });
    }

    return { start: startRule, end: endRule };
};

export const readRuleAsDateString = (ruleName, rules, divisionId) => {
    const rule = readRule(ruleName, rules, divisionId);
    return parseRuleAsDateString(rule);
};

export const readRuleValue = (ruleName, rules, divisionId) => {
    return readRule(ruleName, rules, divisionId).value;
};

export const readRuleMetadata = (ruleName, rules, divisionId) => {
    return readRule(ruleName, rules, divisionId).metadata;
};

export const readRuleAsBoolean = (ruleName, rules, divisionId) => {
    return !!readRuleValue(ruleName, rules, divisionId);
};

export const readRuleOperation = (ruleName, rules, divisionId) => {
    return readRule(ruleName, rules, divisionId).operation;
};

export const readRuleTarget = (ruleName, rules, divisionId) => {
    return readRule(ruleName, rules, divisionId).target;
};

export function getActiveLotteries(permit) {
    return permit.lotteries && filter(permit.lotteries, 'is_active');
}

export function getActiveLotteryId(permit) {
    if (permit) {
        const activeLotteries = getActiveLotteries(permit);

        if (activeLotteries && !isEmpty(activeLotteries)) {
            return activeLotteries[0].id;
        }
    }
    return undefined;
}

export const sumItems = (items) => {
    if (isEmpty(items)) {
        return 0;
    }
    let total = 0;
    items.forEach((i) => {
        total += parseInt(i.amount, 10);
    });
    return total;
};

// Requires that all keys and values be unique.
function transformIntoBidirectionalMap(map) {
    const ret = new Map();
    map.forEach((k, v) => {
        ret.set(k, v);
        ret.set(v, k);
    });
    return ret;
}

export const equipmentTypeToStr = new Map();
// water
equipmentTypeToStr.set(1, 'Kayak');
equipmentTypeToStr.set(2, 'Double Kayak');
equipmentTypeToStr.set(3, 'Hard Shell Kayak');
equipmentTypeToStr.set(4, 'Inflatable Kayak');
equipmentTypeToStr.set(5, 'Tandem Kayak');
equipmentTypeToStr.set(6, 'Cataraft');
equipmentTypeToStr.set(7, 'Canoe');
equipmentTypeToStr.set(8, 'Solo Canoe');
equipmentTypeToStr.set(9, 'Drift');
equipmentTypeToStr.set(10, 'Raft');
equipmentTypeToStr.set(11, 'Sweep');
equipmentTypeToStr.set(12, 'SUP');
equipmentTypeToStr.set(13, 'Kicker Motor');
equipmentTypeToStr.set(14, 'Paddle Board');
equipmentTypeToStr.set(15, 'Tube');
equipmentTypeToStr.set(16, 'Dory');
equipmentTypeToStr.set(17, 'Standing Paddle Board');
equipmentTypeToStr.set(18, 'Motorboat');
equipmentTypeToStr.set(19, 'Sand Stakes');
equipmentTypeToStr.set(20, 'Motorized');
equipmentTypeToStr.set(21, 'Non-Motorized');
equipmentTypeToStr.set(22, 'Packraft');

// snow
equipmentTypeToStr.set(1001, 'Snowmobile');

// ground
equipmentTypeToStr.set(2001, 'Vehicle');

equipmentTypeToStr.set(999999, 'Other');
equipmentTypeToStr.set(9999999, 'Unknown');

export const equipmentTypes = transformIntoBidirectionalMap(equipmentTypeToStr);
export const isWatercraft = (type) =>
    (type >= 1 && type < 1000) || type === 999999 || type === 9999999;
export const isSnowmobile = (type) => type === 1001;
export const isVehicle = (type) => type === 2001;

export const animalTypeToString = new Map();
animalTypeToString.set(1, 'Goat');
animalTypeToString.set(2, 'Horse / Pack Mule');
animalTypeToString.set(3, 'Llama');
animalTypeToString.set(4, 'Dog');
animalTypeToString.set(5, 'Horse/Mule Riding Stock');
animalTypeToString.set(6, 'Dogs or other pets');
animalTypeToString.set(7, 'Burro');
animalTypeToString.set(8, 'Mule');
animalTypeToString.set(9, 'Riding Horse');
animalTypeToString.set(10, 'Stock Horse');
animalTypeToString.set(999999, 'Other');

// bi-directional map.  Requires that all keys and values be unique.
export const issuanceTypes = new Map();
issuanceTypes.set(types.PRIVATE, 0);
issuanceTypes.set(types.COMMERCIAL, 1);
export const issuanceTypesMap = transformIntoBidirectionalMap(issuanceTypes);

export const issuanceTypesLookup = transformIntoBidirectionalMap(issuanceTypes);

export const issuanceQuotaTypes = new Map();
issuanceQuotaTypes.set(types.ADVANCED, 0);
issuanceQuotaTypes.set(types.WALKUP, 1);

export const issuanceQuotaTypesLookup = transformIntoBidirectionalMap(issuanceQuotaTypes);

// bi-directional map.  Requires that all keys and values be unique.
let issuanceStats = new Map();
issuanceStats.set(SharedConstants.PERMIT_PRELIMINARY, 0);
issuanceStats.set(SharedConstants.PERMIT_IN_PROGRESS, 1);
issuanceStats.set(SharedConstants.LOTTERY_IN_PROGRESS, 2);
issuanceStats.set(SharedConstants.LOTTERY_APPLIED, 3);
issuanceStats.set(SharedConstants.LOTTERY_AWARDED, 4);
issuanceStats.set(SharedConstants.LOTTERY_AWARDED_IN_PROGRESS, 5);
issuanceStats.set(SharedConstants.LOTTERY_LOST, 6);
issuanceStats.set(SharedConstants.LOTTERY_DECLINED, 7);
issuanceStats.set(SharedConstants.LOTTERY_ACCEPTED, 8);
issuanceStats.set(SharedConstants.LOTTERY_NO_RESPONSE, 9);
issuanceStats.set(SharedConstants.PERMIT_COMPLETE, 10);
issuanceStats.set(SharedConstants.PERMIT_ISSUED, 11);
issuanceStats.set(SharedConstants.CANCELLED_NO_RELEASE, 12);
issuanceStats.set(SharedConstants.PERMIT_CANCELLED_BY_USER, 13);
issuanceStats.set(SharedConstants.LOTTERY_CANCELLED, 14);
issuanceStats.set(SharedConstants.PERMIT_REFUNDED, 15);
issuanceStats.set(SharedConstants.LOTTERY_VOIDED, 16);
issuanceStats.set(SharedConstants.LOTTERY_DISQUALIFIED, 17);
issuanceStats.set(SharedConstants.PERMIT_NO_SHOW, 18);
issuanceStats.set(SharedConstants.LOTTERY_PENDING, 19);
issuanceStats.set(SharedConstants.PERMIT_IN_REVIEW, 20);
issuanceStats.set(SharedConstants.PERMIT_REVIEWED, 21);

export const issuanceReadable = new Map();
issuanceReadable.set(
    SharedConstants.PERMIT_PRELIMINARY,
    types.PERMIT_PRELIMINARY_READABLE
);
issuanceReadable.set(
    SharedConstants.PERMIT_IN_PROGRESS,
    types.PERMIT_IN_PROGRESS_READABLE
);
issuanceReadable.set(SharedConstants.PERMIT_COMPLETE, types.PERMIT_COMPLETE_READABLE);
issuanceReadable.set(SharedConstants.PERMIT_ISSUED, types.PERMIT_ISSUED_READABLE);
issuanceReadable.set(
    SharedConstants.CANCELLED_NO_RELEASE,
    types.PERMIT_CANCELLED_READABLE
);
issuanceReadable.set(
    SharedConstants.PERMIT_CANCELLED_BY_USER,
    types.PERMIT_CANCELLED_READABLE
);
issuanceReadable.set(SharedConstants.PERMIT_REFUNDED, types.PERMIT_REFUNDED_READABLE);
issuanceReadable.set(SharedConstants.PERMIT_NO_SHOW, types.PERMIT_NO_SHOW_READABLE);
issuanceReadable.set(SharedConstants.PERMIT_IN_REVIEW, types.PERMIT_IN_REVIEW_READABLE);
issuanceReadable.set(SharedConstants.PERMIT_REVIEWED, types.PERMIT_REVIEWED_READABLE);

issuanceReadable.set(types.LOTTERY_IN_PROGRESS, types.LOTTERY_IN_PROGRESS_READABLE);
issuanceReadable.set(types.LOTTERY_APPLIED, types.LOTTERY_APPLIED_READABLE);
issuanceReadable.set(types.LOTTERY_AWARDED, types.LOTTERY_AWARDED_READABLE);
issuanceReadable.set(
    types.LOTTERY_AWARDED_IN_PROGRESS,
    types.LOTTERY_AWARDED_IN_PROGRESS_READABLE
);
issuanceReadable.set(types.LOTTERY_LOST, types.LOTTERY_LOST_READABLE);
issuanceReadable.set(types.LOTTERY_DECLINED, types.LOTTERY_DECLINED_READABLE);
issuanceReadable.set(types.LOTTERY_ACCEPTED, types.LOTTERY_ACCEPTED_READABLE);
issuanceReadable.set(types.LOTTERY_NO_RESPONSE, types.LOTTERY_NO_RESPONSE_READABLE);
issuanceReadable.set(types.LOTTERY_CANCELLED, types.LOTTERY_CANCELLED_READABLE);
issuanceReadable.set(types.LOTTERY_VOIDED, types.LOTTERY_VOIDED_READABLE);
issuanceReadable.set(types.LOTTERY_DISQUALIFIED, types.LOTTERY_DISQUALIFIED_READABLE);
issuanceReadable.set(types.LOTTERY_PENDING, types.LOTTERY_PENDING_READABLE);

export const issuanceTooltip = new Map();
issuanceTooltip.set(types.LOTTERY_ACCEPTED, types.LOTTERY_ACCEPTED_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_APPLIED, types.LOTTERY_APPLIED_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_AWARDED, types.LOTTERY_AWARDED_TOOLTIP);
issuanceTooltip.set(
    types.LOTTERY_AWARDED_IN_PROGRESS,
    types.LOTTERY_AWARDED_IN_PROGRESS_TOOLTIP
);
issuanceTooltip.set(types.LOTTERY_CANCELLED, types.LOTTERY_CANCELLED_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_DECLINED, types.LOTTERY_DECLINED_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_DISQUALIFIED, types.LOTTERY_DISQUALIFIED_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_IN_PROGRESS, types.LOTTERY_IN_PROGRESS_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_LOST, types.LOTTERY_LOST_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_NO_RESPONSE, types.LOTTERY_NO_RESPONSE_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_PENDING, types.LOTTERY_PENDING_TOOLTIP);
issuanceTooltip.set(types.LOTTERY_VOIDED, types.LOTTERY_VOIDED_TOOLTIP);
issuanceTooltip.set(SharedConstants.CANCELLED_NO_RELEASE, types.PERMIT_CANCELLED_TOOLTIP);
issuanceTooltip.set(
    SharedConstants.PERMIT_CANCELLED_BY_USER,
    types.PERMIT_CANCELLED_TOOLTIP
);
issuanceTooltip.set(SharedConstants.PERMIT_COMPLETE, types.PERMIT_COMPLETE_TOOLTIP);
issuanceTooltip.set(SharedConstants.PERMIT_IN_PROGRESS, types.PERMIT_IN_PROGRESS_TOOLTIP);
issuanceTooltip.set(SharedConstants.PERMIT_ISSUED, types.PERMIT_ISSUED_TOOLTIP);
issuanceTooltip.set(SharedConstants.PERMIT_NO_SHOW, types.PERMIT_NO_SHOW_TOOLTIP);
issuanceTooltip.set(SharedConstants.PERMIT_PRELIMINARY, types.PERMIT_PRELIMINARY_TOOLTIP);
issuanceTooltip.set(SharedConstants.PERMIT_REFUNDED, types.PERMIT_REFUNDED_TOOLTIP);
issuanceTooltip.set(SharedConstants.PERMIT_IN_REVIEW, types.PERMIT_IN_REVIEW_TOOLTIP);
issuanceTooltip.set(SharedConstants.PERMIT_REVIEWED, types.PERMIT_REVIEWED_TOOLTIP);

issuanceStats = transformIntoBidirectionalMap(issuanceStats);
export const issuanceStatuses = issuanceStats;

export const getUnitCardinality = (value, singularNoun, pluralNoun) => {
    return value === 1 ? singularNoun : pluralNoun;
};

// helper methods for generating the correct singular/plural nouns
export const getDayCardinality = (value) => {
    const singularNoun = 'day';
    const pluralNoun = 'days';
    return getUnitCardinality(value, singularNoun, pluralNoun);
};

export const getPersonCardinality = (value) => {
    const singularNoun = 'person';
    const pluralNoun = 'people';
    return getUnitCardinality(value, singularNoun, pluralNoun);
};

export const ruleToErrMap = new Map();
ruleToErrMap.set('Rule violated: SeasonStart', {
    text: () =>
        `We appreciate your eagerness and enthusiasm! However, the season has not started and there are no online reservations available at this time. Please check the "Important Dates" section for more information.`,
    formatValue: (value) => moment.unix(value).utc().format('MMMM Do, YYYY'),
});
ruleToErrMap.set('Rule violated: SeasonEnd', {
    text: () =>
        `We applaud your good taste! However, the season has ended and there are no online reservations available at this time. Please check the "Important Dates" section for more information.`,
    formatValue: (value) => moment.unix(value).utc().format('MMMM Do, YYYY'),
});
ruleToErrMap.set('Rule violated: QuotaPerDay', {
    text: () =>
        `This is a popular activity and, unfortunately, there is not enough quota available for the date(s) you selected.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: RegistrationEnd', {
    text: (ruleValue) => `Advanced registration for this permit ended on ${ruleValue}.`,
    formatValue: (value) => moment.unix(value).utc().format('MMMM Do, YYYY'),
});
ruleToErrMap.set('Rule violated: HighUseSeasonStart', {
    text: (ruleValue) =>
        `We appreciate your eagerness and enthusiasm! Unfortunately, the lottery season doesn't begin until ${ruleValue}. Please refer to the "Important Dates" section and try again when the lottery opens.`,
    formatValue: (value) => moment.unix(value).utc().format('MMMM Do, YYYY'),
});
ruleToErrMap.set('Rule violated: HighUseSeasonEnd', {
    text: (ruleValue) =>
        `You made a great selection! However, the lottery season closed on ${ruleValue}. Please refer to the "Important Dates" section and try again when the lottery reopens.`,
    formatValue: (value) => moment.unix(value).utc().format('MMMM Do, YYYY'),
});
ruleToErrMap.set('Rule violated: MaxGroupSize', {
    text: (ruleValue, ruleDivision) => {
        if (ruleDivision)
            return `You cannot have more than ${ruleValue} group members to complete a reservation at ${ruleDivision}.`;
        return `You've got a full house! Please adjust your group so that it doesn't exceed the max group size of ${ruleValue}.`;
    },
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxGroupSizeCommercial', {
    text: (ruleValue) =>
        `You've got a full house! Please adjust your group so that it doesn't exceed the max commercial group size of ${ruleValue}.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: StayLimit', {
    text: (ruleValue) =>
        `This a popular permit activity and, unfortunately, the maximum length of stay is ${ruleValue} ${getDayCardinality(ruleValue)}. Please adjust your dates.`,
    formatValue: (value) => value / (24 * 60 * 60),
});
ruleToErrMap.set('Rule violated: StayMinimum', {
    text: (ruleValue) =>
        `Can you visit for just a little longer? The minimum length of stay is ${ruleValue} ${getDayCardinality(ruleValue)}. Please adjust your dates.`,
    formatValue: (value) => value / (24 * 60 * 60),
});
ruleToErrMap.set('Rule violated: MaxReservationsHighUseSeason', {
    text: (ruleValue) =>
        `You have excellent taste! However, you can only register ${ruleValue} ${getUnitCardinality(ruleValue, 'time', 'times')} this season and have exceeded the max number of applications.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxAccountReservationsOnDay', {
    text: (ruleValue) =>
        `You must really like this location! However, you can only register for ${ruleValue} ${getUnitCardinality(ruleValue, 'permit', 'permits')} per day.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxAccountNotIssuedReservationsPerSeason', {
    text: (ruleValue) =>
        `You can only have ${ruleValue} ${getUnitCardinality(ruleValue, 'permit', 'permits')} at a time, and you'll need to use a permit before you can reserve another one.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: HighUseRegistrationStart', {
    text: (ruleValue) =>
        `You made a great selection! However, the lottery season closed on ${ruleValue}. Please refer to the "Important Dates" section and try again when the lottery reopens.`,
    formatValue: (value) => moment.unix(value).utc().format('MMMM Do, YYYY'),
});
ruleToErrMap.set('Rule violated: HighUseRegistrationEnd', {
    text: (ruleValue) =>
        `You made a great selection! However, lottery registration closed on ${ruleValue}. Please refer to the "Important Dates" section and try again when the lottery reopens.`,
    formatValue: (value) => moment.unix(value).utc().format('MMMM Do, YYYY'),
});
ruleToErrMap.set('Rule violated: LotteryDeadlineToConfirm', {
    text: (ruleValue) =>
        `Unfortunately, the deadline to confirm this lottery award has passed. Confirmation for the lottery was required by ${ruleValue}.`,
    formatValue: (value) => moment.unix(value).utc().format('MMMM Do, YYYY'),
});
ruleToErrMap.set('Rule violated: IsLeaderTransferable', {
    text: () =>
        `Trust your first instinct and stick with this leader. The leader cannot be changed once a reservation is booked.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: IsCustomerTransferable', {
    text: () =>
        `We understand plans can change, but group members can't be updated once a reservation is booked.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: CancellationDeadline', {
    text: () =>
        `Unfortunately, the cancellation window for this reservation has passed. If you choose to cancel this reservation, you may not receive a refund or the cancellation could result in a penalty based on permit regulations.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: DisallowOvernightStock', {
    text: () => `Horses and other stock animals are not permitted for overnight trips.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxPartySize', {
    text: (ruleValue) =>
        `You've got quite the entourage! Please adjust the total number of people and stock animals so that it doesn’t' exceed the max of ${ruleValue}.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxEquipmentPerReservation', {
    text: (ruleValue) =>
        `You might be packing more gear than you'll need. The max amount of equipment allowed is ${ruleValue}.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxOverlappingReservations', {
    text: () => `You have an overlapping reservation.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: ModificationDeadline', {
    text: (ruleValue) =>
        `The modification deadline has passed. You must modify at least ${ruleValue} days before your reservation.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: CheckGapDays', {
    text: (ruleValue) =>
        `You must have a minimum of ${ruleValue} ${getDayCardinality(ruleValue)} between your reservations.`,
    formatValue: (value) => value - 1,
});
ruleToErrMap.set('Rule violated: MaxReservationsCalendarYear', {
    text: (ruleValue) => `You may only have ${ruleValue} reservations per calendar year.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: ActiveIssuances', {
    text: (ruleValue) =>
        `You may only have ${ruleValue} active reservations at this location.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MinGroupSize', {
    text: (ruleValue, ruleDivision) => {
        if (ruleDivision)
            return `You must have a minimum of at least ${ruleValue} group members to complete a reservation at ${ruleDivision}.`;
        return `You must have at least ${ruleValue} group members to complete this reservation.`;
    },
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxHolidayWeekendReservationsPerSeason', {
    text: (ruleValue) =>
        `You cannot reserve for more than ${ruleValue} holiday weekend this season.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxStock', {
    text: (ruleValue) =>
        `Please adjust the total number of stock animals so that it doesn't exceed the maximum total allowed of ${ruleValue}`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: DisableExternalModifications', {
    text: () => `Modifications are unavailable for your reservation.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: GroupMemberDatesInRange', {
    text: () =>
        `The first group member's trip dates must match the Entry & Exit Dates on the reservation. Please review the information provided for the overall reservation and each group member's trip dates.`,
    formatValue: (value) => value,
});
ruleToErrMap.set('Rule violated: MaxLotteryAwardsPerCalendarYear', {
    text: () =>
        `You may not apply to this lottery because you have already been successful in a previous lottery for this permit this year.`,
    formatValue: (value) => value,
});

// Takes raw text of error string and formats to a friendly error message
export const formatErrorMessage = (errString) => {
    const stringArr = [];

    // If last character is a semicolon, remove it before splitting to avoid creating
    // additional (empty) element in errList
    const errList = errString.replace(/;\s*$/, '').split(';');
    errList.forEach((err) => {
        const nameToValue = err.split('|'); // RuleName|Value, i.e. SeasonStart|4823827

        let ruleFound = false;
        for (const [ruleKey, ruleObj] of ruleToErrMap.entries()) {
            if (ruleKey.toLowerCase() === nameToValue[0]?.trim().toLowerCase()) {
                const ruleValue = nameToValue[1]?.trim()
                    ? ruleObj.formatValue(parseInt(nameToValue[1]?.trim(), 10))
                    : '';
                const ruleDivision = nameToValue[2]?.trim() || null;
                stringArr.push(ruleObj.text(ruleValue, ruleDivision));

                ruleFound = true;

                break;
            }
        }

        if (!ruleFound) {
            stringArr.push(err);
        }
    });
    return stringArr.join('\n');
};

export const errorsToStringList = (errors) =>
    flatten(
        errors.map((err) => {
            const errString = typeof err === 'string' ? err : err && err.error;
            return errString ? formatErrorMessage(errString).split('\n') : '';
        })
    ).filter((err) => err !== '');

/**
 * Creates availability map for use by exit date picker or groupmemberitem entry/exit date picker
 * @param {moment|string} startDate - startDate of rangeMap, string is 'YYYY-MM-DD'
 * @param {moment|string} endDate - endDate of rangeMap, string is 'YYYY-MM-DD'
 * @param {number} [minimumstay = 0] - options minimum stay
 */
export const createAvailabilityRangeMap = (startDate, endDate, minimumstay = 0) => {
    const dateMap = {};
    let start = moment.isMoment(startDate)
        ? startDate.utc()
        : moment(startDate, types.AVAILABILITY_KEY_MAP_FORMAT).utc();
    const end = moment.isMoment(endDate)
        ? endDate.utc()
        : moment(endDate, types.AVAILABILITY_KEY_MAP_FORMAT).utc();

    if (minimumstay > 0) {
        start = start.clone().add(minimumstay, 'd');
    }

    const dateDifference = end.diff(start, 'days') + 1;
    if (dateDifference > 0) {
        Array.from(new Array(dateDifference), (day, i) => {
            const newDay = start.clone();
            dateMap[newDay.add(i, 'day').format(types.AVAILABILITY_KEY_MAP_FORMAT)] =
                true;
            return null;
        });
    }
    return dateMap;
};

export const feeDisplayModels = new Map();
feeDisplayModels.set(types.NONE_FEE, 2);
feeDisplayModels.set(types.ONE_FEE, 0);
feeDisplayModels.set(types.ADD_ON_FEE, 1);
feeDisplayModels.set(types.BY_DAY_FEE, 3);

export const feeCalculationModels = new Map();
feeCalculationModels.set(types.NO_USE_FEE, 0);
feeCalculationModels.set(types.FLAT_USE_FEE, 1);
feeCalculationModels.set(types.PER_GROUP_PER_NIGHT_FEE, 2);
feeCalculationModels.set(types.PER_MEMBER_FEE, 3);
feeCalculationModels.set(types.PER_MEMBER_PER_NIGHT_FEE, 4);
feeCalculationModels.set(types.PER_MEMBER_PER_DAY_FEE, 5);
feeCalculationModels.set(types.PER_WATERCRAFT_FEE, 6);
feeCalculationModels.set(types.PER_GROUP_PER_DAY_FEE, 7);
feeCalculationModels.set(types.PER_GROUP_PER_NIGHT_CUSTOM_RANGE_HIGH_USE_ONLY_FEE, 8);
feeCalculationModels.set(types.PER_GROUP_SIZE_PER_DAY_FEE, 9);
feeCalculationModels.set(types.PER_GROUP_PER_NIGHT_EXCLUDE_FIRST_NIGHT_FEE, 10);
feeCalculationModels.set(types.PER_WATERCRAFT_PER_DAY, 11);
feeCalculationModels.set(types.PER_MEMBER_INYO, 12);
feeCalculationModels.set(types.PER_GROUP_SIZE_PER_NIGHT_PARTIAL_FIRST_NIGHT_FEE, 13);
feeCalculationModels.set(types.PER_MEMBER_HIGH_USE_ONLY, 14);
feeCalculationModels.set(types.PER_MEMBER_PER_DAY_BY_SEASON, 15);

export const feeTypes = new Map();
feeTypes.set(types.CLIN_FEE, 0);
feeTypes.set(types.TRANSACTION_FEE, 1);
feeTypes.set(types.USE_FEE, 2);
feeTypes.set(types.TICKET_FEE, 3);
feeTypes.set(types.ATTRIBUTE_FEE, 4);
feeTypes.set(types.POS_FEE, 5);
feeTypes.set(types.TAX_FEE, 6);
feeTypes.set(types.PERCENT_DISCOUNT_FEE, 7);
feeTypes.set(types.ADJUSTMENT_FEE, 8);
feeTypes.set(types.RESERVATION_FEE, 1000);
feeTypes.set(types.NONREFUNDABLE_FEE, 1001);

// Why are fees stored as integers, including cents? We may never know.
export const formatCurrency = (amount) => formatMoneySarsa(amount / 100);

export const getIds = (ids) => {
    const idObj = {};
    const issIdAndPermitId = ids.split(types.RES_ID_DELIMITER);
    if (issIdAndPermitId.length === 2) {
        idObj.issuanceId = issIdAndPermitId[0];
        idObj.permitId = issIdAndPermitId[1];
    }
    return idObj;
};

export function stringifyMoment(m) {
    return m !== null && moment.isMoment(m)
        ? moment(m).format(types.MONTH_DAY_YEAR_NUM_FORMAT)
        : '';
}

export const checkValidationStyle = (validation, field) =>
    validation[field] ? 'rec-error' : '';

export const validationTypes = new Map();
validationTypes.set(types.REQUIRED_BOOLEAN_FIELD, 0);
validationTypes.set(types.REQUIRED_STRING_FIELD, 1);
validationTypes.set(types.REQUIRED_DATE_FIELD, 2);
validationTypes.set(types.REQUIRED_NUM_FIELD, 3);
validationTypes.set(types.REQUIRED_LIST_ENTRY_FIELD, 4);
validationTypes.set(types.REQUIRED_SELECTED_BOOLEAN_FIELD, 5);
validationTypes.set(types.REQUIRED_STRING_FIELD_IN_ARRAY, 6);
validationTypes.set(types.REQUIRED_NUM_FIELD_IN_ARRAY, 7);
validationTypes.set(types.REQUIRED_GROUP_MEMBER_LIST, 8);
validationTypes.set(types.REQUIRED_DATE_FIELD_IN_ARRAY, 9);

export const validationTargets = new Map();
validationTargets.set(types.ENTRY_POINT, 'launchDetail.entryLocationId');
validationTargets.set(types.EXIT_POINT, 'launchDetail.exitLocationId');
validationTargets.set(types.EXIT_DATE, 'launchDetail.exitDate');
validationTargets.set(types.LAUNCH_TIME, 'launchDetail.launchTime');
validationTargets.set(types.ISSUE_STATION, 'issueStationDetail.selectedStationId');
validationTargets.set(types.GROUP_MEMBERS, 'groupMembers');
validationTargets.set(types.WATERCRAFTS, 'watercraftsCount');
validationTargets.set(types.LOTTERY_OPEN_DATE, 'lotteryOpenDate');
validationTargets.set(types.LOTTERY_CLOSE_DATE, 'lotteryCloseDate');
validationTargets.set(types.LOTTERY_RUN_DATE, 'lotteryRunDate');
validationTargets.set(types.LOTTERY_DEADLINE_DATE, 'lotteryDeadlineDate');
validationTargets.set(types.LOTTERY_INFO_DISPLAY_DATE, 'lotteryInfoDisplayDate');
validationTargets.set(types.LOTTERY_NOTIFY_DATE, 'lotteryNotifyDate');
validationTargets.set(types.SEASON_START_DATE, 'lotterySeasonStartDate');
validationTargets.set(types.SEASON_END_DATE, 'lotterySeasonEndDate');
validationTargets.set(types.NEED_TO_KNOW_CHECKBOX, 'needToKnowChecked');
validationTargets.set(types.DIVISION_ID, 'division.id');
validationTargets.set(types.SELECTED_DATES, 'selectedDates');
validationTargets.set(types.CHOICE_DATE, 'preferred_date');
validationTargets.set(types.CHOICE_DATE_RANGE_START, 'preferred_range_start');
validationTargets.set(types.CHOICE_DATE_RANGE_END, 'preferred_range_end');
validationTargets.set(types.CHOICE_DIVISION, 'division_id');
validationTargets.set(types.CHOICE_DURATION, 'length_of_stay');
validationTargets.set(types.CHOICE_GROUP_MAX, 'group_max_size');
validationTargets.set(types.CHOICE_GROUP_MIN, 'group_min_size');
validationTargets.set(types.HOUSEBOAT, 'houseboat.type');
validationTargets.set(types.EMERGENCY_CONTACT_FIRST_NAME, 'emergencyContact.first_name');
validationTargets.set(types.EMERGENCY_CONTACT_LAST_NAME, 'emergencyContact.last_name');
validationTargets.set(types.EMERGENCY_CONTACT_PHONE, 'emergencyContact.phone');

validationTargets.set(
    types.PERMIT_HOLDER_FIRST_NAME,
    'groupDetail.groupLeader.first_name'
);
validationTargets.set(types.PERMIT_HOLDER_LAST_NAME, 'groupDetail.groupLeader.last_name');
validationTargets.set(types.PERMIT_HOLDER_EMAIL, 'groupDetail.groupLeader.email');
validationTargets.set(types.PERMIT_HOLDER_PHONE, 'groupDetail.groupLeader.cell_phone');
validationTargets.set(
    types.PERMIT_HOLDER_STREET_ADDRESS,
    'groupDetail.groupLeader.home_address.address1'
);
validationTargets.set(
    types.PERMIT_HOLDER_CITY,
    'groupDetail.groupLeader.home_address.city'
);
validationTargets.set(
    types.PERMIT_HOLDER_COUNTRY,
    'groupDetail.groupLeader.home_address.country'
);
validationTargets.set(
    types.PERMIT_HOLDER_STATE,
    'groupDetail.groupLeader.home_address.state'
);
validationTargets.set(
    types.PERMIT_HOLDER_ZIP_CODE,
    'groupDetail.groupLeader.home_address.zip_code'
);

validationTargets.set(types.IS_VISITING_MT_WHITNEY, 'isVisitingMtWhitney');
validationTargets.set(types.IS_BRINGING_ANIMALS, 'isBringingAnimals');
validationTargets.set(types.ANIMAL_LIST, 'animals');
validationTargets.set(types.TRAVEL_METHOD, 'travelMethodType');
validationTargets.set(types.PRIOR_EXPERIENCE, 'priorExperience');
validationTargets.set(types.SNOWMOBILE, 'snowmobiles');
validationTargets.set(types.BIRTH_DATE, 'groupMembers|birthdate');
validationTargets.set(types.GROUP_MEMBER_FIRST_NAME, 'groupMembers|fName');
validationTargets.set(types.GROUP_MEMBER_LAST_NAME, 'groupMembers|lName');
validationTargets.set(types.GROUP_MEMBER_AGE, 'groupMembers|age');
validationTargets.set(types.GROUP_MEMBER_START_DATE, 'groupMembers|startDate');
validationTargets.set(types.GROUP_MEMBER_END_DATE, 'groupMembers|endDate');
validationTargets.set(types.PERMIT_HOLDER_BIRTHDATE, 'groupMembers[0].birthdate');
validationTargets.set(types.IS_WATERCRAFT_DETAIL_EMPTY, 'watercraftDetails');
validationTargets.set(types.IS_COMMERCIALLY_GUIDED, 'isCommerciallyGuided');
validationTargets.set(types.GROUP_MEMBERS_MATCH_GROUP_SIZE, 'groupMemberList');
validationTargets.set(types.COMMENT, 'comment');
validationTargets.set(types.VEHICLE_DESCRIPTION, 'vehicles|description');
validationTargets.set(types.LICENSE_PLATE, 'vehicles|license_plate');
validationTargets.set(types.LICENSE_STATE, 'vehicles|license_state');
validationTargets.set(types.VEHICLE_INFORMATION, 'vehicles');
validationTargets.set('commercialClientInfo', 'clientInfo');
validationTargets.set('commercialServiceType', 'serviceType');

export const getValidationErrorStyle = (validation, field, optionalClassName) => {
    if (validation) {
        let className = 'rec-error';
        if (optionalClassName) {
            className = optionalClassName;
        }
        if (Array.isArray(field)) {
            return field.some((str) => validation[str]) ? className : '';
        }
        return validation[field] ? className : '';
    }
    return '';
};

// Special exceptions for validations
// So far, removes validations for conditionally required fields; e.g. Olympic: stock is only required on stock permittype reservations
const validationExceptions = (permittype, validations) => {
    if (
        validations.find((elem) => {
            return get(elem, 'name') === 'IsAnimalsEmptyOnStock';
        }) !== null
    ) {
        if (permittype !== types.OVERNIGHT_WITH_STOCK) {
            remove(validations, (val) => {
                return get(val, 'name') === types.CONDITIONAL_ANIMALS_REQUIRED;
            });
        }
    }

    // Remove License Plate and Vehicle Description check. This will be done in BE.
    remove(validations, (val) => {
        return (
            get(val, 'name') === types.IsLicencePlateEmpty ||
            get(val, 'name') === types.IsVehicleDescriptionEmpty
        );
    });

    return validations;
};

export const runValidations = (
    dispatch,
    validations,
    state,
    registrationType,
    nameIndex,
    permittype
) => {
    if (!validations) {
        return []; // return empty validations if the page is still loading...
    }
    const validateList = [];
    let filteredValidations = validations.filter((v) => v.group === registrationType);
    let val = '';
    let target;
    let size;
    let itemArr;
    let targetArr;
    let itemArrayField;
    let targetField;
    let failedIndices = [];

    filteredValidations = validationExceptions(permittype, filteredValidations);
    filteredValidations.forEach((v) => {
        switch (v.type) {
            case validationTypes.get(types.REQUIRED_GROUP_MEMBER_LIST):
                const maxGroupSize = get(state, 'maxGroupSize', 0);
                const currentGroupSize = get(state, 'groupMembers.length', 0);
                if (
                    maxGroupSize &&
                    currentGroupSize &&
                    maxGroupSize === currentGroupSize
                ) {
                    val = 'match';
                } else {
                    val = '';
                }
                break;
            case validationTypes.get(types.REQUIRED_BOOLEAN_FIELD):
                val = get(state, validationTargets.get(v.target)) ? 'true' : '';
                break;
            case validationTypes.get(types.REQUIRED_STRING_FIELD):
                val = get(state, validationTargets.get(v.target));
                break;
            case validationTypes.get(types.REQUIRED_DATE_FIELD):
                val = stringifyMoment(get(state, validationTargets.get(v.target)));
                break;
            case validationTypes.get(types.REQUIRED_SELECTED_BOOLEAN_FIELD):
                val = get(state, validationTargets.get(v.target));
                val = !isNil(val) ? 'true' : '';
                break;
            case validationTypes.get(types.REQUIRED_NUM_FIELD):
                target = get(state, validationTargets.get(v.target));
                size = 0;

                if (Array.isArray(target)) {
                    size = target.length;
                }
                if (Number.isInteger(target)) {
                    size = target;
                }
                val = size === 0 ? '' : size.toString();
                break;
            case validationTypes.get(types.REQUIRED_STRING_FIELD_IN_ARRAY):
                // Validation Target must be delimited by | with the array name first and element name second
                targetArr = validationTargets.get(v.target).split('|');
                itemArr = get(state, targetArr[0]);

                if (Array.isArray(itemArr)) {
                    failedIndices = itemArr.reduce((acc, item, index) => {
                        if (isEmpty(get(item, targetArr[1], false))) {
                            acc.push(index);
                        }
                        return acc;
                    }, []);
                    val = itemArr.find((item) => isEmpty(get(item, targetArr[1], '')));
                }

                if (typeof val !== 'undefined') {
                    val = '';
                } else {
                    val = 'required array field is populated';
                }
                break;
            case validationTypes.get(types.REQUIRED_NUM_FIELD_IN_ARRAY):
                [itemArrayField, targetField] = validationTargets
                    .get(v.target)
                    .split('|');
                itemArr = get(state, itemArrayField);

                if (Array.isArray(itemArr)) {
                    failedIndices = itemArr.reduce((acc, item, index) => {
                        if (!get(item, targetField, false)) {
                            acc.push(index);
                        }
                        return acc;
                    }, []);
                    val = itemArr.find((item) => !get(item, targetField));
                }

                if (typeof val !== 'undefined') {
                    val = '';
                } else {
                    val = 'required array field is populated';
                }
                break;
            case validationTypes.get(types.REQUIRED_DATE_FIELD_IN_ARRAY):
                [itemArrayField, targetField] = validationTargets
                    .get(v.target)
                    .split('|');
                itemArr = get(state, itemArrayField);

                if (Array.isArray(itemArr)) {
                    failedIndices = itemArr.reduce((acc, item, index) => {
                        if (!get(item, targetField, false)) {
                            acc.push(index);
                        }
                        return acc;
                    }, []);
                    val = itemArr.find((item) => !get(item, targetField));
                }

                val =
                    typeof val !== 'undefined' ? '' : 'required array field is populated';
                break;
            default:
        }

        if (isEmpty(val)) {
            val = '';
        }

        validateList.push(
            <RequiredValidation
                key={`${nameIndex ? `${nameIndex}: ` : ''}${v.name}`}
                controlLabel={`${nameIndex ? `${nameIndex}: ` : ''}${v.name}`}
                onValidated={(isNotValid, error) =>
                    dispatch(
                        vActions.checkIsValid(
                            `${nameIndex ? `${nameIndex}: ` : ''}${v.name}`,
                            isNotValid,
                            error
                        )
                    )
                }
                displayInSummary
                dataIndices={failedIndices}
                value={val}
            >
                <div className="permit-validation" data-indices={failedIndices}>
                    {nameIndex ? `${nameIndex}: ` : ''}
                    {v.message}
                </div>
            </RequiredValidation>
        );
    });
    return validateList;
};

export const filterValidationDataIndices = (validation, index) => {
    return Object.keys(validation).reduce((acc, key) => {
        if (get(validation, [key, 'props', 'data-indices'], '').indexOf(index) !== -1) {
            acc[key] = validation[key];
        }
        return acc;
    }, {});
};

// check division status
export const isDivisionOpen = (status) => {
    return status === types.DIVISION_STATUS.OPEN;
};

// returns the first prop in the divisions map (not guaranteed to be the same if there are multiple divisions)
export const getFirstDivision = (permitDivisions) => {
    for (const prop in permitDivisions) {
        // prevent traversing down the object prototype
        if (!Object.hasOwnProperty.call(permitDivisions, prop)) {
            continue;
        }
        return permitDivisions[prop];
    }
    // no divisions
    return null;
};

export const getIssuanceDivision = (issuance, permit) => {
    // gets the division from the permit, specified by the issuance, or the first division if one not specified
    // or null if nothing.

    const divisions = get(permit, 'divisions', {});
    const divisionId = get(issuance, 'division_id');
    const permitId = get(permit, 'id');

    if (types.IS_HUNTING_PERMIT(permitId)) {
        const huntingDivisions = getHuntingDivisions(
            permit,
            get(issuance, 'permit_type'),
            divisionId
        );
        return {
            name: huntingDivisions,
            permit_id: permitId,
        };
    }

    return get(divisions, divisionId, getFirstDivision(divisions));
};

// returns an open division, otherwise returns the first division if there are none open
export const getDivision = (permitDivisions) => {
    for (const id in permitDivisions) {
        if (!Object.prototype.hasOwnProperty.call(permitDivisions, id)) {
            continue;
        }
        const division = permitDivisions[id];
        if (isDivisionOpen(division.status)) {
            return division;
        }
    }

    return getFirstDivision(permitDivisions);
};

export const getCurrentDayString = (props) => {
    if (props.currentDate) {
        return moment(props.currentDate, types.AVAILABILITY_KEY_MAP_FORMAT).format(
            types.AVAILABILITY_KEY_MAP_FORMAT
        );
    }

    return moment().format(types.AVAILABILITY_KEY_MAP_FORMAT);
};

// Gets props.currentDate if it exists, otherwise gets the current time.
// Useful for faking what time it is for debugging purposes
export const getCurrentTime = (props) => {
    if (props.currentDate) {
        return moment.isMoment(props.currentDate)
            ? props.currentDate.utc()
            : moment(props.currentDate).utc();
    }

    let currentDate = moment().utc();

    // enable timemachine date query param only in dev
    if (process.env.NODE_ENV === 'development') {
        currentDate = moment(get(props, 'location.query.date', moment())).utc();
    }

    return currentDate;
};

// Gets props.currentDate if it exists, otherwise gets the current time.
// Useful for faking what time it is for debugging purposes
export const getCurrentTimeWithOffset = (props) => {
    if (props.currentDate) {
        return moment.isMoment(props.currentDate)
            ? props.currentDate.utcOffset(0, true)
            : moment(props.currentDate).utcOffset(0, true);
    }

    let currentDate = moment().utcOffset(0, true);

    // enable timemachine date query param only in dev
    if (process.env.NODE_ENV === 'development') {
        currentDate = moment(get(props, 'location.query.date', moment())).utcOffset(
            0,
            true
        );
    }

    return currentDate;
};

export const getCurrentWithTimeZone = ({ currentDate, location, permit }) => {
    const timeZone = permit?.time_zone ?? 'UTC';

    if (currentDate) {
        return moment.isMoment(currentDate)
            ? currentDate.tz(timeZone)
            : moment(currentDate).tz(timeZone);
    }

    // enable timemachine date query param only in dev
    if (process.env.NODE_ENV === 'development') {
        return moment(get(location, 'query.date', moment())).tz(timeZone);
    }

    return moment().tz(timeZone);
};

export const hasCurrentActiveLottery = (permit) => {
    if (isEmpty(permit)) {
        return false;
    }

    const currentDate = getCurrentTime({});

    const deadlineToConfirm = readRuleAsMoment(
        'LotteryDeadlineToConfirm',
        permit.rules,
        permit.time_zone,
        undefined,
        false,
        true
    );
    const lotteryDisplayDate = readRuleAsMoment(
        'LotteryDisplayDate',
        permit.rules,
        permit.time_zone,
        undefined,
        false,
        true
    );
    const lotteryStart = readRuleAsMoment(
        'HighUseRegistrationStart',
        permit.rules,
        permit.time_zone,
        undefined,
        false,
        true
    );
    const lotteryEnd = readRuleAsMoment(
        'HighUseRegistrationEnd',
        permit.rules,
        permit.time_zone,
        undefined,
        false,
        true
    );

    // check if permit has lottery rules
    if (!deadlineToConfirm.isValid() || !lotteryDisplayDate.isValid()) {
        return false;
    }

    // check if lottery is currently active relative to current date
    if (
        lotteryStart.isValid() &&
        lotteryEnd.isValid() &&
        currentDate.isBetween(lotteryStart, lotteryEnd, 'minute', '[]')
    ) {
        return true;
    }

    return false;
};

export const isInvalidLotteryAward = (props) => {
    const { permit, lottery, issuance } = props;
    const incorrectStatus =
        issuance.status !== issuanceStatuses.get('Awarded') ||
        (props.issuance.status === issuanceStatuses.get('Awarded') &&
            props.issuance.lottery_accepted);
    if (incorrectStatus) {
        return true;
    }

    const currentTime = getCurrentTime(props);
    const announcedTimestamp = get(lottery, 'lottery.announced_at', false);
    if (
        announcedTimestamp &&
        moment(announcedTimestamp).tz(permit.time_zone).isAfter(currentTime)
    ) {
        return true;
    }

    return false;
};

// sales channel types
export const salesChannelTypes = new Map();
salesChannelTypes.set(types.ONLINE, 0);
salesChannelTypes.set(types.FIELD_SALES, 1);
salesChannelTypes.set(types.CALL_CENTER, 2);

// convert sales channel type enum to string literal
export const salesChannelTypeToString = new Map();
salesChannelTypeToString.set(0, 'Online');
salesChannelTypeToString.set(1, 'Field Sales');
salesChannelTypeToString.set(2, 'Call Center');

// check issuance status for bypassing payment
export const canIssuanceStatusBypassPayment = (issuanceStatus) => {
    return (
        issuanceStatus === issuanceStatuses.get(SharedConstants.LOTTERY_AWARDED) ||
        issuanceStatus ===
            issuanceStatuses.get(SharedConstants.LOTTERY_AWARDED_IN_PROGRESS) ||
        issuanceStatus === issuanceStatuses.get(SharedConstants.PERMIT_COMPLETE)
    );
};

// permit types
export const permitCategories = new Map();
permitCategories.set(SharedConstants.PERMIT_CATEGORY_RIVER, 0);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_HIKING, 1);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_CAMPING, 2);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_CLIMBING, 3);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_WILDLIFE, 4);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_ROADENTRY, 5);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_EVENT, 6);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_BOAT, 7);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_SNOWMOBILE, 8);
permitCategories.set(SharedConstants.PERMIT_CATEGORY_WILDERNESS, 9);

// convert permit type enum to string literal
export const permitCategoriesToString = new Map();
permitCategoriesToString.set(0, 'River');
permitCategoriesToString.set(1, 'Hiking');
permitCategoriesToString.set(2, 'Camping');
permitCategoriesToString.set(3, 'Climbing');
permitCategoriesToString.set(4, 'Wildlife');
permitCategoriesToString.set(5, 'Road Entry');
permitCategoriesToString.set(6, 'Event');
permitCategoriesToString.set(7, 'Boat');
permitCategoriesToString.set(8, 'Snowmobile');

export const passTypesToString = new Map();
passTypesToString.set(0, 'Standard / No Pass'); // This is the default value - any fee created w/o explicit passType has a passType of 0
passTypesToString.set(SharedConstants.ADULT_YOUTH_PASS, 'Adult/Youth'); // 1
passTypesToString.set(SharedConstants.INTERAGENCY_PASS, 'Interagency Access Pass'); // 2
passTypesToString.set(SharedConstants.SENIOR_PASS, 'Interagency Senior Pass'); // 3
passTypesToString.set(SharedConstants.LOCAL_PASS, 'Local Pass'); // 4
passTypesToString.set(SharedConstants.ADULT_PASS, 'Adult'); // 5
passTypesToString.set(SharedConstants.YOUTH_PASS, 'Youth'); // 6
export const passTypesToStringMap = transformIntoBidirectionalMap(passTypesToString);

export const feeTypesToString = new Map();
feeTypesToString.set(0, 'Standard / No Fee'); // 0
feeTypesToString.set(SharedConstants.ADULT_FEE, 'Adult (16 to 61)'); // 1
feeTypesToString.set(SharedConstants.JUNIOR_FEE, 'Junior (below 16)'); // 2
feeTypesToString.set(SharedConstants.SENIOR_FEE, 'Senior (above 61)'); // 3

export const formatFeeDescription = (fee /* : {amount: number, pass_type: number} */) => {
    if (!loSize(fee)) return 'N/A';
    const amount = parseInt(fee.amount / 100, 10);
    if (fee.pass_type === 0) {
        return `$${amount} Standard Fee`;
    }

    const passName = passTypesToString.get(fee.pass_type);
    return `$${amount} ${passName}`;
};

export const getFeeByMemberPass = (passType, fees, useHighUseFee = false) => {
    const useFees = fees.filter((f) => {
        return f.type === feeTypes.get(types.USE_FEE);
    });
    const passFees = fees.filter((f) => {
        return f.type === feeTypes.get(types.PERCENT_DISCOUNT_FEE);
    });

    // assuming the first use fee is the base use fee
    const baseFee = useFees.find((fee) => fee.is_high_use_fee === useHighUseFee);

    if (Array.from(passTypesToString.keys()).includes(passType)) {
        for (const fee of passFees) {
            if (fee.pass_type === passType) {
                return {
                    amount: (baseFee.amount * fee.amount) / 100,
                    pass_type: fee.pass_type,
                };
            }
        }
    }

    return baseFee;
};

// helper methods for checking/retrieving rules
export const hasLotteryPreferenceSelectionLimit = (permit, divisionId) => {
    return hasRule(types.LOTTERY_PREFERENCE_SELECTION_LIMIT, permit.rules, divisionId);
};
export const getLotteryPreferenceSelectionLimit = (permit, divisionId) => {
    return parseInt(
        readRuleValue(types.LOTTERY_PREFERENCE_SELECTION_LIMIT, permit.rules, divisionId),
        10
    );
};

export const getLotteryPreferenceType = (permit, divisionId) => {
    return parseInt(
        readRuleValue(types.LOTTERY_PREFERENCES_TYPE, permit.rules, divisionId),
        10
    );
};

export const hasLotteryPreferenceDateDivisionDuration = (permit, divisionId) => {
    return (
        getLotteryPreferenceType(permit, divisionId) ===
        types.LOTTERY_PREFERENCE_TYPES.DATE_DIVISION_DURATION
    );
};

export const hasLotteryPreferenceDateDivisionGroupSize = (permit, divisionId) => {
    return (
        getLotteryPreferenceType(permit, divisionId) ===
            types.LOTTERY_PREFERENCE_TYPES.DATE_DIVISION_GROUP_MIN_MAX ||
        getLotteryPreferenceType(permit, divisionId) ===
            types.LOTTERY_PREFERENCE_TYPES.DATE_DIVISION_GROUP_MAX
    );
};

export const hasLotteryPreferenceDateDivisionMaxGroupSizeOnly = (permit, divisionId) => {
    return (
        getLotteryPreferenceType(permit, divisionId) ===
        types.LOTTERY_PREFERENCE_TYPES.DATE_DIVISION_GROUP_MAX
    );
};

export const hasLotteryPreferenceDateRangeGroupSize = (permit, divisionId) => {
    return (
        getLotteryPreferenceType(permit, divisionId) ===
        types.LOTTERY_PREFERENCE_TYPES.DATE_RANGE_GROUP_SIZE
    );
};

export const verifyLotteryStatusMessages = new Map();
verifyLotteryStatusMessages.set(
    types.VERIFY_LOTTERY_STATUSES.LOTTERY_AVAILABLE,
    'Lottery is Available!'
);
verifyLotteryStatusMessages.set(
    types.VERIFY_LOTTERY_STATUSES.USER_RESTRICTED,
    "Based on this facility's use restrictions, " +
        'you have been disqualified for further reservations at this facility.  If you feel you have received this message in ' +
        'error or require information about these restrictions, please call the facility.'
);
verifyLotteryStatusMessages.set(
    types.VERIFY_LOTTERY_STATUSES.LOTTERY_NOT_ACTIVE,
    'There is no active lottery for this location.'
);
verifyLotteryStatusMessages.set(
    types.VERIFY_LOTTERY_STATUSES.LOTTERY_NOT_OPEN,
    'This lottery is currently not open for user registration.'
);
verifyLotteryStatusMessages.set(
    types.VERIFY_LOTTERY_STATUSES.EXISTING_LOTTERY,
    'A lottery application for this season already exists. If you have not yet paid for your lottery application, please check the shopping cart for an in-progress application.'
);
verifyLotteryStatusMessages.set(
    types.VERIFY_LOTTERY_STATUSES.EXISTING_HIGH_RESERVATION,
    'You cannot register multiple times for the same lottery.'
);
verifyLotteryStatusMessages.set(
    types.VERIFY_LOTTERY_STATUSES.COMMERCIAL_NOT_ALLOWED,
    "Unfortunately, this lottery doesn't allow for commercial permit applications."
);

export const getAllLotteryIssuanceStatuses = () => {
    const lotteryStatuses = [];
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_IN_PROGRESS));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_APPLIED));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_AWARDED));
    lotteryStatuses.push(
        issuanceStatuses.get(SharedConstants.LOTTERY_AWARDED_IN_PROGRESS)
    );
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_LOST));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_DECLINED));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_ACCEPTED));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_NO_RESPONSE));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_CANCELLED));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_VOIDED));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_DISQUALIFIED));
    lotteryStatuses.push(issuanceStatuses.get(SharedConstants.LOTTERY_PENDING));
    return lotteryStatuses;
};

export const getAllReservationSearchIssuanceStatuses = () => {
    const permitIssuanceStatuses = [];
    permitIssuanceStatuses.push(issuanceStatuses.get(types.PERMIT_PRELIMINARY));
    permitIssuanceStatuses.push(issuanceStatuses.get(types.PERMIT_COMPLETE));
    permitIssuanceStatuses.push(issuanceStatuses.get(types.PERMIT_IN_REVIEW));
    permitIssuanceStatuses.push(issuanceStatuses.get(types.PERMIT_REVIEWED));
    permitIssuanceStatuses.push(issuanceStatuses.get(types.PERMIT_ISSUED));
    permitIssuanceStatuses.push(issuanceStatuses.get(types.PERMIT_CANCELLED_BY_USER));
    permitIssuanceStatuses.push(issuanceStatuses.get(types.PERMIT_NO_SHOW));
    permitIssuanceStatuses.push(issuanceStatuses.get(types.PERMIT_REFUNDED));
    return permitIssuanceStatuses;
};

export const isLotteryStatus = (status) =>
    getAllLotteryIssuanceStatuses().some((lotteryStatus) => lotteryStatus === status);

export const canAcceptLottery = (issuance) =>
    isLotteryStatus(get(issuance, 'status')) &&
    !get(issuance, 'lottery_accepted') &&
    !!get(issuance, 'lottery_awarded');

export const getGroupSizeLabel = (permit) => {
    if (get(permit, 'components.member_counter_ruby')) {
        return 'People and Pets';
    }
    return 'People';
};

export const getMinGroupSizeByDivision = (rules, divisionId) => {
    return readRuleValue('MinGroupSize', rules, divisionId) === ''
        ? 1
        : parseInt(readRuleValue('MinGroupSize', rules, divisionId), 10);
};

export const getMaxGroupSizeByDivision = (rules, divisionId, isCommercial) => {
    if (isCommercial) {
        const val = readRuleValue('MaxGroupSizeCommercial', rules, divisionId);
        if (val) {
            return val;
        }
    }

    return readRuleValue('MaxGroupSize', rules, divisionId) === ''
        ? null
        : parseInt(readRuleValue('MaxGroupSize', rules, divisionId), 10);
};

export const getMinGroupSize = (rules, divisionIds) => {
    if (divisionIds.length > 0) {
        let val = getMinGroupSizeByDivision(rules, divisionIds[0]);
        divisionIds.forEach((divisionId) => {
            const temp = getMinGroupSizeByDivision(rules, divisionId);
            if (temp < val) {
                val = temp;
            }
        });

        return val;
    }

    return getMinGroupSizeByDivision(rules, '');
};

export const getMaxGroupSize = (rules, divisionIds, isCommercial) => {
    if (divisionIds.length > 0) {
        let val = 0;
        divisionIds.forEach((divisionId) => {
            const temp = getMaxGroupSizeByDivision(rules, divisionId, isCommercial);
            if (temp > val) {
                val = temp;
            }
        });

        return val;
    }

    return getMaxGroupSizeByDivision(rules, '', isCommercial);
};

export const AVAILABILITY_FILTERS = (
    permit,
    rules,
    divisions = [],
    isCommercial = false
) => {
    return [
        {
            type: SharedConstants.GROUP_AVAILABILITY_DAILY_KEY_NAME,
            display: SharedConstants.FILTER_NUMERICAL,
            label: 'Daily Groups',
            labelAlt: 'Daily Groups',
            initialValue: 1,
            minValue: 1,
            showStandalone: false,
            doNotRender: true,
        },
        {
            type: SharedConstants.GROUP_AVAILABILITY_KEY_NAME,
            display: SharedConstants.FILTER_NUMERICAL,
            label: 'Daily Groups',
            labelAlt: 'Daily Groups',
            initialValue: 1,
            minValue: 1,
            maxValue: 1,
            showStandalone: false,
            doNotRender: true,
        },
        {
            type: SharedConstants.GROUP_AVAILABILITY_WEEKLY_KEY_NAME,
            display: SharedConstants.FILTER_NUMERICAL,
            label: 'Weekly Groups',
            labelAlt: 'Weekly Groups',
            initialValue: 1,
            minValue: 1,
            maxValue: 1,
            showStandalone: false,
            doNotRender: true,
        },
        {
            type: SharedConstants.MEMBER_AVAILABILITY_KEY_NAME,
            display: SharedConstants.FILTER_NUMERICAL,
            label: getGroupSizeLabel(permit),
            labelAlt: 'Group Members',
            initialValue: '',
            missingInputError: 'Select the number of group members to view availability',
            minValue:
                rules && rules.length > 0
                    ? getMinGroupSize(rules, divisions, isCommercial)
                    : 1,
            maxValue: getMaxGroupSize(rules, divisions, isCommercial),
            showStandalone: true,
        },
        {
            type: SharedConstants.MEMBER_AVAILABILITY_DAILY_KEY_NAME,
            display: SharedConstants.FILTER_NUMERICAL,
            label: getGroupSizeLabel(permit),
            labelAlt: 'Group Members',
            initialValue: '',
            missingInputError: 'Select the number of group members to view availability',
            minValue:
                rules && rules.length > 0
                    ? getMinGroupSize(rules, divisions, isCommercial)
                    : 1,
            maxValue: getMaxGroupSize(rules, divisions, isCommercial),
            showStandalone: true,
        },
        {
            type: SharedConstants.WATERCRAFT_AVAILABILITY_KEY_NAME,
            display:
                permit && permit.components && permit.components.watercraft_pere_marquette
                    ? SharedConstants.FILTER_WATERCRAFT
                    : SharedConstants.FILTER_NUMERICAL,
            label: 'Watercraft',
            labelAlt: 'Watercraft',
            initialValue: null,
            missingInputError: 'Please select watercraft amount',
            minValue: 1,
            maxValue:
                readRuleValue('MaxWatercraftPerReservation', rules) === ''
                    ? null
                    : parseInt(readRuleValue('MaxWatercraftPerReservation', rules), 10), // used by pine river
            maxEquipmentCount:
                readRuleValue('MaxWatercraftPerReservation', rules) === ''
                    ? null
                    : parseInt(readRuleValue('MaxWatercraftPerReservation', rules), 10), // used by pere marquette
            showStandalone: true,
        },
    ];
};

export const convertDateToNearestISOString = (date) => {
    return moment(date).utcOffset(0).startOf('day').toISOString();
};

export const addFilterValues = (filters, oldGroupInfo = [], oldGuides = []) => {
    let item = {
        equipments: [],
    };
    for (const key in filters) {
        if (!Object.prototype.hasOwnProperty.call(filters, key)) {
            continue;
        }

        if (key === SharedConstants.WATERCRAFT_AVAILABILITY_KEY_NAME) {
            const equipmentInfo = {
                equipments: [],
            };
            equipmentInfo.equipments.push({ type: 0, qty: parseInt(filters[key], 10) });
            item = Object.assign(item, equipmentInfo);
        } else if (
            key === SharedConstants.MEMBER_AVAILABILITY_KEY_NAME ||
            key === SharedConstants.MEMBER_AVAILABILITY_DAILY_KEY_NAME
        ) {
            const memberInfo = {
                group_info: [],
            };
            for (let i = 0; i < filters[key]; i++) {
                memberInfo.group_info.push(
                    oldGroupInfo[i] || {
                        first_name: '',
                        middle_name: '',
                        last_name: '',
                        is_adult: true,
                    }
                );
            }
            memberInfo.group_size = memberInfo.group_info.length;
            item = Object.assign(item, memberInfo);
        } else if (key === 'guides') {
            item.guides = [];
            for (let i = 0; i < filters[key]; i++) {
                item.guides.push(
                    oldGuides[i] || {
                        first_name: '',
                        last_name: '',
                        license_number: '',
                        license_expiration_date: '',
                        employee_id: '',
                    }
                );
            }
        }
    }
    return item;
};

export function getDisplayOrderNumber(orderId) {
    if (orderId && typeof orderId === 'string') {
        return orderId.substring(orderId.length - 6);
    }
    return orderId;
}

export function calculateEntrancesExits(
    locations,
    launchDetail,
    startingDivisionId,
    divisions,
    endingDivisionId
) {
    // startingDivisionId, divisions, and endingDivisionId are optional arguments

    let entrances = filter(locations, { is_entry: true });
    let exits = filter(locations, { is_exit: true });

    if (startingDivisionId && divisions) {
        const division = divisions[startingDivisionId];

        if (division && division.entry_ids) {
            entrances = division.entry_ids.map((entryId) =>
                locations.find((location) => location.id === entryId)
            );
        }

        // If no endingDivisionId, then get exits from the starting location
        if (division && division.exit_ids && !endingDivisionId) {
            exits = division.exit_ids.map((exitId) =>
                locations.find((location) => location.id === exitId)
            );
        } else if (endingDivisionId) {
            const exitDivision = divisions[endingDivisionId];
            // Gets exits from the ending location
            if (exitDivision.exit_ids) {
                exits = exitDivision.exit_ids.map((exitId) =>
                    locations.find((location) => location.id === exitId)
                );
            }
        }
    }

    if (entrances.length === 1 && exits.length === 1) {
        return {
            validEntrances: entrances,
            validExits: exits,
            launchDetail: {
                ...launchDetail,
                entryLocationId: entrances[0].id,
                exitLocationId: exits[0].id,
            },
        };
    }
    if (entrances.length === 1) {
        return {
            validEntrances: entrances,
            validExits: exits,
            launchDetail: {
                ...launchDetail,
                entryLocationId: entrances[0].id,
            },
        };
    }
    if (exits.length === 1) {
        return {
            validEntrances: entrances,
            validExits: exits,
            launchDetail: {
                ...launchDetail,
                exitLocationId: exits[0].id,
            },
        };
    }
    return {
        validEntrances: entrances,
        validExits: exits,
    };
}

export function calculateIssueStations(locations, divisionId) {
    let issueStations;
    if (divisionId) {
        issueStations = locations.filter((location) => {
            return (
                location.is_issue_station &&
                (location.division_id ? location.division_id === divisionId : true)
            );
        });
    } else {
        issueStations = filter(locations, { is_issue_station: true });
    }

    if (issueStations.length === 1) {
        return {
            validIssueStations: issueStations,
            issueStationDetail: {
                selectedStationId: issueStations[0].id,
            },
        };
    }
    return {
        validIssueStations: issueStations,
    };
}

export function getSeasonality(rules, timeZone, divisionId, date) {
    // Returns 0 for preseason, 1 for high use, and 2 for post season
    const highUseSeasonStart = readRuleAsMoment(
        'HighUseSeasonStart',
        rules,
        timeZone,
        divisionId
    );
    const highUseSeasonEnd = readRuleAsMoment(
        'HighUseSeasonEnd',
        rules,
        timeZone,
        divisionId
    );

    let mDate = moment(date);

    if (moment.isMoment(date)) {
        mDate = date;
    }

    if (mDate.isBefore(highUseSeasonStart)) {
        return SEASONALITY_PRESEASON; // pre season
    }
    if (mDate.isAfter(highUseSeasonEnd)) {
        return SEASONALITY_POSTSEASON; // post season
    }

    return SEASONALITY_HIGHUSESEASON; // In high use
}

export function getSeasonalStayLimit(rules, timeZone, stayLimitRule, divisionId, date) {
    const season = getSeasonality(rules, timeZone, divisionId, date);
    let value = stayLimitRule.value;

    if (stayLimitRule.operation === 'SeasonSpecificStayLimit') {
        const metadata = JSON.parse(get(stayLimitRule, 'metadata', '{}'));
        switch (season) {
            case SEASONALITY_PRESEASON:
                value = get(metadata, 'preseason', value);
                break;
            case SEASONALITY_HIGHUSESEASON:
                value = get(metadata, 'high_use_season', value);
                break;
            case SEASONALITY_POSTSEASON:
                value = get(metadata, 'postseason', value);
                break;
            default:
                break;
        }
    }

    return value / 60 / 60 / 24;
}

export function hasLotteryApplication(permit, issuances) {
    const highUseSeasonStart = readRuleAsMoment(
        'HighUseSeasonStart',
        permit.rules,
        permit.time_zone
    );
    const highUseSeasonEnd = readRuleAsMoment(
        'HighUseSeasonEnd',
        permit.rules,
        permit.time_zone
    );

    // check if a reservation exists within the same time span of lottery high use season start and end range
    const activeLotteries = getActiveLotteries(permit); // only one lottery can be active at a time
    for (let i = 0; i < issuances.length; i++) {
        const iss = issuances[i];
        // if a reservation is found within the range, return true
        if (
            moment(iss.start_date).isSameOrAfter(highUseSeasonStart) &&
            moment(iss.end_date).isSameOrBefore(highUseSeasonEnd) &&
            iss.lottery_id === activeLotteries[0].id &&
            (iss.status === issuanceStatuses.get(SharedConstants.LOTTERY_APPLIED) ||
                iss.status ===
                    issuanceStatuses.get(SharedConstants.LOTTERY_DISQUALIFIED) ||
                iss.status === issuanceStatuses.get(SharedConstants.LOTTERY_IN_PROGRESS))
        ) {
            return true;
        }
    }
    return false;
}

export function loadPassInfo(permit) {
    if (permit.id === types.PACK_CREEK_ID) {
        const feeOptions = {};

        for (const key of feeTypesToString.keys()) {
            feeOptions[key] = feeTypesToString.get(key);
        }

        return feeOptions;
    }

    const passOptions = {};

    for (const key of passTypesToString.keys()) {
        passOptions[key] = passTypesToString.get(key);
    }

    if (!hasRule('LocalPass', permit.rules)) {
        delete passOptions[SharedConstants.LOCAL_PASS];
    }

    // for sites that just need adult/youth passes, but need names
    if (hasRule('AdultYouthOnly', permit.rules)) {
        delete passOptions[0];
        delete passOptions[SharedConstants.ADULT_YOUTH_PASS];
        delete passOptions[SharedConstants.INTERAGENCY_PASS];
        delete passOptions[SharedConstants.SENIOR_PASS];
    } else {
        delete passOptions[SharedConstants.ADULT_PASS];
        delete passOptions[SharedConstants.YOUTH_PASS];
    }

    return passOptions;
}

export const buttonLabels = {
    AddToCart: 'Add to Cart',
    ProceedToCart: 'Proceed to Cart',
    RemoveFromCart: 'Remove from Cart',
    ContinueShopping: 'Continue Shopping',
    SaveChanges: 'Save Changes',
    CancelChanges: 'Cancel Changes',
    Cancel: 'Cancel',
};

export function setButtonLabels(issuance, isLottery, amountDue) {
    // button labels for new/modify permit and new/modify lottery registrations(see **) before purchase
    let proceedLabel = buttonLabels.ProceedToCart;
    let continueLabel = buttonLabels.ContinueShopping;
    let cancelLabel = buttonLabels.RemoveFromCart;
    // if there's nothing due, then change the button text to refer to changes rather than the cart
    if (amountDue === 0 && !isLottery) {
        proceedLabel = buttonLabels.SaveChanges;
        cancelLabel = buttonLabels.CancelChanges;
        continueLabel = buttonLabels.CancelChanges;
    }

    if (isLottery && !issuance) {
        // ** cancel button label for new lottery registration before purchase
        cancelLabel = buttonLabels.Cancel;
    }
    return { proceed: proceedLabel, continue: continueLabel, cancel: cancelLabel };
}

export function setLotteryButtonLabels(issuance, amountDue) {
    // button labels for new/modify permit and new/modify lottery registrations(see **) before purchase
    let proceedLabel = buttonLabels.ProceedToCart;
    const continueLabel = buttonLabels.ContinueShopping;
    let cancelLabel = buttonLabels.RemoveFromCart;

    // if there's nothing due, then change the button text to refer to changes rather than the cart
    if (amountDue === 0) {
        proceedLabel = buttonLabels.SaveChanges;
    }

    if (!issuance) {
        // ** cancel button label for new lottery registration before purchase
        cancelLabel = buttonLabels.Cancel;
    }
    return { proceed: proceedLabel, continue: continueLabel, cancel: cancelLabel };
}

export function renderList(items, tag) {
    if (!items) {
        return '';
    }
    return items.map((item) => {
        if (tag === 'li') {
            return <li key={item}>{item}</li>;
        }
        if (tag === 'p') {
            return <p key={item}>{item}</p>;
        }
        return '';
    });
}

export function getRecareaName(recarea) {
    let recAreaName = 'Recreation Area';
    if (recarea && recarea[0]) {
        if (recarea[0].name) {
            recAreaName = recarea[0].name;
        }
    }
    return recAreaName;
}

export function getDivisionName(permit, iss) {
    let name = 'Requester Name';
    if (permit && permit.divisions && iss && iss.division_id) {
        const division = permit.divisions[iss.division_id];
        if (division) {
            name = division.name;
        }
    }
    return name;
}

export function formatMoney(val) {
    return formatMoneySarsa(val);
}

let lastId = 0;
export const getNewId = () => {
    lastId += 1;
    return `${lastId}`;
};

let GenerateLineItems;
export async function loadFeesScript() {
    if (GenerateLineItems) return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = await axios
        .get(SharedConstants.GENERATED_FEES_SCRIPT_URL, {
            credentials: 'include',
        })
        .then((d) => {
            return d.data;
        });
    document.body.appendChild(script);

    GenerateLineItems = window.GenerateLineItems;
    delete window.GenerateLineItems;
}

// generates line items
// errors are also generated, but for now they are not used
export const generateLineItems = (rawIssuance, rawDivision, rawPermit) => {
    if (!GenerateLineItems) {
        return [];
    }

    let issuance = null;
    let division = null;
    let permit = null;

    // In some cases the division is not set in the state - we _must_ pass a division in
    let divisionInIssuance;
    if (rawDivision && Object.keys(rawDivision).length === 0) {
        if (rawIssuance.division_id) {
            divisionInIssuance = rawPermit.divisions[rawIssuance.division_id];
        }
        if (!divisionInIssuance) {
            divisionInIssuance = getFirstDivision(rawPermit.divisions);
        }
    } else {
        divisionInIssuance = rawDivision;
    }

    // ensure that all parameters can be parsed as json
    try {
        issuance = JSON.parse(JSON.stringify(rawIssuance));
        division = JSON.parse(JSON.stringify(divisionInIssuance));
        permit = JSON.parse(JSON.stringify(rawPermit));
    } catch (e) {
        return [];
    }

    if (permit.id === types.PERE_MARQUETTE_ID) {
        permit.equipments.forEach((e) => {
            e.weight *= 2;
        });
    }

    const [lineItems] = GenerateLineItems(
        JSON.stringify(issuance),
        JSON.stringify(division),
        JSON.stringify(permit)
    );
    return lineItems;
};

export const convertLineItemsToFeesMap = (fees) => {
    const feesMap = new Map();
    feesMap.set(types.RESERVATION_FEE, 0);
    feesMap.set(types.USE_FEE, 0);
    feesMap.set(types.ATTRIBUTE_FEE, 0);
    feesMap.set(types.ATTRIBUTE_FEE_DESCRIPTION, '');
    feesMap.set(types.NONREFUNDABLE_FEE, 0);

    fees.forEach((fee) => {
        if (
            fee.FeeType === feeTypes.get(types.CLIN_FEE) ||
            fee.FeeType === feeTypes.get(types.RESERVATION_FEE)
        ) {
            feesMap.set(
                types.RESERVATION_FEE,
                feesMap.get(types.RESERVATION_FEE) + fee.Quantity * fee.UnitPrice
            );
        }

        if (fee.FeeType === feeTypes.get(types.USE_FEE)) {
            feesMap.set(
                types.USE_FEE,
                feesMap.get(types.USE_FEE) + fee.Quantity * fee.UnitPrice
            );
        }

        if (fee.FeeType === feeTypes.get(types.ATTRIBUTE_FEE)) {
            feesMap.set(
                types.ATTRIBUTE_FEE,
                feesMap.get(types.ATTRIBUTE_FEE) + fee.Quantity * fee.UnitPrice
            );
            const description = fee.Description ? fee.Description : 'Other Fee';
            feesMap.set(types.ATTRIBUTE_FEE_DESCRIPTION, description);
        }

        if (fee.FeeType === feeTypes.get(types.NONREFUNDABLE_FEE)) {
            feesMap.set(
                types.NONREFUNDABLE_FEE,
                feesMap.get(types.NONREFUNDABLE_FEE) + fee.Quantity * fee.UnitPrice
            );
        }
    });

    feesMap.set(
        types.RESERVATION_FEE,
        convertCartValueToCents(feesMap.get(types.RESERVATION_FEE))
    );
    feesMap.set(types.USE_FEE, convertCartValueToCents(feesMap.get(types.USE_FEE)));
    feesMap.set(
        types.ATTRIBUTE_FEE,
        convertCartValueToCents(feesMap.get(types.ATTRIBUTE_FEE))
    );
    feesMap.set(
        types.NONREFUNDABLE_FEE,
        convertCartValueToCents(feesMap.get(types.NONREFUNDABLE_FEE))
    );
    return feesMap;
};

export const calculateRemainingIssuanceFee = (
    currentLineItems /* : [{UnitPrice: number, Quantity: number}] */ = [],
    amountPaid = 0
) => {
    let totalCost = currentLineItems.reduce(
        (total, fee) => total + fee.UnitPrice * fee.Quantity,
        0
    );

    // TODO: Remove convertCentsToCartValue once this.props.amountPaid is fixed
    totalCost -= convertCentsToCartValue(amountPaid);
    totalCost = convertCartValueToCents(totalCost);

    return totalCost;
};

export const getAmountDue = (issuance, division, permit, amountPaid) => {
    const lineItems = generateLineItems(issuance, division, permit);
    const amountDue = calculateRemainingIssuanceFee(lineItems, amountPaid);
    return amountDue;
};

export const isPermitPickUp = (entrances) => {
    const stations = filter(entrances, { is_issue_station: true });
    return stations.length > 0;
};

export const entranceHasParking = (entranceId, entrances) => {
    if (!entranceId || !entrances) return false;
    const entrance = entrances.find((e) => e.id === entranceId);
    if (get(entrance, 'has_parking')) {
        return true;
    }
    return false;
};

export const retrievePhoneNumberFromAccount = (account) => {
    if (!isEmpty(account.cell_phone)) {
        return account.cell_phone;
    }

    return get(account, 'home_phone', '');
};

export const scrollUp = ({ top = 0, left = 0 } = {}) =>
    ScrollToHelper.scrollTo(top, left);

export const canApplyPass = (permit) => {
    return (
        hasRule('LocalPass', permit.rules) &&
        get(permit, 'components.group_with_fees_and_dates')
    );
};

export const getDefaultPass = (permit) => {
    return canApplyPass(permit) ? types.ADULT_YOUTH_PASS : 0;
};

export const isItineraryPermit = (permit, divisionId, permitMapping = null) => {
    // this is NOT used to redirect to monorepo at the moment (it is legacy only)
    const isMonoRepoItineraryPermit = permitMapping
        ? types.IS_ITINERARY_PERMIT_V2(permit?.id, permitMapping)
        : types.IS_ITINERARY_PERMIT(permit?.id);

    if (isMonoRepoItineraryPermit) return true;

    return (
        readRuleOperation(
            types.MEMBER_AVAILABILITY_DAILY_KEY_NAME,
            permit.rules,
            divisionId
        ) === types.RULE_OP_FIXED_ITINERARY_MULTI_LOWER_DESCHUTES ||
        readRuleOperation(
            types.MEMBER_AVAILABILITY_KEY_NAME,
            permit.rules,
            divisionId
        ) === types.RULE_OP_FIXED_VALUE_NIGHT_MEMBER_ITINERARY ||
        hasRule(types.FLEXIBLE_ITINERARY_ALLOWED, permit.rules) ||
        hasRule(types.ITINERARY_START_NEXT_DAY, permit.rules)
    );
};

export const isEntryDateOnly = (rules, divisions) => {
    // quota rules are by division, so we need to use division to check if the rule exists
    if (divisions.length) {
        return (
            hasRule(types.GROUP_AVAILABILITY_KEY_NAME, rules, divisions[0]) ||
            hasRule(types.MEMBER_AVAILABILITY_KEY_NAME, rules, divisions[0])
        );
    }
    // no divisions!?
    return false;
};

export const getPermitSection = (permit, divisionId) => {
    if (permit.permit_sections) {
        const foundSection = get(permit, 'permit_sections', []).find((section) => {
            const foundDivision = get(section, 'division_ids', []).find(
                (d) => divisionId === d
            );
            return foundDivision !== undefined;
        });
        return get(foundSection, 'name');
    }
    return null;
};

export const shouldIncrementCheckoutDate = (rules = [], divs = [], permitId) => {
    if (permitId === types.GRAND_TETON_ID || permitId === types.PAMELIA_ID) {
        // this is a dirty ugly hack, but I just can't find a way to bypass this for Grand Teton without altering its quota rules
        // grand teton has FixedValueNightsItinerary rule, with exit date being last day in area, and not night
        // same situation is occuring with pamelia
        return false;
    }

    let startNextDay;
    startNextDay = divs.find((div) => {
        if (!startNextDay) {
            startNextDay = readRuleAsBoolean(types.ITINERARY_START_NEXT_DAY, rules, div);
        }
        if (startNextDay) {
            return true;
        }

        // check if ANY rules with this division ID have either of the following operations:
        const nightRules = rules.filter(
            (rule) =>
                (rule.division_id === div || rule.division_id === '') &&
                (rule.operation === types.RULE_OP_FIXED_VALUE_NIGHT_ITINERARY ||
                    rule.operation === types.RULE_OP_FIXED_VALUE_NIGHT_BY_MEMBER ||
                    rule.operation === types.RULE_OP_FIXED_VALUE_NIGHT_MEMBER_ITINERARY)
        );
        return !!nightRules.length;
    });
    if (startNextDay) {
        return true;
    }
    return false;
};

export const NoQuotaMods = (
    <p>
        The current date is past the change window. No quota modifications are possible at
        this time.
    </p>
);

export const isCampsiteAssigned = (campsiteReservations) => {
    let isAssigned = false;
    if (campsiteReservations) {
        Object.keys(campsiteReservations).forEach((date) => {
            if (!isEmpty(campsiteReservations[date])) {
                isAssigned = true;
            }
        });
    }
    return isAssigned;
};

export const isMobile = {
    Android() {
        return window.navigator.userAgent.match(/Android/i);
    },
    BlackBerry() {
        return window.navigator.userAgent.match(/BlackBerry/i);
    },
    iOS() {
        return window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera() {
        return window.navigator.userAgent.match(/Opera Mini/i);
    },
    Windows() {
        return window.navigator.userAgent.match(/IEMobile/i);
    },
    any() {
        return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        );
    },
};

export const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const printWithAnchor = (url, fileName) => {
    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.download = fileName;

    downloadLink.href = url;
    downloadLink.style.display = 'none';

    document.body.append(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
};

const printWithIframe = (url) => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    iframe.addEventListener(
        'load',
        () => {
            setTimeout(() => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
            }, 0);
        },
        false
    );

    iframe.setAttribute('src', url);
};

// eslint-disable-next-line consistent-return
export const printBlob = (blob, fileName = 'file.pdf') => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE opens blobs this way:
        if (fileName) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            window.navigator.msSaveOrOpenBlob(blob);
        }
    } else {
        // Other browsers use iframe printing:
        const url = window.URL.createObjectURL(blob);

        if (isMobile.any()) {
            if (
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPhone/i)
            ) {
                //Safari & Opera iOS
                printWithAnchor(url, fileName);
            } else {
                window.open(url, '_blank');
            }
            return;
        }

        if (isFirefox()) {
            printWithAnchor(url, fileName);
        } else {
            printWithIframe(url);
        }
    }
};

export const getMaxMemberQuota = ({ permit, issuance }) => {
    if (SecurityHelper.isCommercialAccount()) {
        const offset = hasRule(
            types.GUIDES_COUNT_MAX_GROUP_SIZE_KEY_NAME,
            permit.rules,
            get(issuance, 'division_id')
        )
            ? get(issuance, 'guides.length', 0)
            : 0;
        const val = readRuleValue(
            types.MAX_GROUP_SIZE_COMMERCIAL_KEY_NAME,
            permit.rules,
            get(issuance, 'division_id')
        );
        if (val) {
            return val - offset > 0 ? val - offset : 0;
        }
    }

    const maxMemberCount = readRuleValue(
        types.MAX_GROUP_SIZE_KEY_NAME,
        permit.rules,
        get(issuance, 'division_id')
    );
    return maxMemberCount || 99;
};

// Takes original rules, removes date/time rules from list, add the new ones back in w/ spread.
export const tempUpdatePermitWithAggSeasonRules = (permit, aggSeasonRules) => {
    const oldRules = get(permit, 'rules', []) ? permit.rules : [];
    remove(oldRules, (rule) => types.DATE_TIME_RULES.includes(rule.name));
    return { ...permit, rules: [...oldRules, ...aggSeasonRules] };
};

const AccessInfoPermits = {
    [types.TILLY_JANE_ID]: true,
};

// show access info only for permits in AccessInfoPermits map
export const showAccessInfo = (permitId) => {
    return !!AccessInfoPermits[permitId];
};

export const getParameterByName = () => {
    const url = window.location.href;
    const regex = new RegExp(`[?&]end_date(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;

    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

// Divisions used for commercial lotteries
export const commercialDivisionList = {
    [types.HALF_DOME_ID]: types.HALF_DOME_COMMERCIAL_DIVISION_IDS,
};

// Divisions used for seasonal private lotteries
export const seasonalPrivateDivisionList = {
    [types.HALF_DOME_ID]: [types.HALF_DOME_PRIVATE_DIVISION_ID],
};

// Default division used for private lotteries
export const privateDefaultDivisionList = {
    [types.HALF_DOME_ID]: types.HALF_DOME_PRIVATE_DIVISION_ID,
};

// // Default division used for daily lotteries
export const dailyDefaultDivisionList = {
    [types.HALF_DOME_ID]: types.HALF_DOME_DAILY_DIVISION_ID,
};

export const dailyDivisionList = {
    [types.HALF_DOME_ID]: [types.HALF_DOME_DAILY_DIVISION_ID],
};

// selectable daily divisions for lottery preference division selector
export const getSelectableDailyDivisions = (divisions = {}) => {
    return Object.values(divisions)
        .filter((division) => {
            return dailyDivisionList[division.permit_id]
                ? dailyDivisionList[division.permit_id].includes(division.id)
                : true;
        })
        .filter((division) => division.is_active)
        .map((division) => ({ value: division.id, label: division.name }));
};

// Selectable divisions for lottery preference division selector
export const getSelectableSeasonalDivisions = (
    divisions = {},
    isCommercialUser = false
) => {
    if (isCommercialUser) {
        return Object.values(divisions)
            .filter((division) =>
                commercialDivisionList[division.permit_id].includes(division.id)
            )
            .map((division) => ({ value: division.id, label: division.name }));
    }

    return Object.values(divisions)
        .filter((division) => {
            return seasonalPrivateDivisionList[division.permit_id]
                ? seasonalPrivateDivisionList[division.permit_id].includes(division.id)
                : true;
        })
        .filter((division) => division.is_active)
        .map((division) => ({ value: division.id, label: division.name }));
};

// Get default division for lottery preference
export const getDefaultPreferenceDivision = (permitId, isCommercialUser = false) => {
    if (!isCommercialUser) {
        return privateDefaultDivisionList[permitId] || '';
    }
    return '';
};

export const getClassName = (error, displayError) => {
    if (error && displayError) {
        return 'rec-error';
    }
    return '';
};

export const isParkingPermit = (permitId) => {
    const parkingPermits = [];
    return parkingPermits.includes(permitId) && false; // R1S-43239: force falsey now that fossil creek doesn't want vehicle list
};

export const shouldRenderGroupMemberList = ({ components = {} }) => {
    const {
        group_with_fees_and_dates: groupFeesDates,
        group_with_fees_only: groupFeesOnly,
        group_with_birthdates: groupBirthDates,
        group_with_age: groupAge,
        group_member_list: groupList,
        member_counter_ruby: memberCounterRuby,
        member_counter: memberCounter,
        simple_group_list: simpleGroupList,
    } = components;

    return (
        groupFeesDates ||
        groupFeesOnly ||
        groupBirthDates ||
        groupAge ||
        groupList ||
        memberCounterRuby ||
        memberCounter ||
        simpleGroupList
    );
};

// this function is used to take a lottery preference and decide how to format the dates within it, returns a string
export const formatPreferredDates = (pref) => {
    let formattedDates = '';
    const invalidDate = '1970-12-31';
    if (moment(pref.preferred_date).isBefore(invalidDate, 'year')) {
        const prefStart = moment(pref.preferred_range_start).utc();
        const prefEnd = moment(pref.preferred_range_end).utc();
        if (prefStart.isSame(prefEnd)) {
            formattedDates = prefStart.format('ddd, MMM DD, YYYY');
        } else {
            formattedDates = `${prefStart.format('ddd, MMM DD, YYYY')} - ${prefEnd.format('ddd, MMM DD, YYYY')}`;
        }
    } else {
        formattedDates = moment(pref.preferred_date).utc().format('ddd, MMM DD, YYYY');
    }
    return formattedDates;
};

// Used to capatilize the first letter of a word
export const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const stayLimitRuleInDays = (rules) =>
    Number(
        readRuleValue(types.STAY_LIMIT_RULE_NAME, rules, RULE_NOT_SPECIFIC_TO_DIVISION) /
            types.DAYS_TO_SECS
    );

export const getYearsForSelect = (startYear) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= startYear; i -= 1) {
        years.push({ value: i, label: i });
    }
    return years;
};

export const getYearsForTabs = (startYear, endYear) => {
    const currentYear = endYear || new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= startYear; i -= 1) {
        years.push(i);
    }
    return years;
};
